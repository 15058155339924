import React, { useState } from 'react';
import { TextField, Button, Typography, Box, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useTranslation } from "react-i18next";
import { useService } from 'contexts/ServiceContextProvider';
import "../../css/main.css";

const NewTicketForm = ({ user, my_workspace }) => {
    const { t } = useTranslation();
    const { showSnackbar } = useSnackbar();
    const { CreateNewTicket } = useService();  

    const [title, setTitle] = useState('');
    const [issueType, setIssueType] = useState('');
    const [message, setMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const priority = "under_evaluation";

    const issueOptions = [
        { value: 'features', label: t('support_form.issue_features') },
        { value: 'vacations', label: t('support_form.issue_vacations') },
        { value: 'calendar', label: t('support_form.issue_calendar') },
        { value: 'performance', label: t('support_form.issue_performance') },
        { value: 'bug_report', label: t('support_form.issue_bug') },
        { value: 'other', label: t('support_form.issue_other') }
    ];

    const validateForm = () => {
        let errors = {};
        if (!title) errors.title = t('support_form.title_required');
        if (!issueType) errors.issueType = t('support_form.issue_type_required');
        if (!message) errors.message = t('support_form.message_required');
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);
    
        if (Object.keys(errors).length === 0) {
            try {
                setSubmitted(true); 
    
                const response = await CreateNewTicket({
                    user_name: user.full_name,   
                    user_email: user.email,      
                    workspace_id: my_workspace.id, 
                    title: title,               
                    description: message,       
                    issue_type: issueType,     
                    priority: priority          
                });
    
                if (response) {
                    showSnackbar(t('support_form.success_message'), 'success');
    
                    setTitle('');
                    setIssueType('');
                    setMessage('');
    
                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1000);
                }
            } catch (error) {
                showSnackbar(t('support_form.error_message'), 'error');
            } finally {
                setSubmitted(false);  
            }
        } else {
            showSnackbar(t('support_form.error_message'), 'error');
        }
    };
    

    return (
        <Box>
            <Typography variant="h4" gutterBottom>{t("support_form.contact_us")}</Typography>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={t("support_form.name")}
                            value={user.full_name}
                            margin="normal"
                            InputProps={{ 
                                readOnly: true,
                                className: "white-txt-color"
                            }}
                            InputLabelProps={{
                                className: "white-txt-color"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={t("support_form.email")}
                            value={user.email}
                            margin="normal"
                            InputProps={{ 
                                readOnly: true,
                                className: "white-txt-color"
                            }}
                            InputLabelProps={{
                                className: "white-txt-color"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={t("support_form.workspace")}
                            value={my_workspace.name}
                            margin="normal"
                            InputProps={{ 
                                readOnly: true,
                                className: "white-txt-color"
                            }}
                            InputLabelProps={{
                                className: "white-txt-color"
                            }}
                        />
                    </Grid>
                </Grid>

                <TextField
                    fullWidth
                    variant="outlined"
                    label={t("support_form.title")}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                    margin="normal"
                    InputProps={{ className: "white-txt-color" }}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                />

                {/* Issue Type Selection */}
                <FormControl fullWidth margin="normal" error={!!formErrors.issueType}>
                    <InputLabel className="white-txt-color">{t("support_form.issue_type")}</InputLabel>
                    <Select
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}
                        label={t("support_form.issue_type")}
                        className="white-txt-color"
                        inputProps={{
                            className: "white-txt-color",
                        }}
                        MenuProps={{
                            PaperProps: {
                                className: "request-vacation-type-select",
                            },
                        }}
                    >
                        {issueOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                    {formErrors.issueType && <Typography variant="caption" className="white-txt-color">{formErrors.issueType}</Typography>}
                </FormControl>

                <TextField
                    fullWidth
                    variant="outlined"
                    label={t("support_form.message")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    error={!!formErrors.message}
                    helperText={formErrors.message}
                    margin="normal"
                    multiline
                    rows={6}
                    InputProps={{ className: "white-txt-color" }}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                />

                <Box mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitted}
                        className="button-light-bg"
                    >
                        {t("support_form.submit")}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default NewTicketForm;

import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { languages } from '../utils/LanguageImporter';
import "../css/views/SettingsPage.css";

const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('EN');
  const [selectedValue2, setSelectedValue2] = useState('English');
  useEffect(() => {
    document.title = t('titles.app_settings');
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      handleOptionClick(savedLanguage);
    } else if (i18n.language) {
      handleOptionClick(i18n.language.substring(0, 2).toUpperCase());
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    const languageCode = languages[language] ? language : 'EN';
    const languageName = languages[languageCode] ? languages[languageCode] : 'English';
    setSelectedValue(languageCode);
    setSelectedValue2(languageName);
    localStorage.setItem('selectedLanguage', languageCode);
    localStorage.setItem('selectedLanguage2', languageName);
    handleClose();
  };

  const dropdownOptions = Object.entries(languages).filter(
    ([code]) => code !== selectedValue
  );

  return (
    <Box className="main-box-padding-settings">
      <Typography className="select-language-settings">
        {t("settings.lng_select")}
      </Typography>
      
      <Button
        onClick={handleClick}
        variant="contained"
        className="select-language-button-settings"
      >
        <Box>
          <Typography variant="subtitle1" className="selected-language-settings">
            {selectedValue}
          </Typography>
          <Typography variant="caption" className="selected-language-ext-settings">
            {selectedValue2}
          </Typography>
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="language-selection-menu-settings"
      >
        {dropdownOptions.map(([code, name]) => (
          <MenuItem
            key={code}
            onClick={() => handleOptionClick(code)}
            className="language-item-menu-settings"
          >
            <Box className="language-selection-menu-content-settings">
              <Typography variant="body2" className="selected-language-settings">{code} - </Typography>
              <Typography variant="body2" className="language-name-settings">{name}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SettingsPage;
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../css/components/Modals/CreateNewResource.css";
import "../../css/main.css";

export default function NewResourceModal({ openResourceModal, handleResourceModalClose, handleCreateResource, avoidMisfire, myUserPerms }) {
    const { t } = useTranslation();

    // States for form fields
    const [title, setTitle] = useState("");
    const [hyperlink, setHyperlink] = useState("");
    const [visibility, setVisibility] = useState(true);  // Default to 'public' (true)

    const handleSaveResource = () => {
        if (title.trim() === "" || hyperlink.trim() === "") {
            // If Title or Hyperlink is empty, prevent saving
            return;
        }

        // Call handleCreateResource function with title, hyperlink, and visibility (boolean)
        handleCreateResource(title, hyperlink, visibility);
    };

    return (
        <Dialog open={openResourceModal} onClose={handleResourceModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.resource_title")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label={t("calendar.event_title")}
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                    InputProps={{
                        className: "white-txt-color"
                    }}
                />
            </DialogContent>
            <DialogTitle>{t("modal.resource_hyperlink")}</DialogTitle>
            <DialogContent>
                <TextField                    
                    margin="dense"
                    id="hyperlink"
                    label={t("modal.resource_hyperlink")}
                    type="text"
                    fullWidth
                    value={hyperlink}
                    onChange={(e) => setHyperlink(e.target.value)}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                    InputProps={{
                        className: "white-txt-color"
                    }}
                />
            </DialogContent>

            {/* Conditionally render the visibility dropdown for admin/owner */}
            { (myUserPerms.is_owner || myUserPerms.is_admin) && (
                <DialogContent>
                    <FormControl className="create-resource-form">
                        <InputLabel id="visibility-label" className="white-txt-color">
                            {t("global.visibility")}
                        </InputLabel>
                        <Select
                            labelId="visibility-label"
                            id="visibility"
                            value={visibility}
                            onChange={(e) => setVisibility(e.target.value === 'true')}
                            MenuProps={{
                                PaperProps: {
                                    className: "create-resource-select",
                                },
                            }}
                        >
                            <MenuItem value={'true'}>{t("global.public")}</MenuItem>
                            <MenuItem value={'false'}>{t("global.private")}</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
            )}

            <DialogActions>
                <Button onClick={handleResourceModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleSaveResource} disabled={avoidMisfire || title.trim() === "" || hyperlink.trim() === ""} className="button-dark-bg">
                    {t("modal.resource_save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

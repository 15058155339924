import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import {
  CircularProgress,

} from '@mui/material';
const WorkspacesContext = createContext();

export const useWorkspaces = () => useContext(WorkspacesContext);

export const WorkspacesProvider = ({ children }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const { accessToken, authAxios} = useAuthorization();
  const [loading, setLoading] = useState(true);

  

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
          const response = await authAxios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}workflow/workspaces/`,
            headers: {
              "Authorization": "Bearer " + accessToken
            }
          });
          
          setWorkspaces(response.data);
          setLoading(false);
      
      } 
    };

    fetchData();
  }, [accessToken, authAxios]);


  const updateDBOnLastWorkspaceAccessed = async (workspace_id) => {
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `workflow/workspaces/${workspace_id}/workspaceAccess/`,

        { workspace: workspace_id }, 
        { headers: { "Authorization": "Bearer " + accessToken } } 
      );
    } catch (error) {
      console.error('Error creating Workspace Access:', error.message);
    }
  };

  const handleWorkspaceChange = async (id, load_index_page = true) => {
    //const workspaceIndex = workspaces.findIndex(workspace => workspace.id === id);
    await updateDBOnLastWorkspaceAccessed(id);
    if (load_index_page){
      window.location.href = ("/workflow/index");
    }
  };

  if (loading) {
    <CircularProgress />
  }
  return (
    <WorkspacesContext.Provider value={{ workspaces, setWorkspaces, handleWorkspaceChange }}>
      {children}
    </WorkspacesContext.Provider>
  );
};
import CustomCookieConsent from "../components/Custom/CustomCookieConsent.jsx";
import React from 'react';
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import routes from "routes.jsx";
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import { useSidebar } from 'contexts/SidebarContextProvider';
import "../css/layouts/Workflow.css";

const Workflow = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();
    const { isOpen} = useSidebar();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);




    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/workflow") {
                return (
                    <Route path={prop.path} element={prop.component} key={key} exact />
                );
            } else {
                return null;
            }
        });
    };

    const testFunction = () => {

    };


    return (
        <>
            <div className="workflow-container" >
                <Sidebar handleFunction={testFunction} />
                <div className="content-container" style={{ backgroundColor: "#3D393B", height: '100vh'}}>
                    <div ref={mainContent} className={isOpen ? "workflow-main-content" : "workflow-main-content-closed-bar"}>
                        <CustomCookieConsent />
                        <Routes>
                            {getRoutes(routes)}
                            <Route path="*" element={<Navigate to="/workflow/index" replace />} />
                        </Routes>
                    </div>


                </div>
            </div>
        </>)
}


export default Workflow;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Avatar,
  ListItemAvatar,
  ListItemText,
  List,
  ListItem,
  Alert,
  InputAdornment,
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import { useService } from "contexts/ServiceContextProvider";
import { useProjects } from 'contexts/ProjectsContextProvider';
import {useTranslation} from "react-i18next";
import '../../../css/components/Calendar/Events/CreateEventsModal.css';
import '../../../css/main.css';


import { useWorkspaces } from 'contexts/WorkspacesContextProvider';

const CreateEventModal = ({ open, onClose, workspaceId }) => {

  const { t } = useTranslation();
  const { getProjectWorkers, getWorkspaceWorkers, createWorkspaceEvent } = useService();
  const { projects: myProjects } = useProjects();

  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;

  const [workers, setWorkers] = useState([]);
  const [workspaceWorkers, setWorkspaceWorkers] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [manualSelectedWorkers, setManualSelectedWorkers] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [colorCode, setColorCode] = useState("#3788d8");
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({}); 
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (open) {
      setSelectedWorkers([]);
      setManualSelectedWorkers([]);
      setWorkers([]);
      setErrorMessage('');
      setFieldErrors({});
      const fetchWorkspaceWorkers = async () => {
        const workspaceWorkersData = await getWorkspaceWorkers(workspaceId);
        setWorkspaceWorkers(workspaceWorkersData);
      };
      fetchWorkspaceWorkers();
  
      const currentYear = new Date().getFullYear();
      const defaultStartDate = new Date();
      const defaultEndDate = new Date();
      defaultStartDate.setFullYear(currentYear);
      defaultEndDate.setFullYear(currentYear);
  
      setDateStart(defaultStartDate.toISOString().substring(0, 16));  
      setDateEnd(defaultEndDate.toISOString().substring(0, 16));    
    }
  }, [open, workspaceId, getWorkspaceWorkers]);
  

  useEffect(() => {
    if (!open) {
      setTitle('');
      setDescription('');
      setDateStart('');
      setDateEnd('');
      setAllDayEvent(false);
      setVisibility(false);
      setColorCode("#3788d8");
      setSelectedWorkers([]);
      setManualSelectedWorkers([]);
      setWorkers([]);
      setSelectedProject('');
      setFieldErrors({});
      setErrorMessage('');
    }
  }, [open]);

  const handleProjectChange = async (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);

    try {
      const projectWorkers = await getProjectWorkers( myWorkspaces[0].id ,projectId);

      const filteredProjectWorkers = projectWorkers.filter(
        (worker) => !selectedWorkers.includes(worker.id) && !manualSelectedWorkers.includes(worker.id)
      );

      setWorkers(filteredProjectWorkers);
      setSelectedWorkers((prevSelectedWorkers) => [
        ...prevSelectedWorkers,
        ...filteredProjectWorkers.map((worker) => worker.id),
      ]);
    } catch (error) {
      console.error(t("calendar.project_workers_err"), error);
    }
  };

  const handleWorkerToggle = (workerId) => {
    if (manualSelectedWorkers.includes(workerId)) {
      setManualSelectedWorkers((prevSelectedWorkers) =>
        prevSelectedWorkers.filter((id) => id !== workerId)
      );
    } else {
      setManualSelectedWorkers((prevSelectedWorkers) => [
        ...prevSelectedWorkers,
        workerId,
      ]);
    }
  };

  const handleRemoveCollaborator = (workerId) => {
    if (selectedWorkers.includes(workerId)) {
      setSelectedWorkers((prevSelectedWorkers) =>
        prevSelectedWorkers.filter((id) => id !== workerId)
      );
    } else if (manualSelectedWorkers.includes(workerId)) {
      setManualSelectedWorkers((prevSelectedWorkers) =>
        prevSelectedWorkers.filter((id) => id !== workerId)
      );
    }
  };

  const handleCreateEvent = async () => {
    if (!title || !dateStart || !dateEnd || (selectedWorkers.length === 0 && manualSelectedWorkers.length === 0)) {
      setErrorMessage(t("calendar.req_fields_err"));
      return;
    }

    const eventData = {
      title,
      description,
      date_start: dateStart,
      date_end: dateEnd,
      all_day_event: allDayEvent,
      visibility,
      collaborators: [...selectedWorkers, ...manualSelectedWorkers],
      color_code: colorCode,
    };

    try {
      await createWorkspaceEvent(eventData, workspaceId);
      onClose();
    } catch (error) {
      if (error.response && error.response.data) {
        const errors = error.response.data;
        const newFieldErrors = {};

        Object.keys(errors).forEach((field) => {
          if (Array.isArray(errors[field])) {
            newFieldErrors[field] = errors[field][0];  
          } else {
            newFieldErrors[field] = errors[field]; 
          }
        });

        setFieldErrors(newFieldErrors);
      } else {
        console.error("Error creating event:", error);
        setErrorMessage(t("calendar.unexpected_err"));
      }
    }
  };

  // Filter collaborators based on search term
  const filteredCollaborators = [
    ...workers.filter((worker) => selectedWorkers.includes(worker.id)),
    ...workspaceWorkers.filter((worker) => manualSelectedWorkers.includes(worker.id)),
  ].filter(worker => worker.full_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const availableWorkspaceWorkers = workspaceWorkers.filter(
    (worker) => !filteredCollaborators.some((selectedWorker) => selectedWorker.id === worker.id)
  ).filter(worker => worker.full_name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="create-event-main-style">
        <Typography variant="h5" gutterBottom className="create-event-title">
          {t("calendar.create_event")}
        </Typography>
        {errorMessage && (
          <Alert severity="error" className="create-event-error">
            {errorMessage}
          </Alert>
        )}
        <Grid container spacing={2}>
          {/* Title */}
          <Grid item xs={12}>
            <TextField
              label={t('calendar.event_title')}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('calendar.event_title_placeholder')}
              error={!!fieldErrors.title}
              helperText={fieldErrors.title}
              className="create-event-title-text"
              InputLabelProps={{
                shrink: true,
                className: "create-event-title"
              }}
              InputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              multiline
              label={t('calendar.event_description')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="create-event-title-text"
              InputLabelProps={{
                shrink: true,
                className: "create-event-title"
              }}
              InputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>

          {/* Date and Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              type={allDayEvent ? "date" : "datetime-local"}
              label={t('global.start_date')}
              value={dateStart}
              onChange={(e) => setDateStart(e.target.value)}
              error={!!fieldErrors.date_start}
              helperText={fieldErrors.date_start}
              className="create-event-title-text"
              InputLabelProps={{
                shrink: true,
                className: "create-event-title"
              }}
              InputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type={allDayEvent ? "date" : "datetime-local"}
              label={t('calendar.event_end_date')}
              value={dateEnd}
              onChange={(e) => setDateEnd(e.target.value)}
              error={!!fieldErrors.date_end}
              helperText={fieldErrors.date_end}
              className="create-event-title-text"
              InputLabelProps={{
                shrink: true,
                className: "create-event-title"
              }}
              InputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>

          {/* All Day and Visibility Checkboxes */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allDayEvent}
                      onChange={(e) => setAllDayEvent(e.target.checked)}
                      className="create-event-title"
                    />
                  }
                  label={t('calendar.event_all_day')}
                  className="create-event-title"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibility}
                      onChange={(e) => setVisibility(e.target.checked)}
                      className= "create-event-title"
                    />
                  }
                  label={t('calendar.event_public')}
                  className="create-event-title"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Project Dropdown */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel className="create-event-title">{t("calendar.event_select_project")}</InputLabel>
              <Select
                value={selectedProject}
                onChange={handleProjectChange}
                label={t('calendar.event_select_project')}
                className="create-event-title-text"
                inputProps={{
                  className: "white-txt-color"
                }}
              >
                {myProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Color Picker */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('calendar.event_color')}
              value={colorCode}
              onChange={(e) => setColorCode(e.target.value)}
              type="color"
              className="create-event-title-text"
            />
          </Grid>

          {/* Search Collaborators */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('calendar.event_collab_search')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="create-event-title-text create-event-search-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="create-event-title" />
                  </InputAdornment>
                ),
                className: "white-txt-color"
              }}
              InputLabelProps={{ className: "create-event-title" }}
            />
          </Grid>

          {/* Collaborators */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className= "create-event-title">
              {t('calendar.event_collabs')}
            </Typography>
            <Box className="create-event-collaborators-box">
              <List>
                {filteredCollaborators.map((worker) => (
                  <ListItem
                    key={worker.id}
                    className={`create-event-collab-list-default ${selectedWorkers.includes(worker.id) ? 'create-event-collab-list-selected' : ''}`}
                    button
                    onClick={() => handleRemoveCollaborator(worker.id)}
                  >
                    <ListItemAvatar>
                      <Avatar src={process.env.REACT_APP_BACKEND_URL + worker.image} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={worker.full_name}
                      className={`create-event-title ${selectedWorkers.includes(worker.id) ? 'white-txt-color' : ''}`}
                    />
                    <RemoveCircleIcon className="create-event-remove-collab-icon" />
                  </ListItem>
                ))}
              </List>
            </Box>
            {fieldErrors.collaborators && (
              <Typography variant="body2" color="error">
                {fieldErrors.collaborators}
              </Typography>
            )}
          </Grid>

          {/* Add more workers */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className="create-event-title">
              {t('calendar.event_add_collabs')}
            </Typography>
            <Box className="create-event-collaborators-box">
              <List>
                {availableWorkspaceWorkers.map((worker) => (
                  <ListItem
                    key={worker.id}
                    button
                    onClick={() => handleWorkerToggle(worker.id)}
                  >
                    <ListItemAvatar>
                      <Avatar src={process.env.REACT_APP_BACKEND_URL + worker.image} />
                    </ListItemAvatar>
                    <ListItemText primary={worker.full_name} className="create-event-title" />
                    <AddCircleIcon className="create-event-add-collab-icon" />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateEvent}
              className="button-dark-bg"
            >
              {t('calendar.create_event')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateEventModal;

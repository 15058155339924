import React from 'react';
import { Card, CardContent, Typography, LinearProgress, Box, Divider } from '@mui/material';
import { useTranslation } from "react-i18next";
import "../../css/components/Custom/RecentProjectStatus.css";
import "../../css/main.css";

const RecentProjectStatus = ({ projectData }) => {
    const { t } = useTranslation();

    const { project_name, lists } = projectData;

    const toDoList = lists.find(list => list.title === "To Do");
    const doingList = lists.find(list => list.title === "Doing");
    const doneList = lists.find(list => list.title === "Done");

    const totalTasksToDoAndDoing = (toDoList?.task_count || 0) + (doingList?.task_count || 0);
    const totalTasksDoing = doingList?.task_count || 0;
    const totalTasksDone = doneList?.task_count || 0;

    const totalTasks = totalTasksToDoAndDoing + totalTasksDone;

    const weightForDoing = 0.5;
    const weightedDoneTasks = totalTasksDone + (totalTasksDoing * weightForDoing);
    const overallProgress = (weightedDoneTasks / totalTasks) * 100;

    const getColorForProgress = (progress) => {
        const colors = [
            { progress: 0, color: '#ff0000' },
            { progress: 50, color: '#ff8000' },
            { progress: 80, color: '#ffff00' },
            { progress: 100, color: '#00ff00' }
        ];

        if (progress === 100) return colors[colors.length - 1].color;

        let startColor, endColor;
        for (let i = 0; i < colors.length - 1; i++) {
            if (progress >= colors[i].progress && progress < colors[i + 1].progress) {
                startColor = colors[i];
                endColor = colors[i + 1];
                break;
            }
        }

        if (!startColor || !endColor) return '#000000';

        const startProgress = startColor.progress;
        const endProgress = endColor.progress;
        const startRGB = parseInt(startColor.color.slice(1), 16);
        const endRGB = parseInt(endColor.color.slice(1), 16);
        const interpolateColor = (c1, c2, p) => Math.round(c1 + (c2 - c1) * (p / 100));
        const red = interpolateColor((startRGB >> 16) & 0xff, (endRGB >> 16) & 0xff, (progress - startProgress) / (endProgress - startProgress) * 100);
        const green = interpolateColor((startRGB >> 8) & 0xff, (endRGB >> 8) & 0xff, (progress - startProgress) / (endProgress - startProgress) * 100);
        const blue = interpolateColor(startRGB & 0xff, endRGB & 0xff, (progress - startProgress) / (endProgress - startProgress) * 100);
        return `rgb(${red}, ${green}, ${blue})`;
    };

    return (

        <>
            <Typography variant="h6" color="primary" sx={{mt:1}} gutterBottom>{t("profile.last_accessed_project")}</Typography><Divider className="recent-project-divider" />
            <Typography variant="subtitle1" gutterBottom>{t("profile.last_accessed_project")} {project_name}</Typography><Box className="recent-project-progress">
                <Typography variant="subtitle2" className="white-txt-color">Doing / To Do</Typography>
                <LinearProgressWithLabel
                    value={totalTasksDoing / Math.max(totalTasksToDoAndDoing, 1) * 100}
                    total={totalTasksToDoAndDoing}
                    completed={totalTasksDoing} />
            </Box>
            <Box className="recent-project-progress">
                <Typography variant="subtitle2" className="white-txt-color">Done</Typography>
                <LinearProgressWithLabel
                    value={totalTasksDone / totalTasks * 100}
                    total={totalTasks}
                    completed={totalTasksDone} />
            </Box><Typography variant="subtitle2" color="#FFF">{t("custom.overall_progress")}</Typography><LinearProgress
                variant="determinate"
                value={overallProgress}
                className="recent-project-linear-progress"
                sx={{
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: getColorForProgress(overallProgress)
                    }
                }} />
            <Typography variant="body2" className="white-txt-color">{`${t("custom.progress")} ${overallProgress.toFixed(2)}%`}</Typography>
        </>
    );
};

const LinearProgressWithLabel = ({ value, total, completed }) => {
    return (
        <Box className="recent-project-progress-label">
            <LinearProgress variant="determinate" value={value} className="recent-project-overall-progress" />
            <Typography variant="body2" className="white-txt-color">{`${completed} / ${total === 0 ? "Total" : total} (${value.toFixed(0)}%)`}</Typography>
        </Box>
    );
};

export default RecentProjectStatus;

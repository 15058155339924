import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css";

export default function DeleteListModal({ openDeleteListModal, handleDeleteListModalClose, handleDeleteList, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openDeleteListModal} onClose={handleDeleteListModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {t("modal.list_delete")}<br />
                {t("modal.list_no_return")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteListModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleDeleteList} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
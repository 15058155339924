import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import "../../css/components/Custom/CustomCookieConsent.css";

const CustomCookieConsent = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="top"
      buttonText={t("login.ok")}
      cookieName="workflowCookie"
      buttonClasses="cookies-button"
      containerClasses="cookies-container"
      expires={750}

    >
      {t("login.consent")}
    </CookieConsent>
  );
};

export default CustomCookieConsent;

import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Paper, Grid, Tabs, Tab } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import EuroIcon from '@mui/icons-material/Euro';
import {useTranslation} from "react-i18next";
import "../../css/components/Modals/PurchasePlanModal.css";
import "../../css/main.css";

const PurchasePlanModal = ({ open, onClose, plan, extras, onAddExtras, handleOpenCheckOut,currentWorkspace }) => {
    const { t } = useTranslation();

    const [selectedExtras, setSelectedExtras] = useState([]);
    const [value, setValue] = useState(0);
    const [newMembersTotal, setNewMembersTotal] = useState(plan ? plan.amount_members : 0);
    const [newStorageTotal, setNewStorageTotal] = useState(plan ? Number(plan.storage).toFixed(2) : 0);
    const [newPriceTotal, setNewPriceTotal] = useState(plan ? plan.price : 0);

    useEffect(() => {
        if (plan) {
            setSelectedExtras([]);
        }
    }, [plan]);

    useEffect(() => {
        if (selectedExtras.length > 0) {
            let totalMembers = plan.amount_members;
            let totalStorage = plan.storage;
            let totalPrice = plan.price;

            selectedExtras.forEach(({ extra, quantity }) => {
                totalMembers += extra.amount_members * quantity;
                totalStorage += extra.storage * quantity;
                totalPrice += extra.price * quantity;
            });

            setNewMembersTotal(totalMembers);
            setNewStorageTotal(totalStorage);
            setNewPriceTotal(totalPrice);
        } else {
            setNewMembersTotal(plan.amount_members);
            setNewStorageTotal(plan.storage);
            setNewPriceTotal(plan.price);
        }
    }, [selectedExtras, plan]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleConfirmAddExtras = () => {
        handleOpenCheckOut(plan, selectedExtras);
        onClose();
    };

    const handleIncrementExtra = (extra) => {
        setSelectedExtras((prevSelected) => {
            const existingExtra = prevSelected.find(e => e.extra.id === extra.id);
            if (existingExtra) {
                // Only increment if there is no other extra with features already selected
                const hasOtherFeatureExtra = prevSelected.some(e => e.extra.features.length > 0 && e.extra.id !== extra.id);
                if (!hasOtherFeatureExtra) {
                    return prevSelected.map(e =>
                        e.extra.id === extra.id ? { ...e, quantity: e.quantity + 1 } : e
                    );
                }
                // Prevent adding if another feature extra is already selected
                return prevSelected;
            } else {
                // Only add if there is no other extra with features already selected
                const hasOtherFeatureExtra = prevSelected.some(e => e.extra.features.length > 0);
                if (!hasOtherFeatureExtra || extra.features.length === 0) {
                    return [...prevSelected, { extra, quantity: 1 }];
                }
                // Prevent adding if another feature extra is already selected
                return prevSelected;
            }
        });
    };

    const handleDecrementExtra = (extra) => {
        setSelectedExtras((prevSelected) => {
            const existingExtra = prevSelected.find(e => e.extra.id === extra.id);
            if (existingExtra && existingExtra.quantity > 1) {
                return prevSelected.map(e =>
                    e.extra.id === extra.id ? { ...e, quantity: e.quantity - 1 } : e
                );
            } else {
                return prevSelected.filter(e => e.extra.id !== extra.id);
            }
        });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="purchase-plan-modal"
        >
            <Box
                className="purchase-plan-box"
            >
                <Tabs value={value} onChange={handleChange} aria-label="purchase plan tabs">
                    <Tab className="project-txt-color" label={t("modal.plan_details")} />
                    <Tab className="project-txt-color" label={t("modal.plan_extras")} />
                    <Tab className="project-txt-color" label={t("modal.plan_checkout")} />
                </Tabs>
                <Box className="purchase-plan-box-padding">
                    {value === 0 && (
                        <Box>
                            <Typography variant="h5">{t("modal.plan_details")}</Typography>
                            {plan && (
                                <Paper elevation={3} className="purchase-plan-paper">
                                    <Typography variant="h6">{plan.name}</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_members")} {plan.amount_members}</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_storage")} {Number(plan.storage).toFixed(2)} GB</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_price")} {plan.price}€</Typography>
                                    <Typography variant="body2" gutterBottom>{plan.description}</Typography>
                                </Paper>
                            )}
                        </Box>
                    )}
                    {value === 1 && (
                        <Box>
                            <Typography variant="h5">{t("modal.plan_extras")}</Typography>
                            {extras && extras.length > 0 && (
                                <Grid container spacing={2}>
                                    {extras
                                        .filter(extra => {
                                            // Get the features of the selected plan and the current extra
                                            const planFeatures = new Set(plan.features.map(feature => feature.id));
                                            const extraFeatures = new Set(extra.features.map(feature => feature.id));

                                            // Check if any feature in the extra is already in the plan
                                            const hasCommonFeatures = [...extraFeatures].some(featureId => planFeatures.has(featureId));

                                            // Only include the extra if it doesn't share any features with the plan
                                            return !hasCommonFeatures;
                                        })
                                        .map(extra => {
                                            const selectedExtra = selectedExtras.find(e => e.extra.id === extra.id);
                                            const quantity = selectedExtra ? selectedExtra.quantity : 0;

                                            return (
                                                <Grid item xs={12} key={extra.id}>
                                                    <Paper
                                                        elevation={3}
                                                        className={`purchase-plan-grid-item ${quantity > 0 ? 'purchase-plan-disabled' : ''}`}
                                                    >
                                                        <Typography variant="body1">{extra.name}</Typography>
                                                        <Typography variant="body2">{t("workspace_settings.plan_price")} {extra.price}€</Typography>
                                                        {extra.amount_members > 0 && <Typography variant="body2">{t("modal.extra_members")} {extra.amount_members}</Typography>}
                                                        {extra.storage > 0 && <Typography variant="body2">{t("modal.extra_storage")} {Number(extra.storage).toFixed(2)} GB</Typography>}

                                                        <Box className="purchase-plan-extra-actions">
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => handleDecrementExtra(extra)}
                                                                disabled={quantity === 0}
                                                            >
                                                                -
                                                            </Button>
                                                            <Typography variant="body2" className="purchase-plan-extra-counter">
                                                                {quantity}
                                                            </Typography>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => handleIncrementExtra(extra)}
                                                                disabled={quantity === 1 && extra.features.length>0}
                                                            >
                                                                +
                                                            </Button>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            )}
                        </Box>
                    )}
                    {value === 2 && (
                        <Box>
                            <Typography variant="h5">{t("modal.plan_checkout")}</Typography>
                            <Typography variant="body1">{t("modal.confirm_checkout")}</Typography>
                            {currentWorkspace?.active_plan?.price > 0 &&(
                                <Typography variant="body1" className="purchase-plan-price-info"><InfoIcon/> {t("modal.plan_buy_info")}</Typography>
                            )}
                        </Box>
                    )}
                </Box>
                <Box className="purchase-plan-confirm-buttons">
                    <Button variant="contained" color="secondary" onClick={onClose} className="purchase-plan-button">{t("global.cancel")}</Button>
                    {value === 2 && (
                        <>
                            <Button variant="contained" className="purchase-plan-button" color="primary" onClick={handleConfirmAddExtras}>{t("modal.confirm_checkout_button")}</Button>
                        </>
                    )}
                </Box>

                <Box className="purchase-plan-button">
                    <Typography variant="h5">{t("modal.plan_updated")}</Typography>
                    <Box
                        className="purchase-plan-summary"
                    >
                        <Paper elevation={3} className="purchase-plan-summary-paper purchase-plan-summary-paper-group">
                            <GroupIcon className="purchase-plan-summary-icon-group" />
                            <Typography variant="body1" className="purchase-plan-black-txt">{t("modal.members_title")} <b>{newMembersTotal}</b></Typography>
                        </Paper>

                        <Paper elevation={3} className="purchase-plan-summary-paper purchase-plan-summary-paper-storage">
                            <StorageIcon className="purchase-plan-summary-icon-storage"/>
                            <Typography variant="body1"  className="purchase-plan-black-txt">{t("workspace_settings.plan_storage")} <b>{Number(newStorageTotal).toFixed(2)} GB</b></Typography>
                        </Paper>

                        <Paper elevation={3} className="purchase-plan-summary-paper purchase-plan-summary-paper-price">
                            <EuroIcon className="purchase-plan-summary-icon-price"/>
                            <Typography variant="body1"  className="purchase-plan-black-txt">{t("workspace_settings.plan_price")} <b>{newPriceTotal}€</b></Typography>
                        </Paper>
                    </Box>
                    <Typography variant="body2" className="purchase-plan-no-features">{t("modal.updated_features")}</Typography>
                    <>
                        {(selectedExtras && selectedExtras.length > 0) || (plan && plan.features.length > 0) ? (
                            <>
                                {selectedExtras && selectedExtras.length > 0 && (
                                    <>

                                        <ul>
                                            {selectedExtras.map(extra => (
                                                <>
                                                    {extra.extra.features?.map(feature => (
                                                        <li key={feature.id}>
                                                            <Typography variant="body2">{feature.name}</Typography>
                                                        </li>
                                                    ))}
                                                </>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                {plan && plan.features.length > 0 && (
                                    <>
                                        <ul>
                                            <>
                                                {plan.features?.map(feature => (
                                                    <li key={feature.id}>
                                                        <Typography variant="body2">{feature.name}</Typography>
                                                    </li>
                                                ))}
                                            </>
                                        </ul>
                                    </>
                                )}
                            </>

                        ): (
                            <>
                                <Typography variant="body2">{t("modal.no_features")}</Typography>
                            </>
                        )}
                    </>

                </Box>

                <Box className="purchase-plan-button">
                    <Typography variant="h5">{t("modal.current_details")}</Typography>
                    <Box
                        className="purchase-plan-summary"
                    >
                        <Paper elevation={3} className="purchase-plan-summary-paper">
                            <GroupIcon />
                            <Typography variant="body1">{currentWorkspace?.workers?.length}/{currentWorkspace?.active_plan?.amount_members}</Typography>
                        </Paper>

                        <Paper elevation={3} className="purchase-plan-summary-paper">
                            <StorageIcon />
                            <Typography variant="body1">{t("workspace_settings.plan_storage")} {Number(currentWorkspace?.storage_size).toFixed(2)}/{Number(currentWorkspace?.active_plan?.storage).toFixed(2)} GB</Typography>
                        </Paper>

                        <Paper elevation={3} className="purchase-plan-summary-paper">
                            <EuroIcon />
                            <Typography variant="body1">{t("workspace_settings.plan_price")} {currentWorkspace?.active_plan?.price}€</Typography>
                        </Paper>
                    </Box>
                </Box>

            </Box>
        </Modal>
    );
};

export default PurchasePlanModal;

import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import { Modal, Box, Typography, Avatar, Grid, Tooltip, List, ListItem, ListItemAvatar, ListItemText, IconButton } from "@mui/material";
import SubjectIcon from '@mui/icons-material/Subject'; 
import { useService } from "contexts/ServiceContextProvider";
import {useTranslation} from "react-i18next";
import "../../../css/components/Calendar/Events/ViewEventsModal.css";

const ViewEventsModal = ({ open, onClose, workspaceId }) => {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const [descriptionVisibility, setDescriptionVisibility] = useState({});
  const [showRightHeader, setShowRightHeader] = useState(window.innerWidth >  700)
  const { getWorkspaceEvents } = useService();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >  700) {
        setShowRightHeader(true);
      } else {
        setShowRightHeader(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (open) {
      const fetchEvents = async () => {
        try {
          const eventsData = await getWorkspaceEvents(workspaceId);

          const formattedEvents = eventsData.map((event) => ({
            id: event.id,
            title: event.title,
            start: event.all_day_event ? event.date_start.split("T")[0] : event.date_start,
            end: event.all_day_event ? event.date_end.split("T")[0] : event.date_end,
            allDay: event.all_day_event,
            description: event.description,
            backgroundColor: event.color_code,
            collaborators: event.collaborators,
          }));

          setEvents(formattedEvents);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
      fetchEvents();
    }
  }, [open, getWorkspaceEvents, workspaceId]);

  const toggleDescriptionVisibility = (eventId) => {
    setDescriptionVisibility((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId], 
    }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="view-events-main-styles">
        <Typography variant="h5" gutterBottom>
            {t("calendar.event_view")}
        </Typography>
        <FullCalendar
          plugins={[listPlugin]}
          initialView="listMonth"
          events={events}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: showRightHeader ? 'prev,next today' : false,
          }}
          height="auto"
          locale={t("global.lng")}
          buttonText={{
              today: t("calendar.today")
          }}
          eventContent={(eventInfo) => {
            const collaborators = eventInfo.event.extendedProps.collaborators || [];
            const description = eventInfo.event.extendedProps.description || '';
            const isDescriptionVisible = descriptionVisibility[eventInfo.event.id] || false; 

            return (
              <Grid container className="view-events-content-grid">
                <Box className="view-events-list-flex">
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item className="view-events-title-padding">
                      <Typography>{eventInfo.event.title}</Typography>
                    </Grid>
                    {/* Icon to toggle the description */}
                    {description && (
                      <Grid item className="view-events-description-padding">
                        <IconButton
                          size="small"
                          onClick={() => toggleDescriptionVisibility(eventInfo.event.id)}
                          aria-label="Toggle Description"
                          
                        >
                          <SubjectIcon  />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  {description && isDescriptionVisible && (
                    <Box className="view-events-description">
                      <Typography variant="body2" >{description}</Typography>
                    </Box>
                  )}
                </Box>

                {collaborators.length > 0 && (
                  <Tooltip
                    title={
                      <List className="view-events-collab-tooltip">
                        <Typography variant="subtitle2">Collaborators:</Typography>
                        {collaborators.map((collaborator) => (
                          <ListItem key={collaborator.id}>
                            <ListItemAvatar>
                              <Avatar
                                src={collaborator.image}
                                alt={collaborator.full_name}
                                className="view-events-tooltip-avatar"
                              />
                            </ListItemAvatar>
                            <ListItemText primary={collaborator.full_name} />
                          </ListItem>
                        ))}
                      </List>
                    }
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    placement="bottom-start" 
                    arrow 
                  >
                    <Grid item container spacing={1} className="view-events-collab-grid">
                      {collaborators.map((collaborator) => (
                        <Grid item key={collaborator.id}>
                          <Avatar
                            src={collaborator.image}
                            alt={collaborator.full_name}
                            className="view-events-avatar"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            );
          }}
          noEventsContent={t("calendar.no_events")}
        />
      </Box>
    </Modal>
  );
};

export default ViewEventsModal;

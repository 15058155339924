import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Box,
    IconButton,
    Typography,
    Divider,
    Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useProjects } from 'contexts/ProjectsContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';

import ProfileCard from 'components/Profile/ProfileCard';
import PersonalInformation from 'components/Profile/PersonalInformation';
import WorkflowInformation from 'components/Profile/WorkflowInformation';
import PlatformInformation from 'components/Profile/PlatformInformation';
import ResetPasswordModal from 'components/Profile/ResetPassword';
import WorkingHours from 'components/Profile/WorkingHours';

import SkeletonProfileCard from 'components/Profile/Skeletons/SkeletonProfileCard';
import SkeletonPersonalInformation from 'components/Profile/Skeletons/SkeletonPersonalInformation';
import SkeletonWorkflowInformation from 'components/Profile/Skeletons/SkeletonWorkflowInformation';
import SkeletonPlatformInformation from 'components/Profile/Skeletons/SkeletonPlatformInformation';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useTranslation } from "react-i18next";
import '../css/views/ProfilePage.css';

/* eslint-disable react-hooks/exhaustive-deps */

const ProfilePage = () => {
    useEffect(() => {
        document.title = t('titles.profile');
      }, []);
    const { t } = useTranslation();
    const [user, setUser] = useState('');
    const [userStatData, setUserStatData] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const { updateWorkspaceJob, GetUserLastAccessedProjects, updateUserWorkflowWorkingHours, getWorkingHours, updateUserProfileImage, updateUserPassword, getUserProfile, updateUserPersonalInfo, updateUserWorkflowInfo, getUserStatData, getUserTimeZone, getLoggedUserPermissions, calculateBoardStats } = useService();

    const [myUserTimezone, setMyUserTimezone] = useState();
    const { getLoggedUser } = useAuthorization();
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    const [myUserPerms, setMyUserPerms] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [hasAccessToLastProject, setHasAccessToLastProject] = useState(null);
    const [workingHours, setWorkingHours] = useState([]);
    const [isPersonalInfoEditable, setIsPersonalInfoEditable] = useState(false);
    const [isWorkflowInfoEditable, setIsWorkflowInfoEditable] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [personalInfoChanged, setPersonalInfoChanged] = useState(false);
    const [workflowInfoChanged, setWorkflowInfoChanged] = useState(false);

    const [loadingUserProfileCard, setLoadingUserProfileCard] = useState(true);
    const [loadingPlatformInformation, setLoadingPlatformInformation] = useState(true);
    const [loadingRecentProjectStatusWrapper, setLoadingRecentProjectStatusWrapper] = useState(true);
    const [loadingPersonalInformation, setLoadingPersonalInformation] = useState(true);
    const [loadingWorkflowInformation, setLoadingWorkflowInformation] = useState(true);

    const [isWorkflowHoursEditable, setIsWorkflowHoursEditable] = useState(false);


    const projectsContext = useProjects();
    const myProjects = projectsContext.projects;

    const { showSnackbar } = useSnackbar();

    const getUserIDFromNavBar = () => {
        const path = window.location.pathname;
        const parts = path.split('/');
        const user_id = parts[parts.length - 1];
        return user_id;
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const user_id = getUserIDFromNavBar();
                const userProfile = await getUserProfile(user_id, myWorkspaces[0]?.id);


                setUser(userProfile);
                setIsEditable(getLoggedUser() === user_id);
                const myTime = await getUserTimeZone(getLoggedUser());
                setMyUserTimezone(myTime);

                setLoadingUserProfileCard(false);
                setLoadingPersonalInformation(false);
                setLoadingWorkflowInformation(false)
            } catch (error) {
                showSnackbar(t("profile.fetch_user_fail"), 'error');
            }
        };
        if (myWorkspaces[0]?.id) {
            fetchUserProfile();
        }
    }, [myWorkspaces]);

    useEffect(() => {
        const fetchUserPerms = async () => {
            if (myWorkspaces[0]?.id) {
                try {
                    const userPerms = await getLoggedUserPermissions(myWorkspaces[0].id, getLoggedUser());
                    setMyUserPerms(userPerms);

                    const MyWorkTime = await getWorkingHours(myWorkspaces[0].id, getUserIDFromNavBar());


                    setWorkingHours(MyWorkTime.working_hours_events || []);

                } catch (error) {
                    showSnackbar(t("profile.fetch_user_perms_fail"), 'error');
                }
            }
        };
        fetchUserPerms();
    }, [myWorkspaces]);

    useEffect(() => {
        const fetchUserStatData = async () => {
            try {
                const stats = await getUserStatData(getUserIDFromNavBar());
                setUserStatData(stats);
                setLoadingPlatformInformation(false);
            } catch (error) {
                showSnackbar(t("profile.fetch_user_stats_fail"), 'error');
            }
        };
        fetchUserStatData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
        if (isPersonalInfoEditable) {
            setPersonalInfoChanged(true);
        } else if (isWorkflowInfoEditable) {
            setWorkflowInfoChanged(true);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        try {
            await updateUserProfileImage(getUserIDFromNavBar(), file);
            showSnackbar(t("profile.profile_image_update"), 'success');
        } catch (error) {
            showSnackbar(t("profile.profile_image_update_fail"), 'error');
        }
    };

    useEffect(() => {
        if (!isPersonalInfoEditable && personalInfoChanged) {
            handleSavePersonalInfo();
            setPersonalInfoChanged(false);
        }
    }, [isPersonalInfoEditable]);

    useEffect(() => {
        if (!isWorkflowInfoEditable && workflowInfoChanged) {
            handleSaveWorkflowInfo();
            setWorkflowInfoChanged(false);
        }
    }, [isWorkflowInfoEditable]);

    const handleSavePersonalInfo = async () => {
        setLoading(true);
        const personalInfo = {
            full_name: user.full_name || '',
            username: user.username || '',
            email: user.email || '',
            phone: user.phone || '',
            address: user.address || ''
        };
        try {
            await updateUserPersonalInfo(getUserIDFromNavBar(), personalInfo);
            setLoading(false);
            showSnackbar(t("profile.personal_info_update"), 'success');
        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.personal_info_update_fail"), 'error');
        }
    };

    const handleSaveWorkflowInfo = async () => {
        setLoading(true);
        const workflowInfo = {
            timezone: user.timezone,
            timezone_offset: user.timezone_offset,
            location: user.location,
            job: user.job || ''
        };
        try {
            await updateUserWorkflowInfo(getUserIDFromNavBar(), workflowInfo);
            setLoading(false);
            showSnackbar(t("profile.workflow_info_update"), 'success');

        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.workflow_info_update_fail"), 'error');
        }
    };

    const handleSaveWorkingHours = async (updated_working_hours, workspace_working_hours) => {
        setLoading(true);
        try {
            await updateUserWorkflowWorkingHours(workspace_working_hours, updated_working_hours);
            setLoading(false);
            showSnackbar(t("profile.workspace_work_hours_update"), 'success');
            setIsWorkflowHoursEditable(false);

            setWorkingHours(updated_working_hours);

        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.workspace_work_hours_update_fail"), 'error');
        }
    };

    const handleUpdateWorkspaceJob = async (workspace_id, new_job) => {
        setLoading(true);
        try {
            await updateWorkspaceJob(workspace_id, new_job);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.workspace_work_hours_update_fail"), 'error');
        }
    };


    

    const handleSavePassword = async (password, confirmPassword) => {
        setLoading(true);
        if (password !== confirmPassword) {
            showSnackbar(t("profile.password_not_match"), 'error');
            setLoading(false);
            return;
        }
        if (password && (password.length < 8 || !isNaN(password))) {
            showSnackbar(t("profile.password_rules"), 'error');
            setLoading(false);
            return;
        }
        try {
            await updateUserPassword({ new_password: password });
            setLoading(false);
            showSnackbar(t("profile.password_update"), 'success');
            setResetPasswordModalOpen(false);
        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.password_update_fail"), 'error');
        }
    };




    const handleWorkingHoursChange = (index, field, value) => {
        const newWorkingHours = [...workingHours];
        newWorkingHours[index][field] = value;
        setWorkingHours(newWorkingHours);
        setWorkflowInfoChanged(true);
    };

    const addWorkingHoursSlot = (workspace) => {
        const newSlot = {
            days: [],
            start: '',
            end: '',
            workspace: workspace.name,
            workspace_id: workspace.id,
            job: workspace.job
        };
        setWorkingHours([...workingHours, newSlot]);
    };



    const removeWorkingHoursSlot = (index) => {
        if (workingHours) {
            const newWorkingHours = workingHours.filter((_, i) => i !== index);
            setWorkingHours(newWorkingHours);
            handleSaveWorkflowInfo(true);
        }
    };

    const calculateTotalWorkingHours = (workingHourGroups = null) => {
        let totalMinutes = 0;
        let validInputs = true;
        let hours_to_calculate = {};
        console.log(workingHourGroups);
    
        if (workingHourGroups) {
            const formattedHours = workingHourGroups.reduce((acc, group) => {
                acc.push({
                    days: group.days,  // Consider days as well
                    start: group.start,
                    end: group.end,
                });
                return acc;
            }, []);
            hours_to_calculate = formattedHours;
        } else {
            hours_to_calculate = workingHours;
        }
    
        // Calculate total working hours considering multiple days
        hours_to_calculate.forEach(slot => {
            const { start, end, days } = slot;
            
            if (!start || !end || !days || days.length === 0) {
                validInputs = false;
                return;
            }
    
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;
            const minutesPerDay = endTime - startTime;
    
            // Multiply the time slot by the number of days
            totalMinutes += minutesPerDay * days.length;
        });
    
        if (!validInputs) {
            return t("profile.notSetupHoursError");
        }
    
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    };
    

    const handleOpenResetPasswordModal = () => {
        setResetPasswordModalOpen(true);
    };

    const handleCloseResetPasswordModal = () => {
        setResetPasswordModalOpen(false);
    };

    return (
        <Box className="profile-page-background">
            <Container className="profile-container">
                <Grid container spacing={4} className="profile-grid-container">
                    {/* PROFILE CARD */}
                    <Grid item xs={12} lg={4}>
                        {loadingUserProfileCard ? <SkeletonProfileCard /> : (
                            <ProfileCard
                                user={user}
                                workingHours={workingHours}
                                isEditable={isEditable}
                                selectedFile={selectedFile}
                                handleFileChange={handleFileChange}
                                myUserTimezone={myUserTimezone}
                            />
                        )}
                        {loadingPlatformInformation ? <SkeletonPlatformInformation /> : (
                            <PlatformInformation userStatData={userStatData} />
                        )}

                        {isEditable && (
                            <Box className="profile-reset-password-button-container">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenResetPasswordModal}
                                    className="profile-reset-password-button"
                                    fullWidth
                                >
                                    {t('profile.reset_password')}
                                </Button>
                            </Box>
                        )}
                    </Grid>
                    {/* PERSONAL INFORMATION */}
                    <Grid item xs={12} lg={8}>
                        <Card className="profile-card">
                            <CardContent>
                                <Box className="profile-card-header">
                                    <Typography variant="h5" className="profile-card-title">{t('profile.personal_info')}</Typography>
                                    {isEditable && (
                                        <IconButton onClick={() => setIsPersonalInfoEditable(!isPersonalInfoEditable)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider className="profile-card-divider" />
                                {loadingPersonalInformation ? <SkeletonPersonalInformation /> : (
                                    <PersonalInformation
                                        user={user}
                                        isEditable={isPersonalInfoEditable}
                                        handleInputChange={handleInputChange}
                                        handleSave={handleSavePersonalInfo}
                                        loading={loading}
                                    />
                                )}
                            </CardContent>
                        </Card>

                        <Card className="profile-card">
                            <CardContent>
                                <Box className="profile-card-header">
                                    <Typography variant="h5" className="profile-card-title">{t('profile.workflow_info')}</Typography>
                                    {isEditable && (
                                        <IconButton onClick={() => setIsWorkflowInfoEditable(!isWorkflowInfoEditable)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider className="profile-card-divider" />
                                {loadingWorkflowInformation ? <SkeletonWorkflowInformation /> : (
                                    <WorkflowInformation
                                        user={user}
                                        isEditable={isWorkflowInfoEditable}
                                        handleInputChange={handleInputChange}
                                        handleSave={handleSaveWorkflowInfo}
                                        loading={loading}
                                    />
                                )}
                            </CardContent>
                        </Card>


                    </Grid>
                    <Grid item xs={12}>
                        <Card className="profile-card">
                            <CardContent>
                                <Box className="profile-card-header">
                                    <Typography variant="h5" className="profile-card-title">{t('profile.workspace_info')}</Typography>
                                    {isEditable && (
                                        <IconButton
                                            onClick={() => {
                                                setIsWorkflowHoursEditable(!isWorkflowHoursEditable);
                                            }}
                                        >
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>

                                <Divider className="profile-card-divider" />
                                {loadingWorkflowInformation ? <SkeletonWorkflowInformation /> : (
                                    <WorkingHours
                                        isEditable={isWorkflowHoursEditable}
                                        workingHoursByWorkspace={workingHours || []}
                                        setWorkingHours={setWorkingHours}
                                        handleWorkingHoursChange={handleWorkingHoursChange}
                                        addWorkingHoursSlot={addWorkingHoursSlot}
                                        removeWorkingHoursSlot={removeWorkingHoursSlot}
                                        calculateTotalWorkingHours={calculateTotalWorkingHours}
                                        handleSave={handleSaveWorkingHours}
                                        loading={loading}
                                        calculateBoardStats={calculateBoardStats}
                                        parsedData={parsedData}
                                        setParsedData={setParsedData}
                                        setLoadingRecentProjectStatusWrapper={setLoadingRecentProjectStatusWrapper}
                                        handleUpdateWorkspaceJob={handleUpdateWorkspaceJob}
                                        myUserPerms={myUserPerms}
                                        myProjects={myProjects}
                                        getLoggedUser={getLoggedUser}
                                        loadingRecentProjectStatusWrapper={loadingRecentProjectStatusWrapper}
                                        GetUserLastAccessedProjects={GetUserLastAccessedProjects}
                                        user_id={user.id}
                                        workspace_current={myWorkspaces[0].name}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <ResetPasswordModal
                open={resetPasswordModalOpen}
                onClose={handleCloseResetPasswordModal}
                onSave={handleSavePassword}
                loading={loading}
            />
        </Box>
    );
};

export default ProfilePage;

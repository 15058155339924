import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useParams } from "react-router-dom";
import { Typography, CardContent, Box, Menu, MenuItem, Tooltip, Divider, Paper, Card } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useSnackbar } from 'contexts/SnackbarContextProvider';

import CustomFallback from 'components/Custom/CustomFallBack';
import UnarchiveTaskModal from 'components/Modals/UnarchiveTask';
import UnarchiveListModal from 'components/Modals/UnarchiveList';
import DeleteTaskModal from 'components/Modals/DeleteTask';
import DeleteListModal from 'components/Modals/DeleteList';
import { useTranslation } from "react-i18next";

import '../css/views/ArchivePage.css';

const ArchivePage = () => {

    useEffect(() => {
        document.title = t('titles.project_archives'); 
    }, []);

    const { t } = useTranslation();

    const { projectId } = useParams();
    const { GetProjectBoardArchives, ArchiveTask, ArchiveList, fetchAvailableListsInBoard, getLoggedUserPermissions, UnarchiveTask, UnarchiveList, DeleteArchivedTasksInList } = useService();

    const [myBoard, setMyBoard] = useState(null);

    const [openDeleteTaskModal, setOpenDeleteTaskModal] = useState(false);
    const [openDeleteListModal, setOpenDeleteListModal] = useState(false);
    const [openUnarchiveTaskModal, setOpenUnarchiveTaskModal] = useState(false);
    const [openUnarchiveListModal, setOpenUnarchiveListModal] = useState(false);

    const { showSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);
    const [avoidMisfire, ] = useState(false);
    const [contextTaskMenuPosition, setContextTaskMenuPosition] = useState({ x: 0, y: 0 });
    const [contextListMenuPosition, setContextListMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedTask, setSelectedTask] = useState([null]);
    const [selectedList, setSelectedList] = useState([null]);
    const { getLoggedUser } = useAuthorization();
    const [myUserPerms, setMyUserPerms] = useState();
    const containerRef = useRef(null);
    const boxRef = useRef(null);
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const getProjectBoard = async () => {
            const response = await GetProjectBoardArchives(myWorkspaces[0]?.id, projectId);
            const boardData = response.data;
            setMyBoard(boardData);
            setIsLoading(false);
        };
        if (myWorkspaces[0]?.id) {
            getProjectBoard();
        }
    }, [myWorkspaces]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchUserPerms = async () => {
            if (myWorkspaces[0]?.id) {
                try {
                    const userPerms = await getLoggedUserPermissions(myWorkspaces[0]?.id, getLoggedUser());
                    setMyUserPerms(userPerms);
                } catch (error) {
                    console.error('Error fetching user permissions:', error);
                }
            }
        };
        fetchUserPerms();
    }, [myWorkspaces[0]?.id]);


    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    });


    const handleDeleteListModalOpen = () => {
        setOpenDeleteListModal(true);
    };

    const handleDeleteTaskModalClose = () => {
        setOpenDeleteTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleUnarchiveTaskModalOpen = () => {
        setOpenUnarchiveTaskModal(true);
    };

    const handleUnarchiveTaskModalClose = () => {
        setOpenUnarchiveTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleUnarchiveListModalOpen = () => {
        setOpenUnarchiveListModal(true);
    };

    const handleUnarchiveListModalClose = () => {
        setOpenUnarchiveListModal(false);
        handleListCloseMenu();
    };

    const handleUnarchiveTask = async (task_id) => {
        try {
            await UnarchiveTask(myWorkspaces[0].id, myBoard.project, task_id);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists.forEach(list => {
                list.tasks = list.tasks.filter(task => task.id !== task_id);
            });
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.unarchive_task_info'), 'success');
        } catch (error) {
            showSnackbar(t('archives.unarchive_task_fail'), 'error');
        }
        handleTaskCloseMenu();
    };

    const handleDeleteArchivedTasksInList = async (listId) => {
        try {
            await DeleteArchivedTasksInList(myWorkspaces[0].id, myBoard.project, listId);

            const updatedBoard = { ...myBoard };

            // Find the list by its ID
            const listIndex = updatedBoard.lists.findIndex(list => list.id === listId);
            if (listIndex > -1) {
                // Remove tasks where is_deleted is true (archived)
                updatedBoard.lists[listIndex].tasks = updatedBoard.lists[listIndex].tasks.filter(task => !task.is_deleted);
            }

            // Update the state with the modified board
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.delete_all_archived_tasks_success'), 'success');
        } catch (error) {
            console.error('Error deleting archived tasks:', error);
            showSnackbar(t('archives.delete_all_archived_tasks_fail'), 'error');
        }
        handleListCloseMenu(); // Close the context menu
    };

    const handleUnarchiveList = async (list_id) => {
        try {
            await UnarchiveList(myWorkspaces[0].id, myBoard.project, list_id);
            const updatedBoard = { ...myBoard };
            const unarchivedListIndex = updatedBoard.lists.findIndex(list => list.id === list_id);
            if (unarchivedListIndex > -1) {
                updatedBoard.lists[unarchivedListIndex].is_deleted = false;
                updatedBoard.lists[unarchivedListIndex].tasks.forEach(task => task.is_deleted = false);
            }
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.unarchive_list_info'), 'success');
        } catch (error) {
            showSnackbar(t('archives.unarchive_list_fail'), 'error');
        }
        handleListCloseMenu();
    };

    const handleDeleteTask = async () => {
        try {
            await ArchiveTask(myWorkspaces[0].id, myBoard.project, selectedList, selectedTask);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists.forEach(list => {
                list.tasks = list.tasks.filter(task => task.id !== selectedTask.id);
            });
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.archive_task'), 'success');
        } catch (error) {
            showSnackbar(t('archives.archive_task_fail'), 'error');
        }
        handleDeleteTaskModalClose();
    };

    const handleDeleteList = async () => {
        try {
            await ArchiveList(myWorkspaces[0].id, myBoard.project, selectedList);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists = updatedBoard.lists.filter(list => list.id !== selectedList.id);
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.archive_list'), 'success');
        } catch (error) {
            showSnackbar(t('archives.archive_list_fail'), 'error');
        }
        handleDeleteListModalClose();
    };

    const handleTaskRightClick = (event, task, list) => {
        event.preventDefault();
        event.stopPropagation();
        setContextTaskMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedTask(task);
        setSelectedList(list);
    };

    const handleTaskCloseMenu = () => {
        setContextTaskMenuPosition({ x: 0, y: 0 });
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const handleListRightClick = (event, list) => {
        if (event.target.classList.contains('task')) {
            return;
        }
        setContextListMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedList(list);
    };

    const handleListCloseMenu = () => {
        setContextListMenuPosition({ x: 0, y: 0 });
    };

    const handleDeleteListModalClose = () => {
        setOpenDeleteListModal(false);
        handleListCloseMenu();
    };

    const getOverArchingBoxClass = (isDeleted) => {
        return `
        overarching-box-archive
        ${isDeleted ? 'deleted-box-archive' : 'default-box-archive'}
    `;
    };

    const handleDeleteTaskModalOpen = () => {
        setOpenDeleteTaskModal(true);
    };

    return (
        <>
            {isLoading ? (
                <CustomFallback />
            ) : (
                <>
                    <div ref={containerRef} className="custom-container-archive">
                        <div className="container-archive">
                            <Card className="custom-card-archive">
                                <CardContent className="custom-card-content-archive">
                                    <div className="centered-flex-archive">
                                        <div className="flex-row-archive">
                                            <div className="circle-archive circle-unarchived-archive"></div>
                                            <Typography variant="body2" className="typography-unarchived-archive">
                                                {t('archives.unarchived_list')}
                                            </Typography>
                                            <div className="circle-archive circle-archived-archive"></div>
                                            <Typography variant="body2" className="typography-archived-archive">
                                                {t('archives.archived_list')}
                                            </Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>



                        <div className="flex-container-archive">
                            <div className="my-lists-class-archive">
                                {myBoard.lists.map((list) => (
                                    <Box
                                        key={list.id}
                                        className={getOverArchingBoxClass(list.is_deleted)}
                                    >
                                        <CardContent
                                            onContextMenu={(event) => handleListRightClick(event, list)}
                                            className="card-content-archive"
                                        >
                                            <Typography variant="h5" gutterBottom className="typography-h5-archive">
                                                        <Box className="box-list-title-archive">
                                                            <Box className="box-text-overflow-archive">
                                                                <Tooltip title={list.title}>
                                                                    <Typography
                                                                        noWrap
                                                                        // This was my solution to the actual size of the titles, based on screen size..
                                                                        className="typography-title-archive"
                                                                    >
                                                                        {list.title}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                            {(list.title === "Done" || list.title === "Doing" || list.title === "To Do") && (
                                                                <Tooltip title="This list cannot be deleted or edited">
                                                                    <LockIcon className="lock-icon-archive" />
                                                                </Tooltip>
                                                            )}
                                                        </Box>
                                            </Typography>
                                            <Box ref={boxRef} className="task-box-archive">
                                                {list?.tasks?.map((task) => (
                                                    <Paper
                                                        key={task.id}
                                                        className="task-archive"
                                                        elevation={4}
                                                        onContextMenu={(event) => handleTaskRightClick(event, task, list)}
                                                    >
                                                        <div className="task-title-archive"><b>#{task.frontend_order}</b> - {task.title}</div>
                                                    </Paper>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Box>
                                ))}

                            </div>
                        </div>
                    </div>

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0
                                ? { top: contextTaskMenuPosition.y, left: contextTaskMenuPosition.x }
                                : undefined
                        }
                        open={contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0}
                        onClose={handleTaskCloseMenu}
                        PaperProps={{
                            className: "menu-paper-archive"
                        }}
                    >

                    {[
                            <MenuItem disableRipple key="task-title">
                                <Box className="ellipsis-box-archive">

                                {selectedTask ? selectedTask.title : 'No task selected'}
                                </Box>
                            </MenuItem>,
                            <Divider className="custom-divider-archive" key="divider" />,
                            (myUserPerms?.is_admin || myUserPerms?.is_owner) && (
                                <MenuItem onClick={() => handleDeleteTaskModalOpen()} key="archive-task">{t("archives.delete_task")}</MenuItem>
                            ),
                            <MenuItem key="deleteList" onClick={() => handleUnarchiveTaskModalOpen()} >{t("archives.unarchive_task")}</MenuItem>
                        ]}
                    </Menu>

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0
                                ? { top: contextListMenuPosition.y, left: contextListMenuPosition.x }
                                : undefined
                        }
                        open={contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0}
                        onClose={handleListCloseMenu}
                        PaperProps={{
                            className: "list-menu-paper-archive"
                        }}
                    >
                    {[
                            <MenuItem disableRipple key="list-title">
                                <Box className="custom-box-archive">
                                    {selectedList ? selectedList.title : t("global.no_list")}
                                </Box>
                            </MenuItem>,
                            <Divider className="custom-divider-archive" key="divider" />,
                            !(selectedList.title === "Doing" || selectedList.title === "To Do" || selectedList.title === "Done") && [
                                selectedList.is_deleted ? (
                                    <>
                                        <MenuItem key="unarchiveList" onClick={() => handleUnarchiveListModalOpen()} >{t("archives.unarchive_list")}</MenuItem>
                                        <MenuItem key="deleteList" onClick={() => handleDeleteListModalOpen()} >{t("archives.delete_list")}</MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem key="notArchived" disabled>{t("archives.list_not_archived")}</MenuItem>

                                        {selectedList?.tasks?.some(task => !task.is_archived) && (myUserPerms.is_admin || myUserPerms.is_owner) && (
                                            <MenuItem key="deleteAllWithinList" onClick={() => handleDeleteArchivedTasksInList(selectedList.id)}>
                                                {t("archives.delete_all_within_list")}
                                            </MenuItem>
                                        )}
                                    </>
                                )
                            ],
                        ]}
                    </Menu>


                </>
            )}
            <UnarchiveTaskModal openUnarchiveTaskModal={openUnarchiveTaskModal} handleUnarchiveTaskModalClose={handleUnarchiveTaskModalClose} handleUnarchiveTask={handleUnarchiveTask} selectedTask={selectedTask} selectedList={selectedList} availableLists={myBoard?.lists} board_id={myBoard?.id} fetchAvailableListsInBoard={fetchAvailableListsInBoard} avoidMisfire={avoidMisfire} />
            <UnarchiveListModal openUnarchiveListModal={openUnarchiveListModal} handleUnarchiveListModalClose={handleUnarchiveListModalClose} handleUnarchiveList={handleUnarchiveList} selectedList={selectedList} avoidMisfire={avoidMisfire} />
            <DeleteTaskModal openDeleteTaskModal={openDeleteTaskModal} handleDeleteTaskModalClose={handleDeleteTaskModalClose} handleDeleteTask={handleDeleteTask} avoidMisfire={avoidMisfire} />
            <DeleteListModal openDeleteListModal={openDeleteListModal} handleDeleteListModalClose={handleDeleteListModalClose} handleDeleteList={handleDeleteList} avoidMisfire={avoidMisfire} />
        </>
    );
};

export default ArchivePage;

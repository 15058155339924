import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {useTranslation} from "react-i18next";
import "../../css/components/Core/TaskDatesDialog.css";

const TaskDatesDialog = ({ open, onClose, task, handleSaveDates }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(task.start_date || null);
    const [dueDate, setDueDate] = useState(task.due_date || null);
    const [endDate, setEndDate] = useState(task.end_date || null);

    const [isStartDateNull, setIsStartDateNull] = useState(!task.start_date);
    const [isDueDateNull, setIsDueDateNull] = useState(!task.due_date);
    const [isEndDateNull, setIsEndDateNull] = useState(!task.end_date);

    const handleSave = () => {
        handleSaveDates({
            start_date: isStartDateNull ? null : startDate,
            due_date: isDueDateNull ? null : dueDate,
            end_date: isEndDateNull ? null : endDate
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="task-dates-main-dialog"
        >
            <DialogTitle className="task-dates-dialog-title">{t("core.dates")}</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={t("global.short_lng")}>
                    <Box className="task-dates-box">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isStartDateNull}
                                    onChange={(e) => setIsStartDateNull(e.target.checked)}
                                    className="task-dates-checkbox-control"
                                />
                            }
                            label={t("core.no_start_date")}
                            className="task-dates-checkbox-label"
                        />

                        <DateTimePicker
                            label={t("global.start_date")}
                            value={isStartDateNull ? null : dayjs(startDate)}
                            onChange={(newValue) => setStartDate(newValue)}
                            className="task-dates-datetime-picker"
                            disabled={isStartDateNull}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isDueDateNull}
                                    onChange={(e) => setIsDueDateNull(e.target.checked)}
                                    className="task-dates-checkbox-control"
                                />
                            }
                            label={t("core.no_due_date")}
                            className="task-dates-checkbox-label"
                        />

                        <DateTimePicker
                            label={t("core.due_date")}
                            value={isDueDateNull ? null : dayjs(dueDate)}
                            onChange={(newValue) => setDueDate(newValue)}
                            className="task-dates-datetime-picker"
                            disabled={isDueDateNull}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isEndDateNull}
                                    onChange={(e) => setIsEndDateNull(e.target.checked)}
                                    className="task-dates-checkbox-control"
                                />
                            }
                            label={t("core.no_end_date")}
                            className="task-dates-checkbox-label"
                        />

                        <DateTimePicker
                            label={t("calendar.event_end_date")}
                            value={isEndDateNull ? null : dayjs(endDate)}
                            onChange={(newValue) => setEndDate(newValue)}
                            className="task-dates-datetime-picker"
                            disabled={isEndDateNull}
                        />
                    </Box>
                </LocalizationProvider>
            </DialogContent>

            <DialogActions className="task-dates-dialog-actions">
                <Button onClick={onClose}>{t("global.cancel")}</Button>
                <Button onClick={handleSave}>{t("global.save")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TaskDatesDialog;

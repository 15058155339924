import React, { forwardRef } from 'react';
import Badge from '@mui/material/Badge';
import "../../css/components/Custom/StyledBadge.css";


const BadgeWrapper = forwardRef(({ status, children }, ref) => {
  return (
    <Badge
      ref={ref}
      status={status}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      className={`badge-style badge-style-${status}`}
    >
      {children}
    </Badge>
  );
});

export default BadgeWrapper;

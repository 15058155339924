import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { debounce } from 'lodash';
import "../../css/components/Modals/ListHeader.css";
import "../../css/main.css";

import { useService } from 'contexts/ServiceContextProvider';
import {useTranslation} from "react-i18next";
/* eslint-disable react-hooks/exhaustive-deps */
const ListHeaderModal = ({
    openListHeaderModal = false,
    handleListHeaderModalClose,
    List = {},
    workspace_id,
    project_id,
}) => {
    const { t } = useTranslation();

    const { updateListHeader } = useService();

    const [title, setTitle] = useState('');
    const [color, setColor] = useState('');
    const [emoji, setEmoji] = useState('');
    const [textColor, setTextColor] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [debouncedColor, setDebouncedColor] = useState('');
    const [titleError, setTitleError] = useState('');
    const [imageError, setImageError] = useState('');

    useEffect(() => {

        if (List.header) {
            setTitle(List.header.title);
            setColor(List.header.color || '#000000'); // Default to black if color is not provided
            setEmoji(List.header.emoji || '');
            setTextColor(List.header.title_color || '#000000'); // Default to black if title_color is not provided
            if (List.header.image) {
                setImagePreview(`${process.env.REACT_APP_BACKEND_URL}${List.header.image}`);
                setImageFile(null); // Clear the file input if the image is loaded from the server
            } else {
                setImageFile(null);
                setImagePreview('');
            }
            setDebouncedColor(List.header.color || ''); // Set debouncedColor to the initial color
        } else {
            setTitle('');
            setColor('#000000'); // Default black color
            setEmoji('');
            setTextColor('#000000');
            setImageFile(null);
            setImagePreview('');
            setDebouncedColor(''); // Reset debouncedColor
        }
    }, [List]);

    const updateDebouncedColor = useCallback(
        debounce((colorValue) => {
            setDebouncedColor(colorValue);
        }, 300),
        []
    );

    const handleColorChange = (e) => {
        const newColor = e.target.value;
        setColor(newColor);
        updateDebouncedColor(newColor);
    };

    const handleTextColorChange = (e) => {
        setTextColor(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.name.length > 100) {
                setImageError(t("modal.img_err"));
                return;
            }
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
            setImageError('');
        }
    };

    const handleRemoveImage = () => {
        setImageFile(null);
        setImagePreview('');
        document.getElementById("raised-button-file").value = "";
    };

    const handleSave = useCallback(async () => {
        const headerData = {
            emoji,
            title,
            title_color: textColor,
            color: debouncedColor,
        };
        if (imageFile) {
            headerData.image = imageFile; 
        }

        try {
            const response = await updateListHeader(workspace_id, project_id, List.id, headerData);

            if (imageFile) {
                List.header = {
                    title: response.data.title,
                    color: response.data.color,
                    emoji: response.data.emoji,
                    title_color: response.data.title_color || '#000000', 
                    image: response.data.image || List.header.image, 
                };
            } else {
                List.header = {
                    title: response.data.title,
                    color: response.data.color,
                    emoji: response.data.emoji,
                    title_color: response.data.title_color || '#000000', // Default to black if title_color is null
                    image: null
                };

            }

            handleListHeaderModalClose(response.data);
        } catch (error) {
            console.error("Error saving header:", error);
        }
    }, [title, debouncedColor, emoji, textColor, imageFile, handleListHeaderModalClose, List, updateListHeader]);

    const handleReset = () => {
        setTitle('');
        setColor('#000000');
        setEmoji('');
        setTextColor('#000000');
        setImageFile(null);
        setImagePreview('');
        setDebouncedColor('');
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        if (newTitle.length <= 18) {
            setTitle(newTitle);
            setTitleError('');
        } else {
            setTitleError(t("modal.title_err"));
        }
    };

    const onEmojiSelect = useCallback((emoji) => {
        setEmoji(emoji.native);
        setShowEmojiPicker(false);
    }, []);

    return (
        <Modal
            open={openListHeaderModal}
            onClose={() => handleListHeaderModalClose()}
            aria-labelledby="list-header-modal-title"
            aria-describedby="list-header-modal-description"
        >
            <Box className="list-header-modal-box">
                <Typography id="list-header-modal-title" variant="h6" component="h2">
                    {List.header ? t("modal.header_edit") : t("modal.header_create")}
                </Typography>
                <Box className="list-header-flex">
                    <TextField
                        label={t("calendar.event_title")}
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        margin="normal"
                        className="list-header-text-field"
                        error={!!titleError}
                        helperText={titleError}
                    />
                    <Box className="list-header-color-box">
                        <Typography variant="body1" className="list-header-text-color-margin">{t("modal.text_color")}</Typography>
                        <input
                            type="color"
                            value={textColor}
                            onChange={handleTextColorChange}
                            className="list-header-color-input"
                        />
                    </Box>
                </Box>
                {!imagePreview && (
                    <TextField
                        label={t("modal.header_color")}
                        value={color}
                        onChange={handleColorChange}
                        fullWidth
                        margin="normal"
                        type="color"
                        className="list-header-text-field"
                    />
                )}
                <input
                    accept="image/*"
                    className="list-header-file-input"
                    id="raised-button-file"
                    type="file"
                    onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" className="button-dark-bg list-header-upload-button">
                        {t("modal.upload_image")}
                    </Button>
                </label>
                {imagePreview && (
                    <div className="image-container-list-header">
                        <img src={imagePreview} alt="Selected" className="preview-image-size image-border" />
                        <div className="overlay">
                            <button className="image-reset-button" onClick={handleRemoveImage}>{t("workspace.rem_image")}</button>
                        </div>
                    </div>
                )}
                {imageError && (
                    <Typography variant="body2" color="error" className="list-header-error">
                        {imageError}
                    </Typography>
                )}
                <Box className="list-header-emoji-box">
                    <Typography variant="body1">{t("modal.emoji")}</Typography>
                    <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                        <InsertEmoticonIcon className="list-header-emoji-icon" />
                    </IconButton>
                    <Box className="list-header-emoji">{emoji}</Box>
                </Box>
                {showEmojiPicker && (
                    <Box className="list-header-emoji-picker">
                        <Picker data={data} onEmojiSelect={onEmojiSelect} />
                    </Box>
                )}
                <Box className="list-header-actions">
                    <Button onClick={handleReset} className="button-dark-bg">
                        {t("modal.reset")}
                    </Button>
                    <Box>
                        <Button onClick={() => handleListHeaderModalClose()} className="button-dark-bg">
                            {t("global.cancel")}
                        </Button>

                        <Button onClick={handleSave} className="button-dark-bg list-header-text-color-margin" variant="contained">
                            {t("global.save")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>

    );
};

export default ListHeaderModal;

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css";

export default function DeleteTaskModal({ openDeleteTaskModal, handleDeleteTaskModalClose, handleDeleteTask, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openDeleteTaskModal} onClose={handleDeleteTaskModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {t("modal.task_delete")}<br/>
                {t("modal.task_no_return")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteTaskModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleDeleteTask} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import NewTicketForm from '../components/Support/SubmitNewTicket';
import OngoingTicketsTable from '../components/Support/OngoingTickets';
import PastTicketsTable from '../components/Support/PastTickets';
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';

import { useTranslation } from 'react-i18next';
import "../css/views/SupportPage.css";
import "../css/main.css";

const SupportPage = () => {

  const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.support');
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const [myUserInSupportFormat, setMyUserInSupportFormat] = useState();
    const { WhoAmISupportInfo, ReopenTicket } = useService();

    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;

    const getStatusLabel = (status) => {
        const statusLabels = {
            open: 'Open',
            in_progress: 'In Progress',
            resolved: 'Resolved',
            closed: 'Closed'
        };
        return statusLabels[status] || status;
    };

    const getPriorityLabel = (priority) => {
        const priorityLabels = {
            low: 'Low',
            medium: 'Medium',
            high: 'High',
            urgent: 'Urgent',
            under_evaluation: 'Under Evaluation'
        };
        return priorityLabels[priority] || priority;
    };

    const getTypeLabel = (type) => {
        const typeLabels = {
            features: 'Features',
            vacations: 'Vacations',
            calendar: 'Calendar',
            performance: 'Performance',
            bug_report: 'Bug Report',
            other: 'Other'
        };
        return typeLabels[type] || type;
    };

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const user = await WhoAmISupportInfo();
            setMyUserInSupportFormat(user);
        };
        if (!myUserInSupportFormat) {
            fetchCurrentUser();
        }
    }, [myUserInSupportFormat]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box className="content-support">
            <div className="tabs-support">
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#4caf50', // Color of the indicator for the selected tab
                        }
                    }}
                >
                    <Tab
                        label="My Current Tickets"
                        sx={{
                            color: activeTab === 0 ? '#4caf50' : '#ffffff', // Selected tab is green, unselected tabs are white
                        }}
                    />
                    <Tab
                        label="My Past Tickets"
                        sx={{
                            color: activeTab === 1 ? '#4caf50' : '#ffffff', // Same logic for second tab
                        }}
                    />
                    <Tab
                        label="Submit a New Ticket"
                        sx={{
                            color: activeTab === 2 ? '#4caf50' : '#ffffff', // Same logic for third tab
                        }}
                    />
                </Tabs>
            </div>

            {/* Content for each tab */}
            <TabPanel value={activeTab} index={0}>
                <OngoingTicketsTable user={myUserInSupportFormat} getStatusLabel={getStatusLabel} getPriorityLabel={getPriorityLabel} getTypeLabel={getTypeLabel} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <PastTicketsTable user={myUserInSupportFormat} getStatusLabel={getStatusLabel} getPriorityLabel={getPriorityLabel} getTypeLabel={getTypeLabel} ReopenTicket={ReopenTicket} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
                <NewTicketForm user={myUserInSupportFormat} my_workspace={myWorkspaces[0]} />
            </TabPanel>
        </Box>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="tab-panel-support">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default SupportPage;

import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Avatar,
    Paper,
    Divider,
    Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { format } from 'date-fns';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { MentionsInput, Mention } from 'react-mentions';
import {useTranslation} from "react-i18next";
import "../../css/components/Core/TaskComments.css";
import "../../css/main.css";

const renderCommentWithMentions = (text) => {
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g; 

    return text.replace(mentionRegex, (match, display, id) => {
        return `<a href="/workflow/profile/${id}">@${display}</a>`; 
    });
};

export default function Comments({ task_id, project_id }) {
    const { t } = useTranslation();

    const { GetTaskComments, PostTaskComment, getWorkspaceWorkersForComments } = useService();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyComment, setReplyComment] = useState({});
    const { getLoggedUser } = useAuthorization();
    const [workspaceWorkers, setWorkspaceWorkers] = useState([]);
    const [showReplyBoxForCommentId, setShowReplyBoxForCommentId] = useState(null); // Track reply box for a specific comment

    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchWorkspaceWorkers = async () => {
            const workspaceWorkersData = await getWorkspaceWorkersForComments(myWorkspaces[0].id);
            setWorkspaceWorkers(workspaceWorkersData);
        };

        fetchWorkspaceWorkers();
    }, [task_id]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const fetchedComments = await GetTaskComments(myWorkspaces[0].id, project_id ,task_id);
                const sortedComments = fetchedComments.sort(
                    (a, b) => new Date(a.created_at) - new Date(b.created_at)
                );
                setComments(sortedComments);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    }, [task_id]);


    const extractMentionedIds = (text) => {
        const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
        const mentionedIds = [];
        let match;

        while ((match = mentionRegex.exec(text)) !== null) {
            mentionedIds.push(match[2]);
        }

        return mentionedIds;
    };


    const handlePostComment = async () => {
        if (newComment.trim() === '') return;

        const mentionedIds = extractMentionedIds(newComment);

        try {
            const commentData = {
                text: newComment,
                parent_comment: null,
                user_id: getLoggedUser(),
                mentioned_users: mentionedIds,
            };
            const postedComment = await PostTaskComment(myWorkspaces[0].id ,project_id, task_id, commentData);
            setComments([...comments, postedComment]);
            setNewComment('');
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handlePostReply = async (parentId) => {
        const replyText = replyComment[parentId]?.trim();
        if (!replyText) return;

        const mentionedIds = extractMentionedIds(replyText);

        try {
            const replyData = {
                text: replyText,
                parent_comment: parentId,
                user_id: getLoggedUser(),
                mentioned_users: mentionedIds,
            };
            const postedReply = await PostTaskComment(myWorkspaces[0].id, project_id, task_id, replyData);

            const updatedComments = comments.map((comment) => {
                if (comment.id === parentId) {
                    return {
                        ...comment,
                        replies: [postedReply, ...comment.replies], // Unshift new reply to the top
                    };
                }
                return comment;
            });

            setComments(updatedComments);
            setReplyComment({ ...replyComment, [parentId]: '' });
            setShowReplyBoxForCommentId(null); // Hide reply box after posting the reply
        } catch (error) {
            console.error('Error posting reply:', error);
        }
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handlePostComment();
        }
    };

    const handleReplyKeyPress = (event, parentId) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handlePostReply(parentId);
        }
    };

    const mentionData = workspaceWorkers.map(worker => ({
        id: worker.id,
        display: worker.full_name,
        image: worker.image,
    }));

    const handleShowReplyBox = (commentId) => {
        setShowReplyBoxForCommentId(commentId); // Show reply box for the selected comment
    };

    return (
        <Box className="comments-margin">
            <Typography variant="h5">{t("modal.comments_title")}</Typography>
            <Divider className="comments-divider" />
            {comments.length > 0 ? (
                comments.map((comment) => (
                    <Box key={comment.id} className="comments-box">
                        <Comment
                            comment={comment}
                            onReply={(text) =>
                                setReplyComment({ ...replyComment, [comment.id]: text })
                            }
                            replyText={replyComment[comment.id] || ''}
                            onPostReply={() => handlePostReply(comment.id)}
                            onReplyKeyPress={(e) => handleReplyKeyPress(e, comment.id)}
                            mentionData={mentionData}
                            showReplyBox={showReplyBoxForCommentId === comment.id} // Conditionally show reply box
                            onShowReplyBox={() => handleShowReplyBox(comment.id)} // Handle reply box toggle
                            t={t}
                        />
                        <Divider />
                    </Box>
                ))
            ) : (
                <Typography>{t("modal.no_comments")}</Typography>
            )}

            <Typography variant="h6">{t("modal.leave_comment")}</Typography>
            <Paper className="comments-paper">
                <MentionsInput
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="mentions"
                    onKeyPress={handleKeyPress}
                    placeholder={t("modal.write_comments")}
                >
                    <Mention
                        trigger="@"
                        data={mentionData}
                        className="comments-mention-input"
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <Box className="comments-flex">
                                <Avatar
                                    src={`${process.env.REACT_APP_BACKEND_URL}${suggestion.image}`}
                                    className="comments-avatar"
                                />
                                <Typography variant="body2">{highlightedDisplay}</Typography>
                            </Box>
                        )}
                        displayTransform={(id, display) => `@${display}`}
                    />
                </MentionsInput>
                <IconButton
                    className="comments-icon-button"
                    onClick={handlePostComment}
                >
                    <SendIcon className="white-txt-color" />
                </IconButton>
            </Paper>
        </Box>
    );
}

const Comment = ({ comment, onReply, replyText, onPostReply, onReplyKeyPress, mentionData, showReplyBox, onShowReplyBox, t }) => (
    <Box className="comments-comment-padding">
        <Box className="comments-flex">
            <Avatar src={`${process.env.REACT_APP_BACKEND_URL}${comment.user.image}`}  className="comments-avatar-margin">{comment.user.full_name[0]}</Avatar>
            <Box>
                <Typography variant="subtitle1">
                    {comment.user.full_name} - {format(new Date(comment.created_at), 'dd/MM/yyyy HH:mm')}
                </Typography>
                <Typography
                    variant="body2"
                    className="comments-comment-text"
                    dangerouslySetInnerHTML={{ __html: renderCommentWithMentions(comment.text) }}
                />
            </Box>
        </Box>

        {comment.replies && comment.replies.length > 0 && (
            <Box className="comments-reply-button">
                {comment.replies.map((reply) => (
                    <Box key={reply.id} className="comments-reply-padding">
                        <Box className="comments-flex">
                            <Avatar src={`${process.env.REACT_APP_BACKEND_URL}${comment.user.image}`} className="comments-avatar-margin">{reply.user.full_name[0]}</Avatar>
                            <Box>
                                <Typography variant="subtitle2">
                                    {reply.user.full_name} - {format(new Date(reply.created_at), 'dd/MM/yyyy HH:mm')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className="comments-comment-text"
                                    dangerouslySetInnerHTML={{ __html: renderCommentWithMentions(reply.text) }}
                                />
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        )}

        {/* Reply button */}
        <Box className="comments-reply-button">
            <Button onClick={onShowReplyBox} variant="text" className="button-dark-bg">
                {t("core.reply")}
            </Button>
        </Box>

        {/* Conditionally render the reply input field if showReplyBox is true */}
        {showReplyBox && (
            <Box className="comments-reply-box">
                <MentionsInput
                    value={replyText}
                    onChange={(e) => onReply(e.target.value)}
                    className="mentions"
                    onKeyPress={onReplyKeyPress}
                    placeholder={t("core.reply_placeholder")}
                >
                    <Mention
                        trigger="@"
                        data={mentionData}
                        className="comments-mention-input"
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <Box className="comments-flex">
                                <Avatar
                                    src={`${process.env.REACT_APP_BACKEND_URL}${suggestion.image}`}
                                    className="comments-avatar"
                                />
                                <Typography variant="body2">{highlightedDisplay}</Typography>
                            </Box>
                        )}
                        displayTransform={(id, display) => `@${display}`}
                    />
                </MentionsInput>
                <IconButton
                    className="comments-icon-button"
                    onClick={onPostReply}
                >
                    <SendIcon className="white-txt-color" />
                </IconButton>
            </Box>
        )}
    </Box>
);

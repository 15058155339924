import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import "../../css/components/Core/UserActivityLogModal.css";

const groupLogsByProject = (logs, t) => {
  const groupedLogs = {};

  logs.forEach((log) => {
    const projectName = log.project_name || t("core.no_project");

    if (!groupedLogs[projectName]) {
      groupedLogs[projectName] = [];
    }

    groupedLogs[projectName].push(log);
  });

  return groupedLogs;
};


const UserActivityLogModal = ({ open, handleClose, userId }) => {

  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;
  const { t } = useTranslation();


  const { getUserActivityLogs } = useService();

  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      fetchUserActivityLogs();
    }
  }, [open]);

  const fetchUserActivityLogs = async () => {
    try {
      setLoading(true);

      const data = await getUserActivityLogs(myWorkspaces[0]?.id, userId);


      setLogs(data);
      setFilteredLogs(data);
    } catch (error) {
      console.error('Error fetching user activity logs:', error);
    } finally {
      setLoading(false);  // Ensure loading state is reset regardless of success or error
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = logs.filter(
        (log) =>
          (log.project_name && log.project_name.toLowerCase().includes(term)) ||  // Search by project name
          log.log_message.toLowerCase().includes(term) ||
          log.created_at.toLowerCase().includes(term)
      );
      setFilteredLogs(filtered);
    } else {
      setFilteredLogs(logs);
    }
  };

  // Group logs by project
  const groupedLogs = groupLogsByProject(filteredLogs, t);

  return (
    <Modal open={open} onClose={handleClose} className="user-activity-modal">
      <Box className="user-activity-box">
        <Typography variant="h5" gutterBottom>
          {t("core.activity_logs")}
        </Typography>
        <TextField
          variant="outlined"
          placeholder={t("core.activity_logs_search")}
          value={searchTerm}
          onChange={handleSearchChange}
          className="user-activity-search-input"
        />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(groupedLogs).map((projectName) => (
            <Box key={projectName} mb={4}>
              <Typography display="flex" justifyContent={"center"} variant="h6" color="primary" gutterBottom>
                {projectName}
              </Typography>
              <List>
                {groupedLogs[projectName].map((log, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={log.log_message}
                        secondary={
                          <>
                            <Typography variant="body2" color="secondary" component="span">
                              {t("core.task_label")} {log.task_title || t("core.no_task")} {/* Display task title */}
                            </Typography>
                            <br />
                            <Typography variant="body2" color="secondary" component="span">
                              {t("global.date_title")} {new Date(log.created_at).toLocaleString()}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    {index < groupedLogs[projectName].length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          ))
        )}
      </Box>
    </Modal>
  );
};

export default UserActivityLogModal;


import React, { useCallback, useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    Menu,
    MenuItem, Divider
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import { useService } from "contexts/ServiceContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CasinoIcon from "@mui/icons-material/Casino";
import MenuIcon from '@mui/icons-material/Menu';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ArchiveIcon from '@mui/icons-material/Archive';
import AttachmentsModal from './AttachmentsModal';
import DeleteLabelModal from './DeleteLabel'
import 'react-medium-image-zoom/dist/styles.css';
import "../../css/main.css";
import "../../css/components/Modals/ViewTask.css";


import { useSnackbar } from 'contexts/SnackbarContextProvider';

import { checkForWorkspaceStorageLimitError } from "utils/WorkspaceStorageLimit";

import { debounce } from "lodash";
import {
    convertToUTC,
    formatDateToString,
} from "components/Custom/TimezoneConvertion.jsx";
import TaskDatesDialog from "components/Core/TaskDatesDialog.jsx";
import ArchiveTaskModal from "./ArchiveTask";
import TextEditor from "../Custom/TextEditor";
import ChecklistComponent from "../Custom/ChecklistComponent";
import { useTranslation } from "react-i18next";
import CoverPickerModal from "../Modals/CoverPickerModal";
import DownloadWarningModal from "../Modals/DownloadWarning";


import Comments from "../Core/TaskComments";
/* eslint-disable react-hooks/exhaustive-deps */
export default function ViewTaskModal({
    openViewTaskModal,
    handleSaveTask,
    handleViewTaskModalClose,
    Task,
    List,
    myBoard,
    setMyBoard,
    updateToggleLabel,
    projectWorkers,
    handleMarkAsDone,
    handleMarkAsDoing,
    handleArchiveTask,
    avoidMisfire,
    setContextTaskMenuPosition,
    autoFocusLabel,
    openCover,
    setCoverModalOpen,
    workspace_id,
}) {
    const { t } = useTranslation();

    const { showSnackbar } = useSnackbar();

    const [editingTaskTitle, setEditingTaskTitle] = useState(false);
    const [editTaskNewTitle, setEditTaskNewTitle] = useState("");
    const {
        UpdateTaskDates,
        DeleteChecklist,
        UpdateTask,
        SaveTaskLabels,
        AddLabelToBoard,
        DeleteLabel,
        updateTaskMembers,
        GetTaskActivityLog,
        UpdateTaskDescription,
        CreateChecklist,
        CreateChecklistItem,
        GetChecklists,
        UpdateChecklistItem,
        DeleteChecklistItem,
        UpdateChecklistTitle,
        UpdateChecklistOrder,
        UpdateLabel,
        UpdateTaskCover,
        HandleUploadAttachment,
        DeleteTaskAttachment,
        ToggleHiddenStatusOfChecklists,
        ToggleHiddenStatusOfChecklistItems,
        ConvertToTask
    } = useService();

    const [open, setOpen] = useState(false);
    const [labelTitle, setLabelTitle] = useState("");
    const [labelAdding, setLabelAdding] = useState(false);

    const [selectedTaskLabels, setSelectedTaskLabels] = useState([]);
    const [selectedTaskMembers, setSelectedTaskMembers] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [taskMembersDialogOpen, setTaskMembersDialogOpen] = useState(false);
    const [taskDatesDialogOpen, setTaskDatesDialogOpen] = useState(false);
    const [taskActivityLog, setTaskActivityLog] = useState([]);
    const [description, setDescription] = useState();
    const [openArchiveTaskModal, setOpenArchiveTaskModal] = useState(false);

    const [checklists, setChecklists] = useState([]);
    const [editChecklistTitles, setEditChecklistTitles] = useState({});
    const [newChecklistTitles, setNewChecklistTitles] = useState({});

    const [taskTitleError, setTaskTitleError] = useState([]);

    const [showAllActivity, setShowAllActivity] = useState(false);
    const [labelEditing, setLabelEditing] = useState(false);
    const [labelEditingTitle, setLabelEditingTitle] = useState(null);
    const [labelEditingColor, setLabelEditingColor] = useState(null);
    const [labelEditingID, setLabelEditingID] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const [, setPastedImage] = useState(null);
    const [labelDescription, setLabelDescription] = useState('');
    const [labelEditingDescription, setLabelEditingDescription] = useState('');

    const [openCoverImageModal, setOpenCoverImageModal] = useState(false);

    const [actionsDropdownOpen, setActionsDropdownOpen] = useState(window.innerWidth > 900);

    const [openModal, setOpenModal] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [openDownloadWarningModal, setOpenDownloadWarningModal] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState("");


    const handleOpenDownloadWarning = (attachment, fileUrl) => {
        setSelectedAttachment(attachment);
        setSelectedFileUrl(fileUrl);
        setOpenDownloadWarningModal(true);
    };
    
    const handleProceedDownload = () => {
        setOpenDownloadWarningModal(false);
        handlePreviewImage(selectedAttachment, selectedFileUrl); 
    };
    
    const handleCloseDownloadWarningModal = () => {
        setOpenDownloadWarningModal(false);
    };
    
    


    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 900) {
                setActionsDropdownOpen(true);
            } else {
                setActionsDropdownOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const toggleModal = () => {
        setOpenModal((prevOpen) => !prevOpen);
    };

    const handleCoverClick = () => {
        if (Task.cover_image) {
            setOpenCoverImageModal(true);
        }
    };

    const handleCloseCoverImageModal = () => {
        setOpenCoverImageModal(false);
    };


    const handlePaste = async (event) => {
        const clipboardItems = event.clipboardData.items;

        for (let item of clipboardItems) {
            if (item.type.startsWith("image")) {
                const file = item.getAsFile();
                setPastedImage(URL.createObjectURL(file));
                await saveNewCoverImage(file, Task.cover_color);
            }
        }
    };

    useEffect(() => {
        if (editingTaskTitle) {
            document.addEventListener("paste", handlePaste);
        } else {
            document.removeEventListener("paste", handlePaste);
        }

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [editingTaskTitle]);


    useEffect(() => {
        if (autoFocusLabel) {
            setOpen(true);
        }
        if (openCover) {
            setCoverModalOpen(true);
        }
    }, [autoFocusLabel, openCover]);

    const handleOpenLabelEditor = () => {
        setOpen(true);
    };

    <AddIcon className="view-task-add-label-icon" onClick={handleOpenLabelEditor} />;

    useEffect(() => {
        const fetchTaskActivityLog = async () => {
            if (Task.id) {
                const activityLog = await GetTaskActivityLog(workspace_id, myBoard.project, Task.id);
                setTaskActivityLog(activityLog);
            }
        };
        if (Task.label) {
            setSelectedTaskLabels(Task.label);
            if (Task.members !== selectedTaskMembers) {
                setSelectedTaskMembers(selectedTaskMembers);
            } else {
                setSelectedTaskMembers(Task.members);
            }
            setDescription(Task.description);
            fetchTaskActivityLog();
        }


    }, [Task, GetTaskActivityLog, refreshFlag, openViewTaskModal]);

    useEffect(() => {
        return () => {
            setTaskActivityLog([]);
        };
    }, []);


    const [newChecklistAdded, setNewChecklistAdded] = useState(false);

    useEffect(() => {
        if (newChecklistAdded) {
            setTimeout(() => setNewChecklistAdded(false), 500); 
        }
    }, [newChecklistAdded]);

    const handleAddChecklist = async () => {
        try {
            const response = await CreateChecklist(workspace_id, myBoard.project, Task.id, t("modal.checklist_new"));

            setChecklists((prevChecklists) => [...prevChecklists, response]);
            setRefreshFlag(!refreshFlag);

            setNewChecklistAdded(true);
        } catch (error) {
            console.error("Error creating checklist:", error);
        }
    };


    const getRandomHex = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const [labelColor, setLabelColor] = useState(getRandomHex());
    const defaultColors = [
        "#CC0000",
        "#D21414",
        "#E63B3B",
        "#F46262",
        "#FF8888",
        "#FFAA00",
        "#FFBB33",
        "#FFCC66",
        "#FFDD99",
        "#FFEECC",
        "#FFFF00",
        "#CCFF33",
        "#99FF66",
        "#66FF99",
        "#33FFCC",
        "#00FFFF",
        "#33CCFF",
        "#6699FF",
        "#9966FF",
        "#CC33FF",
        "#FF00FF",
        "#FF33CC",
        "#FF6699",
        "#FF9966",
        "#FFCC33",
    ];

    const debouncedHandleColorSelection = useCallback(
        debounce((color) => {
            setLabelColor(color);
        }, 300),
        []
    );

    const debouncedHandleEditColorSelection = useCallback(
        debounce((color) => {
            setLabelEditingColor(color);
        }, 300),
        []
    );
    const myBoardSortedLabels = myBoard?.labels?.sort(
        (a, b) => a.order - b.order
    );

    const handleTitleClick = (title) => {
        if (!editingTaskTitle) {
            setEditTaskNewTitle(title);
            setEditingTaskTitle(true);
        }
    };

    useEffect(() => {
        const fetchTaskActivityLog = async () => {
            if (Task.id) {
                const activityLog = await GetTaskActivityLog(workspace_id, myBoard.project, Task.id);
                setTaskActivityLog(activityLog);
            }
        };

        if (Task.label) {
            setSelectedTaskLabels(Task.label);
            setSelectedTaskMembers(Task.members);
            setDescription(Task.description);
            fetchTaskActivityLog();
        }
    }, [Task, GetTaskActivityLog]);

    const handleTitleBlur = async () => {
        if (editTaskNewTitle === "") {
            setTaskTitleError(t("modal.task_empty"));
        } else {
            setEditingTaskTitle(false);

            await UpdateTask(workspace_id, myBoard.project, List, Task, editTaskNewTitle);
            setRefreshFlag(!refreshFlag);
            const updatedBoard = { ...myBoard };

            const listToUpdate = updatedBoard.lists.find(
                (list) => list.id === List.id
            );
            const taskToUpdate = listToUpdate.tasks.find(
                (task) => task.id === Task.id
            );
            // Update the title of the task
            if (taskToUpdate) {
                taskToUpdate.title = editTaskNewTitle;
                setMyBoard(updatedBoard);
            }

            setEditingTaskTitle(null);
        }
    };

    const handleTitleChange = (event) => {
        event.target.value.toLowerCase();
        setEditTaskNewTitle(event.target.value);
    };

    const handleAddLabel = () => {
        setOpen(true);
    };

    const handleArchiveTaskModalOpen = () => {
        setOpenArchiveTaskModal(true);
    };

    const handleArchiveTaskModalClose = () => {
        setOpenArchiveTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleCloseAndArchive = () => {
        setOpenArchiveTaskModal(false);
        handleViewTaskModalClose();
        handleArchiveTask();
    };

    const handleTaskCloseMenu = () => {
        setContextTaskMenuPosition({ x: 0, y: 0 });
    };

    const handleColorSelection = (color) => {
        setLabelColor(color);
    };

    const handleEditColorSelection = (color) => {
        setLabelEditingColor(color);
    };

    const handleAddNewLabel = async (title, color) => {
        const response = await AddLabelToBoard(workspace_id, myBoard.project, myBoard.id, title, color, labelDescription);

        if (response.status === 201) {
            setSelectedTaskLabels((prevLabels) => {
                prevLabels = prevLabels || [];
                // Label doesn't exist, add it
                const updatedLabels = [...prevLabels, response.data];
                Task.label = updatedLabels;
                return updatedLabels;
            });

            setMyBoard((prevBoard) => ({
                ...prevBoard,
                labels: [...prevBoard.labels, response.data],
            }));
        }

        handleSetLabelAddClose();
    };



    const saveNewCoverImage = async (image, color) => {
        const formData = new FormData();
        if (image) {
            formData.append('cover_image', image);
            Task.cover_image = URL.createObjectURL(image);
        } else {
            Task.cover_image = null;
        }
        if (color) {
            formData.append('cover_color', color);
            Task.cover_color = color;
        } else {
            Task.cover_color = null;
        }

        UpdateTaskCover(workspace_id, myBoard.project, Task.id, formData);
    };

    const handleEditLabel = async (title, color, label_id, description) => {
        const response = await UpdateLabel(workspace_id, myBoard.project, label_id, title, color, description);

        if (response.status === 200) {
            setSelectedTaskLabels((prevLabels) => {
                const initializedLabels = prevLabels || [];
                const indexToUpdate = initializedLabels.findIndex(label => label.id === label_id);

                if (indexToUpdate !== -1) {

                    const updatedLabels = [
                        ...initializedLabels.slice(0, indexToUpdate),
                        response.data,
                        ...initializedLabels.slice(indexToUpdate + 1)
                    ];
                    Task.label = updatedLabels;
                    return updatedLabels;
                }
                return initializedLabels;
            });

            setMyBoard((prevBoard) => {
                const updatedLabels = prevBoard.labels.map(label => label.id === label_id ? response.data : label);
                const updatedLists = prevBoard.lists.map(list => ({
                    ...list,
                    tasks: list.tasks.map(task => ({
                        ...task,
                        label: task.label.map(label => label.id === label_id ? response.data : label)
                    }))
                }));

                return {
                    ...prevBoard,
                    labels: updatedLabels,
                    lists: updatedLists
                };
            });


        }
        setLabelEditing(false);
    };


    const handleClose = () => {
        setOpen(false);
        handleSaveLabel();
        setLabelAdding(false);
        setLabelEditing(false);
        if (autoFocusLabel) {
            handleViewTaskModalClose()
        }
    };

    const handleSetLabelAddOpen = () => {
        setLabelColor(getRandomHex());
        setLabelTitle("");
        setLabelAdding(true);
    };

    const handleSetLabelAddClose = () => {
        handleAddLabel(labelTitle, labelColor);
        setLabelAdding(false);
    };

    const handleSetCancelEdit = () => {
        setLabelEditing(false);
    };



    const toggleLabelSelection = (label) => {
        setSelectedTaskLabels((prevLabels) => {
            prevLabels = prevLabels || [];
            const labelIndex = prevLabels.findIndex((item) => item.id === label.id);
            if (labelIndex !== -1) {
                const updatedLabels = prevLabels.filter((item) => item.id !== label.id);
                // Update Task.label
                Task.label = updatedLabels;
                return updatedLabels;
            } else {
                // Label doesn't exist, add it
                const updatedLabels = [...prevLabels, label];
                // Update Task.label
                Task.label = updatedLabels;
                return updatedLabels;
            }
        });
    };

    const handleSaveLabel = () => {
        SaveTaskLabels(workspace_id, myBoard.project, Task.id, selectedTaskLabels);

        const updatedBoard = {
            ...myBoard,
            lists: myBoard.lists.map((list) => ({
                ...list,
                tasks: list.tasks.map((task) => {
                    if (task.id === Task.id) {
                        return {
                            ...task,
                            label: Task.label
                        };
                    }
                    return task;
                }),
            })),
        };

        setMyBoard(updatedBoard);

        setOpen(false);
        setLabelTitle("");
        setLabelColor(getRandomHex());
    };

    const [openDeleteLabelModal, setOpenDeleteLabelModal] = useState(false);
    const [labelIdToDelete, setLabelIdToDelete] = useState(null);

    const DeleteLabelModalOpen = (label_id) => {
        setLabelIdToDelete(label_id)
        setOpenDeleteLabelModal(true);
    };

    const DeleteLabelModalClose = () => {
        setLabelIdToDelete(null)
        setOpenDeleteLabelModal(false);
    };

    const handleDeleteLabel = async (label_id) => {
        await DeleteLabel(workspace_id, myBoard.project, myBoard.id, label_id);
        const updatedBoard = { ...myBoard };
        updatedBoard.labels = updatedBoard.labels.filter(
            (label) => label.id !== label_id
        );
        setMyBoard(updatedBoard);

        setSelectedTaskLabels((prevLabels) => {
            prevLabels = prevLabels || [];
            const labelIndex = prevLabels.findIndex((item) => item.id === label_id);
            if (labelIndex !== -1) {
                const updatedLabels = prevLabels.filter((item) => item.id !== label_id);
                Task.label = updatedLabels;
                return updatedLabels;
            }
        });
        DeleteLabelModalClose();
    };

    const handleEditClick = (label) => {
        setLabelEditingTitle(label.title)
        setLabelEditingColor(label.color)
        setLabelEditingDescription(label.description)
        setLabelEditingID(label.id)
        setLabelEditing(true);
    };

    const handleLabelTitleChange = (e) => {
        const value = e.target.value;
        setLabelTitle(value);

        if (value.toUpperCase() === "CRAZY") {
            setLabelColor("rainbow");
        }
    };

    const handleEditLabelTitleChange = (e) => {
        const value = e.target.value;
        setLabelEditingTitle(value);

        if (value.toUpperCase() === "CRAZY") {
            setLabelEditingColor("rainbow");
        } else {
            if (labelEditingColor === "rainbow") {
                setLabelEditingColor("#000000")
            } else {
                setLabelEditingColor(labelEditingColor)
            }
        }
    };

    const getRandomColor = () => {
        handleAddNewLabel("", getRandomHex());
    };

    const handleTaskDatesDialogOpen = () => setTaskDatesDialogOpen(true);
    const handleTaskDatesDialogClose = () => {
        setTaskDatesDialogOpen(false);
    };

    const handleTaskMembersDialogOpen = () => setTaskMembersDialogOpen(true);

    const handleTaskMembersDialogClose = () => {
        setTaskMembersDialogOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredProjectWorkers = projectWorkers?.filter((worker) =>
        worker?.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleMemberSelection = (worker) => {
        setSelectedTaskMembers((prevSelectedMembers) => {
            if (prevSelectedMembers.some((member) => member.id === worker.id)) {
                return prevSelectedMembers.filter((member) => member.id !== worker.id);
            } else {
                return [...prevSelectedMembers, worker];
            }
        });
    };

    const handleSaveMembers = async () => {
        try {
            const notify_user = (List.title === "Done") ? false : true;
            await updateTaskMembers(workspace_id, myBoard.project, Task.id, selectedTaskMembers, notify_user);

            const updatedTaskMembers = selectedTaskMembers.map((member) => ({
                ...member,
                image: member.image.replace(process.env.REACT_APP_BACKEND_URL, ""),
            }));

            const updatedBoard = {
                ...myBoard,
                lists: myBoard.lists.map((list) => ({
                    ...list,
                    tasks: list.tasks.map((task) => {
                        if (task.id === Task.id) {
                            return {
                                ...task,
                                members: updatedTaskMembers,
                            };
                        }
                        return task;
                    }),
                })),
            };

            setMyBoard(updatedBoard);
            setTaskMembersDialogOpen(false);
        } catch (error) {
            console.error("Error updating task members:", error);
            // Handle error if any
        } finally {
            setRefreshFlag(!refreshFlag);
        }
    };

    const handleSaveDates = async (dates) => {
        try {
            // Helper function to convert and set dates
            const convertAndSetDate = (dateKey) => {
                if (dates[dateKey]) {
                    return convertToUTC(dates[dateKey]);
                }
                return null;
            };

            // Convert selected dates to UTC format (Because server is UTC!)
            const convertedDates = {
                start_date: convertAndSetDate("start_date"),
                due_date: convertAndSetDate("due_date"),
                end_date: convertAndSetDate("end_date"),
            };

            const response = await UpdateTaskDates(workspace_id, myBoard.project, Task.id, convertedDates);
            if (response.status === 200) {
                const updatedTask = response.data;

                setMyBoard(prevBoard => {
                    return {
                        ...prevBoard,
                        lists: prevBoard.lists.map(list => {
                            return {
                                ...list,
                                tasks: list.tasks.map(task => {
                                    if (task.id === Task.id) {
                                        return { ...task, ...updatedTask };
                                    }
                                    return task;
                                })
                            };
                        })
                    };
                });
            }
            setRefreshFlag(!refreshFlag);

            setTaskDatesDialogOpen(false);
        } catch (error) {
            console.error("Error updating task dates:", error);
        }
    };

    const handleDescription = async (json) => {
        try {
            Task.description = json;
            setDescription(json);
            await UpdateTaskDescription(workspace_id, myBoard.project, Task.id, json);
            setRefreshFlag(!refreshFlag);

            handleViewTaskModalClose();
        } catch (error) {
            checkForWorkspaceStorageLimitError(error, t, showSnackbar);
        }
    };

    const handleCancel = () => {
        handleViewTaskModalClose();
    };



    useEffect(() => {
        const keyframesStyle = `
            @keyframes rainbow {
                0% { background-position: 0% 50%; }
                50% { background-position: 100% 50%; }
                100% { background-position: 0% 50%; }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframesStyle;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);



    const handleCloseModal = () => {
        setTaskActivityLog(null);
        handleViewTaskModalClose();
    };

    //Attachments here
    const [, setFiles] = useState(null);

    const handleFileChange = (event) => {
        const uploadedFiles = event.target.files;
        if (uploadedFiles) {
            setFiles(uploadedFiles);
            HandleUploadAttachment(workspace_id, myBoard.project, Task.id, myBoard.id, uploadedFiles).then((newAttachments) => {

                Task.attachments = [...Task.attachments, ...newAttachments];

                setRefreshFlag(!refreshFlag);
            })
                .catch((error) => {
                    console.error("Error uploading attachments:", error);
                    checkForWorkspaceStorageLimitError(error, t, showSnackbar);
                });
        }
    };

    const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);
    const [openImagePreviewModal, setOpenImagePreviewModal] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenImageModal = (imageUrl) => {
        setSelectedPreviewImage(imageUrl);
        setOpenImagePreviewModal(true);
    };

    const handleCloseImageModal = () => {
        setOpenImagePreviewModal(false);
        setSelectedPreviewImage(null);
    };

    const handleDeleteAttachment = (attachment, event) => {
        event.stopPropagation();
        setSelectedAttachment(attachment);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedAttachment(null);
    };


    const handlePreviewImage = (attachment, fileUrl) => {
        if (attachment.file_type === 'Image') {
            handleOpenImageModal(fileUrl);
        } else {
            window.open(fileUrl, '_blank');
        }
    };

    const handleImageTouch = () => {
        const fileUrl = selectedPreviewImage;
        window.open(fileUrl, '_blank');
    };

    const handleConfirmDelete = async (deleteFromStorage) => {
        try {
            await DeleteTaskAttachment(workspace_id, myBoard.project, Task.id, selectedAttachment.id, deleteFromStorage);
            //Use snackbar here later when mass updating snackbar
            //if (deleteFromStorage) {
            //    console.log(`Deleted attachment ${selectedAttachment.filename} from both task and board's storage.`);
            //} else {
            //    console.log(`Deleted attachment ${selectedAttachment.filename} from task only.`);
            //}

            Task.attachments = Task.attachments.filter((att) => att.id !== selectedAttachment.id);


            handleCloseDeleteDialog();
        } catch (error) {
            console.error("Error deleting attachment:", error);
        }
    };

    const getLabelUsageCount = (labelId) => {
        let count = 0;

        myBoard.lists.forEach(list => {
            list.tasks.forEach(task => {
                if (task.label && task.label.some(label => label.id === labelId)) {
                    count += 1;
                }
            });
        });

        return count;
    };

    return (
        <>
            <ArchiveTaskModal
                openArchiveTaskModal={openArchiveTaskModal}
                handleArchiveTaskModalClose={handleArchiveTaskModalClose}
                handleArchiveTask={handleCloseAndArchive}
                avoidMisfire={avoidMisfire}
            />
            <DeleteLabelModal
                openDeleteLabelModal={openDeleteLabelModal}
                DeleteLabelModalClose={DeleteLabelModalClose}
                handleDeleteLabel={handleDeleteLabel}
                avoidMisfire={avoidMisfire} 
                labelIdToDelete={labelIdToDelete}
            />

            <Dialog
                open={openViewTaskModal}
                onClose={handleCloseModal}
                className="modal-background-color view-task-dialog"
            >
                <Box className="view-task-scrollable-content">
                {(Task.cover_color || Task.cover_image) && (
                    <Box
                        className={`view-task-cover ${Task.cover_image ? 'with-image' : 'no-image'}`}
                        onClick={handleCoverClick}
                        style={{
                            background: Task.cover_image
                                ? `url(${Task.cover_image}), ${Task.cover_color || '#FFFFFF'}`
                                : Task.cover_color || '#FFFFFF',
                        }}
                    />
                )}

                <DialogTitle
                    className="view-task-dialog-title"
                >

                    <Box
                        onClick={() => handleTitleClick(Task.title)}
                        className="view-task-title-box"
                    >
                        <div>
                            {editingTaskTitle ? (
                                <span
                                    className="view-task-edit-title"
                                >
                                    <TextField
                                        className="view-task-width"
                                        value={editTaskNewTitle}
                                        onChange={handleTitleChange}
                                        onBlur={handleTitleBlur}
                                        autoFocus
                                        InputProps={{
                                            className: "view-task-label-title-input"
                                        }}
                                    />
                                </span>
                            ) : (
                                <span
                                    className="view-task-edit-title"
                                >
                                    <b>#{Task.frontend_order}</b> - {Task.title}
                                </span>
                            )}
                            <Typography className="view-task-in-list">
                                {t("modal.in_list")} {List.title}
                            </Typography>
                            <Typography
                                className="view-task-list-error"
                            >
                                {taskTitleError}
                            </Typography>
                        </div>
                    </Box>

                    <div className={`view-task-buttons ${editingTaskTitle ? 'hide' : 'show'}`}>

                        <div className="view-task-dropdown-button" onClick={toggleModal}>
                            <FileOpenIcon />
                        </div>

                        <div className="view-task-dropdown-button" onClick={handleOpenMenu}>
                            <MenuIcon />
                        </div>

                    </div>

                    <AttachmentsModal
                        openModal={openModal}
                        toggleModal={toggleModal}
                        Task={Task}
                        t={t}
                        handleFileChange={handleFileChange}
                        handleDeleteAttachment={handleDeleteAttachment}
                        handlePreviewImage={handlePreviewImage}
                    />

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            className: "view-task-menu-actions"
                        }}
                    >
                        <MenuItem disableRipple>
                            <Box
                                className="view-task-menu-item"
                            >
                                {t("modal.actions")}
                            </Box>
                        </MenuItem>
                        <Divider className="view-task-menu-divider" key="divider" />

                        <MenuItem
                            onClick={() => setCoverModalOpen(true)}
                        >
                            {t("modal.change_cover")}
                        </MenuItem>

                        <MenuItem
                            onClick={handleTaskMembersDialogOpen}
                        >
                            {t("modal.members")}
                        </MenuItem>

                        <MenuItem
                            onClick={handleAddChecklist}
                        >
                            {t("modal.checklist_add")}
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleTaskDatesDialogOpen()}
                        >
                            {t("core.dates")}
                        </MenuItem>

                        {List.title !== "Doing" && (
                            <MenuItem
                                onClick={() => handleMarkAsDoing(Task.id)}
                            >
                                {t("project_page.mark_doing")}
                            </MenuItem>
                        )}
                        {List.title !== "Done" && (
                            <MenuItem
                                onClick={() => handleMarkAsDone(Task.id)}
                            >
                                {t("project_page.mark_done")}
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={handleArchiveTaskModalOpen}
                        >
                            {t("modal.archive")}
                        </MenuItem>
                    </Menu>

                </DialogTitle>

                <DialogContent className="view-task-full-width">
                    <Grid container spacing={2}>
                        <Grid item md={9} xs={12}>
                            <Box
                                className="view-task-content-box"
                            >
                                <div className="view-task-content-div">
                                    <div className="view-task-label-add">
                                        <Typography variant="subtitle2" className="view-task-label-margin">
                                            {t("modal.labels")}
                                        </Typography>
                                        <AddIcon
                                            className="view-task-add-label-icon"
                                            onClick={handleAddLabel}
                                        />
                                    </div>

                                    <div className="view-task-flex">
                                        {Task?.label?.map((label, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        updateToggleLabel();
                                                    }}
                                                    style={{ backgroundColor: label.color }}
                                                    className={`view-task-label-style ${label.color === "rainbow" ? 'rainbow' : ''}`}
                                                >

                                                    <Tooltip title={
                                                        <>
                                                            {label.description || t("modal.label_no_desc")}
                                                            <br />
                                                            {t("modal.label_task_number", { var1: getLabelUsageCount(label.id), var2: getLabelUsageCount(label.id) === 1 ? '' : 's' })}
                                                        </>
                                                    } placement="top">
                                                        <Typography
                                                            className={`view-task-label-title ${label.color === "rainbow" ? 'no-shadow' : 'with-shadow'}`}
                                                        >
                                                            {label.title}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    className="view-task-label-dialog"
                                >
                                    {!labelAdding && !labelEditing && (
                                        <>
                                            <DialogTitle className="view-task-label-title-width">
                                                {t("modal.label_select")}
                                            </DialogTitle>
                                            <DialogContent>
                                                <Typography variant="subtitle2">{t("modal.labels")}</Typography>
                                                <div>
                                                    {myBoardSortedLabels?.map((label) => (
                                                        <div
                                                            key={label.id}
                                                            className="view-task-workers-list"
                                                        >
                                                            <Checkbox
                                                                checked={selectedTaskLabels?.some(
                                                                    (item) => item.id === label.id
                                                                )}
                                                                onChange={() => toggleLabelSelection(label)}
                                                                color="primary"
                                                            />
                                                            <div
                                                                className={`view-task-label-list-style ${label.color === "rainbow" ? 'rainbow' : ''} ${selectedTaskLabels?.some(item => item.id === label.id) ? 'selected' : ''}`}
                                                                style={{ backgroundColor: label.color }}
                                                            >

                                                                <Typography
                                                                    variant="body2"
                                                                    className="white-txt-color"
                                                                >
                                                                    {label.title}
                                                                </Typography>

                                                                {selectedTaskLabels?.some(
                                                                    (item) => item.id === label.id
                                                                ) && <CheckIcon className="white-txt-color" />}
                                                            </div>
                                                            <IconButton
                                                                onClick={() => handleEditClick(label)}
                                                                size="small"
                                                                className="view-task-label-list-icons"
                                                            >
                                                                <EditIcon className="white-txt-color" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => DeleteLabelModalOpen(label.id)}
                                                                size="small"
                                                                className="view-task-label-list-icons"
                                                            >
                                                                <DeleteIcon className="white-txt-color" />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                </div>

                                                <Button onClick={handleSetLabelAddOpen}>
                                                    {t("modal.label_add")}
                                                </Button>
                                                <Tooltip title={t("modal.label_random")}>
                                                    <CasinoIcon
                                                        className="white-txt-color"
                                                        onClick={() => getRandomColor()}
                                                    />
                                                </Tooltip>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>{t("global.cancel")}</Button>
                                                <Button onClick={() => handleSaveLabel()}>{t("global.save")}</Button>
                                            </DialogActions>
                                        </>
                                    )}
                                    {labelAdding && (
                                        <>
                                            <DialogTitle className="view-task-add-label-actions">
                                                {t("modal.label_add")}{" "}
                                                <Button onClick={handleSetLabelAddClose}>{t("modal.return")}</Button>
                                            </DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label={t("modal.label_title")}
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelTitle}
                                                    onChange={handleLabelTitleChange}
                                                    InputProps={{
                                                        className: "view-task-label-title-input"
                                                    }}
                                                />
                                                <TextField
                                                    margin="dense"
                                                    label={t("modal.label_desc")}
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelDescription}
                                                    onChange={(e) => setLabelDescription(e.target.value)}
                                                    InputProps={{
                                                        className: "view-task-label-title-input"
                                                    }}
                                                />
                                                <Typography
                                                    className="view-task-label-title-input view-task-label-color-input"
                                                >
                                                    {t("modal.label_color")}
                                                </Typography>
                                                <input
                                                    type="color"
                                                    value={labelColor !== "rainbow" ? labelColor : "#000000"}
                                                    onChange={(e) => debouncedHandleColorSelection(e.target.value)}
                                                    className={`view-task-label-color-picker ${labelColor === "rainbow" ? 'rainbow' : ''}`}
                                                    disabled={labelColor === "rainbow"} // Disable if it's the rainbow color
                                                />
                                                {labelColor !== "rainbow" && (
                                                    <div
                                                        className="view-task-label-colors"
                                                    >
                                                        {defaultColors.map((color, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: color,
                                                                }}
                                                                className="view-task-label-color-item"
                                                                onClick={() => handleColorSelection(color)}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>{t("global.cancel")}</Button>
                                                <Button
                                                    onClick={() =>
                                                        handleAddNewLabel(labelTitle, labelColor, labelDescription)
                                                    }
                                                >
                                                    {t("modal.label_save")}
                                                </Button>
                                            </DialogActions>
                                        </>
                                    )}
                                    {labelEditing && (
                                        <>
                                            <DialogTitle className="view-task-add-label-actions">
                                                {t("modal.label_edit")}
                                                <Button onClick={handleSetCancelEdit}>{t("modal.return")}</Button>
                                            </DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label={t("modal.label_title")}
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelEditingTitle}
                                                    onChange={handleEditLabelTitleChange}
                                                    InputProps={{
                                                        className: "view-task-label-title-input"
                                                    }}
                                                />
                                                <TextField
                                                    margin="dense"
                                                    label={t("modal.label_desc")}
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelEditingDescription}
                                                    onChange={(e) => setLabelEditingDescription(e.target.value)} // Handle description change
                                                    InputProps={{
                                                        className: "view-task-label-title-input"
                                                    }}
                                                />
                                                <Typography
                                                    className="view-task-label-title-input view-task-label-color-input"
                                                >
                                                    {t("modal.label_color")}
                                                </Typography>
                                                <input
                                                    type="color"
                                                    value={labelEditingColor !== "rainbow" ? labelEditingColor : "#000000"}
                                                    onChange={(e) => debouncedHandleEditColorSelection(e.target.value)}
                                                    className={`view-task-label-color-picker ${labelColor === "rainbow" ? 'rainbow' : ''}`}
                                                    disabled={labelEditingColor === "rainbow"}
                                                />
                                                {labelEditingColor !== "rainbow" && (
                                                    <div
                                                        className="view-task-label-colors"
                                                    >
                                                        {defaultColors.map((color, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: color,
                                                                }}
                                                                className="view-task-label-color-item"
                                                                onClick={() => handleEditColorSelection(color)}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>{t("global.cancel")}</Button>
                                                <Button
                                                    onClick={() =>
                                                        handleEditLabel(labelEditingTitle, labelEditingColor, labelEditingID, labelEditingDescription)
                                                    }
                                                >
                                                    {t("global.save_changes")}
                                                </Button>
                                            </DialogActions>
                                        </>
                                    )}
                                </Dialog>
                            </Box>
                            <Typography className="view-task-description-margin">{t("modal.description")}</Typography>

                            <div
                                className="view-task-description-text-div"
                            >
                                <TextEditor
                                    handleDescription={handleDescription}
                                    handleCancel={handleCancel}
                                    value={description}
                                />
                            </div>

                            <ChecklistComponent
                                Task={Task}
                                handleSaveTask={handleSaveTask}
                                List_id={List.id}
                                checklists={checklists}
                                setChecklists={setChecklists}
                                editChecklistTitles={editChecklistTitles}
                                setEditChecklistTitles={setEditChecklistTitles}
                                newChecklistTitles={newChecklistTitles}
                                setNewChecklistTitles={setNewChecklistTitles}
                                setContextTaskMenuPosition={setContextTaskMenuPosition}
                                UpdateChecklistOrder={UpdateChecklistOrder}
                                UpdateChecklistItem={UpdateChecklistItem}
                                UpdateChecklistTitle={UpdateChecklistTitle}
                                DeleteChecklistItem={DeleteChecklistItem}
                                GetChecklists={GetChecklists}
                                DeleteChecklist={DeleteChecklist}
                                CreateChecklistItem={CreateChecklistItem}
                                avoidMisfire={avoidMisfire}
                                setRefreshFlag={setRefreshFlag}
                                refreshFlag={refreshFlag}
                                workspace_id={workspace_id}
                                project_id={myBoard.project}
                                ToggleHiddenStatusOfChecklists={ToggleHiddenStatusOfChecklists}
                                ToggleHiddenStatusOfChecklistItems={ToggleHiddenStatusOfChecklistItems}
                                newChecklistAdded={newChecklistAdded}
                                ConvertToTask={ConvertToTask}
                            />

                            <Comments task_id={Task.id} project_id={myBoard.project} />

                            <Typography className="view-task-activity-log-margin">
                                {t("modal.activity_log")}
                            </Typography>

                            <ul
                                className="view-task-activity-log-div"
                            >
                                {
                                    taskActivityLog?.length > 0 ? (
                                        taskActivityLog
                                            .slice(0, showAllActivity ? taskActivityLog.length : 2)
                                            .map((log, index) => {
                                                const getParsedMessage = () => {
                                                    const changes = log.changes ? log.changes.split(", ") : [];

                                                    if (
                                                        log.log_message.includes("added member") ||
                                                        log.log_message.includes("removed member")
                                                    ) {
                                                        const addedMember = "added member";
                                                        const removedMember = "removed member";
                                                        let searchTerm;

                                                        if (log.log_message.includes(addedMember)) {
                                                            searchTerm = addedMember;
                                                        } else if (log.log_message.includes(removedMember)) {
                                                            searchTerm = removedMember;
                                                        }

                                                        const index = log.log_message.indexOf(searchTerm) + searchTerm.length;
                                                        if (index > -1) {
                                                            const beforeText = log.log_message.substring(0, index) + " "; // Space added for clarity
                                                            const afterText = log.log_message.substring(index).trim();
                                                            const nameEndIndex =
                                                                afterText.indexOf(",") === -1 ? afterText.length : afterText.indexOf(",");
                                                            const name = afterText.substring(0, nameEndIndex);
                                                            const restOfMessage = afterText.substring(nameEndIndex);
                                                            return (
                                                                <span>
                                                                    {beforeText}
                                                                    <a href={`/workflow/profile/${log.referencing}`}>{name}</a>
                                                                    {restOfMessage}
                                                                </span>
                                                            );
                                                        }
                                                    } else if (changes.length > 0 && log.type !== "label") {


                                                        const changeDetail =
                                                            changes.length > 0 && changes[0].split(" changed from ");
                                                        const [field, values] = changeDetail || ["", ""];
                                                        const [oldVal, newVal] = values ? values.split(" to ") : ["", ""];

                                                        const tooltipContent = (
                                                            <div>
                                                                <span>Old: {oldVal?.trim()}</span>
                                                                <br />
                                                                <span>New: {newVal?.trim()}</span>
                                                            </div>
                                                        );

                                                        const fieldName = field.trim();

                                                        return (
                                                            <>
                                                                <div className="view-task-row-flex">
                                                                    {log.log_message.replace(fieldName, "").trim()}
                                                                    {'\u00A0'}
                                                                    <Tooltip title={tooltipContent} placement="top" arrow>
                                                                        <Typography
                                                                            className="view-task-tooltip"
                                                                        >
                                                                            {fieldName}.
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </div>
                                                            </>
                                                        );
                                                    } else {
                                                        return log.log_message;
                                                    }
                                                };

                                                return (
                                                    <li
                                                        key={index}
                                                        className="view-task-activity-log-list"
                                                    >
                                                        <Avatar
                                                            src={log.user_image}
                                                            alt={log.user?.username}
                                                            className="view-task-activity-log-avatar-margin"
                                                        />
                                                        <div>
                                                            <Typography>{getParsedMessage()}</Typography>

                                                            <Typography
                                                                className="view-task-activity-log-date-text"
                                                                variant="subtitle2"
                                                            >
                                                                {formatDateToString(convertToUTC(log.created_at))}
                                                            </Typography>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                    ) : (
                                        <li
                                            key="no-activity"
                                            className="white-txt-color view-task-activity-log-margin"
                                        >
                                            <Typography variant="body2" color="textSecondary">
                                                <span className="white-txt-color">
                                                    {t("modal.no_activity")}
                                                </span>
                                            </Typography>
                                        </li>
                                    )}
                            </ul>

                            {taskActivityLog?.length > 2 && (
                                <Button
                                    onClick={() => setShowAllActivity(!showAllActivity)}
                                    className="button-dark-bg view-task-show-more-activity-log"
                                >
                                    {showAllActivity ? t("modal.show_less_activity") : t("modal.show_all_activity")}
                                </Button>
                            )}



                        </Grid>
                        <Grid item xs={3} className={`view-task-actions-border ${actionsDropdownOpen ? 'show' : 'hide'}`}>
                            {" "}
                            {/* Actions box takes 20% of the width */}
                            <Box
                                className="view-task-actions"
                            >
                                <Typography className="view-task-actions-title">
                                    {t("modal.actions")}
                                </Typography>

                                <Button
                                    onClick={() => setCoverModalOpen(true)}
                                    className="view-task-actions-button"
                                >
                                    {t("modal.change_cover")}
                                </Button>

                                <Button
                                    onClick={() => handleTaskMembersDialogOpen()}
                                    className="view-task-actions-button"
                                >
                                    {t("modal.members")}
                                </Button>

                                <Dialog
                                    open={taskMembersDialogOpen}
                                    onClose={handleTaskMembersDialogClose}
                                    className="view-task-label-dialog"
                                >
                                    <DialogTitle className="view-task-label-title-width">
                                        {t("modal.select_members")}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="subtitle2">{t("modal.project_workers")}</Typography>
                                        <TextField
                                            placeholder={t("modal.search_workers")}
                                            onChange={handleSearchChange}
                                            variant="outlined"
                                            className="view-task-search-workers"
                                        />
                                        <div>
                                            {filteredProjectWorkers?.map((worker) => (
                                                <div
                                                    key={worker.id}
                                                    className="view-task-workers-list"
                                                >
                                                    <Checkbox
                                                        checked={selectedTaskMembers?.some(
                                                            (member) => member.id === worker.id
                                                        )}
                                                        onChange={() => toggleMemberSelection(worker)}
                                                        color="primary"
                                                    />
                                                    <div
                                                        onClick={() => toggleMemberSelection(worker)}
                                                        className={`view-task-member-list ${selectedTaskMembers?.some(member => member.id === worker.id) ? 'selected' : ''}`}
                                                    >
                                                        <Avatar
                                                            alt={worker.username}
                                                            src={
                                                                process.env.REACT_APP_BACKEND_URL + worker.image
                                                            }
                                                        />
                                                        <Typography
                                                            variant="body2"
                                                            className="view-task-worker-name"
                                                        >
                                                            {worker.full_name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleTaskMembersDialogClose} className="button-dark-bg">
                                            {t("global.cancel")}
                                        </Button>
                                        <Button onClick={() => handleSaveMembers()} className="button-dark-bg">
                                            {t("global.save")}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Button
                                    onClick={handleAddChecklist}
                                    className="view-task-actions-button"
                                >
                                    {t("modal.checklist_add")}
                                </Button>

                                <Button
                                    onClick={() => handleTaskDatesDialogOpen()}
                                    className="view-task-actions-button"
                                >
                                    {t("core.dates")}
                                </Button>

                                <TaskDatesDialog
                                    open={taskDatesDialogOpen}
                                    onClose={handleTaskDatesDialogClose}
                                    task={Task}
                                    handleSaveDates={handleSaveDates}
                                />

                                {List.title !== "Doing" && (
                                    <Button
                                        onClick={() => handleMarkAsDoing(Task.id)}
                                        className="view-task-actions-button"
                                    >
                                        {t("project_page.mark_doing")}
                                    </Button>
                                )}
                                {List.title !== "Done" && (
                                    <Button
                                        onClick={() => handleMarkAsDone(Task.id)}
                                        className="view-task-actions-button"
                                    >
                                        {t("project_page.mark_done")}
                                    </Button>
                                )}
                                <Button
                                    onClick={() => handleArchiveTaskModalOpen()}
                                    className="view-task-actions-button"
                                >
                                    {t("modal.archive")}
                                </Button>


                                <Typography className="view-task-actions-title">
                                    {t("modal.attachments")}
                                </Typography>

                                {/* Transparent button to upload new attachments */}
                                <Box>
                                    <input
                                        accept="*"
                                        id="upload-button-file"
                                        multiple
                                        type="file"
                                        className="no-display"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="upload-button-file">
                                        <Button
                                            variant="text"
                                            component="span"
                                            className="view-task-upload-button"
                                        >
                                            <UploadFileIcon className="view-task-attachments-icon" />
                                            {t("modal.upload_attachment")}
                                        </Button>
                                    </label>
                                </Box>

                                {/* List existing attachments */}
                                <Box className="view-task-attachments-list">
                                    {Task.attachments && Task.attachments.length > 0 && (
                                        Task.attachments.map((attachment, index) => {
                                            const fileUrl = `${process.env.REACT_APP_BACKEND_URL}${attachment.file}`;

                                            let AttachmentIcon;
                                            if (attachment.file_type === 'Image') {
                                                AttachmentIcon = <ImageIcon className="view-task-attachments-icon" />;
                                            } else if (attachment.file_type === 'Document') {
                                                AttachmentIcon = <InsertDriveFileIcon className="view-task-attachments-icon" />;
                                            } else if (attachment.file_type === 'Archive') {
                                                AttachmentIcon = <ArchiveIcon className="view-task-attachments-icon" />;
                                            } else {
                                                AttachmentIcon = <InsertDriveFileIcon className="view-task-attachments-icon" />;
                                            }

                                            return (
                                                <Box
                                                    key={index}
                                                    className="view-task-image-preview"
                                                    onClick={() => handleOpenDownloadWarning(attachment, fileUrl)}
                                                >
                                                    {AttachmentIcon}
                                                    <Box className="view-task-attachment-box">
                                                        <Typography className="view-task-attachment-name">{attachment.filename}</Typography>
                                                        <Typography variant="body2" color="rgba(255, 255, 255, 0.6)">
                                                            {t(`file_types.${attachment.file_type}`)}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton
                                                        className="view-task-attachment-delete-icon"
                                                        onClick={(event) => handleDeleteAttachment(attachment, event)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            );
                                        })
                                    )}

                                    {/* Image preview modal */}
                                    <Dialog open={openImagePreviewModal} onClose={handleCloseImageModal} >
                                        <DialogContent className="view-task-image-preview-modal">
                                            <IconButton
                                                onClick={handleCloseImageModal}
                                                className="view-task-image-preview-close-icon"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            {selectedPreviewImage && (
                                                <div onClick={handleImageTouch} style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={selectedPreviewImage}
                                                        alt="Preview"
                                                        className="view-task-attachment-image-preview"
                                                    />
                                                </div>
                                            )}
                                        </DialogContent>
                                    </Dialog>

                                    {/* Delete confirmation dialog */}
                                    <Dialog
                                        open={openDeleteDialog}
                                        onClose={handleCloseDeleteDialog}
                                        className="modal-background-color"
                                    >
                                        <DialogTitle className="view-task-delete-attachment-title">{t("modal.delete_attachment_title")}</DialogTitle>
                                        <DialogContent>
                                            <Typography>
                                                {t("modal.delete_attachment", { var1: selectedAttachment?.filename })}
                                            </Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={handleCloseDeleteDialog}
                                                className="button-dark-bg"
                                            >
                                                {t("global.cancel")}
                                            </Button>
                                            <Button
                                                onClick={() => handleConfirmDelete(false)}
                                                className="button-dark-bg"
                                            >
                                                {t("modal.delete_from_task")}
                                            </Button>
                                            <Button
                                                onClick={() => handleConfirmDelete(true)}
                                                className="decline-button"
                                            >
                                                {t("modal.delete_from_all")}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>

                                <CoverPickerModal
                                    open={openCover}
                                    setCoverModalOpen={setCoverModalOpen}
                                    saveNewCoverImage={saveNewCoverImage}
                                    cover_color={Task.cover_color}
                                    cover_image={Task.cover_image}
                                />

                                <DownloadWarningModal
                                    openDownloadWarningModal={openDownloadWarningModal}
                                    handleCloseDownloadWarningModal={handleCloseDownloadWarningModal}
                                    handleProceedDownload={handleProceedDownload}
                                    avoidMisfire={false}  
                                />

                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                </Box>
            </Dialog>
            <Dialog
                open={openCoverImageModal}
                onClose={handleCloseCoverImageModal}
            >
                <DialogContent className="view-task-image-preview-modal">
                    {Task.cover_image && (
                        <img
                            src={Task.cover_image}
                            alt="Cover Preview"
                            className="view-task-cover-image-preview"
                        />
                    )}
                </DialogContent>
            </Dialog>

        </>
    );
}

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation, Trans } from 'react-i18next';
import "../../css/main.css";

import "../../css/components/Modals/DownloadWarning.css";

export default function DownloadWarningModal({ openDownloadWarningModal, handleCloseDownloadWarningModal, handleProceedDownload, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openDownloadWarningModal} onClose={handleCloseDownloadWarningModal} className="modal-background-color">
            <DialogTitle>{t("modal.download_warning_title")}</DialogTitle>
            <DialogContent className="white-txt-color">
                <Trans i18nKey="modal.download_warning_message" />
            </DialogContent>
            <DialogContent className="download-warning-dialog-content">
                <WarningIcon className="download-warning-icon-margin-right" color="warning" />
                    {t("modal.download_risks_p1")}
                    {t("modal.download_risks_p2")}
                <WarningIcon className="download-warning-icon-margin-left" color="warning" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDownloadWarningModal} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleProceedDownload} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.proceed_download")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

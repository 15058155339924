import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Snackbar, Tooltip, FormControl, InputLabel, MenuItem, Select, IconButton, LinearProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useService } from 'contexts/ServiceContextProvider';

import NewSecretModal from 'components/Modals/CreateNewSecret';
import DeleteSecretModal from '../components/Modals/DeleteSecret';
import DeleteStorageModal from '../components/Modals/DeleteStorage';
import DeleteResourceModal from '../components/Modals/DeleteResource';
import NewResourceModal from '../components/Modals/CreateNewResource';
import TextEditor from "../components/Custom/TextEditor";
import { useTranslation } from "react-i18next";
import '../css/views/ProjectDetails.css';
import '../css/main.css';
import { checkForWorkspaceStorageLimitError } from 'utils/WorkspaceStorageLimit';  // Import the utility function
import { useSnackbar } from 'contexts/SnackbarContextProvider';  // Import the Snackbar context

import DownloadWarningModal from '../components/Modals/DownloadWarning'

import LockIcon from '@mui/icons-material/Lock';

const ProjectDetails = () => {

    const { t } = useTranslation();

    const { showSnackbar } = useSnackbar();
    const { UpdateProjectStatus, getProjectStatus, GetBoardDetails, getLoggedUserPermissions, UpdateImportantInformation, AddASecret, DeleteSecret, AddAResource, DeleteResource, AddFileToStorage, DeleteStorageFile } = useService();

    const [myBoardDetails, setMyBoardDetails] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { getLoggedUser } = useAuthorization();
    const workspaceContext = useWorkspaces();
    const [avoidMisfire, setAvoidMisfire] = useState(false);
    const myWorkspaces = workspaceContext.workspaces;

    const [openSecretModal, setOpenSecretModal] = useState(false);
    const [openResourceModal, setOpenResourceModal] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [editedInformation, setEditedInformation] = useState('');
    const [, setOriginalInformation] = useState('');

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [secretToDelete, setSecretToDelete] = useState(null);

    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const [openStorageModal, setOpenStorageModal] = useState(false);
    const [storageFileToDelete, setStorageFileToDelete] = useState(null);

    const [openResourceDeleteModal, setOpenResourceDeleteModal] = useState(false);
    const [resourceToDelete, setResourceToDelete] = useState(null);
    const [accessType,] = useState(0);

    const [status, setStatus] = useState('');
    const [visibility, setVisibility] = useState(true);
    const [projectID, setProjectID] = useState(true);

    const [secretVisibility, setSecretVisibility] = useState({});

    const [myUserPerms, setMyUserPerms] = useState({});

    const [uploadProgress, setUploadProgress] = useState(0);

    const [openDownloadWarningModal, setOpenDownloadWarningModal] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);

    const handleFileClick = (fileUrl) => {
        setSelectedFileUrl(fileUrl);  // Save the file URL
        setOpenDownloadWarningModal(true);  // Show the download warning modal
    };

    // Function to proceed with the download after confirmation
    const handleProceedDownload = () => {
        window.open(selectedFileUrl, '_blank');  // Open the file in a new tab
        setOpenDownloadWarningModal(false);  // Close the modal
    };

    // Function to close the download warning modal
    const handleCloseDownloadWarningModal = () => {
        setOpenDownloadWarningModal(false);
        setSelectedFileUrl(null);  // Clear the file URL
    };


    const getFileType = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const fileExtensionMapping = {
            'pdf': 'Document',
            'doc': 'Document',
            'docx': 'Document',
            'xls': 'Document',
            'xlsx': 'Document',
            'ppt': 'Document',
            'pptx': 'Document',
            'txt': 'Document',
            'jpg': 'Image',
            'jpeg': 'Image',
            'png': 'Image',
            '7z': 'Archive',
            'rar': 'Archive',
            'zip': 'Archive',
        };
        return fileExtensionMapping[extension] || 'Other';
    };

    const handleAddToStorage = async (event) => {
        const file = event.target.files[0];
        event.target.value = ''; // Reset file input field value

        if (file && file.size <= 5000 * 1024 * 1024) { // Check if file size is within limit (5GB)
            try {
                const response = await AddFileToStorage(
                    myWorkspaces[0].id,
                    projectID,
                    myBoardDetails.id,
                    file,
                    // Progress callback function
                    (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                            setUploadProgress(progress);  // Update the progress state
                        }
                    }
                );

                if (response.status === 201) {
                    const newFile = {
                        id: response.data.id,
                        file: response.data.file,
                        filename: response.data.file.split('/').pop(),
                        file_type: getFileType(response.data.file.split('/').pop()),
                    };

                    const updatedBoardDetails = { ...myBoardDetails };
                    updatedBoardDetails.storage.push(newFile);
                    setMyBoardDetails(updatedBoardDetails);

                    setSnackbarMessage(t("project_details.file_uploaded_success"));
                }
            } catch (error) {
                checkForWorkspaceStorageLimitError(error, t, showSnackbar);
            } finally {
                setUploadProgress(0);
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage(t("project_details.file_uploaded_exceed"));
            setSnackbarOpen(true);
        }
    };

    const handleOpenResourceDeleteModal = (resourceID) => {
        setResourceToDelete(resourceID);
        setOpenResourceDeleteModal(true);
    };

    const handleCloseResourceDeleteModal = () => {
        setResourceToDelete(null);
        setOpenResourceDeleteModal(false);
    };

    const handleOpenStorageModal = (fileID) => {
        setStorageFileToDelete(fileID);
        setOpenStorageModal(true);
    };

    const handleCloseStorageModal = () => {
        setStorageFileToDelete(null);
        setOpenStorageModal(false);
    };

    const handleOpenConfirmationModal = (secretID) => {
        setSecretToDelete(secretID);
        setOpenConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
        setSecretToDelete(null);
        setOpenConfirmationModal(false);
    };

    const handleEditClick = () => {
        setOriginalInformation(myBoardDetails.important_information);
        setEditedInformation(myBoardDetails.important_information);
        setEditMode(!editMode);
    };

    const handleSaveClick = async (json) => {

        myBoardDetails.important_information = json;
        try {
            await UpdateImportantInformation(myWorkspaces[0].id, projectID, myBoardDetails.id, json);
            myBoardDetails.important_information = json;
            setEditMode(false);
        }
        catch (error) {
            checkForWorkspaceStorageLimitError(error, t, showSnackbar);
        }
    };

    const handleResourceModalOpen = () => {
        setOpenResourceModal(true);
    };

    const handleResourceModalClose = () => {
        setOpenResourceModal(false);
    };

    const handleSecretModalOpen = () => {
        setOpenSecretModal(true);
    };

    const handleSecretModalClose = () => {
        setOpenSecretModal(false);
    };

    const handleCreateResource = async (title, hyperlink, visibility) => {
        setAvoidMisfire(true);
        try {
            const response = await AddAResource(myWorkspaces[0].id, projectID, myBoardDetails.id, title, hyperlink, visibility);
            if (response) {

                const newResource = response.data;
                const updatedResources = [...myBoardDetails.resources, newResource];
                setMyBoardDetails({ ...myBoardDetails, resources: updatedResources });
            }

            handleResourceModalClose();
        } catch (error) {
            console.error('Creating resource Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleResourceModalClose();
    };

    const handleDeleteResource = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteResource(myWorkspaces[0].id, projectID, myBoardDetails.id, resourceToDelete);
            if (response) {
                const updatedResources = myBoardDetails.resources.filter(resource => resource.id !== resourceToDelete);
                setMyBoardDetails({ ...myBoardDetails, resources: updatedResources });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting resource Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseResourceDeleteModal();
    };
    const handleCreateSecret = async (title, secret, visibility) => {
        setAvoidMisfire(true);
        try {
            const response = await AddASecret(myWorkspaces[0].id, projectID, myBoardDetails.id, title, secret, visibility);
            const newSecret = { "id": response.data.id, "title": title, "secret": secret, "type_of_visibility": visibility }
            const updatedSecrets = [...myBoardDetails.secret_section, newSecret];
            setMyBoardDetails({ ...myBoardDetails, secret_section: updatedSecrets });

        } catch (error) {
            console.error('Creating secret Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleSecretModalClose();
    };

    const handleDeleteSecret = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteSecret(myWorkspaces[0].id, projectID, myBoardDetails.id, secretToDelete);
            if (response) {
                const updatedSecret = myBoardDetails.secret_section.filter(secret => secret.id !== secretToDelete);
                setMyBoardDetails({ ...myBoardDetails, secret_section: updatedSecret });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting secret Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseConfirmationModal();
    };

    const handleDeleteStorage = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteStorageFile(myWorkspaces[0].id, projectID, myBoardDetails.id, storageFileToDelete);
            if (response) {
                const updatedStorage = myBoardDetails.storage.filter(storage => storage.id !== storageFileToDelete);
                setMyBoardDetails({ ...myBoardDetails, storage: updatedStorage });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting file Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseStorageModal();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchUserPerms = async () => {
            if (myWorkspaces[0]?.id) {
                try {
                    const userPerms = await getLoggedUserPermissions(myWorkspaces[0]?.id, getLoggedUser());
                    setMyUserPerms(userPerms);
                } catch (error) {
                    console.error('Error fetching user permissions:', error);
                }
            }
        };
        fetchUserPerms();
    }, [myWorkspaces[0]?.id]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        async function fetchData() {
            const currentPath = window.location.pathname;
            const pathParts = currentPath.split('/');
            const projectId = pathParts[pathParts.length - 2];
            setProjectID(projectId);

            const [boardDetail] = await GetBoardDetails(myWorkspaces[0]?.id, projectId);

            const boardName = boardDetail.name.replace(/^Board for\s*/i, '');

            document.title = boardName + " Details - Workflow PRO";

            setMyBoardDetails(boardDetail);
            setEditedInformation(boardDetail.important_information);
            setOriginalInformation(boardDetail.important_information);


            const projectStatus = await getProjectStatus(myWorkspaces[0]?.id, projectId);
            setStatus(projectStatus.data.status);
            setVisibility(projectStatus.data.visibility);
        }
        if (myWorkspaces[0]) {
            fetchData();
        }
    }, [myWorkspaces]);

    // Function to handle copying the secret to the clipboard and show Snackbar
    const copyToClipboard = (secret) => {
        setSnackbarMessage(t("project_details.copy_secret"))
        navigator.clipboard.writeText(secret);
        setSnackbarOpen(true);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleVisibilityChange = (event) => {
        setVisibility(event.target.value === 'true');
    };

    const handleSaveStatusVisibility = async () => {
        try {
            await UpdateProjectStatus(projectID, { status, visibility });
            setSnackbarMessage(t("project_details.status_visibily_update"));
        } catch (error) {
            setSnackbarMessage(t("project_details.status_visibily_update_fail"));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const toggleSecretVisibility = (id) => {
        setSecretVisibility(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleDescription = async (json) => {
        try {
            setEditedInformation(json);
            handleSaveClick(json);
        } catch (error) {
            console.error("Error updating task description:", error);
        }
    };

    return (
        <>
            <div className="div-row-details">
                <FormControl className="form-details">
                    <InputLabel
                        className="input-label-details"
                    >{t('global.status')}</InputLabel>
                    <Select
                        className={`.select-details ${status === 'completed'
                            ? 'status-completed-details'
                            : status === 'ongoing'
                                ? 'status-ongoing-details'
                                : status === 'archived'
                                    ? 'status-archived-details'
                                    : 'status-not-started-details'
                            }`}
                        MenuProps={{
                            PaperProps: {
                                className: "props-details",
                            },
                        }}
                        value={status}
                        onChange={handleStatusChange}
                        disabled={!(myUserPerms?.is_admin || myUserPerms?.is_owner)}
                    >
                        <MenuItem value="not_started">{t('project.stat_notstart')}</MenuItem>
                        <MenuItem value="ongoing">{t('project.stat_ongoing')}</MenuItem>
                        <MenuItem value="completed">{t('project.stat_completed')}</MenuItem>
                        <MenuItem value="archived">{t('project.stat_archived')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="form-details">
                    <InputLabel
                        className="input-label-details"
                    >{t('global.visibility')}</InputLabel>
                    <Select
                        className={`select-details ${visibility.toString() === 'true' ? 'visibility-true-details' : 'visibility-false-details'
                            }`}
                        MenuProps={{
                            PaperProps: {
                                className: "props-details",
                            },
                        }}
                        value={visibility.toString()}
                        onChange={handleVisibilityChange}
                        disabled={!(myUserPerms?.is_admin || myUserPerms?.is_owner)}
                    >
                        <MenuItem value="true">{t('global.public')}</MenuItem>
                        <MenuItem value="false">{t('global.private')}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleSaveStatusVisibility} disabled={!(myUserPerms?.is_admin || myUserPerms?.is_owner)} className="button-light-bg">
                    {t('project_details.save_stat_vis')}
                </Button>
            </div>

            <div className="div-cards-details">

                {/* Resource Card */}
                <Card className="resource-card-details">
                    <CardContent>
                        <div className="div-resource-card-details">
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.resources')}
                            </Typography>
                            <Button onClick={() => handleResourceModalOpen()} className="add-button-color-details">
                                <span className="button-text-details"> {t('project_details.add_resource')} </span>  <AddIcon className="add-resource-icon-details" />
                            </Button>
                        </div>
                        <Typography variant="body2" className="card-description-details">
                            {t('project_details.resource_info')}
                        </Typography>
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.resources &&
                                    myBoardDetails.resources.map((resource) => {
                                        if (resource.visibility || myUserPerms.is_owner || myUserPerms.is_admin) {
                                            return (
                                                <li key={resource.id}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <a href={resource.hyperlink} target={"_blank"} rel="noopener noreferrer">{resource.title}</a>
                                                        <div style={{ marginRight: 0, marginLeft: "auto", alignItems: "center" }}>
                                                            {!resource.visibility && (
                                                                <Tooltip title={t("project_details.resource_lock")}>
                                                                    <LockIcon sx={{ color: "white", marginRight: "2px" }} />
                                                                </Tooltip>
                                                            )}
                                                            <IconButton sx={{}} color="error" onClick={() => handleOpenResourceDeleteModal(resource.id)} disabled={accessType === 0}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                        // Don't render anything if the user doesn't have permission and visibility is false
                                        return null;
                                    })
                                }

                            </ul>
                        </div>
                    </CardContent>
                </Card>

                {/* Important Information Card */}
                <Card className="resource-card-details">
                    <CardContent className="white-txt-color">
                        <Typography variant="h5" component="div" gutterBottom>
                            {t('project_details.important_info')}
                        </Typography>
                        <div style={{ maxHeight: '275px', overflowY: 'auto', paddingRight: '10px' }}>
                            {editMode ? (
                                <div key={"editable"}>
                                    <TextEditor
                                        handleDescription={handleDescription}
                                        handleCancel={handleEditClick}
                                        value={editedInformation}
                                        editable={true}
                                    />
                                </div>
                            ) : (
                                <div key={"read_only"}>
                                    <TextEditor
                                        handleDescription={handleDescription}
                                        handleCancel={handleEditClick}
                                        value={myBoardDetails.important_information}
                                        editable={false}
                                    />
                                </div>
                            )}
                        </div>
                        {!editMode && (
                            <Button className="button-dark-bg" onClick={handleEditClick}>{t('project_details.edit_info')}</Button>
                        )}
                    </CardContent>
                </Card>


                {/* Password/Secret Section Card */}
                <Card className="resource-card-details">
                    <CardContent>
                        <div className="div-resource-card-details">
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.password_sect')}
                            </Typography>
                            <Button onClick={() => handleSecretModalOpen()} className="add-button-color-details">
                                <span className="button-text-details"> {t('project_details.add_secret')} </span> <AddIcon className="add-resource-icon-details" />
                            </Button>
                        </div>
                        <Typography variant="body2" className="card-description-details">
                            {t('project_details.secret_info')}
                        </Typography>
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.secret_section &&
                                    myBoardDetails.secret_section.map((secret) => {

                                        const hasPermission =
                                            myUserPerms?.is_owner ||
                                            (myUserPerms?.is_admin && (secret.type_of_visibility === "admin" || secret.type_of_visibility === "user")) ||
                                            (!myUserPerms?.is_admin && !myUserPerms?.is_owner && secret.type_of_visibility === "user");

                                        return (
                                            <li key={secret.id}>
                                                <div className="div-secret-section-details">
                                                    <span className={`copy-text-details ${hasPermission ? 'copy-text-pointer-details' : ''}`} onClick={hasPermission ? () => copyToClipboard(secret.secret) : null}>
                                                        {secret.title} : {hasPermission && secretVisibility[secret.id] ? <span className="see-secret-color-details">{secret.secret}</span> : "********"}
                                                    </span>
                                                    {hasPermission && (
                                                        <IconButton className="toggle-secret-btn-details" onClick={() => toggleSecretVisibility(secret.id)}>
                                                            {secretVisibility[secret.id] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    )}
                                                    <Tooltip
                                                        title={!hasPermission ? t('project_details.secrets_permission') : ""}
                                                    >
                                                        <span>
                                                            <IconButton className={`delete-btn-details ${hasPermission ? 'secrets-delete-btn-error-details' : 'secrets-delete-btn-disabled-details'}`} onClick={() => handleOpenConfirmationModal(secret.id)} disabled={!hasPermission}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>

                            {/* Snackbar for displaying copy success notification */}
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={() => setSnackbarOpen(false)}
                                message={snackbarMessage}
                                className="snackbar-details" />
                        </div>
                    </CardContent>
                </Card>

                {/* Cloud Storage for Files Card */}
                <Card className="resource-card-details">
                    <CardContent>
                        <div className="div-resource-card-details">
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.cloud_storage')}
                            </Typography>
                            <div>
                                <label htmlFor="file-upload">
                                    <Button component="span" className="add-button-color-details">
                                        <span className="button-text-details">{t('project_details.add_file')}</span> <AddIcon className="add-resource-icon-details" />
                                    </Button>
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.zip,.7z,.rar,.mkv"
                                    onChange={handleAddToStorage}
                                    className="no-display" />
                            </div>
                        </div>
                        <Typography variant="body2" className="card-description-details">
                            {t('project_details.files_info')}
                        </Typography>
                        {uploadProgress > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                <Typography variant="body2" color="white">
                                    {`${uploadProgress}%`}
                                </Typography>
                                <LinearProgress variant="determinate" value={uploadProgress} />
                            </div>
                        )}
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.storage &&
                                    (myBoardDetails.storage.map((file) => (
                                        <li key={file.id}>
                                            <div className="div-secret-section-details">
                                                <Typography>  {file.filename} :  <a className="file-link" onClick={() => handleFileClick(process.env.REACT_APP_BACKEND_URL + file.file)}>
                                                    {file.file_type}
                                                </a></Typography>
                                                <Tooltip
                                                    title={!(myUserPerms?.is_admin || myUserPerms?.is_owner) ? t('project_details.files_permission') : ""}
                                                >
                                                    <span>
                                                        <IconButton
                                                            onClick={() => handleOpenStorageModal(file.id)}
                                                            disabled={!(myUserPerms?.is_admin || myUserPerms?.is_owner)}
                                                            className={`cloud-storage-icon-details ${(myUserPerms?.is_admin || myUserPerms?.is_owner) ? 'secrets-delete-btn-error-details' : 'secrets-delete-btn-disabled-details'}`}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </li>
                                    )))}
                            </ul>
                        </div>
                    </CardContent>
                </Card>
                <DeleteSecretModal openConfirmationModal={openConfirmationModal} handleCloseConfirmationModal={handleCloseConfirmationModal} handleDeleteSecret={handleDeleteSecret} avoidMisfire={avoidMisfire} />
                <DeleteStorageModal openStorageModal={openStorageModal} handleCloseStorageModal={handleCloseStorageModal} handleDeleteStorage={handleDeleteStorage} avoidMisfire={avoidMisfire} />
                <DeleteResourceModal openResourceDeleteModal={openResourceDeleteModal} handleCloseResourceDeleteModal={handleCloseResourceDeleteModal} handleDeleteResource={handleDeleteResource} avoidMisfire={avoidMisfire} />
                <NewSecretModal openSecretModal={openSecretModal} handleSecretModalClose={handleSecretModalClose} handleCreateSecret={handleCreateSecret} avoidMisfire={avoidMisfire} myUserPerms={myUserPerms} />
                <NewResourceModal openResourceModal={openResourceModal} handleResourceModalClose={handleResourceModalClose} handleCreateResource={handleCreateResource} avoidMisfire={avoidMisfire} myUserPerms={myUserPerms} />
                <DownloadWarningModal
                    openDownloadWarningModal={openDownloadWarningModal}
                    handleCloseDownloadWarningModal={handleCloseDownloadWarningModal}
                    handleProceedDownload={handleProceedDownload}
                    avoidMisfire={avoidMisfire}
                />                    
            </div>
        </>
    );
};

export default ProjectDetails;

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    Typography,
    Avatar,
    Grid,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Divider
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import { useService } from 'contexts/ServiceContextProvider';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import "../../../css/components/Calendar/Events/EventsDetailsModal.css";
import "../../../css/main.css";

const EventDetailsModal = ({ open, onClose, selectedEvent, myUserPerms, onSave, workspaceId }) => {
    const { getWorkspaceWorkers } = useService();
    const { t } = useTranslation();

    const [editableEvent, setEditableEvent] = useState({
        id: '',
        title: '',
        description: '',
        collaborators: [],
        colorCode: '#3788d8',
        all_day_event: false,
        date_start: '',
        date_end: '',
        visibility: false,
    });

    const [initialState, setInitialState] = useState(null);
    const [workspaceWorkers, setWorkspaceWorkers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        if (!open) {
            setIsEditing(false);
        }
    }, [open]);

    useEffect(() => {
        if (selectedEvent) {
            const eventDetails = {
                id: selectedEvent.id || '',
                title: selectedEvent.title || '',
                description: selectedEvent.extendedProps.description || '',
                collaborators: selectedEvent.extendedProps.collaborators || [],
                colorCode: selectedEvent.backgroundColor || '#3788d8',
                all_day_event: selectedEvent.allDay || false,
                date_start: selectedEvent.start ? moment(selectedEvent.start).format(selectedEvent.allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm') : '',
                date_end: selectedEvent.end ? moment(selectedEvent.end).format(selectedEvent.allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm') : '',
                visibility: selectedEvent.extendedProps.visibility || false,
            };
            setEditableEvent(eventDetails);
            setInitialState(eventDetails);
        }
    }, [selectedEvent]);

    useEffect(() => {
        if (open && workspaceId) {
            const fetchWorkspaceWorkers = async () => {
                try {
                    const workers = await getWorkspaceWorkers(workspaceId);
                    setWorkspaceWorkers(workers);
                } catch (error) {
                    console.error("Error fetching workspace workers: ", error);
                }
            };
            fetchWorkspaceWorkers();
        }
    }, [open, workspaceId, getWorkspaceWorkers]);

    const handleAddCollaborator = (worker) => {
        setEditableEvent((prev) => ({
            ...prev,
            collaborators: [...prev.collaborators, worker],
        }));
    };

    const handleRemoveCollaborator = (workerId) => {
        setEditableEvent((prev) => ({
            ...prev,
            collaborators: prev.collaborators.filter((collab) => collab.id !== workerId),
        }));
    };

    const handleEdit = () => {
        setInitialState(editableEvent);
        setIsEditing(true);
    };

    const handleCancel = () => {
        setEditableEvent(initialState);
        setIsEditing(false);
    };

    const handleSave = () => {
        onSave(editableEvent);
        setIsEditing(false);
    };

    const filteredCollaborators = editableEvent.collaborators.filter((collab) =>
        collab.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const availableWorkspaceWorkers = workspaceWorkers.filter(
        (worker) => !editableEvent.collaborators.some((collab) => collab.id === worker.id)
    );

    const filteredAvailableWorkers = availableWorkspaceWorkers.filter((worker) =>
        worker.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    //console.log(editableEvent)

    const formatDate = (date, isAllDay) => {
        const dateObj = new Date(date);
        if (isAllDay) {
            return dateObj.toLocaleDateString();
        } else {
            return `${dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        }
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle
                className="event-details-main-styles"
            >
                {isEditing ? t("calendar.event_edit_title") : editableEvent.title}
            </DialogTitle>

            <DialogContent className="event-details-content-dialogue">
                <Grid container spacing={2}>
                    {isEditing && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t("calendar.event_title")}
                                value={editableEvent.title}
                                onChange={(e) => setEditableEvent({ ...editableEvent, title: e.target.value })}
                                className="event-details-textfield"
                                InputLabelProps={{ className: "event-details-text-color" }}
                                InputProps={{ className: "white-txt-color" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                        {isEditing ? (
                            <TextField
                                fullWidth
                                multiline
                                label={t("calendar.event_description")}
                                value={editableEvent.description}
                                onChange={(e) => setEditableEvent({ ...editableEvent, description: e.target.value })}
                                rows={4}
                                className="event-details-textfield"
                                InputLabelProps={{ className: "event-details-text-color" }}
                                InputProps={{ className: "white-txt-color" }}
                            />
                        ) : (
                            <Typography variant="body1" className="event-details-description">
                                <strong>{t("calendar.event_description")}</strong><br /> {editableEvent.description || t("calendar.event_no_description")}
                            </Typography>
                        )}
                    </Grid>

                    {isEditing && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editableEvent.all_day_event}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, all_day_event: e.target.checked })}
                                        className="event-details-text-color"
                                    />
                                }
                                label={t("calendar.event_all_day")}
                                className="event-details-text-color"
                            />
                        </Grid>
                    )}

                    {isEditing && (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={t("global.start_date")}
                                    type={editableEvent.all_day_event ? 'date' : 'datetime-local'}
                                    value={editableEvent.date_start}
                                    onChange={(e) => setEditableEvent({ ...editableEvent, date_start: e.target.value })}
                                    className="event-details-textfield"
                                    InputLabelProps={{ className: "event-details-text-color", shrink: true }}
                                    InputProps={{ className: "white-txt-color" }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={t("calendar.event_end_date")}
                                    type={editableEvent.all_day_event ? 'date' : 'datetime-local'}
                                    value={editableEvent.date_end}
                                    onChange={(e) => setEditableEvent({ ...editableEvent, date_end: e.target.value })}
                                    className="event-details-textfield"
                                    InputLabelProps={{ className: "event-details-text-color", shrink: true }}
                                    InputProps={{ className: "white-txt-color" }}
                                />
                            </Grid>
                        </>
                    )}

                    {isEditing && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editableEvent.visibility}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, visibility: e.target.checked })}
                                        className="event-details-text-color"
                                    />
                                }
                                label={t("calendar.event_public")}
                                className="event-details-text-color"
                            />
                        </Grid>
                    )}

                    {isEditing && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t("calendar.event_color")}
                                value={editableEvent.colorCode}
                                onChange={(e) => setEditableEvent({ ...editableEvent, colorCode: e.target.value })}
                                type="color"
                                className="event-details-textfield"
                                InputLabelProps={{ className: "event-details-text-color" }}
                                InputProps={{ className: "white-txt-color" }}
                            />
                        </Grid>
                    )}
                    {!isEditing && (
                        <Box className="event-details-edit">
                            <Box>
                                <Box className="event-details-edit-content">

                                    {!editableEvent.all_day_event && (
                                        <>
                                            <EventIcon className="event-details-event-info-margin" />
                                            <Typography variant="body1">
                                                {new Date(editableEvent.date_start).toLocaleDateString()}
                                            </Typography></>
                                    )}

                                    <AccessTimeIcon className="event-details-access-info-margin" />
                                    <Typography variant="body1">
                                        {editableEvent.all_day_event
                                            ? `All Day Event - From ${formatDate(editableEvent.date_start, true)} to ${formatDate(editableEvent.date_end, true)}`
                                            : `${formatDate(editableEvent.date_start, false)} to ${formatDate(editableEvent.date_end, false)}`
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Grid item xs={12} className="event-details-collabs-grid">

                        <Typography variant="h6" className="event-details-text-color">
                            {t("calendar.event_collabs")}
                        </Typography>
                        {isEditing && (
                            <>
                                <Divider orientation="vertical" flexItem className="event-details-divider" />
                                <Typography variant="h6" className="event-details-add-collabs">
                                    {t("calendar.event_add_collabs")}
                                </Typography>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder={t("calendar.event_collab_search")}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="event-details-textfield"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="event-details-text-color" />
                                    </InputAdornment>
                                ),
                                className: "white-txt-color",
                            }}
                            InputLabelProps={{ className: "event-details-text-color" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <List className="event-details-collab-list">
                            {filteredCollaborators.length > 0 ? (
                                filteredCollaborators.map((collab) => (
                                    <ListItem key={collab.id} button={isEditing} onClick={() => isEditing && handleRemoveCollaborator(collab.id)}>
                                        <ListItemAvatar>
                                            <Avatar src={process.env.REACT_APP_BACKEND_URL + collab.image} />
                                        </ListItemAvatar>
                                        <ListItemText primary={collab.full_name} className="event-details-text-color" />
                                        {isEditing && (
                                            <IconButton edge="end" onClick={() => handleRemoveCollaborator(collab.id)}>
                                                <RemoveCircleIcon className="event-details-remove-collab" />
                                            </IconButton>
                                        )}
                                    </ListItem>
                                ))
                            ) : (
                                <Typography variant="body2" className="event-details-no-collabs">
                                    {t("calendar.event_no_collabs")}
                                </Typography>
                            )}
                        </List>
                    </Grid>

                    {isEditing && (
                        <Grid item xs={12} md={6}>
                            <List className="event-details-collab-list">
                                {filteredAvailableWorkers.length > 0 ? (
                                    filteredAvailableWorkers.map((worker) => (
                                        <ListItem key={worker.id} button onClick={() => handleAddCollaborator(worker)}>
                                            <ListItemAvatar>
                                                <Avatar src={process.env.REACT_APP_BACKEND_URL + worker.image} />
                                            </ListItemAvatar>
                                            <ListItemText primary={worker.full_name} className="event-details-text-color" />
                                            <IconButton edge="end" onClick={() => handleAddCollaborator(worker)}>
                                                <AddCircleIcon className="event-details-add-collab" />
                                            </IconButton>
                                        </ListItem>
                                    ))
                                ) : (
                                    <Typography variant="body2" className="event-details-no-collabs">
                                        {t("calendar.event_no_workers")}
                                    </Typography>
                                )}
                            </List>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Box className="buttons-flex">
                            {isEditing ? (
                                <>
                                    <Button onClick={handleCancel} className="decline-button">
                                        {t("global.cancel")}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSave}
                                        className="accept-button"
                                    >
                                        {t("global.save_changes")}
                                    </Button>
                                </>
                            ) : (
                                myUserPerms?.is_admin || myUserPerms?.is_owner ? (
                                    <Button
                                        variant="contained"
                                        onClick={handleEdit}
                                        className="button-light-bg"
                                    >
                                        {t("calendar.event_edit")}
                                    </Button>
                                ) : null
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default EventDetailsModal;

import React, { useEffect, useState, useRef } from "react";
import { Box, Tooltip, Checkbox, IconButton, LinearProgress, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteChecklistModal from "../Modals/DeleteChecklist";
import PreviewIcon from '@mui/icons-material/Preview';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import "../../css/components/Custom/ChecklistComponent.css";
import "../../css/main.css";

const ChecklistComponent = ({ Task, handleSaveTask, List_id, checklists, setChecklists, editChecklistTitles, setEditChecklistTitles,
    newChecklistTitles, setNewChecklistTitles, setContextTaskMenuPosition,
    UpdateChecklistOrder, UpdateChecklistItem, UpdateChecklistTitle, DeleteChecklistItem,
    GetChecklists, DeleteChecklist, CreateChecklistItem, avoidMisfire, setRefreshFlag, refreshFlag, workspace_id,
    project_id, ToggleHiddenStatusOfChecklists, ToggleHiddenStatusOfChecklistItems, newChecklistAdded, ConvertToTask }) => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const [currentlyDraggingChecklistId, setCurrentlyDraggingChecklistId] = useState(null);
    const [openDeleteChecklistModal, setOpenChecklistModal] = useState(false);
    const [selectedChecklistId, setSelectedChecklistId] = useState(-1);
    const [menuState, setMenuState] = useState({ anchorEl: null, itemId: null });
    const [anchorElMap, setAnchorElMap] = useState({});

    const [dragTimeout, setDragTimeout] = useState(null);

    const inputRefs = useRef({});

    const lastChecklistRef = useRef(null);

    useEffect(() => {
        if (newChecklistAdded && lastChecklistRef.current) {
            lastChecklistRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [newChecklistAdded]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (Task.id) {
            fetchChecklists();
        }
    }, [Task]);

    const handleChecklistModalOpen = (id) => {
        setOpenChecklistModal(true);
        setSelectedChecklistId(id);
    };

    const handleDeleteChecklistModalClose = () => {
        setOpenChecklistModal(false);
    };

    const handleCloseAndDelete = () => {
        setOpenChecklistModal(false);
        handleDeleteChecklistModalClose();
        handleDeleteChecklist();
    };

    const fetchChecklists = async () => {
        const fetchedChecklists = await GetChecklists(workspace_id, project_id, Task.id);

        setChecklists(sortChecklists(fetchedChecklists));
    };


    const sortChecklists = (unsortedChecklists) => {
        const sortedChecklists = unsortedChecklists.map(cl => {
            const sortedItems = cl.items.map(item => ({
                ...item,
            })).sort((a, b) => a.order - b.order);
            return {
                ...cl,
                items: sortedItems
            };
        });
        return sortedChecklists.sort((a, b) => a.order - b.order);
    };

    const handleDeleteChecklist = async () => {
        try {
            await DeleteChecklist(workspace_id, project_id, selectedChecklistId);
            const updatedChecklists = checklists.filter(item => item.id !== selectedChecklistId);
            setCompleted(updatedChecklists, selectedChecklistId);
            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);
        } catch (error) {
            console.error("Error creating checklist:", error);
        }
    }

    const handleAddChecklistItem = async (checklistId) => {
        const title = newChecklistTitles[checklistId] || '';
        if (title.trim()) {
            const newItem = await CreateChecklistItem(workspace_id, project_id, checklistId, title);
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return { ...cl, items: [...cl.items, newItem] };
                }
                return cl;
            });
            setChecklists(updatedChecklists);
            setCompleted(updatedChecklists, checklistId);
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: ''
            }));
            setRefreshFlag(!refreshFlag);
        }
        if (inputRefs.current[checklistId]) {
            inputRefs.current[checklistId].focus();
        }
    };

    /**
     * Updates Task.checklist_completion.total and Task.checklist_completion.completed on the parent (ProjectPage).
     * @param updatedChecklists
     * @param checklistId
     */
    const setCompleted = (updatedChecklists, checklistId) => {
        const checklist = updatedChecklists.filter(item => item.id === checklistId);
        const allChecked = checklist[0]?.items.every(item => item.checked === true);

        let total = 0;
        let completed = 0;
        updatedChecklists.forEach(cl => {
            cl.items.forEach(item => {
                if (item.checked) {
                    completed += 1;
                }
                total += 1;
            })
            if (allChecked) {

            }
        })

        Task.checklist_completion.total = total;
        Task.checklist_completion.completed = completed;
    }

    const handleToggleChecklistItem = async (checklistId, itemId, checked) => {
        await UpdateChecklistItem(workspace_id, project_id, checklistId, itemId, { checked });
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                return {
                    ...cl, items: cl.items.map(item => item.id === itemId ? { ...item, checked } : item)
                };
            }
            return cl;
        });
        setCompleted(updatedChecklists, checklistId);
        setChecklists(updatedChecklists);
        setRefreshFlag(!refreshFlag);

    };

    const handleDeleteChecklistItem = async (checklistId, itemId) => {

        try {
            // Delete the item from the backend
            await DeleteChecklistItem(workspace_id, project_id, itemId);

            // Update the state immutably to reflect the deletion
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return {
                        ...cl,
                        items: cl.items.filter(item => item.id !== itemId) // Remove the deleted item
                    };
                }
                return cl;
            });

            // Update the state with the modified checklists
            setChecklists([...updatedChecklists]);

            // Update the completion percentage or other data
            setCompleted(updatedChecklists, checklistId);

            // Trigger a rerender
            setRefreshFlag(!refreshFlag);

        } catch (error) {
            console.error("Error deleting checklist item:", error);
        }
    };




    const handleToggleHideCheckedItems = (checklistId) => {

        console.log("zbczbc")
        const updatedChecklists = checklists.map(cl =>
            cl.id === checklistId ? { ...cl, hideCompleted: !cl.hideCompleted, hidden_checked_items: !cl.hidden_checked_items } : cl
        );
        setChecklists(updatedChecklists);

        ToggleHiddenStatusOfChecklistItems(workspace_id, project_id, checklistId);
    };

    const handleToggleHideChecklist = (checklistId) => {
        setChecklists(prevChecklists =>
            prevChecklists.map(cl =>
                cl.id === checklistId ? { ...cl, hidden: !cl.hidden } : cl
            )
        );

        setAnchorElMap(prevState => ({
            ...prevState,
            [checklistId]: null
        }));

        ToggleHiddenStatusOfChecklists(workspace_id, project_id, checklistId);
    };


    const getChecklistProgress = (checklist) => {
        if (!checklist || !checklist.items) {
            return 0;
        }
        const totalItems = checklist.items.length;
        const checkedItems = checklist.items.filter(item => item.checked).length;
        return totalItems === 0 ? 0 : (checkedItems / totalItems) * 100;
    };


    //Checks the size limit of a
    const checkSizeLimitCheckListItem = (checklistId, value) => {
        if (value.toString().length > 100) {
            return false;
        }
        return true;
    };

    const handleNewChecklistTitleChange = (checklistId, value) => {
        if (checkSizeLimitCheckListItem(checklistId, value)) {
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: value
            }));
        }
    };

    const handleEditChecklistChange = (checklistId, title) => {
        setEditChecklistTitles(prevState => ({
            ...prevState,
            [checklistId]: title
        }));
    };

    const handleEditChecklistItemChange = (checklistId, checklistItemId, title, order, changedOrder = false) => {
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                const updatedItems = cl.items.map(item =>
                    item.id === checklistItemId ? { ...item, title, order } : item
                ).sort((a, b) => a.order - b.order); // Sorting the items by order

                if (changedOrder) {
                    cl.items.forEach(item => {
                        if (item.id === checklistItemId) {
                            handleEditChecklistItemSave(cl.id, item.id, item.title, item.order);
                        }
                    });
                }

                return {
                    ...cl,
                    items: updatedItems
                };
            }
            return cl;
        });

        setChecklists(updatedChecklists);

    };

    const handleEditChecklistItemSave = async (checklistId, checklistItemId, title, order) => {
        await UpdateChecklistItem(workspace_id, project_id, checklistId, checklistItemId, { title, order });
        setRefreshFlag(!refreshFlag);

    };


    const handleSaveChecklistTitle = async (checklistId) => {
        const newTitle = editChecklistTitles[checklistId];

        if (newTitle && newTitle.trim()) {
            try {
                await UpdateChecklistTitle(workspace_id, project_id, checklistId, newTitle);
                const updatedChecklists = checklists.map(cl =>
                    cl.id === checklistId ? { ...cl, title: newTitle } : cl
                );

                setChecklists(updatedChecklists);
                setEditChecklistTitles(prevState => {
                    const newState = { ...prevState };
                    delete newState[checklistId];
                    return newState;
                });
                setRefreshFlag(!refreshFlag);


            } catch (error) {
                console.error("Error updating checklist title:", error);
            }
        }
    };


    const handleSaveChecklistOrder = async (checklistId, order, updateChecklists = true) => {

        try {
            await UpdateChecklistOrder(workspace_id, project_id, checklistId, order);
            const updatedChecklists = checklists.map(cl =>
                cl.id === checklistId ? { ...cl, order: order } : cl
            );
            if (updateChecklists) {
                setChecklists(updatedChecklists);
            }
        } catch (error) {
            console.error("Error updating checklist title:", error);
        }
    };


    const handleBeforeDragStart = (checklistId) => {
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId && cl.hideCompleted) {
                return {
                    ...cl,
                    tempHideCompleted: cl.hideCompleted,
                    hideCompleted: false,
                    hidden_checked_items: !cl.hidden_checked_items
                };
            }
            return cl;
        });

        setChecklists(updatedChecklists);
    };


    const onMouseDownDragHandle = (checklistId) => {
        if (dragTimeout) {
            clearTimeout(dragTimeout);
        }

        const timeoutId = setTimeout(() => {
            handleBeforeDragStart(checklistId);
            setIsDragging(true);
            setCurrentlyDraggingChecklistId(checklistId)
        }, 300);

        setDragTimeout(timeoutId);
    };

    const onMouseUpDragHandle = (checklistId) => {
        if (dragTimeout) {
            clearTimeout(dragTimeout);
        }
        let updatedChecklists = [...checklists];
        updatedChecklists = restoreHiddenItems(updatedChecklists, checklistId);
        
        setChecklists(updatedChecklists);
    };

    const onDragStart = (start) => {
        const { source } = start;
        setCurrentlyDraggingChecklistId(source.droppableId.replace("checklist-items-", "")); // Set the dragged checklist ID
        setIsDragging(true);
    };

    const onDragEnd = async (result) => {
        const { destination, source } = result;


        let updatedChecklists = [...checklists];

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            updatedChecklists = restoreHiddenItems(updatedChecklists, source.droppableId);
            setChecklists(updatedChecklists);
            setIsDragging(false);

            setCurrentlyDraggingChecklistId(null)
        }

        if (destination.droppableId === 'checklist' && source.droppableId === 'checklist') {
            const [removedChecklist] = updatedChecklists.splice(source.index, 1);
            updatedChecklists.splice(destination.index, 0, removedChecklist);

            updatedChecklists.forEach((checklist, index) => {
                checklist.order = index;
            });

            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);

            try {
                for (const checklist of updatedChecklists) {
                    await handleSaveChecklistOrder(checklist.id, checklist.order, false);
                }
            } catch (error) {
                console.error("Error updating checklist order on the backend", error);
            }
        } else {
            const sourceListId = source.droppableId.replace('checklist-items-', '');
            const destinationListId = destination.droppableId.replace('checklist-items-', '');
            const sourceList = checklists.find(cl => cl.id === sourceListId);
            const destinationList = checklists.find(cl => cl.id === destinationListId);

            if (!sourceList || !destinationList) {
                console.error('Source or destination list not found');
                return;
            }

            const sourceSortedItems = [...sourceList.items].sort((a, b) => a.order - b.order);
            if (sourceList.id === destinationList.id) {
                const [removedList] = sourceSortedItems.splice(source.index, 1);
                sourceSortedItems.splice(destination.index, 0, removedList);
                sourceSortedItems.forEach((item, index) => {
                    item.order = index;
                });
                sourceList.items = sourceSortedItems;
                const updatedChecklists = checklists.map(cl => {
                    if (cl.id === sourceList.id) {
                        return sourceList;
                    }
                    return cl;
                });
                setChecklists(updatedChecklists);
            }

            updatedChecklists = restoreHiddenItems(updatedChecklists, destinationListId);
        }

        setIsDragging(false);

        setCurrentlyDraggingChecklistId(null)
        setChecklists(updatedChecklists);
    };


    const restoreHiddenItems = (updatedChecklists, checklistId) => {
        return updatedChecklists.map(cl => {
            if (cl.id === checklistId && cl.tempHideCompleted) {
                return {
                    ...cl,
                    hideCompleted: true,
                    tempHideCompleted: false,
                    hidden_checked_items: !cl.hidden_checked_items
                };
            }
            return cl;
        });
    };


    const handleClick = (event, checklistId, itemId) => {
        setMenuState({ anchorEl: event.currentTarget, checklistId, itemId });
    };

    const handleClickChecklist = (event, checklistId) => {
        setAnchorElMap((prev) => ({
            ...prev,
            [checklistId]: event.currentTarget,
        }));
    };


    const handleCloseChecklist = (checklistId) => {
        setAnchorElMap((prev) => ({
            ...prev,
            [checklistId]: null,
        }));
    };


    const handleClose = () => {
        setMenuState({ anchorEl: null, checklistId: null, itemId: null });
    };

    const handleDelete = (checklistId, itemId) => {
        if (itemId !== null) {
            handleDeleteChecklistItem(checklistId, itemId);
        }
        handleClose();
    };

    const handleConvertToTask = (checklistId, itemTitle, itemId) => {

        handleDeleteChecklistItem(checklistId, itemId)
        handleSaveTask(itemTitle, []);

        handleClose();
    };




    return (
        <>
            <DeleteChecklistModal open={openDeleteChecklistModal} handleDeleteChecklistModalClose={handleDeleteChecklistModalClose} handleCloseAndDelete={handleCloseAndDelete} avoidMisfire={avoidMisfire} />

            {checklists && checklists.length > 0 && (
                <>
                    <Typography className="checklist-items-drag-div-divider">{t("custom.checklists")}</Typography>
                </>
            )}
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="checklist" direction="vertical">
                    {(provided) => (
                        <div className='ChecklistDragDivDivider' {...provided.droppableProps} ref={provided.innerRef}>
                            {checklists.map((checklist, index) => (
                                <Draggable draggableId={checklist.id.toString()} index={index} key={checklist.id}>
                                    {(provided_parent) => (
                                        <Paper
                                            elevation={4}
                                            className="checklist-paper"
                                            {...provided_parent.draggableProps}
                                            ref={index === checklists.length - 1 ? lastChecklistRef : provided_parent.innerRef}
                                        >




                                            {!checklist.hidden ? (
                                                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>

                                                    <Droppable droppableId={`checklist-items-${checklist.id}`} direction="vertical">
                                                        {(provided) => (
                                                            <><div className='ChecklistItemsDragDivDivider' {...provided.droppableProps} ref={provided.innerRef}>
                                                                <Box key={checklist.id} className="checklist-items-drag-div-divider">
                                                                    <Box className="checklist-title-container">
                                                                        <Box
                                                                            className="checklist-drag-icon"
                                                                            {...provided_parent.dragHandleProps}
                                                                            ref={provided_parent.innerRef}


                                                                        >
                                                                            <DragIndicatorIcon className="white-txt-color" />
                                                                        </Box>

                                                                        <TextField
                                                                            variant="standard"
                                                                            value={editChecklistTitles[checklist.id] !== undefined ? editChecklistTitles[checklist.id] : checklist.title}
                                                                            onChange={(e) => handleEditChecklistChange(checklist.id, e.target.value)}
                                                                            onBlur={() => handleSaveChecklistTitle(checklist.id)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.target.blur();
                                                                                }
                                                                            }}
                                                                            className="checklist-title-input"
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                className: "white-txt-color"
                                                                            }}
                                                                            multiline />

                                                                        <div className="checkbox-icons-div">
                                                                            <IconButton onClick={(event) => handleClickChecklist(event, checklist.id)} size="small">
                                                                                <MoreVertIcon className="white-txt-color" />
                                                                            </IconButton>
                                                                            <Menu
                                                                                anchorEl={anchorElMap[checklist.id]}
                                                                                open={Boolean(anchorElMap[checklist.id])}
                                                                                onClose={() => handleCloseChecklist(checklist.id)}
                                                                                className="checklist-menu"
                                                                            >
                                                                                <MenuItem onClick={() => handleToggleHideChecklist(checklist.id)} className="checklist-menu-item">
                                                                                    <PreviewIcon className="checklist-visibility-icon" />
                                                                                    {checklist.hidden ? t("custom.show_checked") : t("custom.hide_checked")}
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleToggleHideCheckedItems(checklist.id)} className="checklist-menu-item">
                                                                                    <VisibilityIcon className="checklist-visibility-icon" />
                                                                                    {checklist.hidden_checked_items ? t("custom.show_item_checked") : t("custom.hide_item_checked")}
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleChecklistModalOpen(checklist.id)} className="checklist-menu-item">
                                                                                    <DeleteIcon className="checklist-delete-icon" />
                                                                                    {t("custom.delete_checklist")}
                                                                                </MenuItem>
                                                                            </Menu>
                                                                        </div>
                                                                    </Box>



                                                                    <Typography variant="body2" className="checklist-progress-text">
                                                                        {`${checklist.items.filter(item => item.checked).length}/${checklist.items.length}`} ({`${getChecklistProgress(checklist).toFixed(2)}%`})
                                                                    </Typography>
                                                                    <LinearProgress variant="determinate" value={getChecklistProgress(checklist)} className="checklist-linear-progress" />

                                                                    {checklist.items?.map((item, index) => {
                                                                        // Move your condition check outside JSX
                                                                        const isChecked = item.checked;

                                                                        const shouldHideItem = checklist.hidden_checked_items && item.checked; // Combine the conditions here
                                                                        if (shouldHideItem) {
                                                                            checklist.hideCompleted = true;
                                                                        }
                                                                        const shouldRenderItem = !shouldHideItem && !(checklist.hideCompleted && isChecked && !isDragging); // Hide if hideCompleted is true and item is checked


                                                                        // Now use a ternary expression to conditionally render the item
                                                                        return shouldRenderItem ? (
                                                                            <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
                                                                                {(provided, snapshot) => (
                                                                                    <Paper
                                                                                        elevation={4}
                                                                                        className={`checklist-small-paper ${isChecked ? 'checklist-item-checked' : ''} ${
                                                                                            isDragging && currentlyDraggingChecklistId === checklist.id ? 'dragging' : ''
                                                                                        }`}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        onMouseDown={(event) => onMouseDownDragHandle(checklist.id)}
                                                                                        onMouseUp={(event) => onMouseUpDragHandle(checklist.id)}
                                                                                    >
                                                                                        <Box key={item.id} className="checklist-title-container">
                                                                                            <Tooltip title="Hold to drag amongst hidden checked items!">
                                                                                                <Box className="checklist-drag-icon">
                                                                                                    <DragIndicatorIcon />
                                                                                                </Box>
                                                                                            </Tooltip>
                                                                                            <Checkbox
                                                                                                checked={item.checked}
                                                                                                onChange={() => handleToggleChecklistItem(checklist.id, item.id, !item.checked)}
                                                                                                className="white-txt-color" />
                                                                                            <TextField
                                                                                                variant="standard"
                                                                                                value={item.title}
                                                                                                onChange={(e) => handleEditChecklistItemChange(checklist.id, item.id, e.target.value, item.order, false)}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === 'Enter') {
                                                                                                        e.target.blur();
                                                                                                    }
                                                                                                }}
                                                                                                onBlur={(e) => handleEditChecklistItemSave(checklist.id, item.id, e.target.value, item.order)}
                                                                                                multiline
                                                                                                className="checklist-title-input"
                                                                                                InputProps={{
                                                                                                    disableUnderline: true,
                                                                                                    className: "white-txt-color"
                                                                                                }} />
                                                                                            <IconButton
                                                                                                onClick={(event) => {
                                                                                                    event.stopPropagation();
                                                                                                    handleClick(event, checklist.id, item.id);
                                                                                                }}
                                                                                                size="small"
                                                                                            >
                                                                                                <MoreVertIcon className="white-txt-color" />
                                                                                            </IconButton>
                                                                                            <Menu
                                                                                                anchorEl={menuState.anchorEl}
                                                                                                open={menuState.checklistId === checklist.id && menuState.itemId === item.id}
                                                                                                onClose={handleClose}
                                                                                                className="checklist-menu"
                                                                                            >
                                                                                                <MenuItem onClick={() => handleDelete(checklist.id, item.id)}>
                                                                                                    {t("custom.delete_item")}
                                                                                                </MenuItem>
                                                                                                <MenuItem onClick={() => handleConvertToTask(checklist.id, item.title, item.id)}>
                                                                                                    {t("custom.convert_item")}
                                                                                                </MenuItem>
                                                                                            </Menu>

                                                                                        </Box>
                                                                                    </Paper>
                                                                                )}
                                                                            </Draggable>
                                                                        ) : null;
                                                                    })}

                                                                </Box>
                                                                {provided.placeholder}
                                                            </div>
                                                                <Box className="checklist-add-item">
                                                                    <TextField
                                                                        size="small"
                                                                        placeholder={t("custom.add_checklist_item")}
                                                                        value={newChecklistTitles[checklist.id] || ''}
                                                                        onChange={(e) => handleNewChecklistTitleChange(checklist.id, e.target.value)}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                handleAddChecklistItem(checklist.id);
                                                                                e.target.blur();
                                                                            }
                                                                        }}
                                                                        inputRef={(el) => (inputRefs.current[checklist.id] = el)}
                                                                        className="checklist-new-item-input"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: "white-txt-color"
                                                                        }} />
                                                                    <IconButton onClick={() => handleAddChecklistItem(checklist.id)} size="small">
                                                                        <AddIcon className="white-txt-color" />
                                                                    </IconButton>
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            ) : (
                                                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>

                                                    <Droppable droppableId={`checklist-items-${checklist.id}`} direction="vertical">
                                                        {(provided) => (
                                                            <div className='ChecklistItemsDragDivDivider' {...provided.droppableProps} ref={provided.innerRef}>
                                                                <Box key={checklist.id} className="checklist-items-drag-div-divider">
                                                                    <Box className="checklist-title-container">
                                                                        <Box
                                                                            className="checklist-drag-icon"
                                                                            {...provided_parent.dragHandleProps}
                                                                            ref={provided_parent.innerRef}


                                                                        >
                                                                            <DragIndicatorIcon className="white-txt-color" />
                                                                        </Box>

                                                                        <TextField
                                                                            variant="standard"
                                                                            value={editChecklistTitles[checklist.id] !== undefined ? editChecklistTitles[checklist.id] : checklist.title}
                                                                            onChange={(e) => handleEditChecklistChange(checklist.id, e.target.value)}
                                                                            onBlur={() => handleSaveChecklistTitle(checklist.id)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.target.blur();
                                                                                }
                                                                            }}
                                                                            className="checklist-title-input"
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                className: "white-txt-color"
                                                                            }}
                                                                            multiline
                                                                        />

                                                                        <div className="checkbox-icons-div">
                                                                            <IconButton onClick={(event) => handleClickChecklist(event, checklist.id)} size="small">
                                                                                <MoreVertIcon className="white-txt-color" />
                                                                            </IconButton>
                                                                            <Menu
                                                                                anchorEl={anchorElMap[checklist.id]}
                                                                                open={Boolean(anchorElMap[checklist.id])}
                                                                                onClose={() => handleCloseChecklist(checklist.id)}
                                                                                className="checklist-menu"
                                                                            >
                                                                                <MenuItem onClick={() => handleToggleHideChecklist(checklist.id)} className="checklist-menu-item">
                                                                                    <PreviewIcon className="checklist-visibility-icon" />
                                                                                    {checklist.hidden ? t("custom.show_checked") : t("custom.hide_checked")}
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleToggleHideCheckedItems(checklist.id)} className="checklist-menu-item">
                                                                                    <VisibilityIcon className="checklist-visibility-icon" />
                                                                                    {checklist.hidden_checked_items ? t("custom.show_item_checked") : t("custom.hide_item_checked")}
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleChecklistModalOpen(checklist.id)} className="checklist-menu-item">
                                                                                    <DeleteIcon className="checklist-delete-icon" />
                                                                                    {t("custom.delete_checklist")}
                                                                                </MenuItem>
                                                                            </Menu>
                                                                        </div>

                                                                    </Box>

                                                                    <Typography variant="body2" className="checklist-progress-text">
                                                                        {`${checklist.items.filter(item => item.checked).length}/${checklist.items.length}`} ({`${getChecklistProgress(checklist).toFixed(2)}%`})
                                                                    </Typography>
                                                                    <LinearProgress variant="determinate" value={getChecklistProgress(checklist)} className="checklist-linear-progress" />
                                                                </Box>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            )}
                                        </Paper>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default ChecklistComponent;

export const getUserStatusPriority = (user, now = new Date(), t) => {
    const lastOnline = new Date(user.last_online);
    const timeDifferenceInMinutes = (now - lastOnline) / 60000; // Difference in minutes

    // Function to convert the current time (`now`) to the user's timezone using the timezoneOffset
    const convertToYourTimezone = (time, userTimezoneOffset) => {
        if (!userTimezoneOffset) {
            userTimezoneOffset = '+00:00'; // Default to UTC if no timezone_offset is provided
        }

        const [hoursOffset, minutesOffset] = userTimezoneOffset.split(':').map(Number);
        const totalOffsetInMinutes = hoursOffset * 60 + (userTimezoneOffset.startsWith('-') ? -minutesOffset : minutesOffset);

        // Adjust the provided time by subtracting the user's offset from GMT (so it's now in GMT+0)
        const adjustedTime = new Date(time.getTime() - totalOffsetInMinutes * 60000);
        return adjustedTime;
    };

    const isWorkingHours = () => {
        if (!user.working_hours || user.working_hours.length === 0) {
            return false; // User has no working hours set up
        }



        // Loop through the user's working hours to check if the current time is within their working hours
        return user.working_hours.some(wh => {
            if (!wh.start || !wh.end) {
                return false;
            }
            const { start, end } = wh;

            // Split the start and end times provided by the user
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);

            // Create Date objects for the start and end times (assumed to be in the user's timezone)
            const startTimeUser = new Date(now);
            startTimeUser.setHours(startHour, startMinute, 0, 0);

            const endTimeUser = new Date(now);
            endTimeUser.setHours(endHour, endMinute, 0, 0);

            // Convert these times into your timezone (GMT+0 in your case)
            const startTimeInYourTimezone = convertToYourTimezone(startTimeUser, user.timezone_offset);
            const endTimeInYourTimezone = convertToYourTimezone(endTimeUser, user.timezone_offset);
            

        
            // Compare your current time (in your timezone) with the converted working hours
            return now >= startTimeInYourTimezone && now <= endTimeInYourTimezone;
        });
    };

    // Calculate the last seen message
    const lastSeenMessage = () => {
        const daysAgo = Math.floor((now - lastOnline) / (60 * 24 * 60 * 1000)); // Time difference in days
        const hoursAgo = Math.floor(((now - lastOnline) % (60 * 24 * 60 * 1000)) / (60 * 60 * 1000)); // Time difference in hours
        const minutesAgo = Math.floor(((now - lastOnline) % (60 * 60 * 1000)) / (60 * 1000)); // Time difference in minutes

        if (daysAgo > 0) {
            return t("working_hours.last_seen_days", { var1: daysAgo, var2: daysAgo === 1 ? '' : 's', var3: hoursAgo, var4: hoursAgo === 1 ? '' : 's' });
        } else if (hoursAgo > 0) {
            return t("working_hours.last_seen_hours", { var1: hoursAgo, var2: hoursAgo === 1 ? '' : 's', var3: minutesAgo, var4: minutesAgo === 1 ? '' : 's' });
        } else {
            return t("working_hours.last_seen_minutes", { var1: minutesAgo, var2: minutesAgo === 1 ? '' : 's' });
        }
    };

    // Priority 5: Always prioritize vacations/unavailable users first
    if (user.current_availability !== 'Available') {
        return { status: 5, message: t("working_hours.on_vacation") }; // Unavailable (vacation/holiday) takes precedence
    }

    // Check if the user has been online within the last 15 minutes
    if (timeDifferenceInMinutes < 15.00) {
        return { status: 1, message: t("working_hours.status_online") }; // Online within the last 15 minutes (if not on vacation)
    }

    // If the user is offline (i.e., not active within the last 15 minutes)
    if (user.current_availability === 'Available') {
        const workingHoursCheck = isWorkingHours();
        
        if (workingHoursCheck) {
            return { status: 3, message: `${lastSeenMessage()}` }; // Offline during working hours + last seen message
        } else if (user.working_hours && user.working_hours.length > 0) {
            return { status: 4, message: `${lastSeenMessage()}` }; // Offline (general) + last seen message
        } else {
            return { status: 6, message: t("working_hours.no_hours_setup") }; // No working hours set up, and user is offline/unavailable
        }
    }

    // Default case: Unavailable (vacation)
    return { status: 5, message: t("working_hours.unavailable") };
};

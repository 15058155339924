import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, CircularProgress, Button } from '@mui/material';
import { useService } from 'contexts/ServiceContextProvider';
import TicketDetailsModal from './TicketDetailsModal';
import "../../css/views/SupportPage.css";

const statusColors = {
    open: '#4caf50',
    in_progress: '#ff9800',
    resolved: '#2196f3',
    closed: '#9e9e9e'
};

const priorityColors = {
    low: '#4caf50',
    medium: '#ffeb3b',
    high: '#f44336',
    urgent: '#9c27b0'
};

// Helper function to calculate if a ticket can be reopened (within 30 days)
const canReopen = (ticket) => {
    if (ticket.status !== 'resolved') return false;

    const updatedAt = new Date(ticket.updated_at);
    const currentDate = new Date();

    const diffInDays = (currentDate - updatedAt) / (1000 * 60 * 60 * 24);  // Convert milliseconds to days
    return diffInDays <= 30;  // Allow reopen if less than or equal to 30 days
};

const PastTicketsTable = ({ user, getStatusLabel, getPriorityLabel, getTypeLabel, ReopenTicket }) => {
    const { CurrentUserPastTickets } = useService();
    const [myTickets, setMyTickets] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchCurrentUserPastTickets = async () => {
            try {
                const response = await CurrentUserPastTickets();
                setMyTickets(response);
            } catch (error) {
                console.error("Error fetching past tickets:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCurrentUserPastTickets();
    }, [CurrentUserPastTickets]);

    const handleRowClick = (ticket) => {
        setSelectedTicket(ticket);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedTicket(null);
    };

    const handleReopenTicket = async (ticket) => {
        try {
            await ReopenTicket(ticket.id); // Call the ReopenTicket service
            window.location.reload();

        } catch (error) {
            console.error("Error reopening ticket:", error);
        }
    };

    if (loading) {
        return (
            <Box className="loading-support">
                <CircularProgress sx={{ color: '#a1989e' }} />
            </Box>
        );
    }

    return (
        <Box mt={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#a1989e' }}>
                My Past Tickets (Closed/Resolved)
            </Typography>
            {myTickets && myTickets.length > 0 ? (
                <TableContainer component={Paper} sx={{ backgroundColor: '#4d4545', color: '#a1989e' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#a1989e' }}>Title</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Status</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Priority</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Type</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Created At</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Updated At</TableCell>
                                <TableCell sx={{ color: '#a1989e' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myTickets.map((ticket) => (
                                <TableRow
                                    key={ticket.id}
                                    onClick={() => handleRowClick(ticket)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell sx={{ color: '#ffffff' }}>{ticket.title}</TableCell>
                                    <TableCell sx={{ color: statusColors[ticket.status] }}>
                                        {getStatusLabel(ticket.status)}
                                    </TableCell>
                                    <TableCell sx={{ color: priorityColors[ticket.priority] }}>
                                        {getPriorityLabel(ticket.priority)}
                                    </TableCell>
                                    <TableCell sx={{ color: '#ffffff' }}>{getTypeLabel(ticket.type)}</TableCell>
                                    <TableCell sx={{ color: '#ffffff' }}>
                                        {new Date(ticket.created_at).toLocaleString()}
                                    </TableCell>
                                    <TableCell sx={{ color: '#ffffff' }}>
                                        {new Date(ticket.updated_at).toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        {canReopen(ticket) && (
                                            <Button
                                                variant="outlined"
                                                className="button-light-bg"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleReopenTicket(ticket);
                                                }}
                                            >
                                                Reopen Ticket
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" mt={3} sx={{ color: '#a1989e' }}>
                    No past tickets found.
                </Typography>
            )}
            {selectedTicket && (
                <TicketDetailsModal
                    ticket={selectedTicket}
                    open={modalOpen}
                    user={user}
                    read_only={['closed'].includes(selectedTicket.status) || ['resolved'].includes(selectedTicket.status)}
                    onClose={handleCloseModal}
                    getPriorityLabel={getPriorityLabel}
                    getStatusLabel={getStatusLabel}
                    getTypeLabel={getTypeLabel}
                    canReopen={canReopen}
                    ReopenTicket={ReopenTicket}
                />
            )}
        </Box>
    );
};

export default PastTicketsTable;

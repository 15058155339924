import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import "../../css/components/Modals/AttachmentsModal.css";

const AttachmentsModal = ({
                              openModal,
                              toggleModal,
                              Task,
                              t,
                              handleFileChange,
                              handleDeleteAttachment,
                              handlePreviewImage,
                          }) => {
    return (
        <Dialog
            open={openModal}
            onClose={toggleModal}
            aria-labelledby="attachment-dialog-title"
            fullWidth
            maxWidth="sm"
            className="modal-background-color"
        >
            <DialogTitle id="attachment-dialog-title">
                <Box className="attachments-header">
                    <Typography>
                        {t("modal.attachments")}
                    </Typography>
                    <label htmlFor="upload-button-file">
                        <Button
                            variant="text"
                            component="span"
                            className="view-task-upload-button"
                        >
                            <UploadFileIcon className="view-task-attachments-icon" />
                        </Button>
                    </label>
                </Box>
            </DialogTitle>

            {/* List existing attachments */}
            <DialogContent>
                <Box className="view-task-attachments-list">
                    {Task.attachments && Task.attachments.length > 0 ? (
                        Task.attachments.map((attachment, index) => {
                            const fileUrl = `${process.env.REACT_APP_BACKEND_URL}${attachment.file}`;

                            let AttachmentIcon;
                            switch (attachment.file_type) {
                                case 'Image':
                                    AttachmentIcon = <ImageIcon className="view-task-attachments-icon" />;
                                    break;
                                case 'Document':
                                    AttachmentIcon = <InsertDriveFileIcon className="view-task-attachments-icon" />;
                                    break;
                                case 'Archive':
                                    AttachmentIcon = <ArchiveIcon className="view-task-attachments-icon" />;
                                    break;
                                default:
                                    AttachmentIcon = <InsertDriveFileIcon className="view-task-attachments-icon" />;
                            }

                            return (
                                <Box key={index} className="view-task-image-preview" onClick={() => handlePreviewImage(attachment, fileUrl)}>
                                    {AttachmentIcon}
                                    <Box className="view-task-attachment-box">
                                        <Typography className="view-task-attachment-name">
                                            {attachment.filename}
                                        </Typography>
                                        <Typography variant="body2" color="rgba(255, 255, 255, 0.6)">
                                            {t(`file_types.${attachment.file_type}`)}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        className="view-task-attachment-delete-icon"
                                        onClick={(event) => handleDeleteAttachment(attachment, event)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        })
                    ) : (
                        <Typography variant="body2">{t("modal.no_attachments")}</Typography>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={toggleModal} className="button-dark-bg">
                    {t("global.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AttachmentsModal;
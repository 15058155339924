import React from 'react';
import red_bar from 'assets/img/loading/red_bar.png';
import yellow_bar from 'assets/img/loading/yellow_bar.png';
import green_bar from 'assets/img/loading/green_bar.png';
import blue_bar from 'assets/img/loading/blue_bar.png';

const TinyCustomFallback = () => {
    const barStyle = {
        width: '10px',  
        height: '40px', 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: '0 1.5px', 
        animation: 'pop 1.2s infinite',
        display: 'inline-block',  
        verticalAlign: 'middle',  
    };

    const animationDelay = (index) => ({
        animationDelay: `${index * 0.3}s`
    });

    const containerStyle = {
        display: 'inline-flex',  
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15
    };

    const keyframes = `
    @keyframes pop {
      0% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0.5;
      }
    }
  `;

    return (
        <div style={containerStyle}>
            <style>{keyframes}</style> 
            <div style={{ ...barStyle, backgroundImage: `url(${red_bar})`, ...animationDelay(0) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${yellow_bar})`, ...animationDelay(1) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${green_bar})`, ...animationDelay(2) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${blue_bar})`, ...animationDelay(3) }}></div>
        </div>
    );
};

export default TinyCustomFallback;

import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useNavigate } from 'react-router-dom';
import {useSnackbar} from "../contexts/SnackbarContextProvider";
import { useTranslation } from "react-i18next";
import '../css/views/NewWorkspace.css';
import '../css/main.css';

const NewWorkspacePage = () => {
  useEffect(() => {
    document.title = t('titles.new_workspace');
  }, []);
  const { t } = useTranslation();
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceBio, setWorkspaceBio] = useState('');
  const [workspaceMail, setWorkspaceMail] = useState('');
  const [workspaceImage, setWorkspaceImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const { accessToken, getLoggedUser } = useAuthorization();
  const navigate = useNavigate();
  const workspaceContext = useWorkspaces();
  const { setWorkspaces } = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;
  const loggedUserID = getLoggedUser();
  const { showSnackbar } = useSnackbar();


  const handleRemoveImage = () => {
    setPreviewImage(null);
    document.getElementById("workspace-image-input").value = "";
};

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setWorkspaceImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (!workspaceName) {
      setNameError(t('workspace.name_err'));
      valid = false;
    } else {
      setNameError('');
    }

    if (!workspaceMail) {
      setEmailError(t('workspace.email_err'));
      valid = false;
    } else if (!validateEmail(workspaceMail)) {
      setEmailError(t('workspace.email_inval'));
      valid = false;
    } else {
      setEmailError('');
    }

    if (!valid) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}workflow/workspaces/create/`,
        { name: workspaceName, bio: workspaceBio, image: workspaceImage, company_mail: workspaceMail, owner: loggedUserID },
        { headers: { "Authorization": "Bearer " + accessToken, 'Content-Type': 'multipart/form-data' } }
      );
      if (response.status === 201) {
        const updatedWorkspaceSet = [response.data, ...myWorkspaces];
        setWorkspaces(updatedWorkspaceSet);
        navigate(`/workflow/index`);
      }
    } catch (error) {
      if (error.response.data.code === 'already_owner'){
        showSnackbar(error.response.data.detail, 'error');
      }
      console.error(error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{t("workspace.new_workspace")}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('workspace.name')}
              variant="outlined"
              value={workspaceName}
              onChange={(event) => setWorkspaceName(event.target.value)}
              error={!!nameError}
              helperText={nameError}
              InputLabelProps={{ className: "white-txt-color" }}
              InputProps={{ className: "white-txt-color" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('workspace.company_email')}
              variant="outlined"
              value={workspaceMail}
              onChange={(event) => setWorkspaceMail(event.target.value)}
              error={!!emailError}
              helperText={emailError}
              InputLabelProps={{ className: "white-txt-color" }}
              InputProps={{ className: "white-txt-color" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('workspace.company_bio')}
              variant="outlined"
              multiline
              rows={4}
              value={workspaceBio}
              onChange={(event) => setWorkspaceBio(event.target.value)}
              InputLabelProps={{ className: "white-txt-color" }}
              InputProps={{ className: "white-txt-color" }}
            />
          </Grid>

          <Grid item xs={12}>
            <input
              accept="image/*"
              id="workspace-image-input"
              type="file"
              onChange={handleImageChange}
              className= "no-display"
            />
            <label htmlFor="workspace-image-input">
              <Button variant="contained" component="span" className="button-light-bg">
                {t('workspace.upload_image')}
              </Button>
            </label>
            <Typography>{t('workspace.rec_image_size')}</Typography>
            
            {previewImage && (
                <div className="image-container-workspace">
                  <img src={previewImage} alt="Workspace" className="preview-image-size image-border" />
                  <div className="overlay">
                    <button className="image-reset-button" onClick={handleRemoveImage}>{t("workspace.rem_image")}</button>
                  </div>
                </div>

            )}
          </Grid>
          <Grid item xs={12}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className="button-light-bg submit-workspace"
            >
              {t('workspace.create')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default NewWorkspacePage;

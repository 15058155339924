import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AccountTree as AccountTreeIcon, Star as StarIcon } from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import "../../css/components/Sidebar/SidebarList.css";
import "../../css/main.css";

const ProjectList = ({
  setShowMoreOptionsProjects,
  showMoreOptionsProjects,
  myProjects,
  myCurrentProject,
  myWorkspaces,
  projectsContext
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleProjectItemClick = (event, project) => {
    if (event.button === 1) {
      event.preventDefault();
      window.open(`/workflow/projects/${project.id}`, '_blank'); 
    } else if (event.button === 0) {
      projectsContext.handleProjectChange(project.id);
      navigate(`/workflow/projects/${project.id}`);
    }
  };

  const handleProjectClick = () => {
    setShowMoreOptionsProjects(!showMoreOptionsProjects);
  };

  const handleMouseDown = (event, project) => {
    event.preventDefault(); 
    if (event.button === 1) { // Middle mouse button
        window.open(`/workflow/projects/${project.id}`, '_blank');
    }
};

  return (
    <List className={`sidebar-list-list $(showMoreOptionsProjects ? (myProjects.length > 1 ? 'max-height' : '') : 'none')`}>
      <ListItem disablePadding onClick={handleProjectClick} onDoubleClick={() => { window.location.href = '/workflow/index'; projectsContext.setCurrentProject(''); }}>
        <ListItemButton>
          <ListItemIcon>
            <AccountTreeIcon className="white-txt-color" />
          </ListItemIcon>
          {myProjects[0] ? (
            <ListItemText
              primary={t("sidebar.my_projects")}
              secondary={
                <Typography
                  variant="body2"
                  className="sidebar-list-text"
                >
                  {myCurrentProject}
                </Typography>
              }
            />
          ) : (
            <ListItemText
              primary={t("sidebar.my_projects")}
              secondary={
                <Typography
                  variant="body2"
                  className="sidebar-list-text"
                >
                  {t("sidebar.no_projects")}
                </Typography>
              }
            />
          )}
        </ListItemButton>
      </ListItem>
      {showMoreOptionsProjects && myProjects.map(
        (project) =>
          project.favorites && (
            <ListItemButton
              key={project.id}
              onMouseDown={(event) => handleMouseDown(event, project)} 
              onClick={(event) => event.button === 0 && handleProjectItemClick(event, project)} 
            >
              <ListItemText primary={project.name} />
              <StarIcon className="sidebar-list-star-icon" />
            </ListItemButton>
          )
      )}
      {showMoreOptionsProjects && myWorkspaces[0] && (
        <ListItemButton className="sidebar-list-item-button" component={Link} to="projects/new">
          <ListItemText primary={t("sidebar.new_project")} />
        </ListItemButton>
      )}
    </List>
  );
};

export default ProjectList;

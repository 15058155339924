import React, { useEffect, useState } from 'react';
import myLogo from 'assets/img/brand/banner.png';
import {
  Box,
  Drawer,
  Toolbar,
  IconButton,
  Divider,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useSidebar } from 'contexts/SidebarContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useProjects } from 'contexts/ProjectsContextProvider';
import { useService } from 'contexts/ServiceContextProvider';
import { useNotification } from 'contexts/NotificationContextProvider';
import NewListModal from 'components/Modals/CreateNewList';
import NewWorkspaceMember from 'components/Modals/AddWorkspaceMember';
import ProjectList from './ProjectList';
import WorkspaceList from './WorkspaceList';
import SidebarExtraButtons from './SidebarExtraButtons';
import TopBar from 'components/Topbar/Topbar'
import { useTranslation } from "react-i18next";
import "../../css/main.css";
import "../../css/components/Sidebar/Sidebar.css";

export default function PersistentDrawerLeft() {
  const { t } = useTranslation();

  const { isOpen, openSidebar, closeSidebar } = useSidebar();
  const [showMoreOptions, setShowMoreOptions] = React.useState(false);
  const [showMoreOptionsProjects, setShowMoreOptionsProjects] = React.useState(false);
  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;
  const projectsContext = useProjects();
  const myProjects = projectsContext.projects;
  const myCurrentProject = projectsContext.currentProject;
  const { setCurrentProject } = useProjects();
  const {
    WhoAmI,
    CreateNewList,
    GetProjectBoardInformation,
    getWorkspaceWorkers,
    getProjectWorkers,
    AddUserToProject,
    RemoveUserFromProject,
    MarkNotificationAsRead,
    SendWorkspaceInvite,
    WorkspaceInviteUpdate
  } = useService();

  const [avoidMisfire, setAvoidMisfire] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const [openNewWorkSpaceMemberModal, SetOpenNewWorkSpaceMemberModal] = useState(false);
  const [nameForList, setNameForList] = useState('');
  const [workspaceWorkers, setWorkspaceWorkers] = useState([]);
  const [workspaceWorkersRaw, setWorkspaceWorkersRaw] = useState([]);
  const [projectWorkers, setProjectWorkers] = useState([]);
  const { myNotifs, SetMyNotifs, unreadNotifications, fetchNotifications } = useNotification();
  const [showNewWorkspace, setShowNewWorkspace] = useState(false);

  const handleAvatarGroupClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleNavigateToProfile = () => {
    const loggedUserID = getLoggedUser();

    if (loggedUserID) {
      window.location.href = `/workflow/profile/${loggedUserID}`;
    }
  };

  const handleNavigateToSupport= () => {
    window.location.href = `/workflow/support`; 
  };

  const { getLoggedUser } = useAuthorization();
  const isWorkflowProjects = window.location.pathname.includes('/workflow/projects/');
  const isWorkflowProjectsNew = window.location.pathname === '/workflow/projects/new';
  const isWorkflowProjectsEdit = window.location.pathname === '/workflow/projects/edit';
  const isWorkflowIndex = window.location.pathname.includes('/workflow/index') ||
    window.location.pathname.includes('/workflow/success-payment') ||
    window.location.pathname.includes('/workflow/failed-payment') ||
    window.location.pathname.includes('/workflow/success-cancel') ||
    window.location.pathname.includes('/workflow/failed-cancel') ||
    window.location.pathname.includes('/workflow/success-suspend') ||
    window.location.pathname.includes('/workflow/failed-suspend') ||
    window.location.pathname.includes('/workflow/success-activate') ||
    window.location.pathname.includes('/workflow/failed-activate') ||
    window.location.pathname.includes('/workflow/feature-unavailable');
  const isWorkflowProfile = window.location.pathname.includes('/profile/');
  const isWorkflowSettings = window.location.pathname.includes('/settings');
  const isWorkflowMembers = window.location.pathname.includes('/members');
  const isProjectDetails = window.location.pathname.includes('/details');
  const isProjectArchive = window.location.pathname.includes('/archive');
  const isVacations = window.location.pathname.includes('/vacations');
  const isVacationsOverview = window.location.pathname.includes('/calendar/overview');


  const isWorkflowCalendar = window.location.pathname.includes('/calendar');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_Element1 = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open_Element2 = Boolean(anchorEl2);
  const loggedUserID = getLoggedUser();
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [myLoggedUser, setMyLoggedUser] = useState(null);
  const [myUserInMemberFormat, setMyUserInMemberFormat] = useState();


  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await WhoAmI();
      setMyUserInMemberFormat(user);
      if (user?.is_superuser) {
        setShowNewWorkspace(true);
      } else {
        if (user?.owns_workspaces) {
          setShowNewWorkspace(false);
        } else {
          setShowNewWorkspace(true);
        }
      }
    };

    if (!myUserInMemberFormat) {
      fetchCurrentUser();
    }
  }, [myUserInMemberFormat, WhoAmI]);



  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredProjectWorkers = projectWorkers.filter((user) =>
    user.full_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filteredWorkspaceWorkers = workspaceWorkers.filter((user) =>
    user.full_name.toLowerCase().includes(searchValue.toLowerCase())
  );



  let buttonText = t("sidebar.project_board");
  if (window.location.pathname.endsWith('/details')) {
    buttonText = t("sidebar.project_details");
  } else if (window.location.pathname.endsWith('/archive')) {
    buttonText = t("sidebar.project_archives");
  } else {
    buttonText = t("sidebar.project_board");
  }

  let buttonWorkspaceText = t("sidebar.projects");
  if (window.location.pathname.endsWith('/settings')) {
    buttonWorkspaceText = t("sidebar.workspace_settings");
  } else {
    buttonWorkspaceText = t("sidebar.projects");
  }

  if (window.location.pathname.endsWith('/settings')) {
    buttonWorkspaceText = t("sidebar.workspace_settings");
  } else if (window.location.pathname.endsWith('/members')) {
    buttonWorkspaceText = t("sidebar.workspace_members");
  } else {
    buttonWorkspaceText = t("sidebar.projects");
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if ((myWorkspaces[0] && isWorkflowProjects && !isWorkflowProjectsEdit)) {
      Promise.all([
        getWorkspaceWorkers(myWorkspaces[0].id),
        getProjectWorkers(myWorkspaces[0].id, getProjectIDFromNavBar()),
      ]).then(([workspaceWorkers, projectWorkers]) => {
        setWorkspaceWorkersRaw(workspaceWorkers);
        const filteredWorkspaceWorkers = workspaceWorkers.filter(
          (worker) => !projectWorkers.some((projectWorker) => projectWorker.id === worker.id)
        );
        setWorkspaceWorkers(filteredWorkspaceWorkers);
        setProjectWorkers(projectWorkers);


        const myUser = workspaceWorkers.find((worker) => worker.id === loggedUserID);
        setMyLoggedUser(myUser);
      }).catch((error) => {
        console.error('Error fetching workers:', error);
      });
    }
  }, [isWorkflowProjects, myWorkspaces[0]]);
  
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchWorkspaceWorkers = async () => {
      if (myWorkspaces[0] && (isWorkflowIndex || isWorkflowSettings || isWorkflowProfile || isWorkflowCalendar)) {
        try {
          const workspaceWorkers = await getWorkspaceWorkers(myWorkspaces[0].id);

          setMyLoggedUser(workspaceWorkers.find((worker) => worker.id === loggedUserID));
          setWorkspaceWorkersRaw(workspaceWorkers);
        } catch (error) {
          console.error('Error fetching workers:', error);
        }
      }
    };

    fetchWorkspaceWorkers();
  }, [isWorkflowIndex, isWorkflowSettings, isWorkflowProfile, isWorkflowCalendar, myWorkspaces[0]]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (loggedUserID) {
      fetchNotifications(loggedUserID);
    }
  }, [loggedUserID]);

  const handleDrawerOpen = () => {
    openSidebar();
  };

  const handleDrawerClose = () => {
    closeSidebar();
  };

  const handleWorkspaceClick = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const handleListModalOpen = () => {
    setOpenListModal(true);
  };

  const handleListModalClose = () => {
    setNameForList('');
    setOpenListModal(false);
  };

  const handleNewWorkSpaceMemberModalOpen = () => {
    SetOpenNewWorkSpaceMemberModal(true);
  };

  const handleNewWorkSpaceMemberModalClose = () => {
    SetOpenNewWorkSpaceMemberModal(false);
  };

  const handleListNameChange = (event) => {
    setNameForList(event.target.value);
  };

  const handleClickProjectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorEl(null);
  };

  const handleClickProjectMemberMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseProjectMemberMenu = () => {
    setAnchorEl2(null);
  };

  const handleNotifOpen = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifClosed = () => {
    setNotifAnchorEl(null);
  };

  const handleNotificationClick = async (processed_notification) => {
    const notification_to_edit = myNotifs.find((notification) => notification.id === processed_notification.id);
    notification_to_edit.is_read = true;


    console.log(processed_notification.hyper)

    // Update notifications state
    SetMyNotifs((prevNotifs) => {
        const updatedNotifs = prevNotifs.map((notif) =>
            notif.id === notification_to_edit.id ? notification_to_edit : notif
        );
        return updatedNotifs;
    });


    if (processed_notification.hyper) {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/${processed_notification.hyper}`;

        const projectId = processed_notification.hyper.split('/')[2];

        let workspaceId = null;

        if (myWorkspaces[0].projects_ids.includes(projectId)) {
            workspaceId = myWorkspaces[0].id;
        } else {
            const foundWorkspace = myWorkspaces.find((workspace) =>
                workspace.projects_ids.includes(projectId)
            );

            if (foundWorkspace) {
                workspaceId = foundWorkspace.id;
            }
        }

        if (workspaceId) {
            await workspaceContext.handleWorkspaceChange(workspaceId, false);
            await markNotificationAsRead(processed_notification);
            window.location.href = fullUrl;
        } else {
            console.log("Project ID not found in any workspace.");
        }

        await markNotificationAsRead(processed_notification);
        window.location.href = fullUrl;


    }
};


  const handleWorkspaceInvite = async (notification, AcceptedInvite) => {
    setAvoidMisfire(true);
    await WorkspaceInviteUpdate(notification.id, notification.invite_identifier, AcceptedInvite, loggedUserID);
  };

  const getProjectIDFromNavBar = () => {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/');

    const isDashboard = currentPath.endsWith('/dashboard');
    const isDetails = currentPath.endsWith('/details');
    const isSettings = currentPath.endsWith('/settings');
    const isArchive = currentPath.endsWith('/archive');
    const hasTaskAnimation = !!window.location.hash // I know it looks retarded but !! is converting it into a boolean, otherwise it gives me the hash

    let projectId;
    if (isDashboard || isDetails || isSettings || isArchive || hasTaskAnimation) {
      projectId = pathParts[pathParts.length - 2];
    } else {
      projectId = pathParts[pathParts.length - 1];
    }


    return projectId;
  };

  const handleAddUserToProject = async (user_id) => {
    const response = await AddUserToProject(myWorkspaces[0].id, getProjectIDFromNavBar(), user_id);
    if (response.status === 200) {
      const userToAdd = workspaceWorkers.find((user) => user.id === user_id);
      if (userToAdd) {
        setProjectWorkers((prevWorkers) => [...prevWorkers, userToAdd]);
        const updatedWorkspaceWorkers = workspaceWorkers.filter((user) => user.id !== user_id);
        setWorkspaceWorkers(updatedWorkspaceWorkers);
      }
    }
  };

  const handleRemoveUserFromProject = async (user_id) => {
    const response = await RemoveUserFromProject(myWorkspaces[0].id, getProjectIDFromNavBar(), user_id);
    if (response.status === 200) {
      const userToRemove = projectWorkers.find((user) => user.id === user_id);
      if (userToRemove) {
        const updatedProjectWorkers = projectWorkers.filter((user) => user.id !== user_id);
        setProjectWorkers(updatedProjectWorkers);
        setWorkspaceWorkers((prevWorkers) => [...prevWorkers, userToRemove]);
      }
    }
  };

  const handleCreateList = async () => {
    setAvoidMisfire(true);
    try {
      const project_id = getProjectIDFromNavBar();
      const responseBoard = await GetProjectBoardInformation(project_id, myWorkspaces[0].id);
      const response = await CreateNewList(myWorkspaces[0].id, project_id, nameForList, responseBoard.data.id);


      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Creating List Failed:', error.message);
    } finally {
      setTimeout(() => {
        setAvoidMisfire(false);
      }, 1000);
      setNameForList(null);
    }
    handleListModalClose();
  };

  const markNotificationAsRead = async (notification) => {
    setAvoidMisfire(true);
    try {
      await MarkNotificationAsRead(notification.id);
    } catch (error) {
      console.error('Marking Notification as Read Failed:', error.message);
    } finally {
      setTimeout(() => {
        setAvoidMisfire(false);
      }, 1000);
    }
  };

  const FormatTimestamp = (timestamp) => {
    const parsedTimestamp = new Date(timestamp);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return parsedTimestamp.toLocaleString('en-US', options);
  };

  const handleSendInvite = async (email) => {
    setAvoidMisfire(true);
    try {
      await SendWorkspaceInvite(myWorkspaces[0].id, email);
    } catch (error) {
      console.error('Sending Invite Failed:', error.message);
    } finally {
      setTimeout(() => {
        setAvoidMisfire(false);
      }, 1000);
    }
    handleNewWorkSpaceMemberModalClose();
  };

  return (
    <Box className="sidebar-main-box">
      <TopBar
        isOpen={isOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleListModalOpen={handleListModalOpen}
        isWorkflowCalendar={isWorkflowCalendar}
        isWorkflowProjects={isWorkflowProjects}
        isWorkflowProjectsNew={isWorkflowProjectsNew}
        isWorkflowProjectsEdit={isWorkflowProjectsEdit}
        isProjectDetails={isProjectDetails}
        buttonText={buttonText}
        open_Element1={open_Element1}
        handleClickProjectMenu={handleClickProjectMenu}
        anchorEl={anchorEl}
        handleCloseProjectMenu={handleCloseProjectMenu}
        projectWorkers={projectWorkers}
        handleAvatarGroupClick={handleAvatarGroupClick}
        open_Element2={open_Element2}
        handleClickProjectMemberMenu={handleClickProjectMemberMenu}
        anchorEl2={anchorEl2}
        handleCloseProjectMemberMenu={handleCloseProjectMemberMenu}
        searchValue={searchValue}
        handleSearchChange={handleSearchChange}
        filteredProjectWorkers={filteredProjectWorkers}
        myLoggedUser={myLoggedUser}
        loggedUserID={loggedUserID}
        handleRemoveUserFromProject={handleRemoveUserFromProject}
        filteredWorkspaceWorkers={filteredWorkspaceWorkers}
        handleAddUserToProject={handleAddUserToProject}
        isWorkflowIndex={isWorkflowIndex}
        isWorkflowMembers={isWorkflowMembers}
        isWorkflowSettings={isWorkflowSettings}
        isProjectArchive={isProjectArchive}
        isVacations={isVacations}
        myWorkspaces={myWorkspaces}
        buttonWorkspaceText={buttonWorkspaceText}
        workspaceWorkersRaw={workspaceWorkersRaw}
        handleNewWorkSpaceMemberModalOpen={handleNewWorkSpaceMemberModalOpen}
        myNotifs={myNotifs}
        handleNotifOpen={handleNotifOpen}
        notifAnchorEl={notifAnchorEl}
        handleNotifClosed={handleNotifClosed}
        unreadNotifications={unreadNotifications}
        handleNotificationClick={handleNotificationClick}
        FormatTimestamp={FormatTimestamp}
        handleWorkspaceInvite={handleWorkspaceInvite}
        getLoggedUser={getLoggedUser}
        myUserInMemberFormat={myUserInMemberFormat}
        getProjectIDFromNavBar={getProjectIDFromNavBar}
        isVacationsOverview={isVacationsOverview}

      />

      <Drawer
          className={`sidebar-drawer ${isOpen ? 'open' : 'closed'}`} // Add classes conditionally
        variant='persistent'
        anchor='left'
        open={isOpen}
      >
        <Toolbar className="sidebar-toolbar">
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className="sidebar-text" />
          </IconButton>
          <a href='/workflow/index'>
            <img className="sidebar-image" src={myLogo} alt='' />
          </a>
        </Toolbar>

        <WorkspaceList
          handleWorkspaceClick={handleWorkspaceClick}
          showMoreOptions={showMoreOptions}
          showNewWorkspace={showNewWorkspace}
          setCurrentProject={setCurrentProject}
          myWorkspaces={myWorkspaces}
          workspaceContext={workspaceContext}
        />

        <Divider className="sidebar-divider" />

        <Box
            className="sidebar-project-list-box"

        >
          <ProjectList
            setShowMoreOptionsProjects={setShowMoreOptionsProjects}
            showMoreOptionsProjects={showMoreOptionsProjects}
            myProjects={myProjects}
            myCurrentProject={myCurrentProject}
            myWorkspaces={myWorkspaces}
            projectsContext={projectsContext}
          />
        </Box>


        <Divider className="sidebar-divider" />

        <SidebarExtraButtons
          handleNavigateToProfile={handleNavigateToProfile}
          handleNavigateToSupport={handleNavigateToSupport}
          workspaceId={myWorkspaces[0]?.id}
        />
      </Drawer>

      <NewListModal
        openListModal={openListModal}
        handleListModalClose={handleListModalClose}
        handleListNameChange={handleListNameChange}
        nameForList={nameForList}
        handleCreateList={handleCreateList}
        avoidMisfire={avoidMisfire}
      />
      {myWorkspaces[0] && myWorkspaces[0].name && (
        <NewWorkspaceMember
          openNewWorkSpaceMemberModal={openNewWorkSpaceMemberModal}
          handleNewWorkSpaceMemberModalClose={handleNewWorkSpaceMemberModalClose}
          handleSendInvite={handleSendInvite}
          workspace_name={myWorkspaces[0].name}
          avoidMisfire={avoidMisfire}
        />
      )}
    </Box>
  );
}

import React from 'react';
import {
    Card,
    CardContent,
    Typography
} from '@mui/material';
import RecentProjectStatus from 'components/Custom/RecentProjectStatus';
import '../../css/components/Profile/ProfileCard.css';
import {useTranslation} from "react-i18next";

const RecentProjectStatusWrapper = ({ hasAccessToLastProject, parsedData }) => {
    const { t } = useTranslation();

    return (
        <>
            {hasAccessToLastProject ? (
                parsedData && <RecentProjectStatus projectData={parsedData} />
            ) : (
                <Card sx={{mt:1}}  className="recent-project-card">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {t("profile.last_accessed_project")}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            {t("profile.last_access_permission")}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default RecentProjectStatusWrapper;

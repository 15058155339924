import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  AppBar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TokenIcon from '@mui/icons-material/Token';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import AddIcon from '@mui/icons-material/Add';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MenuIcon from '@mui/icons-material/Menu';
import CommonAvatarGroup from 'components/Core/CommonAvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';
import VacationRequestModal from 'components/Calendar/VacationRequestModal'
import NotificationMenu from './NotificationMenu'
import logoIcon from 'assets/img/brand/favicon.png'

import VacationApprovalModal from 'components/Calendar/VacationApprovalModal'
import ViewEventsModal from "components/Calendar/Events/ViewEventsModal";
import CreateEventsModal from "components/Calendar/Events/CreateEventsModal";
import HolidayModal from "components/Calendar/Holiday/HolidayModal";
import SearchComponent from "components/Topbar/SearchBar";
import { useTranslation } from "react-i18next";

import { getUserStatusPriority } from '../Custom/userStatusHelper';
import "../../css/components/Topbar/Topbar.css";
import "../../css/main.css";



const TopBar = ({ isOpen, handleDrawerOpen, handleListModalOpen, isWorkflowCalendar, isWorkflowProjects, isWorkflowProjectsNew, isWorkflowProjectsEdit, isProjectDetails, buttonText, open_Element1, handleClickProjectMenu, anchorEl, handleCloseProjectMenu, projectWorkers, handleAvatarGroupClick,
  open_Element2, handleClickProjectMemberMenu, anchorEl2, handleCloseProjectMemberMenu, searchValue, handleSearchChange, filteredProjectWorkers, myLoggedUser, loggedUserID, handleRemoveUserFromProject, filteredWorkspaceWorkers, handleAddUserToProject, isWorkflowIndex,
  isWorkflowMembers, isWorkflowSettings, isProjectArchive, isVacations, myWorkspaces, buttonWorkspaceText, workspaceWorkersRaw, handleNewWorkSpaceMemberModalOpen, myNotifs, handleNotifOpen, notifAnchorEl, handleNotifClosed, unreadNotifications, handleNotificationClick, FormatTimestamp, handleWorkspaceInvite, getLoggedUser,
  myUserInMemberFormat, getProjectIDFromNavBar, isVacationsOverview }) => {
  const { t } = useTranslation();

  const [isVacationRequestModalOpen, setVacationRequestModalOpen] = useState(false);

  const handleOpenVacationRequestModal = () => {
    console.log("i am indeed opening")
    setVacationRequestModalOpen(true);
  };

  const handleCloseVacationRequestModal = () => {

    console.log("i am indeed closing")
    setVacationRequestModalOpen(false);
  };

  const [modalOpenApproveVacations, setModalOpenApproveVacations] = useState(false);

  const handleOpenApproveVacationsModal = () => {
    setModalOpenApproveVacations(true);
  };

  const handleCloseApproveVacationsModal = () => {
    setModalOpenApproveVacations(false);
  };

  const [viewEventsOpen, setViewEventsOpen] = useState(false);
  const [createEventsOpen, setCreateEventsOpen] = useState(false);

  const handleOpenViewEventsModal = () => setViewEventsOpen(true);
  const handleCloseViewEventsModal = () => setViewEventsOpen(false);

  const handleOpenCreateEventsModal = () => setCreateEventsOpen(true);
  const handleCloseCreateEventsModal = () => setCreateEventsOpen(false);

  const [openHolidayModal, setOpenHolidayModal] = useState(false);

  const [showSearchBar, setShowSearchBar] = useState(window.innerWidth > 700);

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(window.innerWidth > 700);

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(prev => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setShowSearchBar(false);
        setActionsDropdownOpen(false);
      } else {
        setShowSearchBar(true);
        setActionsDropdownOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      open={isOpen}
      className={isOpen ? "topbar-open" : ""}
    >
      <Toolbar className="topbar-toolbar">
        {!isOpen && (
          <IconButton aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
            <div className="topbar-icon-button" style={{ backgroundImage: `url(${logoIcon})` }} />
          </IconButton>
        )}

        {isVacationsOverview && (
            <>
              <Button onClick={handleOpenViewEventsModal} className="topbar-view-events-button topbar-hide-buttons">
                <span className="topbar-calendar-text">{t("calendar.event_view")}</span> <EventNoteIcon className="topbar-view-events" />
              </Button>

              <div className="topbar-dropdown-button" onClick={handleOpenMenu}>
                <MenuIcon />
              </div>

              <Menu
                  anchorEl={anchorElMenu}
                  open={Boolean(anchorElMenu)}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    className: "view-task-menu-actions",
                  }}
              >

                <MenuItem disableRipple>
                  <Box className="view-task-menu-item">
                    {t("modal.actions")}
                  </Box>
                </MenuItem>

                <Divider className="view-task-menu-divider" key="divider" />

                <MenuItem onClick={handleOpenViewEventsModal} key="view-events">
                  {t("calendar.event_view")}
                </MenuItem>


                {myLoggedUser?.is_admin || myLoggedUser?.is_owner ? (
                    <>
                      <MenuItem onClick={handleOpenCreateEventsModal} key="create-event">
                        {t("topbar.create_event")}
                      </MenuItem>

                      <MenuItem onClick={() => setOpenHolidayModal(true)} key="manage-holidays">
                        {t("topbar.manage_holidays")}
                      </MenuItem>
                    </>
                ) : null}
              </Menu>


            </>

        )}



        {isVacationsOverview && (myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
          <>
            <Divider orientation="vertical" flexItem className="topbar-divider topbar-events-divider" />
            <Button onClick={handleOpenCreateEventsModal} className="topbar-view-events-button topbar-button topbar-hide-buttons">
              <span className="topbar-calendar-text">{t("topbar.create_event")}</span> <AddIcon className="topbar-view-events" />
            </Button>
            <Divider orientation="vertical" flexItem className="topbar-divider topbar-events-divider" />
            <Button onClick={() => setOpenHolidayModal(true)} className="topbar-view-events-button topbar-button topbar-hide-buttons">
              <span className="topbar-calendar-text">{t("topbar.manage_holidays")}</span> <AddIcon className="topbar-view-events" />
            </Button>
            <HolidayModal open={openHolidayModal} onClose={() => setOpenHolidayModal(false)} workspaceId={myWorkspaces[0]?.id} />
          </>
        )}

        {isVacations && myLoggedUser && (
          
          <Button onClick={handleOpenVacationRequestModal} className="topbar-view-events-button topbar-button">
            <span className="topbar-calendar-text">{t("topbar.vacation_request")}</span> <AddIcon className="topbar-view-events" />
          </Button>

        )}

        {isVacations && (myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
          <>
            <Divider orientation="vertical" flexItem className="topbar-divider" />
            <Button onClick={handleOpenApproveVacationsModal} className="topbar-view-events-button topbar-button">
              <span className="topbar-calendar-text">{t("topbar.vacation_approve")}</span> <AddIcon className="topbar-view-events" />
            </Button>
          </>
        )}



        {isWorkflowProjects && !isWorkflowProjectsNew && !isWorkflowProjectsEdit && (
          <>
            {!isProjectDetails && !isProjectArchive && (
              <Button onClick={handleListModalOpen} className="topbar-view-events-button topbar-button">
                <span className="topbar-add-list">{t("topbar.add_list")} </span> <AddIcon className="topbar-view-events" />
              </Button>
            )}
            <div className="topbar-buttons-div">
              <Button
                id="project-settings-button"
                aria-controls={open_Element1 ? 'project-settings-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element1 ? 'true' : undefined}
                onClick={handleClickProjectMenu}
              >
                <span className="topbar-button-text">{buttonText}</span> <WidgetsIcon className="topbar-widget-button" />
              </Button>
              <Menu
                id="project-settings-menu"
                anchorEl={anchorEl}
                open={open_Element1}
                onClose={handleCloseProjectMenu}
                MenuListProps={{ 'aria-labelledby': 'project-settings-button' }}
                className="topbar-project-settings"
              >
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_board")}
                </MenuItem>
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}/details`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_details")}
                </MenuItem>
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}/archive`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_archives")}
                </MenuItem>
              </Menu>

              <Divider orientation="vertical" flexItem className="topbar-divider topbar-avatar-divider-group" />

              <CommonAvatarGroup users={projectWorkers} handleAvatarGroupClick={handleAvatarGroupClick} myLoggedUser={myLoggedUser} />

              <Button
                id="basic-button"
                aria-controls={open_Element2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element2 ? 'true' : undefined}
                onClick={handleClickProjectMemberMenu}
              >
                <GroupAddIcon className="topbar-add-member-button" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open_Element2}
                onClose={handleCloseProjectMemberMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                className="topbar-members-paper"
              >
                <div className="topbar-members-content">
                  <Typography variant="h6" className="topbar-members-title">
                    {t("modal.members")}
                  </Typography>
                  <TextField
                    id="outlined-search"
                    className="topbar-members-search"
                    label={t("topbar.search_field")}
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    inputProps={{ className: "white-txt-color" }}
                  />
                  <Typography variant="body1" className="topbar-members-title">
                    {t("topbar.project_members")}
                  </Typography>
                  {filteredProjectWorkers.length === 0 ? (
                    <Typography variant="body2" className="topbar-members-no-users">
                      {t("topbar.no_users_project")}
                    </Typography>
                  ) : (
                    filteredProjectWorkers
                      .sort((a, b) => {
                        const now = new Date();  // Get the current time
                        const { status: priorityA } = getUserStatusPriority(a, now, t); // Get the status for user A
                        const { status: priorityB } = getUserStatusPriority(b, now, t); // Get the status for user B
                        return priorityA - priorityB; // Sort in ascending order based on priority
                      })
                      .map((user) => {
                        const { status: statusPriority, message } = getUserStatusPriority(user, new Date(), t); // Get both status and message for each user

                        return (
                          <div key={user.id} className="topbar-workspace-workers-list">
                            <a href={`/workflow/profile/${user.id}`}>
                              <UserOnlineStatus
                                userStatusAvailability={user.current_availability}
                                statusPriority={statusPriority}  // Pass the precomputed priority
                                message={message}                // Pass the precomputed message
                                showBadgeOnly={true}
                              >
                                <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                              </UserOnlineStatus>
                            </a>
                            <Typography variant="body2" className="topbar-members-full-name">
                              {user.full_name}
                            </Typography>
                            <div className="topbar-members-list">
                              {(myLoggedUser.is_admin || myLoggedUser.is_owner) &&
                                user.id !== loggedUserID &&
                                !user.is_owner && (
                                  <Button onClick={() => handleRemoveUserFromProject(user.id)}>{t("topbar.remove")}</Button>
                                )}
                              {user.is_owner && (
                                <Tooltip title={t("topbar.project_owner")}>
                                  <BrightnessHighIcon className="white-txt-color topbar-members-list" />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        );
                      })
                  )}
                  <Typography variant="body1" className="topbar-members-title">
                    {t("sidebar.workspace_members")}
                  </Typography>
                  {filteredWorkspaceWorkers.length === 0 ? (
                    <Typography variant="body2" className="topbar-members-no-users-workspace">{t("topbar.no_users_workspace")}</Typography>
                  ) : (
                    filteredWorkspaceWorkers
                      .sort((a, b) => {
                        const now = new Date();  // Get the current time
                        const { status: priorityA } = getUserStatusPriority(a, now, t); // Get the status for user A
                        const { status: priorityB } = getUserStatusPriority(b, now, t); // Get the status for user B
                        return priorityA - priorityB; // Sort in ascending order based on priority
                      })
                      .map((user) => {
                        const { status: statusPriority, message } = getUserStatusPriority(user, new Date(), t); // Get both status and message for each user

                        return (
                          <div key={user.id} className="topbar-workspace-workers-list">
                            <a href={`/workflow/profile/${user.id}`}>
                              <UserOnlineStatus
                                userStatusAvailability={user.current_availability}
                                statusPriority={statusPriority}  // Pass the precomputed priority
                                message={message}                // Pass the precomputed message
                                showBadgeOnly={true}
                              >
                                <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                              </UserOnlineStatus>
                            </a>
                            <Typography variant="body2" className="topbar-members-full-name">
                              {user.full_name}
                            </Typography>
                            <div className="topbar-members-list">
                              <Button onClick={() => handleAddUserToProject(user.id)}>{t("topbar.add")}</Button>
                            </div>
                          </div>
                        );
                      })

                  )}
                </div>
              </Menu>
            </div>
          </>
        )}
        {(isWorkflowIndex || isWorkflowSettings || isWorkflowMembers || isWorkflowCalendar) && myWorkspaces[0] && (
          <>
            <div className="topbar-buttons-div">

             {isWorkflowIndex &&(
                 <>
                   <Box className={`topbar-search-bar ${showSearchBar ? 'show' : 'hide'}`}>
                     <SearchComponent workspace_id={myWorkspaces[0]?.id} />
                   </Box>
                   <Box className="topbar-search-icon-box">
                     <Button onClick={toggleSearchBar} className="topbar-button">
                       <SearchIcon className="topbar-search-icon" />
                     </Button>
                   </Box>
                </>
            )}

              {(!isWorkflowCalendar) && (
                <>
                  <Button
                    id="workspace-settings-button"
                    aria-controls={open_Element1 ? 'workspace-settings-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Element1 ? 'true' : undefined}
                    onClick={handleClickProjectMenu}
                    className="topbar-button"
                  >
                    <span className="topbar-button-text">{buttonWorkspaceText}</span> <WidgetsIcon className="topbar-widget-button" />
                  </Button><Menu
                    id="workspace-settings-menu"
                    anchorEl={anchorEl}
                    open={open_Element1}
                    onClose={handleCloseProjectMenu}
                    MenuListProps={{ 'aria-labelledby': 'workspace-settings-button' }}
                    className="topbar-workspace-paper"
                  >
                    <MenuItem component={Link} to={`/workflow/index`} onClick={handleCloseProjectMenu}>
                      {t("sidebar.projects")}
                    </MenuItem>
                    <MenuItem component={Link} to={`/workflow/workspace/${myWorkspaces[0].id}/members`} onClick={handleCloseProjectMenu}>
                      {t("sidebar.workspace_members")}
                    </MenuItem>
                    {(myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
                      <MenuItem component={Link} to={`/workflow/workspace/${myWorkspaces[0].id}/settings`} onClick={handleCloseProjectMenu}>
                        {t("sidebar.workspace_settings")}
                      </MenuItem>
                    )}
                  </Menu>
                  <Divider orientation="vertical" flexItem className="topbar-divider topbar-avatar-divider-group" />
                </>
              )}
              <CommonAvatarGroup users={workspaceWorkersRaw} handleAvatarGroupClick={handleAvatarGroupClick} myLoggedUser={myLoggedUser} />
              <Button
                id="basic-button"
                aria-controls={open_Element2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element2 ? 'true' : undefined}
                onClick={handleClickProjectMemberMenu}
                className="topbar-button"
              >
                <GroupAddIcon className="topbar-add-member-button" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open_Element2}
                onClose={handleCloseProjectMemberMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                className="topbar-members-paper"
              >

                <div className="topbar-members-content">
                  <Typography variant="h6" className="topbar-members-title">
                    {t("modal.members")}
                  </Typography>
                  <TextField
                    id="outlined-search"
                    className="topbar-members-search"
                    label={t("topbar.search_field")}
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    inputProps={{ className: "white-txt-color" }}
                  />
                  <div className="topbar-workspace-members-list-title">
                    <Typography variant="body1" className="topbar-new-workspace-member">
                      {t("sidebar.workspace_members")}
                    </Typography>
                    <Button onClick={handleNewWorkSpaceMemberModalOpen}>
                      <AddIcon className="topbar-widget-button" />
                    </Button>
                  </div>
                  {workspaceWorkersRaw.length === 0 ? (
                    <Typography variant="body2">{t("topbar.no_users_workspace")}</Typography>
                  ) : (
                    workspaceWorkersRaw
                      .sort((a, b) => {
                        const now = new Date();  // Get the current time
                        const { status: priorityA } = getUserStatusPriority(a, now, t); // Get the status for user A
                        const { status: priorityB } = getUserStatusPriority(b, now, t); // Get the status for user B
                        return priorityA - priorityB; // Sort in ascending order based on priority
                      })
                      .map((user) => {
                        const { status: statusPriority, message } = getUserStatusPriority(user, new Date(), t); // Get both status and message

                        return (
                          <div key={user.id} className="topbar-workspace-workers-list">
                            <a href={`/workflow/profile/${user.id}`}>
                              <UserOnlineStatus
                                userStatusAvailability={user.current_availability}
                                statusPriority={statusPriority}  // Pass the precomputed priority
                                message={message}                // Pass the precomputed message
                                showBadgeOnly={true}
                              >
                                <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                              </UserOnlineStatus>
                            </a>
                            <Typography variant="body2" className="topbar-members-full-name">
                              {user.full_name}
                            </Typography>
                            {user.is_owner && (
                              <Tooltip title={t("profile.workspace_owner")}>
                                <TokenIcon className="topbar-workspace-icons" />
                              </Tooltip>
                            )}
                            {user.is_admin && (
                              <Tooltip title={t("profile.workspace_admin")}>
                                <BrightnessAutoIcon className="topbar-workspace-icons" />
                              </Tooltip>
                            )}
                          </div>
                        );
                      })
                  )}
                </div>
              </Menu>
            </div>
          </>
        )}

        <Button className='topbar-notif-button topbar-button' onClick={handleNotifOpen}>
          <Badge
            badgeContent={myNotifs && myNotifs.filter((notification) => !notification.is_read).length > 0 ? myNotifs.filter((notification) => !notification.is_read).length.toString() : '0'}
            color="error"
            invisible={!(myNotifs && myNotifs.some((notification) => !notification.is_read))}
          >
            <NotificationsIcon className="topbar-notif-button-icon" />
          </Badge>
        </Button>

        <a href={`/workflow/profile/${getLoggedUser()}`}>
          <Badge className="topbar-badge" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar
              className="topbar-avatars"
              alt={myUserInMemberFormat?.username}
              src={process.env.REACT_APP_BACKEND_URL + myUserInMemberFormat?.image}
            />
          </Badge>
        </a>
        <NotificationMenu
          anchorEl={notifAnchorEl}
          open={Boolean(notifAnchorEl)}
          onClose={handleNotifClosed}
          unreadNotifications={unreadNotifications}
          handleNotificationClick={handleNotificationClick}
          FormatTimestamp={FormatTimestamp}
          handleWorkspaceInvite={handleWorkspaceInvite}
        />
      </Toolbar>
      <VacationRequestModal
        open={isVacationRequestModalOpen}
        handleCloseVacationRequestModal={handleCloseVacationRequestModal}
        myUserInMemberFormat={myUserInMemberFormat}
        workspace_id={myWorkspaces[0]?.id}
      />
      <VacationApprovalModal
        open={modalOpenApproveVacations}
        handleCloseApproveVacationsModal={handleCloseApproveVacationsModal}
        workspace_id={myWorkspaces[0]?.id}
      />
      <ViewEventsModal open={viewEventsOpen} onClose={handleCloseViewEventsModal} workspaceId={myWorkspaces[0]?.id} />
      <CreateEventsModal open={createEventsOpen} onClose={handleCloseCreateEventsModal} workspaceId={myWorkspaces[0]?.id} />
    </AppBar>
  );
};

export default TopBar;

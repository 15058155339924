import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useNavigate } from 'react-router-dom'; // Import useNavigate here

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AuthorizationContext = createContext();

export const useAuthorization = () => useContext(AuthorizationContext);

export const AuthorizationProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token'));
  const [loggedUser, setLoggedUser] = useState(localStorage.getItem("user_id") || '');
  const isRefreshingRef = useRef(false);
  const refreshTokenPromiseRef = useRef(null);

  const WHITELISTED_403_URLS = ["/calculate-stats/"];

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Use useNavigate here directly

  const saveTokens = (newAccessToken, newRefreshToken) => {
    if (newAccessToken) {
      localStorage.setItem('access_token', newAccessToken);
      setAccessToken(newAccessToken);
    }
    if (newRefreshToken) {
      localStorage.setItem('refresh_token', newRefreshToken);
      setRefreshToken(newRefreshToken);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const refreshAccessToken = useCallback(async () => {
    // Check if a refresh is already in progress by inspecting the ref
    if (isRefreshingRef.current) {
      return refreshTokenPromiseRef.current; // Return the ongoing refresh promise
    }

    // Set the ref to indicate a refresh is in progress
    isRefreshingRef.current = true;

    // Create a new refresh promise
    const refreshPromise = new Promise(async (resolve, reject) => {
      try {
        // Call the refresh token endpoint
        const response = await axios.post(BACKEND_URL + '/users/login/refresh/', {
          refresh: refreshToken,
        });

        // Save the new tokens
        saveTokens(response.data.access, response.data.refresh);

        resolve(response.data.access); // Resolve the promise with the new token
      } catch (error) {
        console.error('Token refresh failed:', error.message);
        console.error('Token refresh failed full:', error);

        reject(error); // Reject the promise if the request fails

        // Optionally, log out the user if the refresh fails
        logout();
      } finally {
        // Reset the refs after the refresh completes
        isRefreshingRef.current = false;
        refreshTokenPromiseRef.current = null;
      }
    });

    // Save the current refresh promise in the ref
    refreshTokenPromiseRef.current = refreshPromise;

    return refreshPromise; // Return the newly created promise
  }, [refreshToken]);

  const authAxios = useCallback(async (config) => {
    const headers = {
      ...config.headers,
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    };

    try {
      const response = await axios({
        ...config,
        headers,
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.errors[0]?.code === 'token_not_valid') {
          try {
            const newAccessToken = await refreshAccessToken();
            const retryResponse = await axios({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${newAccessToken}`,
              },
            });
            return retryResponse;
          } catch (refreshError) {
            throw refreshError;
          }
        }

        if (error.response.status === 403) {
          const requestUrl = error.response.config.url;  

          // Check if any of the whitelisted URLs are contained within the request URL this is very unique exceptions
          // that occur for now in th profile page, where you might not have access to ONLY A FEW bits of information instead of the whole picture.
          const isWhitelisted = WHITELISTED_403_URLS.some(whitelistedUrl => requestUrl.includes(whitelistedUrl));

          if (!isWhitelisted) {
            showSnackbar('You do not have permission to perform this action.', 'error');
            navigate('/workflow/index', { replace: true }); 
          }

          return;
        }
      }

      throw error;
    }
  }, [accessToken, refreshAccessToken, navigate, showSnackbar]);

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/users/login/`, { username, password });
      saveTokens(response.data.access, response.data.refresh);

      const profileResponse = await axios.get(`${BACKEND_URL}/users/profile/`, {
        headers: { Authorization: `Bearer ${response.data.access}` },
      });
      setLoggedUser(profileResponse.data.id);
      localStorage.setItem('user_id', profileResponse.data.id);

      return response.data;
    } catch (error) {
      console.error('Login failed:', error.message);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');

    setAccessToken(null);
    setRefreshToken(null);
    setLoggedUser(null);

    setTimeout(() => {
      window.location.href = '/auth/login';
    }, 0);
  };


  const getLoggedUser = () => {
    return loggedUser;
  };

  return (
    <AuthorizationContext.Provider
      value={{
        accessToken,
        refreshToken,
        authAxios,
        login,
        logout,
        getLoggedUser,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import {
    Editor,
    EditorState,
    RichUtils,
    AtomicBlockUtils,
    convertToRaw,
    convertFromRaw,
    getDefaultKeyBinding
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { replaceText } from 'draft-js/lib/DraftModifier';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ImageIcon from '@mui/icons-material/Image';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentState from 'draft-js/lib/ContentState';
import 'react-medium-image-zoom/dist/styles.css';
import {useTranslation} from "react-i18next";
import '../../css/components/Custom/TextEditor.css';
import '../../css/main.css';

const TextEditor = ({ handleDescription, value, handleCancel, editable= true }) => {
    const { t } = useTranslation();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [imageCount, setImageCount] = useState(0);
    const [validationMessage, setValidationMessage] = useState('');
    const [textAlign, setTextAlign] = useState('start');
    const [open, setOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const { showSnackbar } = useSnackbar();

    const editorRef = useRef(null);

    const handleClickOpen = (src) => {
        setModalImageSrc(src);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setModalImageSrc('');
    };

    const handleKeyCommand = (command) => {
        if (command === 'tab') {
            setEditorState(insertText(editorState, '    '));
            return 'handled';
        }

        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const insertMedia = (url, type) => {
        if (imageCount >= 10) {
            showSnackbar(t("TextEditor.MaximumCapacityOfImages"), 'error');
            return;  
        }
        
        console.log("zzzz")
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        setEditorState(newEditorState);
    };

    const handlePastedFiles = (files) => {
        if (imageCount >= 10) {
            showSnackbar(t("TextEditor.MaximumCapacityOfImages"), 'error');
            return 'handled';
        }
        console.log("sssss")

        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            insertMedia(e.target.result, 'image');
        };
        reader.readAsDataURL(file);
        return 'handled';
    };

    const handlePastedText = (text, html) => {
        if (html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const img = doc.querySelector('img');
            if (img && imageCount < 10) {
                const url = img.src;
                insertMedia(url, 'image');
                return 'handled';
            } else if (imageCount >= 10) {
                showSnackbar(t("TextEditor.MaximumCapacityOfImages"), 'error');
                return 'handled';
            }
        }
        return 'not-handled';
    };

    const mediaBlockRenderer = (block) => {
        if (block.getType() === 'atomic') {
            return {
                component: Media,
                editable: false,
            };
        }
        return null;
    };

    const saveContent = () => {
        if (validate()) {
            const content = editorState.getCurrentContent();
            const raw = convertToRaw(content);
            handleDescription(JSON.stringify(raw));
        }
    };

    const loadContent = () => {
        try {
            if (value) {
                const content = convertFromRaw(JSON.parse(value));
                setEditorState(EditorState.createWithContent(content));
            } else {
                const contentState = ContentState.createFromText('');
                setEditorState(EditorState.createWithContent(contentState));
            }
        } catch (error) {
            const contentState = ContentState.createFromText(value);
            setEditorState(EditorState.createWithContent(contentState));
            console.error('Marking Notification as Read Failed:', error.message);
        }
    };

    const validate = () => {
        const contentText = editorState.getCurrentContent().getPlainText();
        if (contentText.length >= 5000) {
            setValidationMessage(t("custom.content_char_err"));
            return false;
        }
        if (imageCount >= 10) {
            setValidationMessage(t("custom.content_img_err"));
            return false;
        }
        setValidationMessage('');
        return true;
    };


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadContent();
    }, [value]);
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        countImages();
        validate();
    }, [editorState]);

    const countImages = () => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const entityMap = rawContent.entityMap;

        let imageCount = 0;

        for (let key in entityMap) {
            if (entityMap[key].type === 'image') {
                imageCount += 1;
            }
        }
        setImageCount(imageCount);
    };

    const handleEditorClick = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const handleUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const handleRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const handleAlignment = (alignment) => {
        setTextAlign(alignment);
    };

    const uploadImage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            insertMedia(e.target.result, 'image');
        };
        reader.readAsDataURL(file);
    };

    const Media = (props) => {
        const entity = props.contentState.getEntity(props.block.getEntityAt(0));
        const { src } = entity.getData();
        const type = entity.getType();

        let media;
        if (type === 'image') {
            const isBase64 = src.startsWith('data:image/');
            const imageSource = isBase64 ? src : processUrl(src);

            media = (
                <img 
                    src={imageSource} 
                    alt="" 
                    className="text-editor-img"
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClickOpen(imageSource);
                    }}
                />
            );
        }
        return media;
    };

    const processUrl = (url) => {
        const baseUrl = process.env.REACT_APP_BACKEND_URL || '';
        return url.startsWith('http') ? url : `${baseUrl}/${url}`;
    };

    return (
        <div>
            {validationMessage && <div className="text-editor-validation-message">{validationMessage}</div>}
            {editable && (
                <div className="text-editor-editable-div">
                    <IconButton onClick={() => toggleInlineStyle('BOLD')}><FormatBoldIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => toggleInlineStyle('ITALIC')}><FormatItalicIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => toggleInlineStyle('UNDERLINE')}><FormatUnderlinedIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => toggleBlockType('code-block')}><CodeIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => toggleBlockType('unordered-list-item')}><FormatListBulletedIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => toggleBlockType('ordered-list-item')}><FormatListNumberedIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => handleAlignment('start')}><FormatAlignLeftIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => handleAlignment('center')}><FormatAlignCenterIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={() => handleAlignment('end')}><FormatAlignRightIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={handleUndo}><UndoIcon className="white-txt-color" /></IconButton>
                    <IconButton onClick={handleRedo}><RedoIcon className="white-txt-color" /></IconButton>
                    <input
                        accept="image/*"
                        className="no-display"
                        id="upload-image"
                        type="file"
                        onChange={uploadImage}
                    />
                    <label htmlFor="upload-image">
                        <IconButton component="span"><ImageIcon className="white-txt-color" /></IconButton>
                    </label>
                </div>
            )}

            <div
                className="text-editor-text-area"
                style={{ textAlignLast: textAlign }}
                onClick={handleEditorClick}
            >
                <Editor
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={setEditorState}
                    blockRendererFn={mediaBlockRenderer}
                    blockStyleFn={blockStyleFn}
                    handlePastedFiles={handlePastedFiles}
                    handlePastedText={handlePastedText}
                    keyBindingFn={myKeyBindingFn}
                    ref={editorRef}
                    readOnly={!editable}
                />
            </div>
            <div>
                {editorState.getCurrentContent().getPlainText().length}/5000
            </div>
            {editable && (
                <div className="buttons-flex">
                    <Button onClick={handleCancel} className="decline-button">
                        {t("global.cancel")}
                    </Button>
                    <Button onClick={saveContent} className="accept-button" disabled={validationMessage !== ''}>
                        {t("global.save")}
                    </Button>
                </div>
            )}

           
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                className="text-editor-dialog"
            >
                <DialogContent className="text-editor-width">
                        <img src={modalImageSrc} alt="" className="text-editor-width" />
                </DialogContent>
            </Dialog>

        </div>
    );
};

const insertText = (editorState, text) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContent = replaceText(currentContent, selection, text);
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return newEditorState;
};

const myKeyBindingFn = (e) => {
    if (e.keyCode === 9 /* TAB */) {
        return 'tab';
    }
    return getDefaultKeyBinding(e);
};

const blockStyleFn = (block) => {
    switch (block.getType()) {
        case 'code-block':
            return 'text-editor-code-block';
        default:
            return null;
    }
};

export default TextEditor;

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import "../../css/main.css";

export default function SuspendSubscriptionModal({ open, onClose, subscription, handleConfirmSuspendSubscription, avoidMisfire }) {
    const handleCancel = () => {
        onClose();
    };

    const handleConfirmSuspend = () => {
        handleConfirmSuspendSubscription(subscription.id);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} className="modal-background-color">
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent className="white-txt-color">
                Are you sure you want to suspend this subscription?<br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} className="button-dark-bg">
                    Cancel
                </Button>
                <Button onClick={handleConfirmSuspend} disabled={avoidMisfire} className="button-dark-bg">
                    Suspend
                </Button>
            </DialogActions>
        </Dialog>
    );
}
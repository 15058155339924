import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import {useTranslation} from "react-i18next";
import "../../css/components/Sidebar/SidebarExtraButtons.css";

const MoreButtonList = ({ handleNavigateToProfile, handleNavigateToSupport, workspaceId}) => {
  const { logout } = useAuthorization();
    const { t } = useTranslation();

  return (
    <List>
        <ListItemButton component={Link} to={`${workspaceId}/calendar/overview`}>
            <ListItemIcon>
                <CalendarTodayIcon className="extra-buttons-text-color" />
            </ListItemIcon>
            <ListItemText primary={t("calendar.calendar")} />
        </ListItemButton>

      <ListItemButton component={Link} to="notifications">
        <ListItemIcon>
          <CircleNotificationsIcon className="extra-buttons-text-color" />
        </ListItemIcon>
        <ListItemText primary={t("notifications.notifications")} />
      </ListItemButton>

      <ListItemButton component={Link} onClick={handleNavigateToProfile}>
        <ListItemIcon>
          <AccountBoxIcon className="extra-buttons-text-color" />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.profile")} />
      </ListItemButton>

      <ListItemButton component={Link} to="settings">
        <ListItemIcon>
          <SettingsIcon className="extra-buttons-text-color" />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.settings")} />
      </ListItemButton>

      <ListItemButton component={Link} onClick={handleNavigateToSupport}>
        <ListItemIcon>
          <SupportAgentIcon className="extra-buttons-text-color" />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.support")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/about">
        <ListItemIcon>
          <ExitToAppIcon className="extra-buttons-text-color" />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.logout")} onClick={() => { logout() }} />
      </ListItemButton>
    </List>
  );
};

export default MoreButtonList;
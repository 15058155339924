import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import {useTranslation} from "react-i18next";
import "../../css/components/Sidebar/SidebarList.css";
import "../../css/main.css";

const WorkspaceList = ({ handleWorkspaceClick, showMoreOptions, showNewWorkspace, setCurrentProject, myWorkspaces, workspaceContext }) => {

  const { t } = useTranslation();


  return (
    <List>
      <ListItem disablePadding onClick={handleWorkspaceClick} onDoubleClick={() => { window.location.href ='/workflow/index'; setCurrentProject("") }}>
        <ListItemButton>
          <ListItemIcon>
            <WorkspacesIcon className="white-txt-color" />
          </ListItemIcon>
          {myWorkspaces[0] ? (
            <ListItemText
              primary={t("sidebar.my_workspaces")}
              secondary={<Typography variant="body2" className="sidebar-list-text">{myWorkspaces[0].name}</Typography>}
            />
          ) : (
            <ListItemText
              primary={t("sidebar.my_workspaces")}
              secondary={<Typography variant="body2" className="sidebar-list-text">{t("sidebar.no_workspace")}</Typography>}
            />
          )}
        </ListItemButton>
      </ListItem>
      {showMoreOptions && myWorkspaces.slice(1).map((item) => (
        <ListItemButton key={item.id} component={Link} onClick={async () => { await workspaceContext.handleWorkspaceChange(item.id)}}>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
      {showMoreOptions && showNewWorkspace === true && (
        <ListItemButton className="sidebar-list-item-button" component={Link} to="workspaces/new" >
          <ListItemText primary={t("sidebar.new_workspace")} />
        </ListItemButton>
      )}
    </List>
  );
};

export default WorkspaceList;

import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { useService } from 'contexts/ServiceContextProvider';
import { useParams } from 'react-router-dom';
import { useAuthorization } from 'contexts/AuthContextProvider';
import moment from 'moment';
import { Typography, Tooltip, Avatar, Badge, Box, FormControlLabel, Checkbox } from '@mui/material';
import { ConvertToMyTimeZone } from 'components/Custom/TimezoneConvertion.jsx';
import TourIcon from '@mui/icons-material/Tour';
import EventDetailsModal from './Events/EventsDetailsModal';
import { useTranslation } from "react-i18next";
import '../../css/components/Calendar/CalendarComponent.css';
import '../../css/main.css';

/* eslint-disable react-hooks/exhaustive-deps */
const CalendarComponent = () => {
  useEffect(() => {
    document.title = "Calendar - Workflow PRO";
  }, []);
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const { fetchWorkspaceCalendar, getUserTimeZone, getLoggedUserPermissions, updateWorkspaceEvent } = useService();
  const { workspaceId } = useParams();
  const [, setMyUserTimezone] = useState(null);
  const [showHolidayEvents, setShowHolidayEvents] = useState(true);
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  const [showNormalEvents, setShowNormalEvents] = useState(true);
  const [myUserPerms, setMyUserPerms] = useState(null);
  const [currentView, setCurrentView] = useState('dayGridMonth');

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { getLoggedUser } = useAuthorization();
  const [dayHeaderFormat, setDayHeaderFormat] = useState(window.innerWidth < 600 ? { weekday: 'short' } : { weekday: 'long' });


  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    const handleResize = (e) => {
      setDayHeaderFormat(e.matches ? { weekday: 'short' } : { weekday: 'long' });
    };
    mediaQuery.addEventListener('change', handleResize);

    // Clean up the event listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const userColors = new Map();

  const generateColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  const getCountryFlag = (countryCode) => {
    return countryCode?.toLowerCase(); // Converts country code like PT to pt for the flag URL
  };

  const getUserColor = (userName) => {
    if (!userColors.has(userName)) {
      const colorIndex = userColors.size;
      userColors.set(userName, generateColor(colorIndex));
    }
    return userColors.get(userName);
  };

  const handleEventSave = async (editableEvent) => {
    try {
      const updatedEvent = {
        ...editableEvent,
        collaborators: editableEvent.collaborators.map(c => c.id),  
      };

      await updateWorkspaceEvent(updatedEvent, workspaceId, updatedEvent.id);

      setEvents(prevEvents => {
        return prevEvents.map(event => {
          if (event.id === updatedEvent.id) {
            return {
              ...event,
              title: editableEvent.title,
              start: editableEvent.date_start,
              end: editableEvent.date_end,
              allDay: editableEvent.all_day_event,
              backgroundColor: editableEvent.colorCode,
              extendedProps: {
                ...event.extendedProps,
                description: editableEvent.description,
                collaborators: editableEvent.collaborators  
              }
            };
          }
          return event;
        });
      });

      setDialogOpen(false);
    } catch (error) {
      console.error("Failed to update event:", error);
    }
  };



  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.extendedProps.type === 'normal') {

      setSelectedEvent(clickInfo.event);
      setDialogOpen(true);
    }
  };


  useEffect(() => {
    const initializeCalendar = async () => {
      try {
        if (workspaceId) {
          const [data, timezoneResponse] = await Promise.all([
            fetchWorkspaceCalendar(workspaceId),
            getUserTimeZone()
          ]);
          setMyUserTimezone(timezoneResponse);
          const users = data.working_hours.map(wh => wh.user_info);

          const holidayEvents = data.holidays.map((holiday) => {
            const affectedUsers = users.filter(user =>
              holiday.country === user.location?.split('(')[1]?.replace(')', '')
            );

            return {
              type: 'holiday',
              title: holiday.title,
              start: holiday.start,
              allDay: holiday.allDay,
              name: holiday.name,
              local_name: holiday.local_name,
              country: holiday.country,
              affects_all_country: holiday.affects_all_country,
              sub_region: holiday.sub_region,
              source: holiday.source,
              backgroundColor: '#ffeeab',
              extendedProps: {
                ...holiday.extendedProps,
                affectedUsers,
              },
            };
          });

          const workingHourEvents = generateWorkingHourEvents(data.working_hours, timezoneResponse);
          const normalEvents = generateNormalEvents(data.events, timezoneResponse);

          setEvents([...holidayEvents, ...workingHourEvents, ...normalEvents]);
        }
      } catch (error) {
        console.error('Error fetching events or timezone:', error);
      }
    };

    initializeCalendar();
  }, [workspaceId]);

  useEffect(() => {
    const fetchUserPerms = async () => {
      if (workspaceId) {
        try {
          const userPerms = await getLoggedUserPermissions(workspaceId, getLoggedUser());
          setMyUserPerms(userPerms);
        } catch (error) {
          console.error('Error fetching user permissions:', error);
        }
      }
    };
    fetchUserPerms();
  }, [workspaceId]);

  const normalizeDateTime = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setMilliseconds(0);
    return normalizedDate.toISOString();
  };

  const generateWorkingHourEvents = (workingHoursData, loggedUserTimezone) => {
    const events = [];
    const groupedEvents = new Map();
    const dayMap = {
      "Monday": 1,
      "Tuesday": 2,
      "Wednesday": 3,
      "Thursday": 4,
      "Friday": 5,
      "Saturday": 6,
      "Sunday": 0,
    };

    workingHoursData.forEach((workingHours) => {
      const { start, end, title, day, timezone, timezone_offset, user_info } = workingHours;

      if (!start || !end || !day) {
        console.warn('Missing start, end time, or day for event:', title);
        return;
      }

      const userName = user_info.full_name;
      const userColor = getUserColor(userName);
      const userAvatar = user_info.user_image;
      const weekday = dayMap[day];

      if (weekday !== undefined) {
        const startDateTime = ConvertToMyTimeZone(
          timezone,
          timezone_offset,
          moment().day(weekday).set({
            hour: parseInt(start.split(':')[0], 10),
            minute: parseInt(start.split(':')[1], 10),
          }).toISOString(),
          loggedUserTimezone.timezone,
          loggedUserTimezone.timezone_offset
        );

        const endDateTime = ConvertToMyTimeZone(
          timezone,
          timezone_offset,
          moment().day(weekday).set({
            hour: parseInt(end.split(':')[0], 10),
            minute: parseInt(end.split(':')[1], 10),
          }).toISOString(),
          loggedUserTimezone.timezone,
          loggedUserTimezone.timezone_offset
        );

        if (startDateTime && endDateTime) {
          const normalizedStartDateTime = normalizeDateTime(startDateTime);
          const normalizedEndDateTime = normalizeDateTime(endDateTime);

          const eventKey = `${day}-${normalizedStartDateTime}-${normalizedEndDateTime}`;

          if (!groupedEvents.has(eventKey)) {
            groupedEvents.set(eventKey, {
              start: normalizedStartDateTime,
              end: normalizedEndDateTime,
              users: [],
            });
          }

          groupedEvents.get(eventKey).users.push({
            userName,
            userColor,
            userAvatar,
          });
        }
      }
    });

    groupedEvents.forEach((value, key) => {
      events.push({
        type: 'working_hours',
        title: `${value.users.length} Users`,
        start: value.start,
        end: value.end,
        backgroundColor: value.users[0].userColor,
        textColor: '#000000',
        display: 'block',
        extendedProps: {
          users: value.users,
        },
      });
    });

    return events;
  };

  const generateNormalEvents = (normalEventsData, loggedUserTimezone) => {
    return normalEventsData.map((event) => {
      let startDateTime = ConvertToMyTimeZone(
        'GMT',
        '+00:00',
        moment(event.date_start).toISOString(),
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );

      let endDateTime = ConvertToMyTimeZone(
        'GMT',
        '+00:00',
        moment(event.date_end).toISOString(),
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );

      if (event.all_day_event) {
        endDateTime = moment(endDateTime).add(1, 'day').toISOString();
      }

      return {
        type: 'normal',
        id: event.id,
        title: event.title,
        start: normalizeDateTime(startDateTime),
        end: normalizeDateTime(endDateTime),
        allDay: event.all_day_event,
        backgroundColor: event.color_code,
        textColor: '#fff',
        extendedProps: {
          description: event.description,
          collaborators: event.collaborators,
        },
      };
    });
  };

  const filteredEvents = events.filter((event) => {
    if (currentView === 'dayGridMonth' && event.type === 'working_hours') return false; // Always hide working hours in month view
    if (!showHolidayEvents && event.type === 'holiday') return false;
    if (!showWorkingHours && event.type === 'working_hours') return false;
    if (!showNormalEvents && event.type === 'normal') return false;
    return true;
  });


  const renderEventContent = (eventInfo) => {
    const { users, collaborators, affectedUsers, local_name, name, country } = eventInfo.event.extendedProps;

    if (users) {
      const distinctUsers = [...new Map(users.map(user => [user.userName, user])).values()];
      return (
        <Tooltip
          title={
            <div>
              {distinctUsers.map((user, index) => (
                <div key={index} className="avatar-box-calendar">
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + user.userAvatar} className="avatar-calendar" />
                  <Typography variant="subtitle1" color="inherit">
                    {user.userName}
                  </Typography>
                </div>
              ))}
            </div>
          }
          arrow
        >
          <Badge
            badgeContent={distinctUsers.length}
            color="primary"
            overlap="circular"
          >
            <Avatar src={process.env.REACT_APP_BACKEND_URL + distinctUsers[0].userAvatar} className="avatar-badge-calendar" />
          </Badge>
        </Tooltip>
      );
    }

    if (collaborators) {
      return (
        <Tooltip
          title={
            <div>
              {collaborators.map((collaborator, index) => (
                <div key={index} className="avatar-box-calendar">
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + collaborator.image} className="avatar-calendar" />
                  <Typography variant="subtitle1" color="inherit">
                    {collaborator.full_name}
                  </Typography>
                </div>
              ))}
            </div>
          }
          arrow
        >
          <div className="flex-content-calendar">
            <Typography variant="subtitle2" className="white-txt-color">
              {eventInfo.event.title}
            </Typography>
          </div>
        </Tooltip>
      );
    }
    if (affectedUsers && affectedUsers.length > 0) {
      const distinctAffectedUsers = [...new Map(affectedUsers.map(user => [user.full_name, user])).values()];
      return (
        <Tooltip
          title={
            <Box className="affected-users-box-calendar">
              <Box className="affected-users-box-items-flex-calendar">
                {eventInfo.event.extendedProps.country ? (
                  <img
                    src={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png`}
                    srcSet={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png 2x`}
                    width="50"
                    height="35"
                    alt="Flag"
                    className="flag-calendar"
                    onError={(e) => { e.target.src = ''; }}
                  />
                ) : (
                  <TourIcon className="no-country-icon-calendar" />
                )}
                <Typography variant="h6" className="holiday-title-calendar">
                  {t("calendar.holiday")}
                </Typography>
              </Box>
              <Typography variant="h6" className="holiday-local-name-calendar">
                {local_name}
              </Typography>
              <Typography variant="subtitle1" className="holiday-name-calendar">
                ({name})
              </Typography>
              <Typography variant="subtitle1" className="holiday-affected-workers-title-calendar">
                {t("calendar.holiday_workers_affected")}
              </Typography>
              {distinctAffectedUsers.map((user, index) => (
                <Box key={index} className="holiday-affected-workers-avatars-box-calendar">
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + user.user_image} className="holiday-affected-workers-avatars-calendar" />
                  <Typography variant="body2" className="holiday-affect-workers-name-calendar">
                    {user.full_name}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
          arrow
          PopperProps={{
            className: "holiday-popper-props-calendar"
          }}
        >
          <div className="extended-info-calendar">
            <Typography variant="subtitle2" className="holiday-extended-local-name-calendar">
              {eventInfo.event.extendedProps.local_name}
            </Typography>
            {eventInfo.event.extendedProps.country ? (
              <img
                src={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png`}
                srcSet={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png 2x`}
                width="25"
                alt="Flag"
                className="extended-flag-calendar"
                onError={(e) => { e.target.src = ''; }}
              />
            ) : (
              <TourIcon className="holiday-extended-props-icon-calendar" />
            )}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={
            <Box className="affected-users-box-calendar">
              <Box className="affected-users-box-items-flex-calendar">
                {country ? (
                  <img
                    src={`https://flagcdn.com/w80/${getCountryFlag(country)}.png`}
                    srcSet={`https://flagcdn.com/w80/${getCountryFlag(country)}.png 2x`}
                    width="50"
                    height="35"
                    alt="Flag"
                    className="flag-calendar"
                  />
                ) : (
                  <TourIcon className="no-country-icon-calendar" />
                )}
                <Typography variant="h6" className="holiday-title-calendar">
                  {t("calendar.holiday")}
                </Typography>
              </Box>
              <Typography variant="h6" className="holiday-local-name-calendar">
                {local_name}
              </Typography>
              <Typography variant="subtitle1" className="holiday-name-calendar">
                {name}
              </Typography>
            </Box>
          }
          arrow
          PopperProps={{
            className: "holiday-popper-props-calendar"
          }}
        >
          <div className="flex-content-calendar">
            <Typography variant="subtitle2" className="holiday-extended-local-name-calendar">
              {local_name}
            </Typography>
            {country ? (
              <img
                src={`https://flagcdn.com/w80/${getCountryFlag(country)}.png`}
                srcSet={`https://flagcdn.com/w80/${getCountryFlag(country)}.png 2x`}
                width="25"
                alt="Flag"
                className="extended-flag-calendar"
              />
            ) : (
              <TourIcon className="holiday-extended-props-icon-calendar" />
            )}
          </div>
        </Tooltip>
      );
    }

  };

  return (
    <div className="main-content-calendar">
      <Typography
        variant="h4"
        className="title-calendar"
      >
        {t("calendar.workspace_project")}
      </Typography>
      <Box className="checkboxes-calendar">
        <FormControlLabel
          control={<Checkbox checked={showHolidayEvents} onChange={() => setShowHolidayEvents(prev => !prev)} />}
          label={t("calendar.show_holiday_events")}
        />
        {currentView !== 'dayGridMonth' && (
          <FormControlLabel
            control={<Checkbox checked={showWorkingHours} onChange={() => setShowWorkingHours(prev => !prev)} />}
            label={t("calendar.show_work_hours")}
          />
        )}
        <FormControlLabel
          control={<Checkbox checked={showNormalEvents} onChange={() => setShowNormalEvents(prev => !prev)} />}
          label={t("calendar.show_scheduled_events")}
        />
      </Box>

      <div className="calendar">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
          initialView="dayGridMonth"
          events={filteredEvents}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          weekends={true}
          editable={false}
          selectable={false}
          eventDisplay="block"
          height="auto"

          eventContent={renderEventContent}
          eventClick={handleEventClick}
          datesSet={(viewInfo) => {
            setCurrentView(viewInfo.view.type);
          }}
          locale={t("global.lng")}
          buttonText={{
            today: t("calendar.today"),
            day: t("calendar.day"),
            week: t("calendar.week"),
            month: t("calendar.month")

          }}
          dayCellClassNames={(date) => {
            const today = moment().startOf('day');
            const cellDate = moment(date.date).startOf('day');
            return cellDate.isBefore(today) ? 'fc-day-past-calendar' : '';
          }}
        />
        <EventDetailsModal
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          selectedEvent={selectedEvent}
          myUserPerms={myUserPerms}
          onSave={handleEventSave}
          workspaceId={workspaceId}
        />
      </div>
    </div>

  );
};

export default CalendarComponent;
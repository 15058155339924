import '../css/views/Login.css';
import '../css/views/Maintenance.css';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.jsx';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useService } from 'contexts/ServiceContextProvider';
import red_bar from 'assets/img/loading/red_bar.png';
import yellow_bar from 'assets/img/loading/yellow_bar.png';
import green_bar from 'assets/img/loading/green_bar.png';
import blue_bar from 'assets/img/loading/blue_bar.png';
/* eslint-disable react-hooks/exhaustive-deps */



const animationDelay = (index) => ({
    animationDelay: `${index * 0.3}s`, // Staggered delay for each bar
});

// Define the keyframes for the pop animation
const keyframes = `
    @keyframes pop {
      0% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0.5;
      }
    }
  `;

const Maintenance = () => {

  const { t } = useTranslation();
  
  useEffect(() => {
    document.title = t('titles.maintenance');
  }, []);

  const navigate = useNavigate();

  const {
    GetServerStatus
  } = useService();


  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const response = await GetServerStatus();
        
        if (!response.is_maintenance) {
          const previousPage = localStorage.getItem('previousPage');
          if (previousPage) {
            localStorage.removeItem('previousPage'); 
            navigate(previousPage);
          } else {
            navigate('/workflow/index');
          }
        }
      } catch (error) {
        //console.error("Error checking maintenance status", error);
      }
    };

    checkMaintenanceStatus();
  }, [navigate]);

  return (
    <>
        <div className="background">
            <CustomCookieConsent />
            <div className="login-container">
                <div className="maintenance-header">
                    <div className="maintenance-text">
                        <h1>
                            Under maintenance...
                        </h1>
                        <h2>
                            We will be back shortly.
                        </h2>
                            (Refreshing this page will take you back to where you left off.)
                        
                    </div>

                    <div>
                        <div className="maintenance-container">
                            <style>{keyframes}</style> {/* Inject keyframes into the component */}
                            <div className="maintenance-bar" style={{backgroundImage: `url(${red_bar})`, ...animationDelay(0) }}></div>
                            <div className="maintenance-bar" style={{backgroundImage: `url(${yellow_bar})`, ...animationDelay(1) }}></div>
                            <div className="maintenance-bar" style={{backgroundImage: `url(${green_bar})`, ...animationDelay(2) }}></div>
                            <div className="maintenance-bar" style={{backgroundImage: `url(${blue_bar})`, ...animationDelay(3) }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Maintenance;

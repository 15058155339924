export const checkForWorkspaceStorageLimitError = (error, t, showSnackbar) => {
    const error_code = error.response?.data?.errors?.[0]?.code;
    const direct_error = error.response?.data.error;

    if (error_code === "storage_limit_exceeded" || direct_error === "storage_limit_exceeded") {
        showSnackbar(t("project_details.file_uploaded_limit"), 'error');
    } else {
        showSnackbar(t("project_details.file_uploaded_err"), 'error');
    }
};

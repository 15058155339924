import {
  CardBody,
  FormGroup,
  Form,
} from "reactstrap";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.jsx';
import workflowProIcon from '../assets/img/login/WorkFlow Pro icon.png';
import '../css/views/Login.css';
import '../css/views/ChangePassword.css';
import { Button, TextField,InputAdornment, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import { Visibility, VisibilityOff } from '@mui/icons-material';


const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { accessToken } = useAuthorization();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState();
  const {request_id} = useParams();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

  function isValidPassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@!&])[A-Za-z\d@!&]{8,}$/;
    return passwordRegex.test(password);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    verifyValidity();
  },[]);

  const verifyValidity = async () => {
    try {
      await axios.post(BACKEND_URL + '/users/verify-change-password/', {
        request_id: request_id
      }
      );
      setValid(true);
    } catch (error) {
      console.error('Change password failed:', error);
      setValid(false);
    } 
  };

  const handleChangePassword = async () => {

      if (password !== '' && confirmPassword !== '') {
        if (password !== confirmPassword) {
          showSnackbar(t("login.password_mismatch"), 'error');
          return;
        }
        if (!isValidPassword(password)) {
          showSnackbar(t("login.bad_password_regex"), 'error');
          return;
        }
      
        try {
          await axios.post(BACKEND_URL + '/users/verified-change-password/', {
            new_password: password,
            request_id: request_id
          },
          );
          window.location.href = '/auth/login'
        } catch (error) {
          console.error('Change password failed:', error);
          showSnackbar(t("login.change_password_hint"), 'error');
        } 
      } else {
        console.error('Register failed. Please fill in all fields');
        showSnackbar(t("login.please_fill"), 'error');
        return;
    }
  };

  const navigateToPage = (pagePath) => {
    window.location.href = pagePath;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (accessToken) {
      navigate('/workflow/index');
    }
  }, [accessToken, navigate]);

  const handleClick = () => {
    window.location.href = '/';
  };

  const handleClickShowPassword = () => {
    const currentCursorPos = inputRef.current.selectionStart; // Save the current cursor position
    setShowPassword(!showPassword); // Toggle password visibility
    setTimeout(() => {
      inputRef.current.setSelectionRange(currentCursorPos, currentCursorPos); // Restore cursor position
    }, 0); // Ensure this happens after the re-render
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // Prevent default behavior of the password input
  };

  const handleClickShowConfirmPassword = () => {
    const currentCursorPos = inputRef2.current.selectionStart; // Save the current cursor position
    setShowConfirmPassword(!showConfirmPassword); // Toggle password visibility
    setTimeout(() => {
      inputRef2.current.setSelectionRange(currentCursorPos, currentCursorPos); // Restore cursor position
    }, 0); // Ensure this happens after the re-render
  };
  



  return (
    <>
      <div className="change-password-background">
        <CustomCookieConsent />
        <div className="change-password-container">
          <div className="change-password-card">

            {valid === true && (
              <CardBody>
              <div className="change-password-header">
                <div
                    onClick={handleClick}
                    className="change-password-back"
                    aria-label="back"
                >
                  <IconButton onClick={handleClick} aria-label="back" className="change-password-back-icon">
                    <ArrowBackIcon />
                  </IconButton>
                  <span className="change-password-back-icon-margin">{t("login.forgot_password2")}</span>
                </div>
              </div>
              <div className="change-password-form-container">
                <div className="change-password-logo">
                  <img src={workflowProIcon} alt="Workflow Pro" />
                </div>
                <Form role="form" className="change-password-form">
                  <FormGroup>
                    <TextField
                      label={t("login.password_placeholder")}
                      name="password"
                      type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={inputRef}
                    />
                  </FormGroup>

                  <FormGroup>
                    <TextField
                      label={t("login.confirm_password_placeholder")}
                      name="password"
                      type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={inputRef2}
                    />
                  </FormGroup>

                  
                  <div className="change-password-text-center">
                    <Button
                      onClick={() => handleChangePassword()}
                      className="change-password-custom-button"
                    >
                      {t("login.change_password")}
                    </Button>
                  </div>
                  <div className="change-password-text-center change-password-footer-links">
                    <small className="change-password-footer-link">
                      <a href="/auth/login">{t("login.remember_your_password")}</a>
                    </small>
                  </div>
                </Form>
              </div>
              </CardBody>
            )}
            {valid === false && (
              <CardBody>
                <div className="change-password-header">
                  <div
                      onClick={handleClick}
                      className="change-password-back"
                      aria-label="back"
                  >
                    <IconButton onClick={handleClick} aria-label="back" className="change-password-back-icon">
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                  <span className="change-password-back-icon-margin">{t("login.verified_password_change")}</span>
                </div>
                <div className="change-password-form-container">
                  <div className="change-password-logo">
                    <img src={workflowProIcon} alt="Workflow Pro" />
                  </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '1%' }}>
                      <span style={{textAlign:"center", fontFamily:"Inter, sans-serif", fontWeight:"normal"}}>
                        {t("login.invalid_change_password")}
                      </span>
                    </div>
                    <div className="change-password-text-center">
                      <Button
                        onClick={() => navigateToPage('/auth/forgot-password')}
                        className="change-password-custom-button"
                      >
                        {t("login.return_to_change_password")}
                      </Button>
                    </div>
                </div>
              </CardBody>
            )}
          </div>
        </div>
        
      </div>
    </>
  );
};

export default ChangePassword;

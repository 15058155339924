import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import workflowProIcon from '../assets/img/login/WorkFlow Pro icon.png';
import '../css/views/ForgotPassword.css';
import {
  CardBody,
  FormGroup,
  Form,
} from 'reactstrap';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.jsx';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import axios from 'axios';
import { useSnackbar } from 'contexts/SnackbarContextProvider';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { accessToken } = useAuthorization();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleRequestPasswordChange = async () => {

    if (!isValidEmail(email)) {
      showSnackbar(t("login.bad_email"), 'error');
      return;
    }

    if(email === "" || email === null){
      showSnackbar(t("login.fill_in"), 'error');
    } else {

      try {
        await axios.post(BACKEND_URL + '/users/request-change-password/', {
          email: email,
        });
        showSnackbar(t("login.success_request_change_if"), 'success');
      } catch (error) {
        showSnackbar(t("login.request_change_comm_error"), 'error');
        console.error('Request password change failed:', error.message);
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      navigate('/workflow/index');
    }
  }, [accessToken, navigate]);


  const handleClick = () => {
    window.location.href = '/';
  };


  return (
    <>
      <div className="forgot-password-background">
        <CustomCookieConsent />
        <div className="forgot-password-container">
          <div className="forgot-password-card">
            <CardBody>
              <div className="forgot-password-header">
                <div
                    onClick={handleClick}
                    className="forgot-password-back"
                    aria-label="back"
                >
                  <IconButton onClick={handleClick} aria-label="back"
                    className="forgot-password-back-icon"
                    >
                    <ArrowBackIcon />
                  </IconButton>
                  <span className="forgot-password-back-icon-margin">{t("login.forgot_password2")}</span>
                </div>
              </div>
              <div className="form-container">
                <div className="forgot-password-logo">
                  <img src={workflowProIcon} alt="Workflow Pro" />
                </div>
                <Form role="form" className="forgot-password-form">
                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.email")}
                      name="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={email}
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                      }}
                    />
                  </FormGroup>
                  
                  <div className="forgot-password-text-center">
                    <Button
                      onClick={() => handleRequestPasswordChange()}
                      className="forgot-password-custom-button"
                    >
                      {t("login.request_password_change")}
                    </Button>
                  </div>
                  <div className="forgot-password-text-center forgot-password-footer-links">
                    <small className="footer-link">
                      {t("login.dont_have_account")} &nbsp;
                      <a href="/auth/register">{t("login.register!")}</a>
                    </small>
                    <small className="footer-link">
                      <a href="/auth/login">{t("login.remember_your_password")}</a>
                    </small>
                  </div>
                </Form>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import { Popover, Box, Avatar, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserActivityLogModal from './UserActivityLogModal';
import {useTranslation} from "react-i18next"; // Ensure this import is correct
import "../../css/components/Core/UserNavbarPopover.css";
import "../../css/main.css";

const UserNavbarPopover = ({ anchorEl, handleClose, user, myLoggedUser }) => {
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'user-navbar-popover' : undefined;
  const navigate = useNavigate();
  const [isActivityLogOpen, setIsActivityLogOpen] = useState(false);

  const handleViewProfile = (event) => {
    // Check for middle mouse button (button 1 is the middle mouse button)
    if (event.button === 1) {
      event.preventDefault();  // Prevent the default middle-click behavior
      window.open(`/workflow/profile/${user.id}`, '_blank'); // Open in a new tab
    } else {
      // Normal left click
      navigate(`/workflow/profile/${user.id}`);
    }
    handleClose();  // Close the popover
  };

  const handleModalClose = () => {
    setIsActivityLogOpen(false);
  };

  return (
      <>
          <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
              }}
              className="user-navbar-popover"
          >
              <Box className="user-navbar-container">
                  <Box className="user-navbar-user-details">
                      <Avatar
                          alt={user.full_name}
                          src={process.env.REACT_APP_BACKEND_URL + user.image}
                          className="user-navbar-avatar"
                      />
                      <Box>
                          <Typography variant="h6" component="h2" className="white-txt-color">
                              {user.full_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" className="user-navbar-username">
                              @{user.username}
                          </Typography>
                      </Box>
                  </Box>
                  <Button
                      variant="contained"
                      className="user-navbar-button"
                      onClick={handleViewProfile}
                  >
                      {t("core.view_profile")}
                  </Button>
                  {(myLoggedUser.is_admin || myLoggedUser.is_owner) && (
                      <Button
                          variant="contained"
                          className="user-navbar-button-full"
                          onClick={(event) => handleViewProfile(event)} // Pass the event
                          onAuxClick={(event) => handleViewProfile(event)} // Handle middle-click as well
                      >
                          {t("core.view_activity")}
                      </Button>
                  )}
              </Box>
          </Popover>

          {/* The modal that shows the user's activity log */}
          <UserActivityLogModal
              open={isActivityLogOpen}
              handleClose={handleModalClose}
              userId={user.id}
          />
      </>
  );
};

export default UserNavbarPopover;

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {useTranslation} from "react-i18next";
import "../../css/main.css";
import "../../css/components/Modals/DeleteWarning.css";

export default function DeleteWorkspaceModal({ openDeleteWorkspaceModal, handleDeleteWorkspaceModalClose, handleArchiveWorkspace}) {
    const { t } = useTranslation();

    return (
        <Dialog open={openDeleteWorkspaceModal} onClose={handleDeleteWorkspaceModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {t("modal.workspace_delete")}
            </DialogContent>
            <DialogContent className="delete-resource-dialog-content">
            <WarningIcon className="delete-resource-warning-margin-right" color="warning"/> {t("modal.irreversible")} <WarningIcon className="delete-resource-warning-margin-left"  color="warning"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteWorkspaceModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleArchiveWorkspace} className="button-dark-bg">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
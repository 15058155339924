import axios from 'axios';

// Function to get the current server status (for maintenance)
const GetServerStatus = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}settings/maintenance/`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting maintenance status!", error);
    throw error;
  }
};

const setupAxiosInterceptors = (navigate) => {
  const storeCurrentLocation = () => {
    const currentUrl = window.location.pathname + window.location.search;

    if (currentUrl !== "/maintenance" && currentUrl !== "/serverdown"){
      localStorage.setItem('previousPage', currentUrl);
    }
  };

  const redirectToPreviousPage = () => {
    const previousPage = localStorage.getItem('previousPage');
    if (previousPage) {
      navigate(previousPage, { replace: true });
    } else {
      navigate('/workflow/index'); 
    }
  };

  const checkMaintenanceMode = async () => {
    try {
      const response = await GetServerStatus();
      if (response.is_maintenance) {
        storeCurrentLocation();  
        navigate('/maintenance');
      } else {
        if (window.location.pathname === '/maintenance') {
          redirectToPreviousPage();
        }
      }
    } catch (error) {
      console.error("Error checking maintenance status:", error);
    }
  };

  // Call the maintenance mode check when the app starts
  checkMaintenanceMode();

  // Intercept all responses to handle network errors
  axios.interceptors.response.use(
    (response) => {
      return response; // Return the response if successful
    },
    (error) => {
      if (error.message === 'Network Error') {
        storeCurrentLocation();  // Store the current page before redirecting
        navigate('/serverdown');
      }
      return Promise.reject(error); // Suppress the error from appearing in the front-end overlay
    }
  );
};

export default setupAxiosInterceptors;

import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button
} from '@mui/material';
import {useTranslation} from "react-i18next";
import '../../css/components/Profile/ResetPassword.css';
import '../../css/main.css';

const ResetPasswordModal = ({ open, onClose, onSave, loading }) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const handleSavePassword = () => {
        onSave(password, confirmPassword);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="reset-password-modal"
            aria-describedby="reset-password-modal-description"
        >
            <Box className="reset-password-modal-box">
                <Typography id="reset-password-modal" variant="h6" component="h2" className="white-txt-color">
                    {t("profile.reset_password")}
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type="password"
                    label={t("profile.password_new")}
                    value={password}
                    onChange={handlePasswordChange}
                    className="reset-password-input"
                    InputLabelProps={{ className: "white-txt-color" }}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    name="confirmPassword"
                    type="password"
                    label={t("profile.password_confirm")}
                    value={confirmPassword}
                    onChange={handlePasswordChange}
                    className="reset-password-input"
                    InputLabelProps={{ className: "white-txt-color" }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSavePassword}
                    className="button-dark-bg reset-password-button"
                    fullWidth
                    disabled={loading}
                >
                    {t("profile.password_save")}
                </Button>
            </Box>
        </Modal>
    );
};

export default ResetPasswordModal;

import React, { forwardRef } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import BadgeWrapper from 'components/Custom/StyledBadge';
import { useTranslation } from 'react-i18next';
import "../../css/components/Modals/UserOnlineChecker.css";
import "../../css/main.css";

const UserOnlineStatus = forwardRef(({ userStatusAvailability, statusPriority, message, children, showBadgeOnly }, ref) => {
  const { t } = useTranslation();

  let status;
  let tooltipTitle = userStatusAvailability || t("working_hours.unavailable");

  // Switch to define user status and badge color based on priority
  switch (statusPriority) {
    case 1:
      status = 'green';  // Online during working hours
      tooltipTitle = t("working_hours.status_online");
      break;
    case 2:
      status = 'purple';  // Online overtime
      tooltipTitle = t("working_hours.status_overtime");
      break;
    case 3:
      status = 'orange';  // Offline during working hours
      tooltipTitle = t("working_hours.status_offline_working_hours");
      break;
    case 4:
      status = 'red';  // Offline general
      tooltipTitle = t("working_hours.status_offline");
      break;
    case 5:
      status = 'blue';  // Unavailable / vacation
      tooltipTitle = t("working_hours.on_vacation");
      break;  
    case 6: // No working hours set up, offline/unavailable
      status = 'white';  // White badge for no hours
      tooltipTitle = t("working_hours.no_hours_setup");
      break;
    default:
      status = 'red';  // Unavailable / vacation
      tooltipTitle = userStatusAvailability;
      break;
  }

  return (
    <>
      {showBadgeOnly ? (
        <BadgeWrapper status={status}>
          <Tooltip title={tooltipTitle} arrow>
            <Box ref={ref} className="online-checker-box">
              {children}
            </Box>
          </Tooltip>
        </BadgeWrapper>
      ) : (
        <Box ref={ref} className="online-checker-text-content">
          <Typography variant="subtitle1" className="white-txt-color online-checker-text-margin">
            {message}
          </Typography>
          <BadgeWrapper status={status}>
            {children}
          </BadgeWrapper>
        </Box>
      )}
    </>
  );
});

export default UserOnlineStatus;

import React from 'react';
import red_bar from 'assets/img/loading/red_bar.png';
import yellow_bar from 'assets/img/loading/yellow_bar.png';
import green_bar from 'assets/img/loading/green_bar.png';
import blue_bar from 'assets/img/loading/blue_bar.png';
import "../../css/components/Custom/CustomFallBack.css";

const CustomFallback = () => {
    const animationDelay = (index) => ({
        animationDelay: `${index * 0.3}s`, // Staggered delay for each bar
    });

    return (
        <div className="fallback-container">
            <div className="fallback-bar" style={{ backgroundImage: `url(${red_bar})`, ...animationDelay(0) }}/>
            <div className="fallback-bar" style={{ backgroundImage: `url(${yellow_bar})`, ...animationDelay(1) }}/>
            <div className="fallback-bar" style={{ backgroundImage: `url(${green_bar})`, ...animationDelay(2) }}/>
            <div className="fallback-bar" style={{ backgroundImage: `url(${blue_bar})`, ...animationDelay(3) }}/>
        </div>
    );
};

export default CustomFallback;

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import "../../css/components/Modals/CheckoutModal.css";
import "../../css/main.css";
import {useTranslation} from "react-i18next";

const CheckoutModal = ({ open, onClose, paypalFormHtml, onConfirm }) => {

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="checkout-modal"
        >
            <Box className="checkout-box">
                <Typography variant="h5" gutterBottom>{t("modal.review_selection")}</Typography>

                {paypalFormHtml && (
                    <div dangerouslySetInnerHTML={{ __html: paypalFormHtml }} />
                )}

                <button onClick={onConfirm}>{t("modal.purchase_plan")}</button>


                <Box className="buttons-flex">
                    <Button variant="contained" className="button-light-bg" onClick={onConfirm}>{t("custom.confirm_proceed")}</Button>
                    <Button variant="contained" className="button-light-bg" onClick={onClose}>{t("global.cancel")}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CheckoutModal;

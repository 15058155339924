import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import workflowProIcon from '../assets/img/login/WorkFlow Pro icon.png';
import '../css/views/Login.css';
import {
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
} from 'reactstrap';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.jsx';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Login = () => {
  useEffect(() => {
    document.title = t('titles.login');
  }, []);
  const { t } = useTranslation();
  const { login, accessToken } = useAuthorization();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    if (accessToken) {
      navigate('/workflow/index');
    }
  }, [accessToken, navigate]);


  const handleLogin = async () => {
    try {
      await login(username, password);
    } catch (error) {
      console.error('Login failed:', error.message);
      setError(error.message);
    }
  };

  const handleClickShowPassword = () => {
    const currentCursorPos = inputRef.current.selectionStart; // Save the current cursor position
    setShowPassword(!showPassword); // Toggle password visibility
    setTimeout(() => {
      inputRef.current.setSelectionRange(currentCursorPos, currentCursorPos); // Restore cursor position
    }, 0); // Ensure this happens after the re-render
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // Prevent default behavior of the password input
  };


  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <>
      <div className="background">
        <CustomCookieConsent />
        <div className="login-container">
          <div className="login-card">
            <CardBody>
              <div className="login-header">
                <div
                    onClick={handleClick}
                    className="login-back"
                    aria-label="back"
                >
                  <IconButton onClick={handleClick} aria-label="back"
                   className="login-back-icon">
                    <ArrowBackIcon />
                  </IconButton>
                  <span className="login-back-icon-margin">{t("login.login")}</span>
                </div>
              </div>
              <div className="form-container">
                <div className="login-logo">
                  <img src={workflowProIcon} alt="Workflow Pro" />
                </div>
                <Form role="form" className="login-form">
                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.email_or_user")}
                      name="username"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={username}
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      onChange={(e) => setUsername(e.target.value)}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label={t("login.password_placeholder")}
                      name="password"
                      type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={inputRef}
                    />
                  </FormGroup>
                  <Row className="login-error">
                    <Col className="text-center">
                      <small className="error-message">{t(error)}</small>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      onClick={handleLogin}
                      className="custom-button"
                    >
                      {t("login.sign_in")}
                    </Button>
                  </div>
                  <div className="text-center footer-links">
                    <small className="footer-link">
                      {t("login.dont_have_account")} &nbsp;
                      <a href="/auth/register">{t("login.register!")}</a>
                    </small>
                    <small className="footer-link">
                      <a href="/auth/forgot-password">{t("login.forgot_password")}</a>
                    </small>
                  </div>
                </Form>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

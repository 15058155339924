import React, { useState } from 'react';
import {AvatarGroup, Avatar, Box} from '@mui/material';
import UserNavbarPopover from 'components/Core/UserNavbarPopover';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';
import { getUserStatusPriority } from '../Custom/userStatusHelper'; 
import "../../css/components/Core/CommonAvatarGroup.css";
import { useTranslation } from "react-i18next";

const CommonAvatarGroup = ({ users, handleAvatarGroupClick, myLoggedUser }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAvatarClick = (event, user) => {
    if (user) {
      setAnchorEl(event.currentTarget);
      setSelectedUser(user);
    }
  };

  const handleMoreClick = (event) => {
    event.stopPropagation();
    handleAvatarGroupClick(event);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  // Calculate and store statusPriority and message for each user
  const usersWithStatus = users.map((user) => {
    const now = new Date();
    const { status, message } = getUserStatusPriority(user, now, t); // Get both status and message
    return { ...user, statusPriority: status, message }; // Store them with the user object
  });

  // Sort users by their status priority before mapping
  const sortedUsers = usersWithStatus.sort((a, b) => a.statusPriority - b.statusPriority); // Sort by priority

  return (
    <Box className="common-avatar-hide">
      <AvatarGroup max={5} className="common-avatar-group">
        {sortedUsers.slice(0, 4).map((user) => (
          <div key={user.id} className="common-avatar-users">
            <UserOnlineStatus
              userStatusAvailability={user.current_availability}
              statusPriority={user.statusPriority}
              message={user.message}
              showBadgeOnly={true}
            >
              <Avatar
                alt={user.full_name}
                src={process.env.REACT_APP_BACKEND_URL + user.image}
                onClick={(event) => handleAvatarClick(event, user)}
              />
            </UserOnlineStatus>
          </div>
        ))}
        {sortedUsers.length > 4 && (
          <div className="common-avatar-users">
            <Avatar onClick={handleMoreClick} className="common-avatar-group">
              +{users.length - 4}
            </Avatar>
          </div>
        )}
      </AvatarGroup>
      {selectedUser && (
        <UserNavbarPopover
          anchorEl={anchorEl}
          handleClose={handleClosePopover}
          user={selectedUser}
          myLoggedUser={myLoggedUser}
        />
      )}
    </Box>
  );
};

export default CommonAvatarGroup;

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css";

export default function DeleteChecklistModal({ open, handleDeleteChecklistModalClose, handleCloseAndDelete, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={handleDeleteChecklistModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {t("modal.checklist_delete")}<br/>
                {t("modal.checklist_no_return")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteChecklistModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleCloseAndDelete} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
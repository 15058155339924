import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/components/Calendar/RefutalNotificationModal.css";
import "../../css/main.css";

const RefutalNotificationModal = ({ open, handleAcknowledge }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={() => {}} className="refutal-notification-margin">
            <Box className="refutal-notification-modal">
                <Typography variant="h6" component="h2" className="refutal-notification-title">
                    {t("calendar.vacation_notif")}
                </Typography>
                <Typography variant="body1" className="refutal-notification-text">
                    {t("calendar.vacation_refuted")}
                </Typography>
                <Box className="refutal-notification-button-box">
                    <Button variant="contained" className="button-dark-bg" onClick={handleAcknowledge}>
                        {t("calendar.vacation_acknowledge")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RefutalNotificationModal;

import React, { createContext, useContext, useState, useEffect } from 'react';

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const savedStatus = localStorage.getItem('SidebarCurrentStatus');
    return savedStatus !== null ? JSON.parse(savedStatus) : true; 
  });

  const openSidebar = () => {
    setIsOpen(true);
    localStorage.setItem('SidebarCurrentStatus', true); 
  };


  const closeSidebar = () => {
    setIsOpen(false);
    localStorage.setItem('SidebarCurrentStatus', false); 
  };

  const isSidebarOpen = () => {
    return isOpen;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        closeSidebar();
      } else {
        openSidebar();
      }
    };


    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('SidebarCurrentStatus', isOpen);
  }, [isOpen]);

  return (
    <SidebarContext.Provider value={{ isOpen, openSidebar, closeSidebar, isSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

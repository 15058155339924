import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import routes from "routes.jsx";
import "../css/layouts/Guest.css";

const Guest = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div ref={mainContent} className="guest-div-style">
        <div style={{width:'100%'}}>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
      </div>
    </>
  );
};

export default Guest;

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {useTranslation, Trans} from "react-i18next";
import "../../css/main.css";
import "../../css/components/Modals/DeleteWarning.css";

export default function RemoveYourselfFromWorkspaceModal({ selfRemoveFromWorkspaceModalOpen, handleSelfRemoveFromWorkspaceModalClose, handleSelfRemoveFromWorkspace}) {
    const { t } = useTranslation();

    return (
        <Dialog open={selfRemoveFromWorkspaceModalOpen} onClose={handleSelfRemoveFromWorkspaceModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
            <Trans i18nKey="modal.workspace_remove_yourself"/>
            </DialogContent>
            <DialogContent className="delete-resource-dialog-content">
            <WarningIcon className="delete-resource-warning-margin-right" color="warning"/> {t("modal.irreversible")} <WarningIcon className="delete-resource-warning-margin-left"  color="warning"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSelfRemoveFromWorkspaceModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleSelfRemoveFromWorkspace} className="button-dark-bg">
                    {t("modal.leave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
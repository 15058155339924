import React, { useState } from 'react';
import { Box, Typography, Modal, Paper, Tab, Tabs, Button, TextField } from '@mui/material';
import Grid from "@mui/material/Grid";
import "../../css/components/Modals/SubscriptionModal.css";

const SubscriptionModal = ({ open, onClose, subscription, plan, transactions, handleCancelSubscription, handleSuspendSubscription }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCancel = () => {
        handleCancelSubscription(subscription.id);
        onClose();
    };

    const handleSuspend = () => {
        handleSuspendSubscription(subscription.id);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper elevation={3} className="sub-modal-paper">
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Transactions" />
                    {subscription.status === 'ACTIVE' && (
                        <Tab label="Edit Subscription" />
                    )}
                </Tabs>

                {/* Transactions Tab */}
                {tabValue === 0 && (
                    <Box>
                        <Typography variant="h6">Transactions</Typography>
                        {transactions.length > 0 ? (
                            transactions.map((transaction) => (
                                <Paper key={transaction.id} elevation={2} className="sub-modal-paper-info">
                                    <Typography variant="body2">Date: {new Date(transaction.date).toLocaleDateString()}</Typography>
                                    <Typography variant="body2">Amount: {transaction.amount}€</Typography>
                                    <Typography variant="body2">Status: {transaction.status}</Typography>
                                </Paper>
                            ))
                        ) : (
                            <Typography variant="body2">No transactions available.</Typography>
                        )}
                    </Box>
                )}

                {/* Edit Subscription Tab */}
                {subscription.status === 'ACTIVE' && tabValue === 1 && (
                    <Box mt={2}>
                        <Typography variant="h6">Edit Subscription</Typography>
                        <TextField
                            fullWidth
                            label="Plan Name"
                            name="planName"
                            value={plan?.main_plan?.name || ''}
                            margin="normal"
                            disabled
                        />
                        <TextField
                            fullWidth
                            label="Status"
                            name="status"
                            value={subscription?.status || ''}
                            margin="normal"
                            disabled
                        />
                        <Grid className="sub-modal-buttons-grid">
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleSuspend}
                                className="sub-modal-buttons-margin"
                            >
                                Suspend Subscription
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCancel}
                                className="sub-modal-buttons-margin"
                            >
                                Cancel Subscription
                            </Button>
                        </Grid>
                    </Box>
                )}
            </Paper>
        </Modal>
    );
};

export default SubscriptionModal;

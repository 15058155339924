import LandingPage from "views/LandingPage.jsx";
import NotificationsPage from "views/NotificationsPage.jsx";

import Login from "views/Login.jsx";
import Register from "views/Register.jsx";
import ForgotPassword from "views/ForgotPassword";
import ChangePassword from "views/ChangePassword";

import NewProjectPage from "views/NewProject";
import EditProjectPage from "views/EditProject";

import NewWorkspacePage from "views/NewWorkspace";
import ProjectPage from "views/ProjectPage";
import ProjectDetails from "views/ProjectDetails";

import WorkspaceMembersManagement from "views/WorkspaceMembersManagement";
import WorkspaceSettings from "views/WorkspaceSettings";

import SettingsPage from "views/SettingsPage.jsx";

import ProfilePage from "views/ProfilePage";

import ProjectArchive from "views/ArchivePage";

import TabbedCalendar from "components/Calendar/TabbedCalendar.jsx";
import React from "react";
import Maintenance from "./views/Maintenance";
import ServerDown from "./views/ServerDown";

import OuterLandingPage from "views/OuterLandingPage.jsx";
import SupportPage from "views/SupportPage.jsx";


var routes = [
  {
    path: "/",
    name: "LandingPage",
    component: <OuterLandingPage />,
    layout: "/"

  },
  {
    path: "/index",
    name: "LandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/success-payment",
    name: "SuccessLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/failed-payment",
    name: "FailedLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/success-cancel",
    name: "SuccessCancelLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/failed-cancel",
    name: "FailedCancelLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/success-suspend",
    name: "SuccessSuspendLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/failed-suspend",
    name: "FailedSuspendLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/success-activate",
    name: "SuccessActivateLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/failed-activate",
    name: "FailedActivateLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/feature-unavailable",
    name: "UnavailableFeatureLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/failed-payment",
    name: "FailedLandingPage",
    component: <LandingPage />,
    layout: "/workflow"

  },
  {
    path: "/:workspaceId/calendar/overview",
    name: "TabbedCalendar",
    component: <TabbedCalendar />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/:workspaceId/calendar/timeline",
    name: "TabbedCalendar",
    component: <TabbedCalendar />,
    layout: "/workflow",
    exact: true
  },{
    path: "/:workspaceId/calendar/vacations",
    name: "TabbedCalendar",
    component: <TabbedCalendar />,
    layout: "/workflow",
    exact: true
  },

  {
    path: "/notifications",
    name: "NotificationsPage",
    component: <NotificationsPage />,
    layout: "/workflow",
    exact: true
  },{
    path: "/support",
    name: "SupportPage",
    component: <SupportPage />,
    layout: "/workflow",
    exact: true
  },

  {
    path: "/settings",
    name: "SettingsPage",
    component: <SettingsPage />,
    layout: "/workflow",
    exact: true
  },

  {
    path: "/projects/:projectId",
    name: "ProjectPage",
    component: <ProjectPage />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/projects/:projectId/details",
    name: "ProjectDetails",
    component: <ProjectDetails />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/projects/:projectId/archive",
    name: "ProjectArchive",
    component: <ProjectArchive />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/projects/new",
    name: "NewProject",
    component: <NewProjectPage />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/projects/edit",
    name: "EditProject",
    component: <EditProjectPage />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/workspaces/new",
    name: "NewWorkspacePage",
    component: <NewWorkspacePage />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/profile/:userId",
    name: "ProfilePage",
    component: <ProfilePage />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/workspace/:workspaceId/members",
    name: "WorkspaceMembersManagement",
    component: <WorkspaceMembersManagement />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/workspace/:workspaceId/settings",
    name: "WorkspaceSettings",
    component: <WorkspaceSettings />,
    layout: "/workflow",
    exact: true
  },
  {
    path: "/login",
    name: "login",
    component: <Login />,
    layout: "/auth",
    hidden: true,
    requires_login: false,
    staff_only: false,
    staff_hide: false
  },
  {
    path: "/register",
    name: "register",
    component: <Register />,
    layout: "/auth",
    hidden: true,
    requires_login: false,
    staff_only: false,
    staff_hide: false
  },{
    path: "/register/:invite_id",
    name: "register",
    component: <Register />,
    layout: "/auth",
    hidden: true,
    requires_login: false,
    staff_only: false,
    staff_hide: false
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: <ForgotPassword />,
    layout: "/auth",
    hidden: true,
    requires_login: false,
    staff_only: false,
    staff_hide: false
  },
  {
    path: "/verified-change-password/:request_id",
    name: "navbar.register",
    component: <ChangePassword />,
    layout: "/auth",
    hidden: true,
    requires_login: true,
    staff_only: false,
    staff_hide: true,
  },
  {
    path: "/maintenance",
    name: "MaintenancePage",
    component: <Maintenance />,
    layout: "/",
    requires_login: false,
    staff_only: false,
    staff_hide: false

  },{
    path: "/serverdown",
    name: "ServerDown",
    component: <ServerDown />,
    layout: "/",
    requires_login: false,
    staff_only: false,
    staff_hide: false

  },
];

export default routes;

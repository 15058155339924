import React, { useState, useRef, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useTranslation, Trans} from 'react-i18next';
import { useAuthorization } from '../contexts/AuthContextProvider';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import emailjs from "@emailjs/browser";

import '../css/views/OuterLandingPage.css';

import navbarLogo from '../assets/img/outerLanding/navbarLogo.png';
import dropdownNavbarLogo from "../assets/img/outerLanding/dropdownNavbarLogo.png";

import headerBackground from '../assets/img/outerLanding/headerBackground.png';
import triangle from '../assets/img/outerLanding/triangle.png';

import benefitsBackground from '../assets/img/outerLanding/benefitsBackground.png';

import planCircles from '../assets/img/outerLanding/planCircles.png';
import planCheckmark from '../assets/img/outerLanding/planCheckmark.png';

import boardsFeature from '../assets/img/outerLanding/boardsFeature.png';
import managementFeature from '../assets/img/outerLanding/managementFeature.png';
import calendarFeature from '../assets/img/outerLanding/calendarFeature.png';
import vacationFeature from '../assets/img/outerLanding/vacationFeature.png';

import aqLogo from '../assets/img/outerLanding/AQlogo.png';
import aqLogoSmall from '../assets/img/outerLanding/AQlogosmall.png';

import aiLogo from '../assets/img/outerLanding/aiLogo.png';
import aiLogoSmall from '../assets/img/outerLanding/aiLogo.png';

import ikLogo from '../assets/img/outerLanding/ikLogo.png';
import ikLogoSmall from '../assets/img/outerLanding/ikLogo.png';

import ingenious from '../assets/img/outerLanding/ingeniousAbout.png';
import decorativePencils from '../assets/img/outerLanding/decorativePencils.png';

import newReadyToImprove from '../assets/img/outerLanding/newReadyToImprove.png';

import footerLogo from '../assets/img/outerLanding/footerLogo.png';

const OuterLandingPage = () => {

  const [dropdownOpen, setDropdownOpen] = useState(window.innerWidth > 768);
  const [mobileMenuVersion, setMobileMenuVersion] = useState(window.innerWidth <= 768);

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >  768) {
        setDropdownOpen(true);
        setMobileMenuVersion(false)
      } else {
        setDropdownOpen(false);
        setMobileMenuVersion(true);
      }
    };

    // Attach resize event listener
    window.addEventListener('resize', handleResize);

    // Check the screen width on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (dropdownOpen && window.innerWidth <  768) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Restore default scrolling
    }

    // Cleanup: Reset the overflow style when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [dropdownOpen]);


  useEffect(() => {
    document.title = t('titles.outer_landing_page');
  }, []);
  const [selectedFeature, setSelectedFeature] = useState("boards");
  const [selectedTestimony, setSelectedTestimony] = useState(0);
  const {t} = useTranslation();
  const {accessToken} = useAuthorization();

  const [emailSent, setEmailSent] = useState(false);
  const form = useRef();

  const testimonies = [
    {
      company: 'Art Quest',
      title: 'title_1',
      text: 'text_1',
      image: aqLogo,
      smallImage: aqLogoSmall
    }];

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9jlfepp",
        "template_rkakvek",
        form.current,
        "kFmtGOqLG_ntm0l8B"
      )
      .then(
        (result) => {
          setEmailSent(true);
        },
        (error) => {
          setEmailSent(false);
        }
      );
    e.target.reset();
  };

  const handleDashboardClick = () => {
    window.location.href = '/workflow/index';
  };

  const handleStartPlanClick = () => {
    window.location.href = '/auth/register';
  };


  return (
    <div className="outer-landing-page-div">

      <div className="outer-landing-page-hero" style={{backgroundImage:`url(${headerBackground})`}}>
        <div className="outer-landing-page-header">
          <nav className="outer-landing-page-nav">
            <img
              src={dropdownOpen && window.innerWidth < 768 ? dropdownNavbarLogo : navbarLogo}
              alt="Logo"
              className="outer-landing-page-logo"
              onClick={() => scroll.scrollToTop()}
            />

            <ul className={`outer-landing-page-nav-list ${dropdownOpen ? 'show' : 'hide'}`}>
              <li>
                <ScrollLink
                  to="features"
                  smooth={true}
                  duration={500}
                  className="outer-landing-page-nav-link"
                  offset={-80}
                  onClick={mobileMenuVersion ? toggleDropdown : null}
                >
                  {t("navbar.features")}
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="benefits"
                  smooth={true}
                  duration={500}
                  className="outer-landing-page-nav-link"
                  offset={-80}
                  onClick={mobileMenuVersion ? toggleDropdown : null}
                >
                  {t("navbar.benefits")}
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="plans"
                  smooth={true}
                  duration={500}
                  className="outer-landing-page-nav-link"
                  offset={-80}
                  onClick={mobileMenuVersion ? toggleDropdown : null}
                >
                  {t("navbar.plans&pricing")}
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="outer-landing-page-nav-link"
                  offset={-80}
                  onClick={mobileMenuVersion ? toggleDropdown : null}
                >
                  {t("navbar.about")}
                </ScrollLink>
              </li>
              {accessToken === null && (
                <li>
                  <ScrollLink
                    to="contacts"
                    smooth={true}
                    duration={500}
                    className="outer-landing-page-nav-link"
                    offset={-80}
                    onClick={mobileMenuVersion ? toggleDropdown : null}
                  >
                    {t("navbar.contacts")}
                  </ScrollLink>
                </li>
              )}
              {accessToken !== null && (
                <li>
                  <a href="/workflow/index" className="outer-landing-page-nav-link">
                    {t("navbar.dashboard")}
                  </a>
                </li>
              )}
              {accessToken === null && (
                  <li>
                    <a href="/auth/register" className="outer-landing-page-nav-link">
                      {t("navbar.sign_up")}
                    </a>
                  </li>
              )}
              {accessToken === null && (
                  <li>
                    <a href="/auth/login" className="outer-landing-page-nav-link">
                      {t("navbar.login")}
                    </a>
                  </li>
              )}


              {/*
              {mobileMenuVersion && (
                  <div className="outer-landing-page-dropdown-footer">
                    <a href="https://www.linkedin.com/company/atlantic-infinity" target="_blank" rel="noopener noreferrer">
                      <LinkedInIcon className="outer-landing-page-dropdown-footer-icon" />
                    </a>
                    <a href="https://www.x.com" target="_blank" rel="noopener noreferrer">
                      <XIcon className="outer-landing-page-dropdown-footer-icon" />
                    </a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                      <YouTubeIcon className="outer-landing-page-dropdown-footer-icon" />
                    </a>
                  </div>
              )}
               */}



            </ul>

            <div className="outer-landing-page-dropdown" onClick={toggleDropdown}>
              {dropdownOpen ? <CloseIcon className="outer-landing-page-close-icon" /> : <MenuIcon />}
            </div>

          </nav>
        </div>

        <div className="outer-landing-page-hero-content">
          <h1 className="outer-landing-page-hero-title"><Trans i18nKey="outer_landing_page.header1"/></h1>
          <div className="white-line"></div>
          <p className="outer-landing-page-hero-description">
            <Trans i18nKey="outer_landing_page.header_desc"/>
          </p>

          {accessToken !== null && (
            <button className="outer-landing-page-hero-button" onClick={handleDashboardClick}>{t("navbar.dashboard")}</button>
            )
          }
          {accessToken === null && (
              <button className="outer-landing-page-hero-button"  onClick={handleStartPlanClick}>{t("outer_landing_page.start_your_free_plan")}</button>
            )
          }
        </div>

        <div className="outer-landing-page-scroll-bar">
          <button className="scroll-button left">
            <ScrollLink
              to="features"
              smooth={true}
              duration={500}
              className='scroll-button left'
              offset={-80}
            >
              <img alt="scrollDown" src={triangle} className='triangle-scrollDown'/>
            </ScrollLink>
          </button>
          
          <div className="scroll-line"></div>
        </div>


        <h1 id="features" className="outer-landing-page-hero-features-title">{t("outer_landing_page.features")}</h1>
      </div>
      
      <div className="outer-landing-page-features">
        <div  className="outer-landing-page-feature-list">
          <div className="outer-landing-page-feature-item" style={{backgroundColor:'#ff7043'}} onClick={()=>{setSelectedFeature("boards")}}>
            {selectedFeature === "boards" && (
                <div className="selected-feature-corner-decor"/>
              )}
            <div className="feature-header">
              <img className="feature-symbol" src={boardsFeature} alt="boards"/>
              <div className="feature-title">{t("outer_landing_page.boardsFeature")}</div>
            </div>
            <div className="feature-description">{t("outer_landing_page.boardsFeatureDesc")}</div>
          </div>
          <div className="outer-landing-page-feature-item" style={{backgroundColor:'#ffab00'}} onClick={()=>{setSelectedFeature("management")}}>
            {selectedFeature === "management" && (
              <div className="selected-feature-corner-decor"/>
            )}
            <div className="feature-header">
              <img className="feature-symbol" src={managementFeature} alt="management"/>
              <div className="feature-title">{t("outer_landing_page.managementFeature")}</div>
            </div>
            <div className="feature-description">{t("outer_landing_page.managementFeatureDesc")}</div>
          </div>
          <div className="outer-landing-page-feature-item" style={{backgroundColor:'#00c853'}} onClick={()=>{setSelectedFeature("calendar")}}>
            {selectedFeature === "calendar" && (
                <div className="selected-feature-corner-decor"/>
              )}
            <div className="feature-header">
              <img className="feature-symbol" src={calendarFeature} alt="calendar"/>
              <div className="feature-title">{t("outer_landing_page.calendarFeature")}</div>
            </div>
            <div className="feature-description">{t("outer_landing_page.calendarFeatureDesc")}</div>
          </div>
          <div className="outer-landing-page-feature-item" style={{backgroundColor:'#526dfd'}} onClick={()=>{setSelectedFeature("vacations")}}>
            {selectedFeature === "vacations" && (
              <div className="selected-feature-corner-decor"/>
            )}
            <div className="feature-header">
              <img className="feature-symbol" src={vacationFeature} alt="vacations"/>
              <div className="feature-title">{t("outer_landing_page.vacationsFeature")}</div>
            </div>
            <div className="feature-description">{t("outer_landing_page.vacationsFeatureDesc")}</div>
          </div>
        </div>
        <img className='outer-landing-page-feature-animation' src={benefitsBackground} alt="tempImage"/>
      </div>

      <div id="benefits" className="outer-landing-page-benefits">
        <h2 className="outer-landing-page-section-title">{t("outer_landing_page.benefits")}</h2>
        <div className="rainbow-line"></div>
        <blockquote className="outer-landing-page-benefits-text">{t("outer_landing_page.benefits_text")}</blockquote>
      </div>

      <div  id="testimony" className="outer-landing-page-testimony" style={{ backgroundImage: `url(${benefitsBackground})`}}>
        <div className="testimony-content" style={{ position: 'relative', overflow: 'visible' }}>
          <div className="testimony-background" style={{backgroundImage: `url(${testimonies[selectedTestimony].smallImage})`, }}/>
          <div className="testimony-company-header">
            <img
              src={testimonies[selectedTestimony].smallImage}
              alt="Company Logo"
              className="testimony-image"
            />
            <h2 className="company-name">{testimonies[selectedTestimony].company}</h2>
          </div>
          <h3 className="testimony-title">{t("testimonies."+testimonies[selectedTestimony].title)}</h3>
          <p className="testimony-text">{t("testimonies."+testimonies[selectedTestimony].text)}</p>
          
          <div className="testimony-buttons">
  <button
    className="testimony-btn"
    id="btn1"
    onClick={() => {
      setSelectedTestimony(0);
    }}
    style={{
      backgroundColor:
        selectedTestimony === 0 ? 'white' : 'rgba(255,255,255,0.5)',
    }}
  >
    <img
      src={testimonies[0].image}
      alt="Company Logo"
      style={{
        filter: selectedTestimony === 0 ? 'brightness(0%)' : 'none',
        opacity: selectedTestimony === 0 ? '0.5' : '1',
      }}
    />
  </button>
</div>



        </div>
      </div>

      <div id="plans" className="outer-landing-page-plans">
        <h2 className="outer-landing-page-section-title">{t("outer_landing_page.plans&pricing")}</h2>
        <div className="rainbow-line"></div>
        <div className="outer-landing-page-pricing-grid">

          <div className='outer-landing-page-plan'>
            <div className="outer-landing-page-plan-header" style={{backgroundColor:'#ff7043'}}>
              <div className='outer-landing-page-plan-name'>{t("outer_landing_page.basic")}</div>
              <div className='outer-landing-page-plan-cost'>{t("outer_landing_page.basic_cost")} </div>
            </div>

            <div className='outer-landing-page-plans-body' style={{border:'1px solid #ff7043'}}>
              <div className='outer-landing-page-plan-target'>{t("outer_landing_page.basic_target")}</div>
              <div className='outer-landing-page-plan-colored-spacer' style={{border:'1px solid #ff7043'}}/>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-orange'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  10 {t("outer_landing_page.basic_entry_members")}
                </span>
              </div>


              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-orange'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  10 GB
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-orange'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  {t("outer_landing_page.basic_entry_1")}
                </span>
              </div>
              
            </div>

            <button className="outer-landing-page-plan-button-orange" onClick={handleStartPlanClick}>
              {t("outer_landing_page.try_for_free")}
            </button>

          </div>

          <div className='outer-landing-page-plan'>
            <div className="outer-landing-page-plan-header" style={{backgroundColor:'#ffab00'}}>
              <div className='outer-landing-page-plan-name'>{t("outer_landing_page.growth")}</div>
              <div className='outer-landing-page-plan-cost'>20€/{t("outer_landing_page.month")}</div>
            </div>

            <div className='outer-landing-page-plans-body' style={{border:'1px solid #ffab00'}}>
              <div className='outer-landing-page-plan-target'>{t("outer_landing_page.standard_target")}</div>
              <div className='outer-landing-page-plan-colored-spacer' style={{border:'1px solid #ffab00'}}/>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-yellow'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  20 {t("outer_landing_page.basic_entry_members")}
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-yellow'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  10 GB
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-yellow'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  {t("outer_landing_page.basic_entry_1")}
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-yellow'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  {t("outer_landing_page.team_management_feature")}
                </span>
              </div>

            </div>

            <button className="outer-landing-page-plan-button-yellow" onClick={handleStartPlanClick}>
              {t("outer_landing_page.purchase")}
            </button>


            
          </div>

          <div className='outer-landing-page-plan'>
            <div className="outer-landing-page-plan-header" style={{backgroundColor:'#00c853'}}>
              <div className='outer-landing-page-plan-name'>{t("outer_landing_page.professional")}</div>
              <div className='outer-landing-page-plan-cost'>30€/{t("outer_landing_page.month")}</div>
            </div>

            <div className='outer-landing-page-plans-body' style={{border:'1px solid #00c853'}}>
              <div className='outer-landing-page-plan-target'>{t("outer_landing_page.enterprise_target")}</div>
              <div className='outer-landing-page-plan-colored-spacer' style={{border:'1px solid #00c853'}}/>


              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-green'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  30 {t("outer_landing_page.basic_entry_members")}
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-green'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  10 GB
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-green'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  {t("outer_landing_page.basic_entry_1")}
                </span>
              </div>

              <div className='outer-landing-page-plan-entry'>
                <div className='outer-landing-page-plan-checkmark-green'>
                  <img alt="checkmark" src={planCheckmark} className='plan-checkmark'/>
                </div>
                <span className='outer-landing-page-plan-target'>
                  {t("outer_landing_page.team_management_feature")}
                </span>
              </div>
            </div>

            <button className="outer-landing-page-plan-button-green" onClick={handleStartPlanClick}>
              {t("outer_landing_page.purchase")}
            </button>
            
          </div>

          <div className='outer-landing-page-plan'>
            <div className="outer-landing-page-plan-header" style={{backgroundColor:'#526dfd'}}>
              <div className='outer-landing-page-plan-name'>{t("outer_landing_page.custom")}</div>
            </div>
            <div className='outer-landing-page-plans-body' style={{border:'1px solid #526dfd'}}>
              <div className='outer-landing-page-plan-target'>{t("outer_landing_page.custom_target")}</div>
            </div>

            <ScrollLink
                to="contacts"
                smooth={true}
                duration={500}
                className="outer-landing-page-plan-button-blue"
                offset={-80}
                onClick={toggleDropdown}
            >
              {t("outer_landing_page.contact_us")}
            </ScrollLink>


          </div>

          <img alt="Circle decoration" src={planCircles} className='planCircles'/>
        </div>

      </div>

      <div id="about" className="outer-landing-page-about">
        <div className="outer-landing-page-about-inner">
          <h2 className="outer-landing-page-section-title">{t("outer_landing_page.about_us")}</h2>
          <img src={decorativePencils} alt="Decorative Pencils" className="decorativePencils"/>
          <div className="rainbow-line"></div>
          <div className="about-square">
            <img src={ingenious} alt="IngeniousImg" className='about-image' />
            <div className='about-inner-text'>
              <h1 className='about-inner-title'>{t("outer_landing_page.ingenious")}</h1>
              <p className="outer-landing-page-about-description">{t("outer_landing_page.ingenious_desc")}</p>
              <p className='outer-landing-page-about-description'>
                {t("outer_landing_page.web")}
                <a href="https://www.ingeniousknowledge.com" target="_blank" rel="noopener noreferrer" className="about-link">
                  www.ingeniousknowledge.com
                </a>
              </p>
            </div>
          </div>
          <div className="about-square">
            <img src={aiLogo} alt="IngeniousImg" className='about-image' />
            <div className='about-inner-text'>
              <h1 className='about-inner-title'>{t("outer_landing_page.atlantic")}</h1>
              <p className="outer-landing-page-about-description">{t("outer_landing_page.atlantic_desc")}</p>
              <p className='outer-landing-page-about-description'>
                {t("outer_landing_page.web")}
                <a href="https://www.atlantic-infinity.com" target="_blank" rel="noopener noreferrer" className="about-link">
                  www.atlantic-infinity.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="outer-landing-image-container">
          <img src={newReadyToImprove} alt="readyToImprove" className="readyToImproveImg"/>
          <div className="shadow"></div>
          <div className="overlay-text">{t("outer_landing_page.ready_to_improve")}</div>
      </div>


      {accessToken === null && (
          <div id="contacts" className="outer-landing-page-contacts">
            <div className="outer-landing-page-contacts-white-decoration"/>
            <div className="outer-landing-page-contacts-white-decoration-triangle"/>
            {accessToken !== null && (
              <button className="outer-landing-page-contacts-button" onClick={handleDashboardClick}>{t("navbar.dashboard")}</button>
            )}
            {accessToken === null && (
              <button className="outer-landing-page-contacts-button" onClick={handleStartPlanClick}>{t("outer_landing_page.start_your_free_plan_today")}</button>
            )}
            <div className="rainbow-line"></div>
            <h2 className="outer-landing-page-section-title">{t("outer_landing_page.contacts")}</h2>
            {emailSent === false && (
              <form className="outer-landing-page-contact-form" onSubmit={sendEmail} ref={form}>
                <input 
                  type="text" 
                  name="from_name" 
                  placeholder={t("outer_landing_page.contacts_name")} 
                  className="outer-landing-page-input"
                  required={true}
                  />
                <input 
                  type="email" 
                  name="reply_to" 
                  placeholder={t("outer_landing_page.contacts_email")} 
                  className="outer-landing-page-input"
                  required={true}
                  />
                <input 
                  type="text" 
                  name="subject" 
                  placeholder={t("outer_landing_page.contacts_subject")} 
                  className="outer-landing-page-input"
                  required={true}
                  />
                <textarea 
                  name="message" 
                  placeholder={t("outer_landing_page.contacts_message")} 
                  className="outer-landing-page-textarea" 
                  rows={10}
                  required={true}
                  />
                <button type="submit" className="outer-landing-page-submit-button">{t("outer_landing_page.contacts_submit")}</button>
              </form>
            )}
            {emailSent === true && (
              <div style={{marginLeft:'auto', marginRight:'auto', marginBottom:'3rem'}}>
                <h2 className="outer-landing-page-thanks">{t("outer_landing_page.thanks_for_contact")}</h2>
                <h2 className="outer-landing-page-get-back">{t("outer_landing_page.get_back_to_you")}</h2>
              </div>
            )}
          </div>
      )}

      <div className="outer-landing-page-footer-green-wrapper">
        <div className="outer-landing-page-footer-yellow-wrapper">
          <div className="outer-landing-page-footer-orange-wrapper">
            <div className="outer-landing-page-footer">
              <div className="outer-landing-page-footer-columns">
                <div className="outer-landing-page-footer-column">
                  <img src={footerLogo} alt="Workflow Pro" className="footer-image" />
                </div>
                <div className="outer-landing-page-footer-column">
                  <h3 className="outer-landing-page-footer-title">{t("outer_landing_page.features").toLowerCase()}</h3>
                  <nav>
                    <ul className="outer-landing-page-footer-links">
                      <li>
                      <ScrollLink
                            to="features"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.feature_1")}
                          </ScrollLink>
                      </li>
                      <li><ScrollLink
                            to="features"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.feature_2")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="features"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.feature_3")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="features"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.feature_4")}
                          </ScrollLink></li>
                    </ul>
                  </nav>
                </div>
                <div className="outer-landing-page-footer-column">
                  <h3 className="outer-landing-page-footer-title">{t("outer_landing_page.benefits").toLowerCase()}</h3>
                  <nav>
                    <ul className="outer-landing-page-footer-links">
                      <li>
                        <ScrollLink
                            to="benefits"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.benefit_1")}
                          </ScrollLink>
                      </li>
                      <li><ScrollLink
                            to="benefits"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.benefit_2")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="benefits"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.benefit_3")}
                          </ScrollLink></li>
                    </ul>
                  </nav>
                </div>
                <div className="outer-landing-page-footer-column">
                  <h3 className="outer-landing-page-footer-title">{t("outer_landing_page.plans&pricing").toLowerCase()}</h3>
                  <nav>
                    <ul className="outer-landing-page-footer-links">
                      <li><ScrollLink
                            to="plans"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.basic")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="plans"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.growth")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="plans"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.professional")}
                          </ScrollLink></li>
                      <li><ScrollLink
                            to="plans"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.custom")}
                          </ScrollLink></li>
                    </ul>
                  </nav>
                </div>
                <div className="outer-landing-page-footer-column">
                  <h3 className="outer-landing-page-footer-title">{t("outer_landing_page.about_us").toLowerCase()}</h3>
                  <nav>
                    <ul className="outer-landing-page-footer-links">
                      <li><ScrollLink
                            to="about"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            Ingenious Knowledge
                          </ScrollLink></li>
                    </ul>
                  </nav>
                </div>
                {accessToken === null && (
                  <div className="outer-landing-page-footer-column">
                    <h3 className="outer-landing-page-footer-title">{t("outer_landing_page.contacts").toLowerCase()}</h3>
                    <nav>
                      <ul className="outer-landing-page-footer-links">
                        <li>
                          <ScrollLink
                            to="contacts"
                            smooth={true}
                            duration={500}
                            className='outer-landing-page-footer-link'
                            offset={-80}
                          >
                            {t("outer_landing_page.send_message")}
                          </ScrollLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default OuterLandingPage;

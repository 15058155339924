import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css";

export default function UnarchiveListModal({
    openUnarchiveListModal,
    handleUnarchiveListModalClose,
    selectedList,
    handleUnarchiveList,
    avoidMisfire
}) {

    const { t } = useTranslation();

    const handleUnarchive = async () => {
        handleUnarchiveList(selectedList.id);
        handleUnarchiveListModalClose();
    };

    return (
        <Dialog open={openUnarchiveListModal} onClose={handleUnarchiveListModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.list_unarchive_title")}</DialogTitle>
            <DialogContent className="white-txt-color">
                <Typography>
                    {t("modal.list_unarchive")}<br/>{t("modal.list_unarchive_tasks")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUnarchiveListModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleUnarchive} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.unarchive")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import moment from 'moment';
import dayjs from 'dayjs';

export const languages = {
    EN: 'English',
    DE: 'German',
    PT: 'Portuguese'
};

export const loadLocales = async () => {
    const localeKeys = Object.keys(languages);

    const localeMap = localeKeys.reduce((acc, lang) => {
        const locale = lang.toLowerCase();
        acc[lang] = {
            moment: locale === 'en' ? null : locale,
            dayjs: locale === 'en' ? null : locale,
        };
        return acc;
    }, {});

    let currentLang = localStorage.getItem('selectedLanguage') || 'EN';

    if (!languages[currentLang]) {
        currentLang = 'EN';
    }

    const locales = localeMap[currentLang];

    // Moment.jsx locales
    if (locales.moment) {
        try {
            require(`moment/locale/${locales.moment}`);
            moment.locale(locales.moment);
        } catch (error) {
            console.error(`Error loading Moment.jsx locale: ${locales.moment}`, error);
        }
    } else {
        moment.locale('en');
    }

    // Day.jsx locales
    if (locales.dayjs) {
        try {
            const dayjsLocale = await import(`dayjs/locale/${locales.dayjs}.js`);
            dayjs.locale(dayjsLocale.default);
        } catch (error) {
            console.error(`Error loading Day.jsx locale: ${locales.dayjs}`, error);
        }
    } else {
        dayjs.locale('en');
    }
};

import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import GuestLayout from "layouts/Guest.jsx";
import CustomFallback from 'components/Custom/CustomFallBack';
import WorkflowLayout from 'layouts/Workflow';
import { WorkspacesProvider } from 'contexts/WorkspacesContextProvider';
import { SidebarProvider } from 'contexts/SidebarContextProvider';
import { AuthorizationProvider } from 'contexts/AuthContextProvider';
import { ProjectsProvider } from 'contexts/ProjectsContextProvider';
import { ServiceProvider } from 'contexts/ServiceContextProvider';
import { NotificationProvider } from 'contexts/NotificationContextProvider';
import { SnackbarProvider } from 'contexts/SnackbarContextProvider';
import { loadLocales } from "./utils/LanguageImporter";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import setupAxiosInterceptors from './utils/AxiosInterceptor';

// Custom theme for the app
const theme = createTheme({
  typography: {
    allVariants: {
      color: "#C0C0C0"
    },
    fontFamily: 'Inter, sans-serif',
  },
  paper: {
    backgroundColor: "#252224",
    color: "#0063B2",
  },
  palette: {
    primary: {
      main: '#0063B2'
    },
    secondary: {
      main: '#8AC3DD'
    }
  }
});

const App = () => {
  return (
    <Routes>
      <Route path="/workflow/*" element={<WorkflowLayout/>} />
      <Route path="/landing/*" element={<GuestLayout/>} />
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="*" element={<GuestLayout />} />
    </Routes>
  );
};

// New component for Axios interceptors
const AxiosInterceptorWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set up Axios interceptors when the app starts
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  return null; // This component only sets up Axios, so no rendering
};

export default function WrappedApp() {
  const { i18n } = useTranslation();

  useEffect(() => {
    loadLocales();
  }, [i18n.language]);

  return (
    <Suspense fallback={<CustomFallback />}>
      <SnackbarProvider>
        <BrowserRouter>
          <AuthorizationProvider> {/* Moved inside BrowserRouter */}
            <ThemeProvider theme={theme}>
              <ServiceProvider>
                <SidebarProvider>
                  <NotificationProvider>
                    <WorkspacesProvider>
                      <ProjectsProvider>
                        <AxiosInterceptorWrapper />
                        <App />
                      </ProjectsProvider>
                    </WorkspacesProvider>
                  </NotificationProvider>
                </SidebarProvider>
              </ServiceProvider>
            </ThemeProvider>
          </AuthorizationProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </Suspense>
  );
}

import React, { useEffect, useState } from 'react';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useProjects } from 'contexts/ProjectsContextProvider';
import { useNavigate } from 'react-router-dom';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useService } from 'contexts/ServiceContextProvider';
import { Divider, Typography, Grid, Select, MenuItem, FormControl, InputLabel, Pagination, Switch, FormControlLabel, TextField, Box, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import ProjectCard from 'components/Core/ProjectCards';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import WarningIcon from '@mui/icons-material/Warning';
import CustomFallback from 'components/Custom/CustomFallBack';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useLocation } from 'react-router-dom';

import '../css/views/LandingPage.css';
import '../css/main.css';

const SortableProjectItem = ({ project, myUserPerms, loggedUserID, setCurrentProject, handleProjectChange, favoriteThisProject, handleEditProject, handleArchiveProject, handleDeleteProject, isDragAndDropEnabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: project.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    touchAction: isDragAndDropEnabled ? 'none' : 'auto',
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      ref={setNodeRef}
      className="sortable-project-landing-page"
      style={style}
      {...attributes}
      {...listeners}
    >
      <ProjectCard
        project={project}
        myUserPerms={myUserPerms}
        loggedUserID={loggedUserID}
        setCurrentProject={setCurrentProject}
        handleProjectChange={handleProjectChange}
        favoriteThisProject={favoriteThisProject}
        handleEditProject={handleEditProject}
        handleArchiveProject={handleArchiveProject}
        handleDeleteProject={handleDeleteProject}
        isDragging={isDragging}
      />
    </Grid>
  );
};


const LandingPage = () => {



  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;

  
  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const projectsContext = useProjects();
  const { setProjects } = projectsContext;
  const myProjects = projectsContext.projects;
  const { setCurrentProject, handleProjectChange } = projectsContext;
  const { handleToggleFavorite } = projectsContext;
  const { getLoggedUserPermissions, ArchiveExistingProject, DeleteExistingProject, reorderProjects, getProjectCustomOrder } = useService();

  const { getLoggedUser } = useAuthorization();
  const loggedUserID = getLoggedUser();
  const [isLoading, setIsLoading] = useState(true);
  const [myUserPerms, setMyUserPerms] = useState(null);
  const [, setIsDragging] = useState(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [localProjects, setLocalProjects] = useState([]);
  const [customOrder, setCustomOrder] = useState([]);
  const [sortOption, setSortOption] = useState('custom');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [statusFilter, setStatusFilter] = useState(['any']);
  const [favoritesFilter, setFavoritesFilter] = useState(false);

  const location = useLocation();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    const isSuccess = location.pathname === '/workflow/success-payment';
    const isFailed = location.pathname === '/workflow/failed-payment';
    const isCancelSuccess = location.pathname === '/workflow/success-cancel';
    const isCancelFailed = location.pathname === '/workflow/failed-cancel';
    const isSuspendSuccess = location.pathname === '/workflow/success-suspend';
    const isSuspendFailed = location.pathname === '/workflow/failed-suspend';
    const isActivateSuccess = location.pathname === '/workflow/success-activate';
    const isActivateFailed = location.pathname === '/workflow/failed-activate';
    const isFeatureUnavailable = location.pathname === '/workflow/feature-unavailable';

    if (isSuccess) {
      showSnackbar('Your payment was successful! Thank you for your purchase.', 'success');
    }

    if (isFailed) {
      showSnackbar('Payment failed.', 'error');
    }

    if (isActivateSuccess) {
      showSnackbar('It was activated successfully.', 'success');
    }

    if (isActivateFailed) {
      showSnackbar('It was not activated successfully.', 'error');
    }

    if (isCancelSuccess) {
      showSnackbar('The subscription was successfully canceled.', 'success');
    }

    if (isCancelFailed) {
      showSnackbar('There was an issue canceling the subscription.', 'error');
    }

    if (isSuspendSuccess) {
      showSnackbar('The subscription was successfully suspended.', 'success');
    }

    if (isSuspendFailed) {
      showSnackbar('There was an issue suspending the subscription.', 'error');
    }

    if (isFeatureUnavailable) {
      showSnackbar("Your plan doesn't include this feature, please update your plan to activate it.", 'error');
    }



  }, [location]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#333"
      },
    },
  };

  const statusOptions = [
    { value: 'any', label: t('main_page.stat_any') },
    { value: 'not_started', label: t('project.stat_notstart') },
    { value: 'ongoing', label: t('project.stat_ongoing') },
    { value: 'completed', label: t('project.stat_completed') },
    { value: 'archived', label: t('project.stat_archived') },
  ];


  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function fetchCustomOrder() {
      if (myProjects.length > 0 && myWorkspaces[0]) {


        document.title = myWorkspaces[0]?.name +" - Workflow PRO";
        const userPerms = await getLoggedUserPermissions(myWorkspaces[0].id, loggedUserID);
        setMyUserPerms(userPerms);

        try {
          const customOrderResponse = await getProjectCustomOrder(myWorkspaces[0].id);
          let customOrder = customOrderResponse.project_ids;

          customOrder = [...new Set(customOrder)];

          const myProjectIds = myProjects.map(p => p.id);

          customOrder = customOrder.filter(id => myProjectIds.includes(id));

          const missingIds = myProjectIds.filter(id => !customOrder.includes(id));
          if (missingIds.length > 0) {
            customOrder = [...customOrder, ...missingIds];

            await reorderProjects({
              project_ids: customOrder,
              items_per_page: itemsPerPage === 'all' ? 0 : itemsPerPage,
              status_filter: statusFilter.join(','),  // Join the array for the backend
              favorites_filter: favoritesFilter,
            }, myWorkspaces[0].id);
          }

          if (customOrder.length > 0) {
            const orderedProjects = customOrder.map(id => {
              const project = myProjects.find(p => p.id === id);
              return project;
            }).filter(p => p !== undefined);
            setLocalProjects(orderedProjects);
            setCustomOrder(customOrder);

            // Set the additional filters from the response
            setItemsPerPage(customOrderResponse.items_per_page === 0 ? 'all' : customOrderResponse.items_per_page);
            setStatusFilter(customOrderResponse.status_filter.split(','));  // Split the string back to array
            setFavoritesFilter(customOrderResponse.favorites_filter);
          } else {
            setLocalProjects(myProjects);
          }
        } catch (error) {
          console.error('Error fetching custom order:', error);
          setLocalProjects(myProjects);
        }
      } else {
        setLocalProjects(myProjects);
      }
      setIsLoading(false); // Set loading to false after data is fetched
    }

    fetchCustomOrder();
  }, [myProjects, myWorkspaces, loggedUserID]);


  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = localProjects.findIndex(project => project.id === active.id);
      const newIndex = localProjects.findIndex(project => project.id === over.id);

      const newProjects = arrayMove(localProjects, oldIndex, newIndex);
      setLocalProjects(newProjects);

      // Update custom order state
      const newCustomOrder = newProjects.map(project => project.id.toString());
      setCustomOrder(newCustomOrder);

      // Call backend to save new order
      await reorderProjects({
        project_ids: newCustomOrder,
        items_per_page: itemsPerPage === 'all' ? 0 : itemsPerPage,
        status_filter: statusFilter.join(','),  // Join the array for the backend
        favorites_filter: favoritesFilter,
      }, myWorkspaces[0].id);

      // Change the selected sort by to custom
      setSortOption('custom');
    }
    setIsDragging(false);
  };

  const favoriteThisProject = (project_id) => {
    handleToggleFavorite(project_id);

    const updatedProjects = localProjects.map(project =>
      project.id === project_id ? { ...project, favorites: !project.favorites } : project
    );
    setLocalProjects(updatedProjects);
    setProjects(updatedProjects);
  };

  const handleEditProject = (project, event) => {
    navigate("/workflow/projects/edit", { state: { project } });
  };

  const handleDeleteProject = async (project) => {
    const response = await DeleteExistingProject(myWorkspaces[0].id, project.id);
    if (response.status === 204) {
      setProjects(localProjects.filter(p => p.id !== project.id));
      showSnackbar(t("main_page.delete_project"), 'success');
    } else {
      showSnackbar(t("main_page.delete_project_fail"), 'error');
    }
  };

  const handleArchiveProject = async (project) => {
    const response = await ArchiveExistingProject(myWorkspaces[0].id, project.id);
    if (response.data.status === "archived") {
      const updatedProjects = myProjects.map(p =>
        p.id === project.id ? { ...p, status: "archived" } : p
      );
      setProjects(updatedProjects);
      showSnackbar(t("main_page.archive_project"), 'success');
    } else {
      showSnackbar(t("main_page.archive_project_fail"), 'error');
    }
  };

  const handleSortChange = (event) => {
    const { value } = event.target;
    setSortOption(value);

    let sortedProjects;
    if (value === 'name') {
      sortedProjects = [...localProjects].sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === 'dateAsc') {
      sortedProjects = [...localProjects].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    } else if (value === 'dateDesc') {
      sortedProjects = [...localProjects].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (value === 'favorites') {
      sortedProjects = [...localProjects].sort((a, b) => b.favorites - a.favorites);
    } else if (value === 'custom') {
      sortedProjects = customOrder.map(id => localProjects.find(p => p.id === id)).filter(p => p !== undefined);
    }
    setLocalProjects(sortedProjects);
  };

  const handleStatusFilterChange = async (event) => {
    const value = event.target.value;
    let newStatusFilter;

    //If any is added last, it removes the others, this way the any behave the way Nuno wants to.
    if (value[value.length - 1] === "any") {
      newStatusFilter = ['any'];
    } else {
      newStatusFilter = value.filter(v => v !== 'any');
      if (newStatusFilter.length === 0) {
        newStatusFilter = ['any'];
      }
    }

    setStatusFilter(newStatusFilter);
    setCurrentPage(1);

    await reorderProjects({
      project_ids: customOrder,
      items_per_page: itemsPerPage === 'all' ? 0 : itemsPerPage,
      status_filter: newStatusFilter.join(','),  // Join the array for the backend
      favorites_filter: favoritesFilter,
    }, myWorkspaces[0].id);
  };


  const handleFavoritesFilterChange = async (event) => {
    const newFavoritesFilter = event.target.checked;
    setFavoritesFilter(newFavoritesFilter);
    setCurrentPage(1);

    await reorderProjects({
      project_ids: customOrder,
      items_per_page: itemsPerPage === 'all' ? 0 : itemsPerPage,
      status_filter: statusFilter.join(','),  // Join the array for the backend
      favorites_filter: newFavoritesFilter,
    }, myWorkspaces[0].id);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
    setIsDragAndDropEnabled(false);
  };

  const handleItemsPerPageChange = async (event) => {
    const value = event.target.value;
    const newItemsPerPage = value === 'all' ? 'all' : Number(value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);

    await reorderProjects({
      project_ids: customOrder,
      items_per_page: newItemsPerPage === 'all' ? 0 : newItemsPerPage,
      status_filter: statusFilter.join(','),  // Join the array for the backend
      favorites_filter: favoritesFilter,
    }, myWorkspaces[0].id);
  };

  // Filter projects first before pagination calculation
  const filteredProjects = localProjects
    .filter(project => project.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(project => statusFilter.length === 0 || statusFilter.includes('any') || statusFilter.includes(project.status))
    .filter(project => !favoritesFilter || project.favorites);



  const indexOfLastProject = itemsPerPage === 'all' ? filteredProjects.length : currentPage * itemsPerPage;
  const indexOfFirstProject = itemsPerPage === 'all' ? 0 : indexOfLastProject - itemsPerPage;
  const currentProjects = itemsPerPage === 'all' ? filteredProjects : filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (value !== 1) {
      setIsDragAndDropEnabled(false);
    }
  };

  const toggleDragAndDrop = () => {
    setIsDragAndDropEnabled((prev) => !prev);
  };

  return (
    <Box className="main-box-landing-page">
      {isLoading ? (
        <CustomFallback />
      ) : (
        <>
          <Box className="title-flex-box-landing-page">
            {myWorkspaces && myWorkspaces.length > 0 && (
              <>
                {myWorkspaces[0].image && (
                  <Box className="image-margin-landing-page">
                    <img src={myWorkspaces[0].image} alt="myWorkspaceImage" className="workspace-image-landing-page" />
                  </Box>
                )}
                <Box className="centered-box-landing-page">
                  <Typography variant="h3" className="workspace-title-landing-page">
                    {myWorkspaces[0].name}
                  </Typography>
                  <Typography variant="subtitle1" className="workspace-subtitle-landing-page">
                    {myWorkspaces[0].company_mail}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <Divider className="divider-landing-page" />

          <Grid container spacing={2} className="user-input-options-landing-page">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label={t('main_page.search')}
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                className="text-input-landing-page"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className="form-control-landing-page">
                <InputLabel className="white-txt-color">{t('main_page.items_per_page')}</InputLabel>
                <Select
                  value={itemsPerPage === 'all' ? 'all' : itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items per page"
                  MenuProps={{
                    PaperProps: {
                      className: "select-menu-landing-page",
                    },
                  }}
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value="all">{t('main_page.all')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className="form-control-landing-page">
                <InputLabel className="white-txt-color">{t('main_page.sort')}</InputLabel>
                <Select
                  value={sortOption}
                  onChange={handleSortChange}
                  label="Sort by"
                  MenuProps={{
                    PaperProps: {
                      className: "select-menu-landing-page",
                    },
                  }}
                >
                  <MenuItem value="name">{t('global.name')}</MenuItem>
                  <MenuItem value="dateAsc">{t('main_page.sort_date_asc')}</MenuItem>
                  <MenuItem value="dateDesc">{t('main_page.sort_date_desc')}</MenuItem>
                  <MenuItem value="favorites">{t('main_page.sort_favs')}</MenuItem>
                  <MenuItem value="custom">{t('main_page.sort_custom')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className="form-control-landing-page">
                <InputLabel className="white-txt-color">{t('main_page.filter_stat')}</InputLabel>
                <Select
                  multiple
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  input={<OutlinedInput label="Filter by Status" />}
                  renderValue={(selected) => selected.map(value => {
                    const option = statusOptions.find(option => option.value === value);
                    return option ? option.label : value;
                  }).join(', ')}
                  MenuProps={MenuProps}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={statusFilter.indexOf(option.value) > -1} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box className="title-flex-box-landing-page">
            <FormControlLabel
              control={
                <Switch
                  checked={isDragAndDropEnabled}
                  onChange={toggleDragAndDrop}
                  color="primary"
                  disabled={currentPage !== 1 || searchTerm !== ''}
                />
              }
              label={t('main_page.reorder')}
              className="form-control-label-landing-page"
            />

            {itemsPerPage !== 'all' && currentProjects.length !== 0 && (
              <Pagination
                count={Math.ceil(filteredProjects.length / itemsPerPage)}  // Update to use filteredProjects length
                page={currentPage}
                onChange={handlePageChange}
                className="pagination-landing-page"
              />
            )}

            <FormControlLabel
              control={<Checkbox checked={favoritesFilter} onChange={handleFavoritesFilterChange} color="primary" />}
              label={t('main_page.favs')}
              className="form-control-label-favs-landing-page"
            />

          </Box>
          {isDragAndDropEnabled && (
            <Box className="warning-box-landing-page">
              <WarningIcon className="warning-icon-left-landing-page" />
              {t('main_page.reorder_warn')}
              <WarningIcon className="warning-icon-right-landing-page" />
            </Box>
          )}
          {currentProjects.length === 0 && (
            <Typography variant="h6" className="no-matches-landing-page">
              {t('main_page.no_matches')}
            </Typography>
          )}

          {isDragAndDropEnabled ? (
              <Box className="sortable-items-landing-page">
                <DndContext
                    onDragEnd={handleDragEnd}
                >
                  <SortableContext items={localProjects.map((project) => project.id)} strategy={rectSortingStrategy}>
                    {currentProjects && currentProjects.length > 0 && myUserPerms && (
                      <Grid container spacing={5} className="draggable-items-landing-page">
                        {currentProjects.map(
                          (project) => (
                            <SortableProjectItem
                              key={project.id}
                              project={project}
                              myUserPerms={myUserPerms}
                              loggedUserID={loggedUserID}
                              setCurrentProject={setCurrentProject}
                              handleProjectChange={handleProjectChange}
                              favoriteThisProject={favoriteThisProject}
                              handleEditProject={handleEditProject}
                              handleArchiveProject={handleArchiveProject}
                              handleDeleteProject={handleDeleteProject}
                              isDragAndDropEnabled={isDragAndDropEnabled}
                            />
                          )
                        )}
                      </Grid>
                    )}
                  </SortableContext>
                </DndContext>
              </Box>
          ) : (
            <Box className="sortable-items-landing-page">
              {currentProjects && currentProjects.length > 0 && myUserPerms && (
                <Grid container spacing={5} className="draggable-items-landing-page">
                  {currentProjects.map(
                    (project) => (
                      <SortableProjectItem
                        key={project.id}
                        project={project}
                        myUserPerms={myUserPerms}
                        loggedUserID={loggedUserID}
                        setCurrentProject={setCurrentProject}
                        handleProjectChange={handleProjectChange}
                        favoriteThisProject={favoriteThisProject}
                        handleEditProject={handleEditProject}
                        handleArchiveProject={handleArchiveProject}
                        handleDeleteProject={handleDeleteProject}
                        isDragAndDropEnabled={isDragAndDropEnabled}
                      />
                    )
                  )}
                </Grid>
              )}
            </Box>
          )}
        </>
      )}

    </Box>
  );
}

export default LandingPage;

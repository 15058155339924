import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {useTranslation} from "react-i18next";
import '../../css/components/Profile/PersonalInformation.css';
import '../../css/main.css';

const PersonalInformation = ({ user, isEditable, handleInputChange, toggleEditable }) => {
    const { t } = useTranslation();

    return (
        <Card className="personal-info-card">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={2} className="personal-info-grid-item-label">
                        <Typography variant="body2" color="primary">{t("profile.full_name")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="full_name"
                                value={user.full_name || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    className: 'white-txt-color'
                                }}
                            />
                        ) : (
                            <Typography>{user.full_name}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="personal-info-content-margin">
                    <Grid item sm={2} className="personal-info-grid-item-label">
                        <Typography variant="body2" color="primary">{t("profile.username")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="username"
                                value={user.username || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    className: 'white-txt-color'
                                }}
                            />
                        ) : (
                            <Typography>{user.username}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="personal-info-content-margin">
                    <Grid item sm={2} className="personal-info-grid-item-label">
                        <Typography variant="body2" color="primary">{t("global.email")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="email"
                                value={user.email || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    className: 'white-txt-color'
                                }}
                            />
                        ) : (
                            <Typography>{user.email}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="personal-info-content-margin">
                    <Grid item sm={2} className="personal-info-grid-item-label">
                        <Typography variant="body2" color="primary">{t("profile.phone")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="phone"
                                value={user.phone || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    className: 'white-txt-color'
                                }}
                            />
                        ) : (
                            <Typography>{user.phone}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="personal-info-content-margin">
                    <Grid item sm={2} className="personal-info-grid-item-label">
                        <Typography variant="body2" color="primary">{t("profile.address")} <Tooltip title={t("profile.address_info")}>
                            <InfoIcon className="white-txt-color" />
                        </Tooltip></Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="address"
                                value={user.address || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    className: 'white-txt-color'
                                }}
                            />
                        ) : (
                            <Typography>{user.address}</Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PersonalInformation;

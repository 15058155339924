import React from 'react';
import {
    Card,
    CardContent,
    Badge,
    Typography,
    Box,
    Avatar,
    IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';
import {useTranslation} from "react-i18next";
import '../../css/components/Profile/ProfileCard.css';
import '../../css/main.css';

import { getUserStatusPriority } from '../Custom/userStatusHelper';

const ProfileCard = ({ user, workingHours, isEditable, selectedFile, handleFileChange, myUserTimezone }) => {
    const { t } = useTranslation();

    const getCountryFlag = (location_string) => {
        const regex = /\(([^)]+)\)/;
        const matches = regex.exec(location_string);
        if (matches && matches.length > 1) {
            return matches[1].toLocaleLowerCase();
        }
    };

    // Append workingHours to the user object before passing it to getUserStatusPriority
    const userWithWorkingHours = { ...user, working_hours: workingHours };

    // Get status priority and message for the user
    const { status: statusPriority, message } = getUserStatusPriority(userWithWorkingHours, new Date(), t);

    return (
        <Card className="profile-card-card">
            <CardContent className="profile-card-content">
                {isEditable ? (
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <IconButton component="label">
                                <CloudUploadIcon className="white-txt-color" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        }
                    >
                        <Avatar
                            src={selectedFile ? URL.createObjectURL(selectedFile) : user.image}
                            alt="avatar"
                            className="profile-card-avatar"
                        />
                    </Badge>
                ) : (
                    <Avatar
                        src={selectedFile ? URL.createObjectURL(selectedFile) : user.image}
                        alt="avatar"
                        className="profile-card-avatar"
                    />
                )}
                <Typography variant="h5" className="white-txt-color">
                    {user.full_name?.split(' ').map((name, index, array) => {
                        if (index === 0) {
                            return name; // First name
                        } else if (index === array.length - 1) {
                            return ` "${user.username}" ${name}`; // Last name
                        } else {
                            return ' '; // Space between first name and username, and between username and last name
                        }
                    })}
                </Typography>
                <Typography variant="h6" className="white-txt-color profile-card-job-title-margin">{user.job}</Typography>
                {user.location && (
                    <Typography variant="body2" className="white-txt-color">
                        {user.location}
                        <img
                            src={`https://flagcdn.com/w80/${getCountryFlag(user.location)}.png`}
                            srcSet={`https://flagcdn.com/w80/${getCountryFlag(user.location)}.png 2x`}
                            alt="Flag"
                            className="profile-card-flag-image"
                        />
                    </Typography>
                )}
                <Typography variant="body2" className="white-txt-color">
                    {t("profile.timezone")} {user.timezone} ({user.timezone_offset})
                </Typography>
                <Box className="profile-card-online-status-box">
                    {myUserTimezone?.timezone && myUserTimezone?.timezone_offset && user?.last_online && (
                        <UserOnlineStatus
                            userStatusAvailability={user.current_availability}
                            statusPriority={statusPriority}  
                            message={message}               
                            showBadgeOnly={false}            
                        />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProfileCard;

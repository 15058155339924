import React, { useState, useEffect } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, TextField, Box, DialogActions } from '@mui/material';

import {useTranslation} from "react-i18next";
import "../../css/components/Modals/CoverPickerModal.css";
import "../../css/main.css";



const CoverPickerModal = ({ open, setCoverModalOpen, saveNewCoverImage, cover_image, cover_color }) => {

    const { t } = useTranslation();

    const [color, setColor] = useState(null);
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        if (cover_image) {
            setImage(cover_image);
            setColor('');
        } else {
            setColor(cover_color);
            setImage(null);
            setImageFile(null);
        }
    }, [cover_image, cover_color, open]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
        setImage(null);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setImageFile(file);
            setColor('');
        }
    };

    const handleClearImage = () => {
        setImage(null);
        setImageFile(null);
        setColor(null);
        document.getElementById("cover-picker-image").value = "";
    };

    const handleClearColor = () => {
        setColor(null);
    };

    const handleClose = () => {
        setCoverModalOpen(false);
    };

    const handleSave = () => {
        saveNewCoverImage(imageFile, color);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}  className="modal-background-color">
            <DialogTitle className="cover-picker-dialog-title">{t("modal.change_the_cover")}</DialogTitle>
            <DialogContent>
                <Box className="cover-picker-content-box">
                    {!image && (
                        <TextField
                            type="color"
                            value={color}
                            onChange={handleColorChange}
                            className="cover-picker-text"
                            InputProps={{ style: {color: color} }}
                        />
                    )}
                    <Button
                        variant="contained"
                        component="label"
                        className="button-dark-bg"
                    >
                        {t("modal.upload_image")}
                        <input
                            type="file"
                            id="cover-picker-image"
                            hidden
                            accept="image/*"
                            onChange={handleImageChange}
                            
                        />
                    </Button>
                        {image && (
                            <Box className="cover-picker-img-box">
                                <img src={image} alt="Preview" className="cover-picker-img-dimensions" />
                                <Button onClick={handleClearImage} className="button-dark-bg cover-picker-margin-top">
                                    {t("modal.clear_image")}
                                </Button>
                            </Box>
                        )}
                        {!image && color !== null && (
                            <Box className="cover-picker-img-box">
                            <Button onClick={handleClearColor} className="button-dark-bg cover-picker-margin-top">
                                {t("modal.clear_color")}
                            </Button>
                            </Box>
                        )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className="button-dark-bg" onClick={handleClose}>{t("global.cancel")}</Button>
                <Button className="button-dark-bg" onClick={handleSave}>{t("global.save")}</Button>
            </DialogActions>
        </Dialog >
    );
};

export default CoverPickerModal;

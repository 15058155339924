import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import "../../css/main.css"

export default function CancelSubscriptionModal({ open, onClose, subscription, handleConfirmCancelSubscription, avoidMisfire }) {

    const handleCancel = () => {
        onClose();
    };

    const handleConfirmCancel = () => {
        handleConfirmCancelSubscription(subscription.id);
        onClose();
    };


    return (
        <Dialog open={open} onClose={onClose} className="modal-background-color">

            <DialogTitle>Warning!</DialogTitle>
            <DialogContent className="white-txt-color">
                Are you sure you want to cancel this subscription?<br/>
                You will lose the features and extras of your current plan immediately.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmCancel} disabled={avoidMisfire} color="primary">
                    Cancel subscription
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import {
    Workspaces as WorkspacesIcon,
    AccountTree as AccountTreeIcon,
    Assignment as AssignmentIcon,
    Check as CheckIcon,
    Close as CloseIcon,
    DoneAll as DoneAllIcon,
} from '@mui/icons-material';

/* eslint-disable react-hooks/exhaustive-deps */

import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Groups3Icon from '@mui/icons-material/Groups3';
import EventNoteIcon from '@mui/icons-material/EventNote';

import { useNotification } from 'contexts/NotificationContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useService } from 'contexts/ServiceContextProvider';
import { useTranslation } from "react-i18next";
import '../css/views/NotificationsPage.css';

const NotificationsPage = () => {
    useEffect(() => {
        document.title = t('titles.notifications');
      }, []);
    const { WorkspaceInviteUpdate, MarkNotificationAsRead, MarkNotificationAsUnread } = useService();
    const { myNotifs, fetchNotifications } = useNotification();
    const { getLoggedUser } = useAuthorization();
    const loggedUser = getLoggedUser();
    const { t } = useTranslation();

    const [, setAvoidMisfire] = useState(false);

    useEffect(() => {
        fetchNotifications(loggedUser);
    }, [loggedUser]);

    const FormatTimestamp = (timestamp) => {
        const parsedTimestamp = new Date(timestamp);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return parsedTimestamp.toLocaleString('en-US', options);
    };

    const markNotificationAsRead = async (notification, markAsRead) => {
        setAvoidMisfire(true);
        try {
            if (markAsRead) {
                await MarkNotificationAsRead(notification.id);
            } else {
                await MarkNotificationAsUnread(notification.id);
            }
        } catch (error) {
            console.error('Marking Notification as Read Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000);
        }
    };

    const handleNotificationClick = async (processed_notification, markAsRead) => {
        await markNotificationAsRead(processed_notification, markAsRead);
        fetchNotifications(loggedUser);
    };

    const handleWorkspaceInvite = async (notification, AcceptedInvite) => {
        setAvoidMisfire(true);
        const response = await WorkspaceInviteUpdate(notification.id, notification.invite_identifier, AcceptedInvite, getLoggedUser());
        if (response.status === 200) {
            fetchNotifications(loggedUser);
        }
    };

    const getIconAndColor = (notification) => {
        let icon, color;

        switch (notification.option_select) {
            case 'Vacation-Accepted':
                icon = <EventAvailableIcon className="icon-size-notif"/>;
                color = 'green';
                break;
            case 'Vacation-Declined':
                icon = <EventBusyIcon className="icon-size-notif"/>;
                color = 'red';
                break;
            case 'Vacation-Refuted':
                icon = <EventRepeatIcon className="icon-size-notif"/>;
                color = 'orange';
                break;
            default:
                if (notification.type === 'Event' && !notification.option_select) {
                    icon = <Groups3Icon className="icon-size-notif"/>;
                    color = 'blue';
                } else if (notification.type === 'Vacation' && !notification.option_select) {
                    icon = <EventNoteIcon className="icon-size-notif"/>;
                    color = 'black';
                } else {
                    switch (notification.type) {
                        case 'Workspace':
                            icon = <WorkspacesIcon className="icon-size-notif"/>;
                            color = 'black';
                            break;
                        case 'Project':
                            icon = <AccountTreeIcon className="icon-size-notif"/>;
                            color = 'black';
                            break;
                        case 'Task':
                            icon = <AssignmentIcon  className="icon-size-notif"/>;
                            color = 'black';
                            break;
                        default:
                            icon = <MarkEmailUnreadIcon className="icon-size-notif" />;
                            color = 'black';
                            break;
                    }
                }
        }

        return { icon, color };
    };

    return (
        <div className="container-notif">
            <article className="title-article-notif">
                <Typography variant="h3" className="title-notif">
                    {t('notifications.notifications')}
                </Typography>
            </article>
            <Divider className="divider-notif" key="divider" />
            <div className="container-notif">
                {myNotifs && myNotifs.length > 0 ? (
                    myNotifs.map(notification => {
                        const { icon, color } = getIconAndColor(notification);

                        return (
                            <Card className="card-notif" key={notification.id} variant="outlined">
                                <CardContent className="card-content-notif">
                                    <Typography variant="h5" className="card-title-notif">{notification.type} {t('notifications.notification')}</Typography>
                                    <ListItem>
                                        <ListItemIcon>
                                            <div className="icon-color-notif" style={{ color }}>
                                                {icon}
                                            </div>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="h5">{notification.message}</Typography>}
                                            secondary={<Typography variant="h6" color="black">{FormatTimestamp(notification.timestamp)}</Typography>}
                                        />
                                        <ListItemIcon>
                                            {notification.is_read ? (
                                                <MarkEmailReadIcon className="mark-read-notif" />
                                            ) : (
                                                <MarkEmailUnreadIcon className="mark-unread-notif" />
                                            )}
                                        </ListItemIcon>
                                    </ListItem>
                                </CardContent>
                                <CardActions>
                                    {notification.option_select === "Workspace-Invite" && (
                                        <>
                                            <Button size="large" className="button-notif button-accept-notif" onClick={() => handleWorkspaceInvite(notification, true)}>
                                                {t('notifications.accept')} <CheckIcon className="icons-margin-notif" fontSize='small' />
                                            </Button>
                                            <Button size="large" className="button-notif button-decline-notif" onClick={() => handleWorkspaceInvite(notification, false)}>
                                                {t('notifications.decline')} <CloseIcon className="icons-margin-notif" fontSize='small' />
                                            </Button>
                                        </>
                                    )}
                                    {notification.option_select === "Workspace-Invite-Accepted" && (
                                        <Button size="large" className="button-notif button-accept-notif">
                                            {t('notifications.accepted')} <CheckIcon className="icons-margin-notif" fontSize='large' />
                                        </Button>
                                    )}
                                    {notification.option_select === "Workspace-Invite-Declined" && (
                                        <Button size="large" className="button-notif button-decline-notif">
                                            {t('notifications.declined')} <CloseIcon className="icons-margin-notif" fontSize='large' />
                                        </Button>
                                    )}
                                    <Button onClick={() => handleNotificationClick(notification, !notification.is_read)} size="large" className={`button-notif ${notification.is_read ? 'button-decline-notif' : 'button-accept-notif'}`}>
                                        <DoneAllIcon className="marked-read-icon-notif"/>
                                        <span className="marked-read-txt-notif">{notification.is_read ? t('notifications.mark_unread') : t('notifications.mark_read')}</span>
                                    </Button>
                                </CardActions>
                            </Card>

                        );
                    })
                ) : (
                    <Typography variant="body1" className="no-notif">{t('notifications.no_notifs')}</Typography>
                )}
            </div>
        </div>

    );
};

export default NotificationsPage;

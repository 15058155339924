import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale/pt';
import { de } from 'date-fns/locale/de';
import { useService } from 'contexts/ServiceContextProvider';
import { useTranslation } from "react-i18next";
import "../../css/components/Calendar/VacationRequestModal.css";
import "../../css/main.css";

import { formattedHistory } from "./formatVacationHistory"

/* eslint-disable react-hooks/exhaustive-deps */
import InfoIcon from '@mui/icons-material/Info';

import { useSidebar } from 'contexts/SidebarContextProvider';

const VacationRequestModal = ({ open, handleCloseVacationRequestModal, myUserInMemberFormat, workspace_id }) => {
  const { t } = useTranslation();
  
  registerLocale('pt', pt);
  registerLocale('de', de);

  const { submitNewVacation, getMyRemainingWorkspaceVacationDays } = useService();
  const { isSidebarOpen } = useSidebar();
  const [vacationDays, setVacationDays] = useState({});

  useEffect(() => {
    async function fetchVacationDays() {
      if (workspace_id && open) {
        try {
          const response = await getMyRemainingWorkspaceVacationDays(workspace_id);
          setVacationDays(response.data);
          console.log("test", response.data)
        } catch (error) {
          console.error('Failed to fetch vacation days:', error);
        }
      }
    }

    fetchVacationDays();
  }, [workspace_id, getMyRemainingWorkspaceVacationDays, open]);


  const [vacationData, setVacationData] = useState({
    title: '', 
    description: '', 
    type: 'RV',
    date_start: new Date(),
    date_end: new Date(),
    visibility: false,
    workspace_id: workspace_id || '',
    do_not_deduct: false,
  });

  const [error, setError] = useState('');
  const [totalDays, setTotalDays] = useState(0);
  const [totalWeekdays, setTotalWeekdays] = useState(0);

  useEffect(() => {
    if (myUserInMemberFormat && myUserInMemberFormat.full_name) {
      setVacationData((prevState) => ({
        ...prevState,
        title: `${t("calendar.vacation_person", { person: myUserInMemberFormat.full_name })}`,
      }));
    }
  }, [myUserInMemberFormat]);

  useEffect(() => {
    if (workspace_id) {
      setVacationData((prevState) => ({
        ...prevState,
        workspace_id: workspace_id,
      }));
    }
  }, [workspace_id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVacationData({
      ...vacationData,
      [name]: value,
    });
  };

  const handleDateChange = (name, date) => {
    let newVacationData = { ...vacationData, [name]: date };

    if (name === 'date_start' && date > vacationData.date_end) {
      setError(t("calendar.vacation_err1"));
    } else if (name === 'date_end' && date < vacationData.date_start) {
      setError(t("calendar.vacation_err2"));
    } else {
      setError('');

      const start = new Date(newVacationData.date_start);
      const end = new Date(newVacationData.date_end);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setTotalDays(diffDays);

      const weekdaysCount = getWeekdaysCount(start, end);
      setTotalWeekdays(weekdaysCount);
    }

    setVacationData(newVacationData);
  };

  useEffect(() => {
    if (myUserInMemberFormat && myUserInMemberFormat.full_name) {
      let titleSuffix = t("calendar.vacation_suffix1"); // Default for RV which is the one we always start
      switch (vacationData.type) {
        case 'SL':
          titleSuffix = t("calendar.vacation_suffix2");
          break;
        case 'PL':
          titleSuffix = t("calendar.vacation_suffix3");
          break;
        case 'MPL':
          titleSuffix = t("calendar.vacation_suffix4");
          break;
        default:
          titleSuffix = t("calendar.vacation_suffix1");
      }

      setVacationData((prevState) => ({
        ...prevState,
        title: `${myUserInMemberFormat.full_name}${titleSuffix}`,
      }));
    }
  }, [myUserInMemberFormat, vacationData.type]);


  const getWeekdaysCount = (start, end) => {
    let count = 0;
    const current = new Date(start);
    while (current <= end) {
      const day = current.getDay();
      if (day !== 0 && day !== 6) count++;
      current.setDate(current.getDate() + 1);
    }
    return count;
  };

  const handleVisibilityChange = (event) => {
    setVacationData({
      ...vacationData,
      visibility: event.target.checked,
    });
  };

  const handleDeductabilityChange = (event) => {
    setVacationData({
      ...vacationData,
      do_not_deduct: event.target.checked,
    });
  };

  const handleSubmit = () => {
    if (vacationData.date_start > vacationData.date_end) {
      setError(t("calendar.vacation_err1"));
      return;
    }

    const formattedData = {
      ...vacationData,
      date_start: format(vacationData.date_start, 'yyyy-MM-dd'),
      date_end: format(vacationData.date_end, 'yyyy-MM-dd'),
    };
    submitNewVacation(formattedData, workspace_id);
    handleClose();
  };

  const handleClose = () => {
    setVacationData({
      title: `${myUserInMemberFormat.full_name || ''}'s Vacation`,
      description: '',
      type: 'RV',
      date_start: new Date(),
      date_end: new Date(),
      visibility: false,
      workspace_id: workspace_id || '',
    });
    handleCloseVacationRequestModal();
  };




  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="vacation-request-modal-title"
      aria-describedby="vacation-request-modal-description"
      className={`request-vacation-modal ${isSidebarOpen() ? 'sidebar-open' : ''}`}
    >
      <Box className="request-vacation-main-style">
        <Typography id="vacation-request-modal-title" variant="h6" component="h2" className="white-txt-color">
          {t("calendar.vacation_request")}
        </Typography>
        <TextField
          label={t("calendar.event_title")}
          name="title"
          value={vacationData.title}
          fullWidth
          margin="normal"
          variant="outlined"
          disabled
          className="request-vacation-disabled"
          InputLabelProps={{ className: "request-vacation-type-title" }}
          InputProps={{
            className: "request-vacation-type-select"
          }}
        />
        <TextField
          label={t("calendar.event_description")}
          name="description"
          value={vacationData.description}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={3}
          InputLabelProps={{ className: "request-vacation-type-title" }}
          InputProps={{
            className: "request-vacation-type-select",
          }}
          onChange={handleChange}
        />
        <FormControl margin="normal" className="request-vacation-form">
          <InputLabel className="request-vacation-type-title">{t("calendar.vacation_type")}</InputLabel>
          <Select
            label={t("calendar.vacation_type")}
            name="type"
            value={vacationData.type}
            onChange={handleChange}
            variant="outlined"
            className="request-vacation-type-select"
            MenuProps={{
              PaperProps: {
                className: "request-vacation-type-select",
              },
            }}
          >
            <MenuItem value="RV">{t("calendar.vacation_type1")}</MenuItem>
            <MenuItem value="SL">{t("calendar.vacation_type2")}</MenuItem>
            <MenuItem value="PL">{t("calendar.vacation_type3")}</MenuItem>
            <MenuItem value="MPL">{t("calendar.vacation_type4")}</MenuItem>
          </Select>
        </FormControl>
        <div className="request-vacation-date-info">
          <Box className="request-vacation-date-picker">
            <DatePicker
              selected={vacationData.date_start}
              onChange={(date) => handleDateChange('date_start', date)}
              dateFormat="yyyy-MM-dd"
              selectsStart
              startDate={vacationData.date_start}
              endDate={vacationData.date_end}
              customInput={
                <TextField
                  label={t("global.start_date")}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ className: "request-vacation-type-title" }}
                  InputProps={{
                    className: "request-vacation-type-select",
                  }}
                />
              }
              locale={t("global.short_lng")}
            />
            <DatePicker
              selected={vacationData.date_end}
              onChange={(date) => handleDateChange('date_end', date)}
              dateFormat="yyyy-MM-dd"
              selectsEnd
              startDate={vacationData.date_start}
              endDate={vacationData.date_end}
              minDate={vacationData.date_start}
              customInput={
                <TextField
                  label={t("calendar.event_end_date")}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ className: "request-vacation-type-title" }}
                  InputProps={{
                    className: "request-vacation-type-select",
                  }}
                />
              }
              locale={t("global.short_lng")}
            />
          </Box>
          <Box className="request-vacation-info-box">
            <Typography variant="body1" className="request-vacation-info-title">
              <strong>{t("calendar.vacation_info")}</strong>
            </Typography>
            <Typography variant="body2" className="request-vacation-type-title">
              {t("calendar.vacation_per_year", { count: vacationDays.remaining_vacation_days })}
            </Typography>
            <Typography variant="body2" className="request-vacation-type-title">
              {t("calendar.vacation_rollovers", { count: vacationDays.rollover_days })}
              {vacationDays.rollover_days > 0 && (
                <Tooltip
                  title={<span dangerouslySetInnerHTML={{ __html: formattedHistory(vacationDays.rollover_days_history, t) }} />}
                >
                  <InfoIcon className="white-txt-color" />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2" className="request-vacation-type-title">
              {t("calendar.vacation_used_days", { count: vacationDays.used_vacation_days })}
            </Typography>
            <Typography variant="body2" className="request-vacation-type-title">
              {t("calendar.vacation_total_days", { count: vacationDays.total_available_days })}
            </Typography>
          </Box>
        </div>

        <div className="request-vacation-total-days">
          <Typography variant="subtitle1" className="request-vacation-total-count">
            {t("calendar.vacation_total_count", { count1: totalDays })}
          </Typography>
          <Typography variant="subtitle1">
            {t("calendar.vacation_total_count_weekdays", { count1: totalWeekdays})}
          </Typography>
        </div>

        {error && (
          <Typography variant="body2" className="request-vacation-error">
            {error}
          </Typography>
        )}
        <Box className="request-vacation-flex">
          <FormControlLabel
            control={
              <Switch
                checked={vacationData.visibility}
                onChange={handleVisibilityChange}
                name="visibility"
                color="primary"
              />
            }
            label={t("calendar.event_public")}
            className="request-vacation-total-count"
          />
          <FormControlLabel
            control={
              <Switch
                checked={vacationData.do_not_deduct}
                onChange={handleDeductabilityChange}
                name="visibility"
                color="primary"
              />
            }
            label={t("calendar.vacation_no_deduct")}
            className="request-vacation-total-count"
          />
        </Box>
        {(!vacationData.visibility || vacationData.do_not_deduct) && (
          <Box className="request-vacation-admins-info-box">
            {!vacationData.visibility && (
              <Typography variant="body2" className="request-vacation-type-title">
                {t("calendar.vacation_admins_info1")}
              </Typography>
            )}
            {vacationData.do_not_deduct && (
              <Typography variant="body2" className="request-vacation-total-count">
                {t("calendar.vacation_admins_info2")}
              </Typography>
            )}
          </Box>
        )}
        <Box className="buttons-flex request-vacation-buttons-box">
          <Button
              variant="outlined"
              className="decline-button request-vacation-cancel-button"
              onClick={handleClose}
          >
            {t("global.cancel")}
          </Button>
          <Button
            variant="contained"
            className="accept-button"
            onClick={handleSubmit}
            disabled={!!error}
          >
            {t("calendar.vacation_submit")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};


export default VacationRequestModal;

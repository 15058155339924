import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CalendarComponent from './CalendarComponent';
import TodayVisTimeline from './TodayVisTimeline';
import { Tabs, Tab, Box } from '@mui/material';
import VacationCalendar from './VacationCalendar';
import {useTranslation} from "react-i18next";
import "../../css/components/Calendar/TabbedCalendar.css";
import "../../css/main.css";

const TabbedView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceId } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('overview')) {
      setCurrentTab(0);
    } else if (path.includes('timeline')) {
      setCurrentTab(1);
    } else if (path.includes('vacations')) {
      setCurrentTab(2);
    }
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);

    if (newValue === 0) {
      navigate(`/workflow/${workspaceId}/calendar/overview`);
    } else if (newValue === 1) {
      navigate(`/workflow/${workspaceId}/calendar/timeline`);
    } else if (newValue === 2) {
      navigate(`/workflow/${workspaceId}/calendar/vacations`);
    }
  };
  return (
      <Box>
        <div className="tabbed-calendar-box">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={t("calendar.calendar")}
              className={currentTab === 0 ? 'tabbed-calendar-primary' : 'white-txt-color'}
            />
            <Tab
              label={t("calendar.calendar_today")}
              className={currentTab === 1 ? 'tabbed-calendar-primary' : 'white-txt-color'}
            />
            <Tab
              label={t("calendar.vacations")}
              className={currentTab === 2 ? 'tabbed-calendar-primary' : 'white-txt-color'}
            />
          </Tabs>
        </div>
      {currentTab === 0 && <CalendarComponent />}
      {currentTab === 1 && <TodayVisTimeline />}
      {currentTab === 2 && <VacationCalendar />}
      </Box>
  );
};

export default TabbedView;

import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useService } from 'contexts/ServiceContextProvider';
import { useTranslation } from "react-i18next";
import '../css/views/EditProject.css';
import '../css/main.css'

const EditProjectPage = () => {
 

  const location = useLocation();
  const { project } = location.state || {};
  const { t } = useTranslation();

  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectImage, setProjectImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [projectOwner, setProjectOwner] = useState('');
  const [workspaceID, setWorkspaceID] = useState('');
  const [removeImage, setRemoveImage] = useState(false);
  const { UpdateExistingProject } = useService();
  const [visibility, setVisibility] = useState(false);
  const [status, setStatus] = useState('ongoing');

  useEffect(() => {
    document.title = "Editing " + projectName + " - Workflow PRO";
  }, [projectName]);

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.checked);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (project) {
      setProjectName(project.name);
      setProjectDescription(project.desc);
      setPreviewImage(project.image ? project.image : null);
      setProjectOwner(project.owner);
      setWorkspaceID(project.workspace);
      setVisibility(project.visibility);
      setStatus(project.status); // Set the initial status
    }
  }, [project]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProjectImage(file);
    setRemoveImage(false);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetImage = () => {
    setProjectImage(null);
    setPreviewImage(null);
    setRemoveImage(true);
    document.getElementById("project-image-input").value = "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('name', projectName);
      formData.append('owner', projectOwner);
      formData.append('desc', projectDescription);
      formData.append('workspace', workspaceID);
      formData.append('visibility', visibility);
      formData.append('status', status); // Append status

      if (projectImage) {
        formData.append('image', projectImage);
      }
      if (removeImage) {
        formData.append('remove_image', 'true');
      }
      const response = await UpdateExistingProject(formData, project.id, workspaceID);

      if (response.status === 200) {
        window.location.href = `/workflow/index`;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'completed':
        return t('project.stat_completed');
      case 'ongoing':
        return t('project.stat_ongoing');
      case 'not_started':
        return t('project.stat_notstart');
      case 'archived':
        return t('project.stat_archived');
      default:
        return t('project.stat_notstart');

    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{t('project.edit')}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('project.name')}
              variant="outlined"
              fullWidth
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
              InputLabelProps={{
                className: "white-txt-color"
              }}
              inputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('project.desc')}
              variant="outlined"
              multiline
              fullWidth
              rows={4}
              value={projectDescription}
              onChange={(event) => setProjectDescription(event.target.value)}
              InputLabelProps={{
                className: "white-txt-color"
              }}
              InputProps={{
                className: "white-txt-color"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              id="project-image-input"
              type="file"
              onChange={handleImageChange}
              className="no-display"
            />
            <label htmlFor="project-image-input">
              <Button variant="contained" component="span" className="button-light-bg">
                {t('project.img')}
              </Button>
            </label>
            <Typography>{t('project.rec_image_size')}</Typography>
            {previewImage && (
              <div className="image-container">
                <img src={previewImage} alt="Preview" className="preview-image-size image-border" />
                <div className="overlay">
                  <button className="image-reset-button" onClick={resetImage}>{t("workspace.rem_image")}</button>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibility}
                  onChange={handleVisibilityChange}
                  color="primary"
                />
              }
              label={t('project.public')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className="form-control-edit-project">
              <InputLabel className="input-label-edit-project">
                {t('global.status')}
              </InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                MenuProps={{
                  PaperProps: {
                    className: "select-menu-edit-project",
                  },
                }}
              >
                <MenuItem value="not_started">{t('project.stat_notstart')}</MenuItem>
                <MenuItem value="ongoing">{t('project.stat_ongoing')}</MenuItem>
                <MenuItem value="completed">{t('project.stat_completed')}</MenuItem>
                <MenuItem value="archived">{t('project.stat_archived')}</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" className="current-stat-edit-project">
              {t('project.current_stat')} {getStatusLabel(project.status)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" className="button-light-bg">
              {t('global.save_changes')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditProjectPage;


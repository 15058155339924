import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css"

export default function ArchiveTaskModal({ openArchiveTaskModal, handleArchiveTaskModalClose, handleArchiveTask, avoidMisfire, listID }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openArchiveTaskModal} onClose={handleArchiveTaskModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {t("modal.archive_task")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleArchiveTaskModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleArchiveTask} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.archive")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
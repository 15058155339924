import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import workflowProIcon from '../assets/img/login/WorkFlow Pro icon.png';
import '../css/views/Register.css';
import {
  CardBody,
  FormGroup,
  Form,
} from 'reactstrap';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.jsx';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation to get query params
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import axios from 'axios';
import { useSnackbar } from 'contexts/SnackbarContextProvider';

const Register = () => {
  useEffect(() => {
    document.title = t('titles.register');
  }, []);
  const { t } = useTranslation();
  const { accessToken, login } = useAuthorization();
  const { showSnackbar } = useSnackbar();
  
  const location = useLocation(); // Get current URL location
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [emailLocked, setEmailLocked] = useState(false); // For locking email

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (accessToken) {
      navigate('/workflow/index');
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const invite_id = queryParams.get('invite_id');
    const emailFromUrl = queryParams.get('email'); 

    if (emailFromUrl) {
      setEmail(emailFromUrl);
      setEmailLocked(true); 
    }
  }, [location.search]);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidPassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;
    return passwordRegex.test(password);
  }

  const handleRegister = async () => {
    if (email !== '' && username !== '' && password !== '' && confirmPassword !== '' && fullName !== '') {
      if (!isValidEmail(email)) {
        showSnackbar(t("login.bad_email"), 'error');
        return;
      }
  
      if (password !== confirmPassword) {
        showSnackbar(t("login.password_mismatch"), 'error');
        return;
      }
  
      if (!isValidPassword(password)) {
        showSnackbar(t("login.bad_password_regex"), 'error');
        return;
      }
  
      try {
        const queryParams = new URLSearchParams(location.search);
        const invite_id = queryParams.get('invite_id');
  
        const registrationData = {
          email: email,
          username: username,
          password: password,
          full_name: fullName,
        };
  
        if (invite_id) {
          registrationData.invite_id = invite_id;
        }
  
        const response = await axios.post(`${BACKEND_URL}/users/register/`, registrationData);
  
        if (response.data["email"] === email) {
          try {
            await login(email, password);
          } catch (error) {
            console.error('Login failed:', error.message);
            navigate('/auth/login');
          }
        }
      } catch (error) {
        console.error('Register failed:', error);
        
        if (error.response && error.response.data && error.response.data.detail) {
          const errorMessage = error.response.data.detail;
          
          if (errorMessage === "user with this username already exists.") {
            showSnackbar(t("login.username_exists"), 'error');
          } else if (errorMessage === "user with this email already exists.") {
            showSnackbar(t("login.email_exists"), 'error');
          } else if (errorMessage === "This invitation has expired.") {
            showSnackbar(t("login.invitation_expired"), 'error');
          } else if (errorMessage === "This invitation has already been accepted by a user.") {
            showSnackbar(t("login.invitation_already_accepted"), 'error');
          } else if (errorMessage === "The email provided does not match the invitation email.") {
            showSnackbar(t("login.invitation_email_mismatch"), 'error');
          } else {
            showSnackbar(t("login.register_fail"), 'error'); 
          }
        } else {
          showSnackbar(t("login.register_fail"), 'error');
        }
      }
    } else {
      console.error('Register failed. Please fill in all fields');
      showSnackbar(t("login.please_fill"), 'error');
      return;
    }
  };
  

  const handleClickShowPassword = () => {
    const currentCursorPos = inputRef.current.selectionStart; 
    setShowPassword(!showPassword); 
    setTimeout(() => {
      inputRef.current.setSelectionRange(currentCursorPos, currentCursorPos); 
    }, 0); 
  };

  const handleClickShowConfirmPassword = () => {
    const currentCursorPos = inputRef2.current.selectionStart;
    setShowConfirmPassword(!showConfirmPassword); 
    setTimeout(() => {
      inputRef2.current.setSelectionRange(currentCursorPos, currentCursorPos);
    }, 0); 
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <>
      <div className="register-background">
        <CustomCookieConsent />
        <div className="register-container">
          <div className="register-card">
            <CardBody>
              <div className="register-header">
                <div
                    onClick={handleClick}
                    className="register-back"
                    aria-label="back"
                >
                  <IconButton onClick={handleClick} aria-label="back"
                    className="register-back-icon">
                    <ArrowBackIcon />
                  </IconButton>
                  <span className="register-back-icon-margin">{t("login.sign_up")}</span>
                </div>
              </div>
              <div className="register-form-container">
                <div className="register-logo">
                  <img src={workflowProIcon} alt="Workflow Pro" />
                </div>
                <Form role="form" className="register-form">
                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.fullName_placeholder")}
                      name="fullname"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={fullName}
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      onChange={(e) => setFullName(e.target.value)}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.username_placeholder")}
                      name="username"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={username}
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      onChange={(e) => setUsername(e.target.value)}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.email")}
                      name="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={email}
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        readOnly: emailLocked, // Make the email field read-only if it's locked
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-1">
                    <TextField
                      label={t("login.password_placeholder")}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={inputRef}
                    />
                  </FormGroup>

                  <FormGroup>
                    <TextField
                      label={t("login.confirm_password_placeholder")}
                      name="password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ style: { color: '#78909c' } }}
                      InputProps={{
                        style: { color: 'black', backgroundColor: '#ffffff', borderRadius: '0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={inputRef2}
                    />
                  </FormGroup>

                  <div className="register-text-center">
                    <Button
                      onClick={handleRegister}
                      className="register-custom-button"
                    >
                      {t("login.sign_up")}
                    </Button>
                  </div>
                  <div className="register-text-center register-footer-links">
                    <small className="footer-link">
                      {t("login.already_have_acc")} &nbsp;
                      <a href="/auth/login">{t("login.sign_in")}</a>
                    </small>
                    <small className="footer-link">
                      <a href="/auth/forgot-password">{t("login.forgot_password")}</a>
                    </small>
                  </div>
                </Form>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

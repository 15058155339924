import React, { useState, useEffect, useRef } from 'react';  // Add useRef for the chat container reference
import { Modal, Box, Typography, Divider, TextField, Button, CircularProgress, IconButton } from '@mui/material';
import { useService } from 'contexts/ServiceContextProvider';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CloseIcon from '@mui/icons-material/Close';

const statusColors = {
    open: '#4caf50',
    in_progress: '#ff9800',
    resolved: '#2196f3',
    closed: '#9e9e9e'
};

const priorityColors = {
    low: '#4caf50',
    medium: '#ffeb3b',
    high: '#f44336',
    urgent: '#9c27b0'
};

const formatDate = (isoString, timezone = 'GMT') => {
    const date = new Date(isoString);
    return date.toLocaleString('en-US', { timeZone: timezone });
};



const TicketDetailsModal = ({ ticket, open, onClose, user, read_only, getStatusLabel, getPriorityLabel, getTypeLabel, canReopen, ReopenTicket }) => {
    const { GetTicketChat, SendTicketMessage } = useService();
    const [chatMessages, setChatMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState('');
    const [attachment, setAttachment] = useState(null);  // State to handle file attachment
    const [sending, setSending] = useState(false);

    const chatContainerRef = useRef(null);  // Reference for the chat container

    const handleReopenTicket = async (ticket) => {
        try {
            await ReopenTicket(ticket.id); // Call the ReopenTicket service
            window.location.reload();

        } catch (error) {
            console.error("Error reopening ticket:", error);
        }
    };

    useEffect(() => {
        const fetchTicketChat = async () => {
            try {
                const response = await GetTicketChat(ticket.id);
                setChatMessages(response);
            } catch (error) {
                console.error("Error fetching ticket chat:", error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchTicketChat();
        }
    }, [ticket.id, open, GetTicketChat]);

    // Scroll to the bottom of the chat container whenever chatMessages change
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleSendMessage = async () => {
        if (newMessage.trim() === '' && !attachment) {
            return;  // Don't send an empty message or an empty attachment
        }

        setSending(true);

        const formData = new FormData();  // Use FormData for file uploads
        formData.append('message', newMessage);  // Add the message to FormData
        if (attachment) {
            formData.append('attachment', attachment);  // Add the file to FormData, if any
        }

        try {
            const response = await SendTicketMessage(ticket.id, formData);  // Send the FormData
            setChatMessages((prevMessages) => [...prevMessages, response.data]);  // Update the chat messages

            setNewMessage('');  // Clear the input field after sending
            setAttachment(null);  // Clear the attachment after sending
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setSending(false);
        }
    };

    const handleFileChange = (e) => {
        setAttachment(e.target.files[0]);  // Set the selected file as attachment
    };

    const handleViewAttachment = (attachmentUrl) => {
        window.open(attachmentUrl, '_blank');
    };

    const handleRemoveAttachment = () => {
        setAttachment(null);  // Remove the selected attachment
    };


    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box className="modal-ticket-support">
                    <Box className="modal-ticket-chat-support">
                        <Typography variant="h6" sx={{ color: '#a1989e' }}>Ticket Chat</Typography>
                        <Divider className="modal-ticket-divider-support" />
                        {loading ? (
                            <CircularProgress sx={{ color: '#a1989e' }} />
                        ) : (
                            <Box className="modal-ticket-chat-content-support" ref={chatContainerRef}>
                                {chatMessages.length > 0 ? (
                                    chatMessages.map((message) => (
                                        <Box key={message.id} className="modal-ticket-margin-bottom-support">
                                            <Typography variant="body2" sx={{ color: '#a1989e' }}>
                                                <strong>{message.user_name}</strong> - {formatDate(message.timestamp, user.timezone)}
                                            </Typography>
                                            <Typography className="white-txt-color modal-ticket-details-text-support">{message.message}</Typography>
                                            {message.attachment && (
                                                <Box className="modal-ticket-message-support">
                                                    <AttachEmailIcon className="modal-ticket-attachment-icon-support" />
                                                    <Button
                                                        onClick={() => handleViewAttachment(message.attachment)}
                                                        sx={{ color: '#a1989e' }}
                                                    >
                                                        - VIEW ATTACHMENT
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    ))
                                ) : (
                                    <Typography>No chat messages yet.</Typography>
                                )}
                            </Box>
                        )}
                        {!read_only && (
                            <Box className="modal-ticket-margin-top-support">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Type your message"
                                    multiline
                                    rows={3}
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    sx={{
                                        backgroundColor: '#3c393b',
                                        input: { color: '#ffffff' },
                                    }}
                                    InputProps={{
                                        className: "request-vacation-type-select"
                                    }}
                                />

                                <Box className="modal-ticket-button-box-support">
                                    <Box className="modal-ticket-attach-file-button-support">
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            accept="image/*"
                                            className="no-display"
                                            id="attachment-input"
                                        />
                                        <label htmlFor="attachment-input">
                                            <Button variant="outlined" component="span" className="white-txt-color">
                                                {attachment ? 'Change File' : 'Choose File'}
                                            </Button>
                                        </label>

                                        {attachment && (
                                            <Box className="modal-ticket-attachments-support">
                                                <Typography
                                                    className="modal-ticket-attachments-text-support"
                                                >
                                                    {attachment.name}
                                                </Typography>
                                                <IconButton onClick={handleRemoveAttachment} sx={{ color: '#f44336' }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>

                                    {/* Send button aligned to the right */}
                                    <Box className="modal-ticket-send-message-button-text-support">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSendMessage}
                                            disabled={sending}
                                            className="button-dark-bg"
                                        >
                                            {sending ? 'Sending...' : 'Send Message'}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        <Box className="modal-ticket-info-support">
                            {ticket.status === 'resolved' && canReopen(ticket) ? (
                                <Box>
                                    <Typography className="modal-ticket-info-text-support">
                                        This ticket can be reopened within 30 days of being resolved.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleReopenTicket(ticket)}
                                        sx={{ backgroundColor: '#4caf50' }}
                                    >
                                        Reopen Ticket
                                    </Button>
                                </Box>
                            ) : ticket.status === 'closed' ? (
                                <Typography className="modal-ticket-info-text-support">
                                    This ticket has been resolved and can no longer be modified.
                                </Typography>
                            ) : null}
                        </Box>

                    </Box>

                    <Divider orientation="vertical" flexItem sx={{ borderColor: '#a1989e' }} />

                    <Divider className="modal-ticket-divider-support"/>

                    <Box className="modal-ticket-details-support">
                        <Typography variant="h6" sx={{ color: '#a1989e' }}>Ticket Details</Typography>
                        <Divider className="modal-ticket-divider-support" />
                        <Typography className="modal-ticket-details-text-support"><strong>Title:</strong> {ticket.title}</Typography>
                        <Typography className="modal-ticket-details-text-support"><strong>Description:</strong> {ticket.description}</Typography>
                        <Typography><strong>Status:</strong>
                            <span style={{ color: statusColors[ticket.status] }}>{getStatusLabel(ticket.status)}</span>
                        </Typography>
                        <Typography><strong>Priority:</strong>
                            <span style={{ color: priorityColors[ticket.priority] }}>{getPriorityLabel(ticket.priority)}</span>
                        </Typography>
                        <Typography><strong>Type:</strong> {getTypeLabel(ticket.type)}</Typography>
                        <Typography><strong>Created At:</strong> {formatDate(ticket.created_at, user.timezone)}</Typography>
                        <Typography><strong>Updated At:</strong> {formatDate(ticket.updated_at, user.timezone)}</Typography>
                        {ticket.assigned_to && (
                            <Typography><strong>Assigned To:</strong> {ticket.assigned_to_name}</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default TicketDetailsModal;

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import "../../css/main.css";
import "../../css/components/Modals/UnarchiveTask.css";

export default function UnarchiveTaskModal({
    openUnarchiveTaskModal,
    handleUnarchiveTaskModalClose,
    selectedTask,
    selectedList,
    avoidMisfire,
    availableLists,
    handleUnarchiveTask
}) {
    const { t } = useTranslation();

    const [selectedListId, setSelectedListId] = useState('');
    const [listWarning, setListWarning] = useState('');

    // Filter the available lists to include only those that are not soft deleted
    const filteredLists = availableLists?.filter(list => !list.is_deleted);
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (selectedList && selectedList.id && filteredLists?.some(list => list.id === selectedList.id)) {
            setSelectedListId(selectedList.id);
            setListWarning('');
        } else if (filteredLists?.length > 0) {
            setSelectedListId(filteredLists[0].id);
            setListWarning(t("modal.list_not_available"));
        }
    }, [selectedList, filteredLists]);

    const [retainPosition, setRetainPosition] = useState(true);
    const [newPosition, setNewPosition] = useState(null);

    const handleListChange = (event) => {
        setSelectedListId(event.target.value);
    };

    const handleRetainPositionChange = (event) => {
        setRetainPosition(event.target.checked);
    };

    const handleNewPositionChange = (event) => {
        const selectedList = filteredLists.find(list => list.id === selectedListId);
        const maxPosition = selectedList ? selectedList.unarchived_task_count + 1 : 1;
        const value = Math.min(event.target.value, maxPosition);
        setNewPosition(value);
    };

    const handleUnarchive = async () => {
        handleUnarchiveTask(selectedTask.id, selectedListId, retainPosition, newPosition);
        handleUnarchiveTaskModalClose();
    };

    return (
        <Dialog open={openUnarchiveTaskModal} onClose={handleUnarchiveTaskModalClose} className="modal-background-color">
            <DialogTitle>{t("modal.task_unarchive_title")}</DialogTitle>
            <DialogContent className="white-txt-color">
                {listWarning && (
                    <Typography color="error" gutterBottom>
                        {listWarning}
                    </Typography>
                )}
                <FormControl fullWidth margin="normal">
                    <InputLabel className="white-txt-color">{t("modal.list")}</InputLabel>
                    <Select
                        value={selectedListId || ''}
                        onChange={handleListChange}
                        className="unarchive-task-list-select"
                        MenuProps={{
                            PaperProps: {
                                className: "unarchive-task-menu-props"
                            }
                        }}
                    >
                        {filteredLists?.map(list => (
                            <MenuItem key={list.id} value={list.id}>
                                {list.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={retainPosition}
                            onChange={handleRetainPositionChange}
                            color="primary"
                        />
                    }
                    label={t("modal.retain_position")}
                />
                {!retainPosition && (
                    <TextField
                        margin="normal"
                        label={t("modal.new_position")}
                        type="number"
                        fullWidth
                        value={newPosition || ''}
                        onChange={handleNewPositionChange}
                        InputLabelProps={{ className: "white-txt-color" }}
                        InputProps={{ className: "white-txt-color" }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUnarchiveTaskModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleUnarchive} disabled={avoidMisfire} className="button-dark-bg">
                    {t("modal.unarchive")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
